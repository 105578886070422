<template>
  <section>
    <transition-group name="list" tag="div" class="hippo_logs">
      <article v-for="item in items" :key="item.id">
        <header>
          <span class="title">{{ item.sent ? '✅' : '❌' }} <b>{{ item.transport }} </b>{{ item.user.firstname }} {{ item.user.lastname }}</span>
          <span class="date">{{ item.at | format('d MMM à H:m:s')}}</span>
        </header>
        <div class="item-content">
          <div>
            {{ item.tpl.action }} ({{ item.tpl.user }})
          </div>
          <div class="hippo-mail hippo-preview" @click="selected = item" v-if="item.notif">
            <div v-if="item.transport === 'email'" v-html="item.notif.html"></div>
            <div v-if="item.transport === 'sms'" class="hippo-sms">
              <pre>{{ item.notif }}</pre>
            </div>
            <div v-if="item.transport === 'manychat'" class="hippo-manychat">
              <div class="many-text">{{ item.notif.text }}</div>
              <button class="many-action" v-if="item.notif.buttons && item.notif.buttons.length" v-for="button in item.notif.buttons">{{ button.caption}}</button>
            </div>
            <div v-if="item.transport === 'push'" class="hippo-push">
              <img :src="item.notif.icon"/>
              <div>
                <header><b>{{ item.notif.title }}</b></header>
                <p>{{ item.notif.body }}</p>
              </div>
            </div>
          </div>
          <div class="hippo-mail hippo-preview" v-else>
            <pre>{{ item.err }}</pre>
          </div>
        </div>
        <footer>{{item.batch}} / {{item.id}}</footer>
      </article>

      <article ref="observable" style="background-color: transparent;" key='observer'>
        <spinner v-if="loading " style="transform: scale(0.2, 0.2);"></spinner>
      </article>
    </transition-group>

    <sweet-modal ref="viewer" v-if="selected" @close="selected = null">
      <div v-if="selected.transport === 'email'" class="hippo-email">
        <div style="float: right;">
          <label for="html">
            <input id="html" type="radio" v-model="radio" value="html">html
          </label>
          <label for="text">
            <input id="text" type="radio" v-model="radio" value="text">text
          </label>
        </div>
        <div>subject : <i>{{ selected.notif.subject }}</i></div>
        <div>from : <i>{{ selected.notif.from || 'aucun' }}</i> to : <i>{{ selected.notif.to || 'aucun' }}</i> cc: <i>{{ (cc.length && cc.join(', ')) || 'aucun' }}</i></div>
        <div v-if="radio === 'html'" v-html="selected.notif.html" class="thumb"></div>
        <pre v-if="radio === 'text'" class="hippo-email">
        {{ selected.notif.text }}
        </pre>
        <div class="attachments">
          <header>Pieces Jointes</header>
          <ul v-for="attachment in selected.notif.attachments">
            <li :key="attachment.href">
              <a :href="attachment.href">{{attachment.href.split('/').pop()}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="selected.transport === 'sms'" class="hippo-sms">
        <pre>{{ selected.notif }}</pre>
      </div>
      <div v-if="selected.transport === 'manychat'" class="hippo-manychat">
        <div class="many-text">{{ selected.notif.text }}</div>
        <button class="many-action" v-if="selected.notif.buttons && selected.notif.buttons.length" v-for="button in selected.notif.buttons">{{ button.caption}}</button>
      </div>
      <div v-if="selected.transport === 'push'" class="hippo-push">
        <img :src="selected.notif.icon"/>
        <div>
          <header><b>{{ selected.notif.title }}</b></header>
          <p>{{ selected.notif.body }}</p>
        </div>
      </div>
    </sweet-modal>
  </section>
</template>
<script>
import Notify from '/notify'
import Recruiter from '/user/recruiter/recruiter.entity'
import { SweetModal } from 'sweet-modal-vue'
import Spinner from '/layout/spinner'

const gtag = new RegExp('src\=\"https\://www\.google\-analytics\.com/.*\"')

export default {
  name: 'hippolyte.notify.history',
  components: { SweetModal, Spinner },
  data () {
    return {
      items: [],
      selected: null,
      radio: 'html',
      loading: false,
      offset: 0,
      observer: null
    }
  },
  watch: {
    selected (val, old) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.viewer.open()
        })
      }
    }
  },
  mounted () {
    this.$socket.sub(
      `/system/notify`,
      'NOTIFY/HISTORY',
      async ({ body }) => this.items.unshift(await this.getId(body.id))
    )
    this.$nextTick(() => {
      this.observer = new IntersectionObserver(this.onReachBottom.bind(this), { root: this.$parent.$el})
      this.observer.observe(this.$refs.observable)
    })
  },
  methods: {
    async load () {
      if (this.loading) {
        return this.loading
      }
      this.isLoading = true
      try {
        this.loading = this.$socket.service(
          'notify/LIST',
          {
            offset: this.items.length,
            limit: 10
          }
        ).then(list => list.map(data => {
          if (data.transport === 'email' && data.notif && data.notif.html) {
            data.notif.html = data.notif.html.replace(gtag, '')
          }
          return data
        }))
        this.items.push(...await this.loading)
      } catch (err) {
        console.log(err)
      }
      this.loading = null
      this.isLoading = false
    },
    getId (id) {
      return this.$socket.service('notify/GET', { id })
    },
    async onReachBottom (entries, observer) {
      const entrie = entries.find(e => e.target === this.$refs.observable)
      if (entrie?.isIntersecting) {
        if (await this.load()) {
          this.resetObserver()
        }
      }
    },
    resetObserver () {
      if (this.$refs.observable && this.$refs.observable instanceof Element) {
        this.observer.unobserve(this.$refs.observable)
        this.observer.observe(this.$refs.observable)
      }
    }
  },
  computed: {
    cc () {
      if (this.selected?.cc) {
        if (typeof this.selected.cc === 'string') {
          return [this.selected.cc]
        }
        if (Array.isArray(this.selected.cc)) {
          return this.selected.cc
        }
      }
      return []
    }
  },
  destroyed () {
    this.$socket.unsub('/system/notify', 'NOTIFY/HISTORY')
    this.observer.disconnect()
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
section
  margin 1em
  height calc(100% - 2em)
  overflow-y scroll
  .hippo_logs
    article
      margin 1em
      color $color-astronaut
      border-radius 0.2em
      border 1px solid $color-astronaut
      background-color $color-blue_lighter
      header
        display flex
        flex-direction row
        justify-content space-between
        margin 0.4em 1em
        .date
          font-size 0.8em
      .item-content
        margin 1em
        line-height 1.5em
        display flex
        flex-direction row
        align-items center
        justify-content space-between
        overflow hidden
        .hippo-preview
          max-height 100px
          cursor pointer
          &.hippo-mail
            transform scale(0.2)
            transform-origin top right
          &.hippo-sms, &.hippo-manychat, &.hippo-push
            max-width 300px
            font-size 0.6em
            line-height 0.8em
  .hippo-sms
    background-color $color-blue_grey_light
    border-radius 10px
    padding 0.4em
  .many-text
    background-color $color-gray_manychat
    border-radius 8px
    padding 0.4em
    margin 0.8em
  .many-action
    background-color $color-gray_manychat
    border-radius 8px
    box-shadow 0 4px 8px 0 rgba(0, 0, 0, 0.2)
    margin 0.8em
    float right
  .hippo-push
    padding 0.4em
    display flex
    flex-direction row
    background-color #333
    color white
    border-radius 0
  pre
    white-space pre-wrap
  .list-enter-active, .list-leave-active
    transition all 1s
    max-height 160px
  .list-enter, .list-leave-to
    opacity 0
    max-height 0px
  .attachments
    header, ul
      display inline-block
    ul
      list-style none
      margin 0.2em
      padding 0
      li
        display inline-block
        a
          text-decoration none
          color $color-astronaut

</style>
<style lang="stylus">
section
  .sweet-content
    text-align left !important
</style>
