<template>
  <span class="survey">
    <button @click="onOpen" class="survey-campaign-btn">
      <icon-satisfaction
        class="satisfaction-survey"
        :title="'envoyer l\'enquête de satisfaction'"
        ></icon-satisfaction>
    </button>
    <sweet-modal ref="modal" width="40%">
      <header>
        <p> Envoi du mail de satisfaction pour la campagne:  </p>
        {{ campaign.name }}
        <p v-if="campaign.surveyDate"> Une enquête a été envoyé le {{ getSurveyDate() }}</p>
      </header>
      <spinner v-if="sending" style="transform: scale(0.2, 0.2);"></spinner>
      <span v-if="error || sent" id="message" :class="[error ? 'error' : 'green']">{{ message() }}</span>

      <form @submit.prevent="send">
        <button v-on:click.prevent="addEmail">Ajouter un destinataire</button>
        <span class='label'>
          <span>Adresses mails</span>
          <span>Prénoms</span>
        </span>
        <span v-for="({ email }, i) in accountList" class='input-line' :key="email">
          <input type="email" v-model.lazy="accountList[i].email" :id="`email${i}`" required>
          <input type="text" v-model.lazy="accountList[i].firstname" :id="`firstname${i}`">
          <span v-on:click="deleteEmail(email)" >
            <icon-remove
              :title="'Supprimer le destinataire'"
            ></icon-remove>
          </span>
        </span>
        <div class="button-container">
          <button :disabled="sent">Envoyer</button>
        </div>

      </form>
    </sweet-modal>
  </span>
</template>

<script>
import Campaign from '/campaign/entity'
import Spinner from '/layout/spinner'
import { SweetModal } from 'sweet-modal-vue'
import { add, format, parseISO } from 'date-fns'
import cancel from '/cancel.mixin.js'
import IconEdit from '/icons/edit'
import IconAdd from '/icons/add'
import IconRemove from '/icons/remove'
import IconSatisfaction from '/icons/satisfaction'


export default {
  name: 'hippolyte.campaign.survey',
  components: {
    SweetModal,
    IconEdit,
    Spinner,
    IconAdd,
    IconRemove,
    IconSatisfaction
  },
  mixins: [cancel],
  props: {
    campaign: Campaign,
  },
  data () {
    return {
      error: false,
      sending: false,
      sent: false,
      accountList: this.campaign['recruiter.detail'].docs[0]['recruiter.mail'].docs.map(a => {
        if (a.email && a.firstname) {
          return {
            email: a.email,
            firstname: a.firstname,
            country: this.campaign['recruiter.detail'].docs[0].country.toLowerCase()
          }
        }
      })
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    message() {
      return this.error ? 'L\'enquête n\'a pas pu être envoyée !' : 'L\'enquête a bien été envoyée !'
    },
    getSurveyDate () {
      return format(this.campaign.surveyDate, 'dd/MM/yyyy')
    },
    async changeSurveyDate (field, value) {
      try {
        this.cancel('date')
        const params = {
          field,
          id: this.campaign.id,
          value: add(value, { minutes: -value.getTimezoneOffset() }).toISOString()
        }
        await Campaign.api(this.$socket)
          .update(params, this.token('date'))
          .then(r => Object.assign(this.campaign, { [r.data.field] : (r.data.value instanceof Date) ? parseISO(r.data.value) : r.data.value }))
        this.cancel('date', null)
      } catch (err) {
        this.handleCancel(err)
      }
      this.$emit('update', value)
    },
    async send () {
      this.sending = true
      this.error = false
      await this.$socket.service('survey/SEND', this.accountList)
        .then(() => {
          this.changeSurveyDate('surveyDate', new Date)
          this.sent = true
        },
        error => {
          console.log(error)
          this.error = true
        })
      this.sending = false
    },
    onOpen () {
      this.$refs.modal.open()
    },
    deleteEmail (email) {
      this.accountList = this.accountList.filter(a => a.email !== email)
    },
    addEmail () {
      this.accountList.unshift({
        email: '',
        firstname: ''
      })
    },
    update (index, value) {
      this.accountList[index] = value
    }
  },
}
</script>
<style scoped lang="stylus">
@require '~/colors.styl'
.survey
  .survey-campaign-btn
    background none
    .satisfaction-survey
      padding-left 0.2em
    &:hover svg
      fill $color-iceberg
      transform scale(1.2)
  .sweet-modal-overlay
    z-index 1499
  .sweet-content-content
    header
      padding-bottom 1em
      text-align center
    #message
      display flex
      justify-content center
    &>.error
      color $color-pink
    &>.green
      color $color-green
    form
      display flex
      flex-direction column
      .label
        display flex
        justify-content space-around
      button
        align-self center
        width 8em
        margin 0.5em
        border-radius 10px
        background-color $color-green
        color $color-white
        &:disabled
          background-color $color-blue_grey_light
      .button-container
        display flex
        align-self center
      .input-line
        display flex
        align-items center
        justify-content center
        padding-bottom 1em
        input, textarea
          border 1px solid $color-input-border
          border-radius 4px
        input
          height 32px
          font-size 14px
          width 40%
        svg
          padding-left 1em
      ul
        list-style none
</style>
