<template>
  <form @submit.prevent="save">
    <table>
      <thead>
        <tr>
          <td>recherche</td>
          <td>champ personnalisé</td>
          <td>expiration</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="search in recruiter.search.docs" :key="search.id" v-if="config[search.id]">
          <td>{{searchName(search)}}</td>
          <td><input v-model="config[search.id].fieldname" @change="onUpdate"/></td>
          <td><input v-model="config[search.id].expires" @change="onUpdate"/></td>
          <td><button @click.prevent="remove(search)">Supprimer</button></td>
        </tr>
        <tr v-if="recruiter.search.docs.filter(s => !config[s.id]).length">
          <td>
            <select v-model="prompt.search">
              <template v-for="search in recruiter.search.docs" v-if="!config[search.id]">
                <option :value="search" :key="search.id">{{searchName(search)}}</option>
              </template>
            </select>
          </td>
          <td><input v-model="prompt.fieldname" @change="onUpdate"/></td>
          <td><input v-model="prompt.expires" @change="onUpdate"/></td>
          <td><button @click.prevent="add">Ajouter</button></td>
      </tbody>
    </table>
    <button type="submit">Enregistrer</button>
  </form>
</template>
<script>
import Recruiter from '/user/recruiter/recruiter.entity'
export default {
  name: 'hippolyte.services.audio.config',
  props: {
    value: Object,
    recruiter: Recruiter
  },
  watch: {
    value () {
      this.config = this.value
    }
  },
  data () {
    return {
      prompt: {
        search: null,
        fieldname: 'custom_field_audio_presentation',
        expires: '1d'
      },
      config: JSON.parse(JSON.stringify(this.value))
    }
  },
  methods: {
    onUpdate () {
      this.$emit('input', this.config)
    },
    searchName (search) {
      return [search.title, search.trade.docs[0]?.name]
        .filter(i => i)
        .join(' - ')
    },
    add () {
      this.config[this.prompt.search.id] = {
        fieldname: this.prompt.fieldname,
        expires: this.prompt.expires
      }
      this.prompt = {
        search: null,
        fieldname: 'custom_field_audio_presentation',
        expires: '1d'
      }
    },
    remove (search) {
      this.config[search.id] = null
      this.$forceUpdate()
    },
    save () {
      this.$emit('input', this.config)
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
form
  padding 1em
input[type="checkbox"]
  height auto
button
  background white
  border none
  color black
  border-radius 2em
  line-height 1em
  margin 0.2em 0
  padding 0.4em 1.5em
  font-size 0.9em
  cursor pointer
  text-decoration none
  &::first-letter
    text-transform capitalize
  &:focus, &:hover
    outline none
  &.invert
    color white
    border 1px white solid
  &:disabled
    color #ccc
    cursor default
</style>
