import Candidate from '/user/candidate/candidate.entity'
import Recruiter from '/user/recruiter/recruiter.entity'

/** @exemple
 * this.profiles
 *   .load(this.user.id)
 *   .then(profile => this.profile = profile)
 */
// @todo refresh on profile update
class Profiles {
  constructor (Model, socket) {
    this.Model = Model
    this.socket = socket
    this.cache = new Map()
  }

  load (...ids) {
    // @todo subscribe to update
    const toLoad = ids.map(id => {
      id = String(id)
      if (!this.cache.has(id)) {
        const profile = new this.Model({ id }, this.socket)
        this.cache.set(id, profile)
        return profile
      }
    }).filter(p => p)
    this.Model.loadIds(toLoad, this.socket)
    const ret = ids.map(id => this.cache.get(id))
    return ret.length === 1 ? ret[0] : ret
  }
  find (id) {
    if (this.cache.has(id)) {
      return this.cache.get(id)
    }
  }
}

export class RecruiterProfiles extends Profiles {
  constructor (socket) {
    super(Recruiter, socket)
  }
}

export class CandidateProfiles extends Profiles {
  constructor (socket) {
    super(Candidate, socket)
  }
}
