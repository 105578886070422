<template>
  <ul class="expenses">
    <li v-for="(expense,i) in expenses" :key="expense.type+i">
      <component v-if="$options.components[expense.type]" :is="expense.type"></component>
      <span v-else>{{expense.type}} -</span> {{ Intl.NumberFormat('fr-FR').format(expense.price / 100) }}€
      <small v-if="expense.impressions"> - impressions: {{ Intl.NumberFormat('fr-FR').format(expense.impressions) }}</small>
      <small v-if="expense.coverage">{{ expense.impressions ? '/' : '-' }} couverture: {{ Intl.NumberFormat('fr-FR').format(expense.coverage) }}</small>
      <small v-if="expense.clicks"> {{ (expense.impressions || expense.coverage) ? '/' : '-' }} clics: {{ Intl.NumberFormat('fr-FR').format(expense.clicks) }}</small>
      <span v-if="modal" v-on:click="deleteExpense(expense)">
        <icon-remove
          :title="'Supprimer la source de dépense'"
        ></icon-remove>
     </span>
    </li>
  </ul>
</template>

<script>
import TikTok from '/icons/social/tiktok'
import Indeed from '/icons/social/indeed'
import LinkedIn from '/icons/social/linkedin'
import Facebook from '/icons/social/facebook'
import Twitter from '/icons/social/twitter'
import Snapchat from '/icons/social/snapchat'
import IconRemove from '/icons/remove'

export default {
  name: 'hippolyte.campaign.expenseList',
  components: {
    TikTok,
    Indeed,
    Facebook,
    Twitter,
    Snapchat,
    LinkedIn,
    IconRemove
  },
  props: {
    expenses: Array,
    modal: Boolean || false,
    deleteExpense: Function
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
small
  color $color-grey

</style>