<template>
  <ats
     v-bind="$props"
    service="gestmax"
    :savingConf="savingConf"
    :credential="{ url: '', username: '', password: '' }"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="url">url API</label>
        <input v-model="credential.url" id="url" name="url"/>
      </div>
      <div>
        <label for="username">user API</label>
        <input v-model="credential.username" id="username" name="username"/>
        <modified :enable="value.credential.url !== credential.url"/>
      </div>
      <div>
        <label for="password">password API</label>
        <input v-model="credential.password" id="password" name="password"/>
        <modified :enable="value.credential.password !== credential.password"/>
      </div>
      <div>
        <label for="media">media id</label>
        <input v-model="credential.media" id="media" name="media"/>
        <modified :enable="value.credential.media !== credential.media"/>
      </div>
    </template>
    <template v-slot:title="{ job }">{{ title(job) }}</template>
    <template v-slot:open="{ job }"><a class="button" :href="job.vacancy_detail_url" target="_blank" title="ouvrir">👁️</a></template>
  </ats>
</template>
<script>
import Ats from './sdk-v3'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.gestmax.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    title (job) {
      return  [
        job.id || job.reference,
        job.vacancy_title || job.hippolyte?.search.title,
        job.vac_ville || job.vac_localisation || job.hippolyte?.search.location?.docs[0]?.city
      ].filter(t => t).join(' - ')
    },
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
