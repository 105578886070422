import { download } from '/fun'

async function Csv (data) {
  const csv = data
    .map(line => line.map(cell => `"${cell}"`).join(';'))
    .join('\n')
  download(
    new Blob(['\ufeff', csv], { type: 'text/plain;charset=utf8' }),
    `Hippolyte_rh_${Date.now()}.csv`
  )
}

export { Csv }
