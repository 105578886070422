<template>
  <div>
    <label :for="name">Trade</label>
    <treeselect
      v-model="value"
      :id="name"
      :name="name"
      :defaultOptions="modelValue"
      :valueFormat="'object'"
      :multiple="false"
      :async="true"
      :load-options="getTrades"
      :loadingText="'Recherche en cours ...'"
      :searchPromptText="'Ecrivez pour lancer la recherche ...'"
      :noResultsText="'Aucun résultats ...'"
      :normalizer="normalizer"
      placeholder="Forcer le Trade"
      @input="input"
      ></treeselect>
    <modified :enable="original !== value"></modified>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Trade from '/trade/entity'
import cancel from '/cancel.mixin.js'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.ats.filter.tradeSelect',
  components: {
    Treeselect, Modified
  },
  mixins: [cancel],
  props: {
    modelValue: Object,
    name: String
  },
  data () {
    return {
      value: this.modelValue,
      original: this.modelValue
    }
  },
  methods: {
    input (v) {
      this.value = v ? { id: v.id, name: v.name, rome: v.rome } : null
      this.$emit('input', this.value)
    },
    normalizer (trade) {
      return {
        id: trade.id,
        label: trade.name
      }
    },
    async getTrades ({ searchQuery: query, callback }) {
      this.cancel('trade')
      const { docs: list } = await Trade.list({ query }, this.$socket, { cancel: this.token('trade') })
      this.cancel('trade', null)
      const results = list.map(t => ({
        id : t.id,
        name: t.name,
        rome: t.rome
      }))
      callback(null, results)
    },
  }
}
</script>
<style lang="stylus" scoped>
div
  background white
  margin 0.4em 0
  border-radius 4px
  align-items center
  label
    margin-right 1em
    text-align right
    margin-top auto
    margin-bottom auto
  select
    background #eee
    border-radius 0 4px 4px 0
    font-size 1em
    line-height 1.5em
    border 1px #eee solid
    &:focus
      border 1px #ccc solid
</style>