<template>
  <div>
    <help :title="'Répartition des statuts'">
      <template v-slot:content>
        <p>Les statistiques se basent sur les dates de changements de statuts et non de création de la conciliation.</p>
        <p>Pensez à utiliser les datepicker (en haut à droite) si vous souhaitez voir la répartition des statuts sur une autre période.</p>
      </template>
    </help>
    <header>
      <h2>Répartition par statut actuels des conciliations du {{start | format('dd/MM/yy')}} au {{end | format('dd/MM/yy')}}</h2>
    </header>
    <div class="donut-container" style="position: relative">
      <div v-if="busy" style="position: absolute; top: 0; margin-top: -2em; left: 0;">
        <spinner style="transform: scale(0.2, 0.2);"></spinner>
      </div>
      <canvas ref="donut" width="650" height="300"></canvas>
    </div>
    <div>
      <small title="Nombre de candidats avec les status courants suivants: Envoyés, Consultés, Interviewés, Rencontrés, Rdvs, Recrutés, Archivés, Rejetés"> *transmis</small>
    </div>
    <div>
      <small title="Nombre de candidats avec les status courants suivants: Suggérés, Acceptés, Recalés, Envoyés, Consultés, Interviewés, Rencontrés, À transmettre, Rdvs, Recrutés, Archivés, Rejetés, Indésirables"> *reçus</small>
    </div>
  </div>
</template>
<script>
import Recruiter from '/user/recruiter/recruiter.entity.js'
import Search from '/requirement/entity'
import Help from '/components/help'
import { Labels } from '/interview/status'
import { Chart, ArcElement, DoughnutController, LinearScale, CategoryScale, Legend, Title, Tooltip } from 'chart.js'
import { add, endOfDay, startOfDay } from 'date-fns'
import Spinner from '/layout/spinner'
import cancel from '/cancel.mixin.js'
import { Solr, eq, and } from '/solr'
import { received, transmitted, backgroundColor } from './utilsArray'
import ordered from '/interview/ordered.status'
Chart.register(ArcElement, DoughnutController, LinearScale, CategoryScale, Legend, Title, Tooltip)

export default {
  name: 'hippolyte.reporting.donut',
  props: {
    a: Boolean,
    recruiter: Recruiter,
    search: Search,
    start: Date,
    end: Date
  },
  components: { 
    Spinner, 
    Help 
  },
  mixins: [cancel],
  data () {
    return {
      busy: false,
      Labels,
      donut: null,
      cancels: {},
      facets: {}
    }
  },
  watch: {
    search: 'load',
    recruiter: 'load',
    start: 'load',
    end: 'load',
    a: 'load',
    facets: 'update'
  },
  mounted () {
    this.load()
  },
  destroyed () {
    this.donut?.destroy()
  },
  methods: {
    format (data) {
      const buckets = Array.from(data.facets.status?.buckets || []).sort((a, b) => ordered.indexOf(a.val) - ordered.indexOf(b.val))
      return {
        labels: buckets.map(obj => `${obj.count} ${(obj.count > 1) ? Labels[obj.val].plural : Labels[obj.val].singular}`),
        datasets:[{
          data: buckets.map(obj => obj.count),
          backgroundColor
        }]
      }
    },
    async load () {
      this.cancel('donut')
      this.busy = true
      try {
        const req = new Solr({
          entity: 'ConciliationStatus',
          raw: true,
          query: 'current:true',
          limit: 0,
          offset: 0
        })
        const r = req.join({
          entity: 'Conciliation',
          query: and('{!join entity=ConciliationStatus v="date:[@start TO @end]"}')
        })
        if (this.a) {
          r.query.push(`{!join entity=Search v="active:true"}`)
        }
        if (this.search && this.search.id) {
          r.query.push(eq('search', this.search.id))
        }
        if (this.recruiter && this.recruiter.id) {
          r.query.push(eq('recruiter', this.recruiter.id))
        }
        req.parameter('start', add(startOfDay(this.start), { minutes: -this.start.getTimezoneOffset() }).toISOString())
        req.parameter('end', add(endOfDay(this.end), { minutes: -this.end.getTimezoneOffset() }).toISOString())
        const reqFacets = {
          facets: [{
            type: 'terms',
            name: 'status',
            field: 'status',
            mincount: 0,
            limit: -1
          }]
        }
        this.facets = await this.$socket.service('entity_solr/QUERY', Object.assign(req.send(), reqFacets), this.token('donut'))
        this.cancel('donut', null)
      } catch (err) {
        this.handleCancel(err).catch(() => {
          this.cancel('donut', null)
        })
      }
      this.busy = false
    },
    getOptions() {
      return {
        plugins: {
          counter: {
            text: [
              this.received + ' reçu' + (this.received > 1 ? 's' : ''),
              this.transmitted + ' transmis',
            ],
            colors: ['black', 'black'],
            fontSize: 20,
            fontFamily: 'Montserrat'
          },
          legend: {
            onClick: function(e, legendItem, legend) {
              if(legendItem.hidden) {
                this.chart.options.plugins.counter.text += this.chart.data.datasets[0].data[legendItem.index]
              } else {
                this.chart.options.plugins.counter.text -= this.chart.data.datasets[0].data[legendItem.index]
              }
              Chart.overrides.doughnut.plugins.legend.onClick(e, legendItem, legend)
              this.chart.update()
            },
            position: 'right',
            labels: {
              boxWidth: 20,
              pointStyle: 'rectRounded',
              usePointStyle: true,
              color: 'rgb(19, 77, 96)',
              font:{
                size: 16,
                family: 'Montserrat'
              }
            }
          }
        },
        cutout: '75%',
        responsive: false,
        maintainAspectRatio: false
      }
    },
    update () {
      // plugins for display value in the middle of donut
      const counter = {
        id: 'counter',
        beforeDraw (chart, arg, options) {
          const { ctx, chartArea: { top, right,bottom, left, width, height } } = chart
          ctx.save()
          ctx.textAlign = 'center'
          ctx.font = options.fontSize + 'px ' + options.fontFamily
          ctx.fillStyle = options.colors[0]
          ctx.fillText(options.text[0], width / 2, top + (height / 2) - options.fontSize / 2)
          ctx.fillStyle = options.colors[1]
          ctx.fillText(options.text[1], width / 2, top + (height / 2) + options.fontSize / 2)
        }
      }
      if (this.donut) {
        this.donut.data = this.format(this.facets)
        this.donut.options.plugins.counter.text = [
          this.transmitted + ' transmis',
          this.received + (this.received <= 1 ? ' reçu' : ' reçus')
        ],

        this.donut.update()
      } else {
        this.donut = new Chart(this.$refs.donut, {
          type: 'doughnut',
          data: this.format(this.facets),
          plugins: [counter],
          options: this.getOptions()
        })
      }
    }
  },
  computed: {
    transmitted () {
      return count(this.facets, transmitted)
    },
    received () {
      return count(this.facets, received)
    }
  }
}
function count(facets, keep) {
  return facets.facets?.status?.buckets.filter(status => keep.includes(status.val)).reduce((acc, status) => acc + status.count, 0) ?? '0'
}
</script>
<style lang="stylus" scoped>
div
  small
    padding-left 2em
    cursor help
</style>
