<template>
  <ats
    v-bind="$props"
    service="altays"
    :savingConf="savingConf"
    :credential="{
      slug: '',
      key1: '',
      key2: '',
      clientId: '',
      hostname: '',
      entity_id: '0'
    }"
    :cached="true"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="slug">company slug</label>
        <input v-model="credential.slug" id="slug" name="slug"/>
        <modified :enable="value.credential.slug !== credential.slug"/>
      </div>
      <div>
        <label for="clientId">id client</label>
        <input v-model="credential.clientId" id="clientId" name="clientId"/>
        <modified :enable="value.credential.clientId !== credential.clientId"/>
      </div>
      <div>
        <label for="hostname">url</label>
        <input v-model="credential.hostname" id="hostname" name="hostname"/>
        <modified :enable="value.credential.hostname !== credential.hostname"/>
      </div>
      <div>
        <label for="key1">clé api offres</label>
        <input v-model="credential.key1" id="key1" name="key1"/>
        <modified :enable="value.credential.key1 !== credential.key1"/>
      </div>
      <div>
        <label for="key2">clé api apply</label>
        <input v-model="credential.key2" id="key2" name="key2"/>
        <modified :enable="value.credential.key2 !== credential.key2"/>
      </div>
      <div>
        <label for="entity_id">entity id</label>
        <input v-model="credential.entity_id" id="entity_id" name="entity_id"/>
        <modified :enable="value.credential.entity_id !== credential.entity_id"/>
      </div>
    </template>
  </ats>
</template>
<script>
import Ats from './sdk-v3'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.altays.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
