import axios from 'axios'

class Api {
  constructor (socket, path) {
    this.socket = socket
    this.path = path
    this.ws = axios.create({
      baseURL: socket.config.socketServer
    })
  }

  async headers (headers = {}) {
    // clone headers and include jwt
    return Object.assign({ Authorization: `Bearer ${ await this.socket.auth.getToken() }` }, headers)
  }

  async list (params = {}, headers = {}) {
    return this.ws({
      method: 'get',
      headers: await this.headers(headers),
      url: this.path,
      params
    })
  }

  async update (params = {}, headers = {}) {
    return this.ws({
      method: 'patch',
      headers: await this.headers(headers),
      url: this.path,
      params
    })
  }

  async deleteExpense (params = {}, headers = {} ) {
    return this.ws({
      method: 'delete',
      headers: await this.headers(headers),
      url: this.path + `/${params.campaign}/expense/${params.id}`,
    })
  }

  async addExpense (params = {}, headers = {} ) {
    return this.ws({
      method: 'post',
      headers: await this.headers(headers),
      url: this.path + `/${params.campaign}/expense`,
      params
    })
  }

  async get (params = {}, headers = {}, path = '', mixin = {}) {
    return this.ws(Object.assign(mixin, {
      method: 'get',
      headers: await this.headers(headers),
      cancelToken: headers.cancel || headers.cancelToken,
      url: this.path + path,
      params
    }))
  }
}

export default Api
