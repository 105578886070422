<template>
  <ul>
    <li v-for="ref in this.fields" :key="ref.id">
      <details v-if="ref.referentiel" @toggle="onToggle($event, ref)">
        <summary>{{ ref.label }}</summary>
        <loader v-if="ref.loading"></loader>
        <table>
          <tr v-for="item in ref.items" :key="item.id">
            <td><b>{{ item.id }}</b></td>
            <td>{{ item.label }}</td>
          </tr>
        </table>
      </details>
      <span v-else>{{ ref.label }}</span>
    </li>
  </ul>
</template>
<script>
import Recruiter from '/user/recruiter/recruiter.entity'
import cancel from '/cancel.mixin.js'
import Loader from '/layout/spinner'

export default {
  name: 'hippolyte.services.ats.config.referential',
  components: { Loader },
  mixins: [cancel],
  props: {
    recruiter: Recruiter,
    service: String
  },
  data () {
    return {
      fields: [],
      loading: false
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    async load () {
      this.cancel('clients')
      try {
        const data = await this.$socket.service(`${this.service}.referentials/GET`, {
          recruiter: this.recruiter.id
        }, { cancel: this.token('clients') })
        this.fields.splice(0, this.fields.length, ...data.map(field => field.referentiel ? Object.assign(field, { loading: false, items: [] }) : field))
        this.cancel('clients', null)
      } catch (err) {
        this.handleCancel(err)
      }
    },
    async loadReferentiel (referentiel) {
      this.cancel('referentiel')
      referentiel.loading = true
      await this.$socket.service(`${this.service}.referentials/GET`, {
        recruiter: this.recruiter.id,
        referentiel
      }, { cancel: this.token('referentiel') }).then(data => {
        referentiel.items.push(...data.sort((a, b) => Number(a.id) - Number(b.id)))
        this.cancel('referentiel', null)
      }).catch(err => {
        this.handleCancel(err)
      })
      referentiel.loading = false
    },
    onToggle (event, ref) {
      if (event.target.open && ref.referentiel && !ref.items.length && !ref.loading) {
        this.loadReferentiel(ref)
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
  ul
    list-style none
  table
    width 100%
    td
      padding 0.5em
      border-bottom 1px solid #ccc
</style>
