<template>
  <div :class="['hippo-help', className]">
    <details :open="startOpen">
      <summary title="Aide">{{ title }}</summary>
      <slot name="content"></slot>
    </details>
  </div>
</template>

<script>
export default {
  name: 'hippolyte.components.help',
  props: {
    title: {
      type: String,
      default: ''
    },
    className: {
      type: String,
      default: ''
    },
    startOpen: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~/colors.styl'
.hippo-help
  background $color-peach
  color $color-white
  border-radius 8px
  padding 16px
  width fit-content
  summary
    cursor pointer
    font-weight bold
</style>