<template>
  <li :key="conciliation.id">
      <div @click="onProfile" class="edit-link">
      <template v-if="conciliation.search">
        <template v-if="conciliation.search.trade">{{conciliation.search.trade.name}}</template>
        <template v-if="conciliation.recruiter && $isAdmin()"> - {{conciliation.recruiter.company}}</template>
        <template v-if="conciliation.search.location"> - {{conciliation.search.location.name | camelize }}</template>
        <template v-if="creationDate"> - {{creationDate}}</template>
      </template>
      <template v-else-if="conciliation.recruiter && $isAdmin()">
        <template>{{conciliation.recruiter.company}} - {{conciliation.recruiter.location.name | camelize}}</template>
      </template>
      <template v-if="$isAdmin()">
        <template v-if="conciliation.score">(score : {{conciliation.tags.score}})</template>
        <template v-if="conciliation.validLocation">
          <span class="valid_location valid" title="Valid Location"></span>
        </template>
        <template v-else>
          <span class="valid_location" title="No Valid Location"></span>
        </template>
        <template>{{ translateStatus(conciliation.status) }}</template>
        <small v-if="conciliation.status === 'discarded'">({{ getReason() }})</small>
      </template>
    </div>
    <button @click="openEditStatus" title="Editer le statut" class="edit-button">
      <svg
        height="484pt"
        viewBox="-15 -15 484.00019 484"
        width="484pt"
        xmlns="http://www.w3.org/2000/svg">
        <path d="m401.648438 18.234375c-24.394532-24.351563-63.898438-24.351563-88.292969 0l-22.101563 22.222656-235.269531 235.144531-.5.503907c-.121094.121093-.121094.25-.25.25-.25.375-.625.746093-.871094 1.121093 0 .125-.128906.125-.128906.25-.25.375-.371094.625-.625 1-.121094.125-.121094.246094-.246094.375-.125.375-.25.625-.378906 1 0 .121094-.121094.121094-.121094.25l-52.199219 156.96875c-1.53125 4.46875-.367187 9.417969 2.996094 12.734376 2.363282 2.332031 5.550782 3.636718 8.867188 3.625 1.355468-.023438 2.699218-.234376 3.996094-.625l156.847656-52.324219c.121094 0 .121094 0 .25-.121094.394531-.117187.773437-.285156 1.121094-.503906.097656-.011719.183593-.054688.253906-.121094.371094-.25.871094-.503906 1.246094-.753906.371093-.246094.75-.621094 1.125-.871094.125-.128906.246093-.128906.246093-.25.128907-.125.378907-.246094.503907-.5l257.371093-257.371094c24.351563-24.394531 24.351563-63.898437 0-88.289062zm-232.273438 353.148437-86.914062-86.910156 217.535156-217.535156 86.914062 86.910156zm-99.15625-63.808593 75.929688 75.925781-114.015626 37.960938zm347.664062-184.820313-13.238281 13.363282-86.917969-86.917969 13.367188-13.359375c14.621094-14.609375 38.320312-14.609375 52.945312 0l33.964844 33.964844c14.511719 14.6875 14.457032 38.332031-.121094 52.949218zm0 0"/>
      </svg>
    </button>
    <div :class="{opened: openButtons}" class="edit-menu">
      <button @click="openButtons = false" class="close-button">
        <icon-base name="close"><icon-close /></icon-base>
      </button>
      <div class="edit-menu-content">
        <h2 class="edit-menu-title">Modifier le statut de la conciliation</h2>
        <div class="action-buttons">

          <button v-if="isAllowed('suggest')"
            @click.stop="onSuggest"
            title="Suggérer le candidat"
            class="action-button">
            <icon-base name="suggested"><icon-suggested /></icon-base>
          </button>

          <button v-if="isAllowed('send')"
            @click.stop="onSend"
            title="Envoyer au recruteur"
            class="action-button">
            <icon-base name="send"><icon-send /></icon-base>
          </button>

          <button v-if="isAllowed('archive')"
            @click.stop="onArchive"
            title="Archiver le candidat"
            class="action-button">
            <icon-base name="archive"><icon-archive /></icon-base>
          </button>

          <button v-if="isAllowed('unarchive')"
            @click.stop="onUnarchive"
            title="Désarchiver le candidat"
            class="action-button">
            <icon-base name="unarchive"><icon-unarchive /></icon-base>
          </button>

          <button v-if="isAllowed('discard')"
            @click.stop="confirm('discarded')"
            title="Rejeter le candidat"
            class="action-button">
            <icon-base name="reject"><icon-reject /></icon-base>
          </button>

          <sweet-modal class="modal" ref="confirm">
            <confirm
              :status='toStatus'
              :reasons='reasons'
              :conciliation="conciliation"
              @close="closeConfirm"
            ></confirm>
          </sweet-modal>

          <button v-if="isAllowed('reject')"
           @click.stop="confirm('rejected')"
           title="Rejeter le candidat"
           class="action-button">
           <icon-base name="reject"><icon-reject /></icon-base>
          </button>

          <button v-if="isAllowed('undiscard')"
            @click.stop="onUndiscard"
            title="Suggérer le candidat"
            class="action-button">
            <icon-base name="suggested"><icon-suggested /></icon-base>
          </button>

      </div>
    </div>
  </li>
</template>
<script>
import Conciliation from '/interview/interview.js'
import Interview from '/interview/interview.js'
import { Status, allowed, Labels } from '/interview/status'
import { SweetModal } from 'sweet-modal-vue'
import IconBase from '/layout/components/IconBase'
import format from 'date-fns/format'
import Confirm from '../reasons/confirm'

export default {
  name: 'hippolyte.profile.conciliation.item',
  components: {
    SweetModal,
    IconBase,
    Confirm
  },
  props: {
    interview: Interview,
    conciliation: Conciliation,
    reasons: Array
  },
  data () {
    return {
      openButtons: false,
      creationDate: Date,
      reason: null,
      toStatus: null
    }
  },
  watch: {
    conciliation (val, old) {
      old?.off('update', this.update, this)
      this.conciliation?.on('update', this.update, this)
      this.conciliation?.search.off('update', this.update, this)
      this.conciliation?.search.on('update', this.update, this)
      this.$forceUpdate()
      this.getCreationDate()
    }
  },
  mounted () {
    this.conciliation.on('update', this.update, this)
    this.conciliation?.search.off('update', this.update, this)
    this.conciliation?.search.on('update', this.update, this)
    this.getCreationDate()
  },
  destroyed () {
    this.conciliation.off('update', this.update, this)
  },
  methods: {
    closeConfirm () {
      this.$refs.confirm.close()
    },
    confirm (status) {
      this.toStatus = status
      this.$refs.confirm.open()
    },
    getCreationDate () {
      this.creationDate = format(this.conciliation.statuses.find(s => s.status === 'created').date, 'dd/MM/y')
    },
    update () {
      this.conciliation?.search.off('update', this.update, this)
      this.conciliation?.search.on('update', this.update, this)
      this.$forceUpdate()
    },
    getReason () {
      return this.conciliation.statuses.find(s => s.status === this.conciliation.status).reasons?.name
    },
    translateStatus () {
      return Labels[this.conciliation.status]?.singular
    },
    onProfile () {
      this.$router.push({
        name: 'candidates_profile',
        params: {
          search: this.conciliation.search.id,
          interview: this.conciliation.id
        }
      })
    },
    isAllowed (transition) {
      return allowed(this.conciliation.status, transition, this.$socket.auth)
    },
    async onSend () {
      await this.conciliation.send()
      this.openButtons = false
    },
    async onSuggest () {
      await this.conciliation.suggest()
      this.openButtons = false
    },
    async onUnarchive () {
      await this.conciliation.unarchive()
      this.openButtons = false
    },
    async onArchive () {
      await this.conciliation.archive()
      this.openButtons = false
    },
    async onUndiscard () {
      await this.conciliation.undiscard()
      this.openButtons = false
    },
    openEditStatus () {
      this.openButtons = !this.openButtons
    },
  },
  filters: {
    camelize (text) {
      return text.toLowerCase()
        .split(' ')
        .map(([f, ...rest]) => [f.toUpperCase(), ...rest].join(''))
        .join(' ')
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~/colors.styl'

.valid_location
  display inline-block
  background $color-tomato
  width 10px
  height 10px
  border-radius 100%
  &.valid
    background $color-green
.edit-link
  width 90%
  &:hover
    cursor pointer
li
  display flex
  align-items center
.edit-button
  width 2.2em
  height 2.2em
  background none
  fill  #45ADA7
  &:hover
    cursor pointer
    transform scale(1.2)
  svg
    width 100%
    height 100%
.edit-menu
  opacity 0
  visibility hidden
  position absolute
  background #fff
  box-shadow:
    0 1px 5.3px rgba(0, 0, 0, 0.028),
    0 3.4px 17.9px rgba(0, 0, 0, 0.042),
    0 15px 80px rgba(0, 0, 0, 0.07)
  width 22em
  height 10em
  top 3em
  margin 0 auto
  padding 1em
  transition all 200ms ease-in
  &.opened
    opacity 1
    visibility visible
    transition all 200ms ease-in
  &-content
    height 100%
  &-title
    text-align center
    color #45ADA7
    font-weight bold
  .action-buttons
    height 80%
    display flex
    align-items center
    justify-content center
  .close-button
    width 2em
    height 2em
    background none
    position absolute
    top 0
    right 0
    svg
      width 100%
      height 100%
      fill #404042
    &:hover
      cursor pointer
    &:hover svg
      transform scale(1.1)
  .action-button
    width 3em
    height 3em
    background none
    margin 0 0.4em
    border-radius 50%
    background alpha(#45ADA7, 0.8)
    display flex
    align-items center
    justify-content center
    &:hover
      background #45ADA7
      cursor pointer
    &:hover svg
      transform scale(1.1)
    svg
      width 80%
      height 80%
  .fill-current
    fill #fff
</style>
