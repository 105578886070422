<template>
  <section class="chat-menu">
    <button class="close-menu" @click="$emit('close')">&#10005;</button>
    <template>
      <slot name="application"></slot>
    </template>
    <article class="room-list">
      <header>
        <b>{{ title }}</b>
      </header>
      <slot name="filter"></slot>
      <div class="room-list_container">
        <template v-for="room in rooms" >
          <slot name="roomItem" :room="room"></slot>
        </template>
      </div>
      <slot name="items"></slot>
    </article>
  </section>
</template>

<script>
import Filters from '/filter'

export default {
  name: 'hippolyte.menu.chat',
  props: {
    title: String,
    rooms: Array,
    filters: {
      type: Filters,
      required: false,
      default () { return  null }
    }
  },
  mounted () {
    this.$chat.on('room:update', this.$forceUpdate, this)
    this.filters?.on('update', this.$forceUpdate, this)
  },
  destroyed () {
    this.$chat.off('room:update', this.$forceUpdate, this)
    this.filters?.off('update', this.$forceUpdate, this)
  }
}
</script>
<style lang="stylus">
@require '~/colors.styl'
.chat-menu
    display: none
    flex-direction: column
    border-right 2px solid #45ADA7
    color: #404042
    height 100%
    width: 100%
    z-index: 5
    padding-top 0.5em
    &.show
        display: flex
    .close-menu
        position: absolute;
        top: 0.5em;
        right: 0.5em;
        color: #fff;
        background: transparent;
        border: 0;
        font-size: 1.5em;
        z-index 999
    .room-list
        flex-grow 1
        height 100%
        overflow-y unset
        display flex
        flex-direction column
        header
            .notify-switch
                float: right;
                margin-right: 0.5em;
                margin-top: -0.5em;
            font-size 1em
            margin-left 1.5rem
            ticks()
            &:after
              border 2px $color-yellow solid
        .room-list_container
            margin-top 0.5em
            overflow-y auto
        .room-item_container
            &:hover
                background-color: $color-menu_light
    .application
        background-color rgba(184,224,221,0.5)

        header
            font-size 1em
            margin-left 1.5rem
            padding 1em 0
            font-weight bold
@media (min-width: $breakpoint)
    .chat-menu
        height auto
        display: flex
        flex-grow 0
        flex-shrink 0
        flex-basis "%s" % $panel.second.width
        // flex-basis "calc(%s - %s - %s - %s)" % ($second-panel_width $panel.first.width $third-panel_margin $third-panel_margin)
        // width "calc(%s - %s - %s - %s)" % ($second-panel_width $firstpanel.width $third-panel_margin $third-panel_margin)
        .close-menu
            display: none

@media (max-width: $breakpoint)
  .chat-menu.show
    padding-top 0
    .room-list
      padding-top 1em
      height calc(100% - 1em)
    .application
      margin-top 3em
</style>
