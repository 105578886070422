<template>
  <sweet-modal ref="modal" @close='onClose'>
    <h4>{{ title }}</h4>
    <div v-if="running">
      <spinner style="transform: scale(0.5, 0.5);"></spinner>
    </div>
    <button @click="onConfirm" :disabled="running" v-else>{{ valid }}</button>
    <button @click="onClose">{{ cancel }}</button>
  </sweet-modal>
</template>
<script>
import Spinner from '/layout/spinner'
import { SweetModal } from 'sweet-modal-vue'

function reset () {
  return {
    running: false,
    resolve: null,
    reject: null,
    title: 'Êtes-vous sûr ?',
    valid: 'Valider',
    cancel: 'Annuler'
  }
}

export default {
  name: 'hippolyte.confirm',
  components: {
    Spinner, SweetModal
  },
  data () {
    return reset()
  },
  methods: {
    open (opts = {}) {
      Object.assign(this, reset(), opts)
      const p = new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
        this.$refs.modal.open()
      })
      p.catch(() => {})
      p.then(() => {
        this.running = true
      },this.close)
      return p
    },
    onConfirm () {
      this.resolve()
    },
    onClose () {
      this.reject && this.reject()
    },
    close () {
      this.$refs.modal.close()
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~/colors.styl'
section, .action
  display flex
  justify-content end
  .btn
    display flex
    align-items center
    background-color $color-torrent

button, .btn
  background white
  border none
  color black
  border-radius 2em
  line-height 1em
  margin 0.2em 0
  padding 0.4em 1.5em
  font-size 0.9em
  cursor pointer
  text-decoration none
  &::first-letter
    text-transform capitalize
  &:focus, &:hover
    outline none

</style>
