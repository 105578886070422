<template>
  <layout>
    <template v-slot:panel>
      <header>
        <h1>Colors</h1>
      </header>
    </template>
    <template v-slot:form>
      <ul>
        <template v-for="color in colors">
          <li :key="color"><span :class="color"></span> {{ color }}</li>
        </template>
      </ul>
    </template>
  </layout>
</template>
<script>
import Layout from '/layout/session'

export default {
  name: 'hippolyte.colors',
  components: { Layout },
  data () {
    return {
      colors: ('color-pink$color-pink_lighter$color-pink_light$color-yellow$color-yellow_light$color-blue$color-astronaut_lighter$color-sky$color-astronaut_light$color-metal$color-astronaut$color-astronaut_dark$color-blue_light$color-blue_grey$color-blue_lighter$color-blue_grey_light$color-white$color-grey_lighter$color-grey$color-grey_harder$color-green$color-tomato$color-cloud').split('$')
    }
  }
}
</script>
<style lang="stylus" scoped>
@require "~/colors.styl"

ul
  overflow-x scroll
  height 70%
  list-style none
  margin 0
  li
    display flex
    flex-direction row
    align-items center
    span
      margin 0.2em 1em
      display: inline-block
      width: 30px
      height: 30px
      border 1px black solid
      border-radius 2px
  .color-pink
    background: $color-pink
  .color-pink_lighter
    background: $color-pink_lighter
  .color-pink_light
    background: color-pink_light
  .color-yellow
    background: $color-yellow
  .color-yellow_light
    background: $color-yellow_light
  .color-blue
    background: $color-blue
  .color-astronaut_lighter
    background: $color-astronaut_lighter
  .color-sky
    background: $color-sky
  .color-astronaut_light
    background: $color-astronaut_light
  .color-metal
    background: $color-metal
  .color-astronaut
    background: $color-astronaut
  .color-astronaut_dark
    background: $color-astronaut_dark
  .color-blue_light
    background: $color-blue_light
  .color-blue_lighter
    background: $color-blue_lighter
  .color-blue_grey
    background: $color-blue_grey
  .color-white
    background: $color-white
  .color-grey_lighter
    background: $color-grey_lighter
  .color-grey
    background: $color-grey
  .color-grey_harder
    background: $color-grey_harder
  .color-green
    background: $color-green
  .color-tomato
    background: $color-tomato
  .color-cloud
    background: $color-cloud
  .color-blue_grey_light
    background: $color-blue_grey_light
</style>
