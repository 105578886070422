<template>
  <button @click.prevent="registered = !registered" :title="(registered ? 'Désactiver' : 'Activer') + ' les notifications'">
    <img src="/assets/icon/bell_white.png" v-if="registered">
    <img src="/assets/icon/bell_white-slash.png" v-else>
  </button>
</template>
<script>
import Notify from '/notify'
import Account from '/user/account/entity'

export default {
  name: 'hippolyte.notify.account.tool',
  props: {
    account: Account
  },
  data () {
    const notifier = new Notify(this.account, this.$socket)
    notifier.on('update', s => Object.assign(this, { registered: s }))
    return {
      notifier,
      registered: notifier.registered
    }
  },
  async mounted () {
    this.registered = await this.notifier.status()
    this.$watch('registered', async function (value, old) {
      if (old !== null && value !== this.notifier.registered) {
        try {
          if (this.registered) {
            const register = await this.notifier.register()
            await this.notifier.save(register)
          } else {
            await this.notifier.remove()
          }
        } catch {
          this.registered = old
        }
      }
    })
  },
  destroyed () {
    this.notifier.destroy()
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
  img
    width 27px
</style>
