<template>
  <div>
    <div class="dateTable-container">
      <div v-if="busy" style="position: relative; top: 0; margin-top: -2em; left: 0;">
        <spinner style="transform: scale(0.2, 0.2);"></spinner>
      </div>
      <div v-else-if="table.length">
        <button v-on:click.stop="csv"> export </button>
        <table>
          <caption>Répartition du nombre de candidats envoyés par jour</caption>
          <thead>
            <tr>
              <th></th>
              <th v-for="date in table" :key="date.val">{{ formatDate(date.val) }}</th>
              <th>Total</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <th>Total</th>
              <th v-for=" (v,i) in table" :key="'count'+i">{{ v.count }}</th>
              <th>{{ total.count }}</th>
            </tr>
          </tfoot>
          <tbody>
            <tr v-for="search in searches" :key="search.id">
              <td>{{ search.title }}</td>
              <td v-for="date in table" :key="formatDate(date.val)">
                {{ (date[search.title] && date[search.title].count) || 0}}
              </td>
              <td>{{ total.searches[search.title] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else>Aucun candidats envoyés durant la période selectionnée</div>
    </div>
  </div>
</template>
<script>
import Recruiter from '/user/recruiter/recruiter.entity.js'
import { Labels } from '/interview/status'
import { add, endOfDay, startOfDay, format, parseISO } from 'date-fns'
import Spinner from '/layout/spinner'
import cancel from '/cancel.mixin.js'
import { Solr, eq, and } from '/solr'
import { Csv } from './export'

export default {
  name: 'hippolyte.reporting.dateTables',
  props: {
    a: Boolean,
    recruiter: Recruiter,
    search: Boolean,
    start: Date,
    end: Date,
    gap: Object
  },
  components: { Spinner },
  mixins: [cancel],
  data () {
    return {
      busy: false,
      Labels,
      table: [],
      total: {
        searches: {},
        count: 0
      },
      searches: [],
      onRecruiter: null
    }
  },
  watch: {
    recruiter (val, old) {
      old?.off('update', this.load, this)
      if (val) {
        if (val.id) {
          this.load()
        } else {
          this.reset()
          val.on('update', this.load, this)
        }
      } else {
        this.reset()
      }
    },
    start: 'load',
    end: 'load',
    a: 'load',
  },
  mounted () {
    this.load()
  },
  methods: {
    async csv () {
      this.exp = true
      await Csv(this.toTable())
      this.exp = false
    },
    toTable () {
      return [
        ['', ...this.table.map(d => this.formatDate(d.val))],
      ].concat(Object
        .values(this.searches)
        .map(({title}) => [title, ...this.table.map(d => d[title]?.count || 0)])
      )
    },
    sum (search) {
      return this.table.reduce((acc, current) => acc + (current[search]?.count || 0), 0)
    },
    format (data, searches) {
      if (searches.length && data.length) {
        this.searches.splice(0, this.searches.length, ...searches)
        this.table.splice(0, this.table.length, ...data)
        this.total.searches = searches.reduce((acc, s) => Object.assign({ [s.title]: this.sum(s.title) }, acc), {})
        this.total.count = Object.values(this.total.searches).reduce((acc, t) => t + acc, 0)
      }
    },
    async getSearches () {
      const opts = {
        solr: true,
        limit: 1,
        offset: 0,
        table: true
      }
      if (this.recruiter && this.recruiter.id) {
        opts.query = eq('id', this.recruiter.id)
      }
      if (this.a) {
        opts.active = true
      }
      const { docs } = await Recruiter.search(opts, this.$socket, this.token('table'))
      return docs[0].searches.docs
    },
    reset () {
      this.table.splice(0, this.table.length)
      this.searches.splice(0, this.searches.length)
      this.total.searches = {}
      this.total.count = 0
    },
    async load () {
      this.busy = true
      this.reset()
      try {
        this.cancel('table')
        const searches = await this.getSearches()
        const req = new Solr({
          entity: 'ConciliationStatus',
          raw: true,
          query: and(eq('status','sent'), 'date:[@start TO @end]'),
          limit: 0,
          offset: 0
        })
        const r = req.join({
          entity: 'Conciliation',
        })
        if (this.recruiter && this.recruiter.id) {
          r.query.push(eq('recruiter', this.recruiter.id))
        }
        if (this.a) {
          r.query.push(`{!join entity=Search v="active:true"}`)
        }
        req.parameter('start', add(startOfDay(this.start), { minutes: -this.start.getTimezoneOffset() }).toISOString())
        req.parameter('end', add(endOfDay(this.end), { minutes: -this.end.getTimezoneOffset() }).toISOString())
        const facets = {
          facets: [{
            type: 'range',
            name: 'date',
            field: 'date',
            start: add(startOfDay(this.start), { minutes: -this.start.getTimezoneOffset() }).toISOString(),
            end: add(endOfDay(this.end), { minutes: -this.end.getTimezoneOffset() }).toISOString(),
            gap: '+1' + this.gap.gap,
            facets: searches.map(s => ({
              type: 'query',
              name: s.title,
              query: `{!join entity=Conciliation v="search:\\"${s.id}\\""}`,
            }))
          }]
        }
        const data = await this.$socket.service('entity_solr/QUERY', Object.assign(req.send(), facets), this.token('table'))
          .then(res => res.numFound !== 0 ? res.facets.date?.buckets.filter(b => b.count !== 0) : null)
        this.cancel('table', null)
        this.busy = false
        this.format(data, searches)
      } catch (err) {
        this.handleCancel(err).catch(() => {
          this.cancel('table', null)
        })
      }
    },
    formatDate (date) {
      return format(parseISO(date),'dd/MM/yy')
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
.dateTable-container
  margin-top 1em
  max-width 75vw
  overflow-x scroll
  table
    border-collapse: collapse
    caption
      font-weight bold
      padding-bottom 1em
    thead, th
      border 2px solid $color-dark-grey
      padding 0.5em
    thead tr th:nth-child(1)
      border-top-color transparent
      border-left-color transparent
      border-bottom-width 1px
    td
      border 1px solid $color-dark-grey
      padding 0.5em
      text-align center

</style>
