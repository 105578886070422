const zip = (acc, v) => Object.assign(acc, { [v]: v })
export const StatusSet = ['created', 'started', 'rejected', 'opened', 'sent', 'archived', 'scheduled', 'suggested', 'discarded', 'waiting_acceptation', 'declined', 'accepted', 'recruited', 'met', 'do_not_send', 'scheduling_meeting']
export const Status = StatusSet.reduce(zip, {})
export const TransitionsSet = [ 'schedule', 'send', 'suggest', 'start', 'open', 'archive', 'unarchive', 'reject', 'discard', 'undiscard', 'require_acceptation', 'decline', 'accept', 'recruit', 'meet', 'cancel']
export const Transitions = TransitionsSet.reduce(zip, {})
export const Labels = {
  [Status.created]: {
    singular: 'Créé',
    plural: 'Créés'
  },
  [Status.suggested]: {
    singular: 'Suggéré',
    plural: 'Suggérés'
  },
  [Status.sent]: {
    singular: 'Envoyé',
    plural: 'Envoyés'
  },
  [Status.opened]: {
    singular: 'Consulté',
    plural: 'Consultés'
  },
  [Status.started]: {
    singular: 'Interviewé',
    plural: 'Interviewés'
  },
  [Status.archived]: {
    singular: 'Archivé',
    plural: 'Archivés'
  },
  [Status.rejected]: {
    singular: 'Rejeté',
    plural: 'Rejetés'
  },
  [Status.discarded]: {
    singular: 'Indésirable',
    plural: 'Indésirables'
  },
  [Status.waiting_acceptation]: {
    singular: 'En attente',
    plural: 'En attente'
  },
  [Status.declined]: {
    singular: 'Refusé',
    plural: 'Refusés'
  },
  [Status.accepted]: {
    singular: 'Accepté',
    plural: 'Acceptés'
  },
  [Status.recruited]: {
    singular: 'Recruté',
    plural: 'Recrutés'
  },
  [Status.met]: {
    singular: 'Rencontré',
    plural: 'Rencontrés'
  },
  [Status.do_not_send]: {
    singular: 'Recalé',
    plural: 'Recalés'
  },
  [Status.scheduling_meeting]: {
    singular: 'Rdv',
    plural: 'Rdvs'
  },
  [Status.scheduled]: {
    singular: 'À transmettre',
    plural: 'À transmettre'
  }
}

const AllowedRoles = {
  ADMIN: {
    [Transitions.suggest]: {
      from: [Status.created]
    },
    [Transitions.discard]: {
      from: [Status.suggested]
    },
    [Transitions.schedule]: {
      from: [Status.created, Status.suggested, Status.do_not_send]
    },
    [Transitions.send]: {
      from: [Status.scheduled, Status.created, Status.suggested, Status.accepted]
    },
    [Transitions.reject]: {
      from: [Status.sent, Status.opened, Status.started, Status.met]
    },
    [Transitions.open]: {
      from: [Status.sent]
    },
    [Transitions.start]: {
      from: [Status.opened]
    },
    [Transitions.archive]: {
      from: [Status.sent, Status.opened, Status.started, Status.met]
    },
    [Transitions.unarchive]: {
      from: [Status.archived, Status.rejected]
    },
    [Transitions.undiscard]: {
      from: [Status.discarded]
    },
    [Transitions.require_acceptation]: {
      from: [Status.created, Status.suggested]
    },
    [Transitions.decline]: {
      from: [Status.waiting_acceptation]
    },
    [Transitions.accept]: {
      from: [Status.waiting_acceptation]
    },
    [Transitions.recruit]: {
      from: [Status.sent, Status.opened, Status.started, Status.met]
    },
    [Transitions.meet]: {
      from: [Status.sent, Status.opened, Status.started]
    },
    [Transitions.cancel]: {
      from: [Status.scheduled, Status.accepted]
    }

  },
  AUTHENTICATED: {
    [Transitions.suggest]: {
      from: []
    },
    [Transitions.discard]: {
      from: []
    },
    [Transitions.schedule]: {
      from: []
    },
    [Transitions.send]: {
      from: []
    },
    [Transitions.reject]: {
      from: [Status.sent, Status.opened, Status.started, Status.met],
    },
    [Transitions.open]: {
      from: [Status.sent],
    },
    [Transitions.start]: {
      from: [Status.opened],
    },
    [Transitions.archive]: {
      from: [Status.sent, Status.opened, Status.started],
    },
    [Transitions.unarchive]: {
      from: [Status.archived],
    },
    [Transitions.undiscard]: {
      from: []
    },
    [Transitions.require_acceptation]: {
      from: [Status.created, Status.suggested]
    },
    [Transitions.decline]: {
      from: [Status.waiting_acceptation]
    },
    [Transitions.accept]: {
      from: [Status.waiting_acceptation]
    },
    [Transitions.recruit]: {
      from: [Status.sent, Status.opened, Status.started, Status.met]
    },
    [Transitions.meet]: {
      from: [Status.sent, Status.opened, Status.started]
    },
    [Transitions.cancel]: {
      from: []
    }
  },
  USER: {
    [Transitions.suggest]: {
      from: [],
    },
    [Transitions.discard]: {
      from: [],
    },
    [Transitions.schedule]: {
      from: [],
    },
    [Transitions.send]: {
      from: [],
    },
    [Transitions.reject]: {
      from: [],
    },
    [Transitions.open]: {
      from: []
    },
    [Transitions.start]: {
      from: []
    },
    [Transitions.archive]: {
      from: []
    },
    [Transitions.unarchive]: {
      from: []
    },
    [Transitions.undiscard]: {
      from: []
    },
    [Transitions.require_acceptation]: {
      from: [Status.created, Status.suggested]
    },
    [Transitions.decline]: {
      from: [Status.waiting_acceptation]
    },
    [Transitions.accept]: {
      from: [Status.waiting_acceptation]
    },
    [Transitions.recruit]: {
      from: [Status.sent, Status.opened, Status.started, Status.met]
    },
    [Transitions.cancel]: {
      from: []
    }
  }
}
export function allowed (status, transition, auth) {
  return StatusSet.includes(status) &&
    TransitionsSet.includes(transition) &&
    auth?.user?.roles?.some(role => AllowedRoles[role]?.[transition].from.includes(status))
}
