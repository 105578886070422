<template >
  <tr class="profile_item">
    <template v-for="(question, i) in valueHolder">
      <editable-item :key='i' :index="i" :item='question' @update="update"></editable-item>
    </template>
  </tr>
</template>
<script>

import editableItem from '/profile/voicecall_editable_item'

export default {
  name: 'hippolyte.profile.editable_list',
  components: { editableItem },
  props: ['value', 'field'],
  data () {
    return {
      valueHolder: this.value,
    }
  },
  watch: {
    value () {
      this.valueHolder = this.value // JSON complet
    }
  },
  mounted () {
  },
  computed: {},
  methods: {
    update (e) {
      this.valueHolder.splice(e.index, 1, e.value)
      console.log('update JSON JSON', this.valueHolder)
      this.$emit('update', { value: this.valueHolder, field: this.field, callback: e.callback })
    },
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
.profile_item
    margin-top 1.2em
    color $color-profile_second_invert
    font-size 0.8em
    display flex
    flex-direction column
    > *
        padding 0.8em
        padding-left 0
        vertical-align middle
    @media (min-width $breakpoint)
        margin-top 1.5em
    .profile-item_name
        background-color #45ADA7
        padding 0.2em 0.2em 0.2em 0.6em
</style>
