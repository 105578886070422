<template>
  <ats
    v-bind="$props"
    service="werecruit2"
    :savingConf="savingConf"
    :credential="{
      key: '', state: ''
    }"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="token">clé API</label>
        <input v-model="credential.key" id="password" name="password"/>
        <modified :enable="value.credential.key !== credential.key"/>
      </div>
      <div>
        <label for="state">status de publication</label>
        <select v-model="credential.state" id="state" name="state">
          <option value="">tout</option>
          <option value="draft">brouillon</option>
          <option value="active">activé</option>
          <option value="published">publié</option>
          <option value="closed">fermé</option>
        </select>
        <modified :enable="value.credential.state !== credential.state"/>
      </div>
    </template>
    <template v-slot:title="{ job }">{{ title(job) }}</template>
    <template v-slot:open="{ job }"><a class="button" :href="job.ref" target="_blank" title="ouvrir">👁️</a></template>
  </ats>
</template>
<script>
import Ats from './sdk-v2'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.werecruit2.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    title (job) {
      return  [
        job.id,
        job.title || job.hippolyte?.search.title,
        job.addressCity || job.hippolyte?.search.location?.docs[0]?.city
      ].filter(t => t).join(' - ')
    },
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
