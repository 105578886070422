<template>
  <ats
    v-bind="$props"
    service="samsic"
    :savingConf="savingConf"
    :credential="{ save: '', token: '', list: ''}"
    :cached="true"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="list">list url</label>
        <input v-model="credential.list" id="list" name="list"/>
        <modified :enable="value.credential.list !== credential.list"/>
      </div>
      <div>
        <label for="save">send url</label>
        <input v-model="credential.save" id="save" name="save"/>
        <modified :enable="value.credential.save !== credential.save"/>
      </div>
      <div>
        <label for="token">token</label>
        <input v-model="credential.token" id="secret" name="token"/>
        <modified :enable="value.credential.token !== credential.token"/>
      </div>
    </template>
  </ats>
</template>
<script>
import Ats from './sdk-v3'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.samsic.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
