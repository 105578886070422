<template>
  <article class="candidate-comment-container">
    <header>
      <template v-if="conciliation.search">
        <template v-if="conciliation.search.trade">{{conciliation.search.trade.name}}</template>
        <template v-if="conciliation.recruiter && $isAdmin()"> - {{conciliation.recruiter.company}}</template>
        <template v-if="conciliation.search.location"> - {{conciliation.search.location.name | camelize }}</template>
        <template v-if="creationDate"> - {{creationDate}}</template>
      </template>
      <template v-else-if="conciliation.recruiter && $isAdmin()">
        <template>{{conciliation.recruiter.company}} - {{conciliation.recruiter.location.name | camelize}}</template>
      </template>
    </header>
    <p>{{ conciliation.comment|nl2br }}</p>
  </article>
</template>
<script>
import Conciliation from '/interview/interview.js'
import Interview from '/interview/interview.js'
import { Status, allowed, Labels } from '/interview/status'
import { SweetModal } from 'sweet-modal-vue'
import IconBase from '/layout/components/IconBase'
import format from 'date-fns/format'

export default {
  name: 'hippolyte.profile.conciliation.comment',
  components: {
    SweetModal,
    IconBase
  },
  props: {
    interview: {
      type: Interview,
      default: null
      },
    conciliation: {
      type: Conciliation,
      default: null
    }
  },
  data () {
    return {
      openButtons: false,
      creationDate: Date
    }
  },
  watch: {
    conciliation (val, old) {
      old?.off('update', this.update, this)
      this.conciliation?.on('update', this.update, this)
      this.conciliation?.search.off('update', this.update, this)
      this.conciliation?.search.on('update', this.update, this)
      this.$forceUpdate()
      this.getCreationDate()
    }
  },
  mounted () {
    this.conciliation.on('update', this.update, this)
    this.conciliation?.search.off('update', this.update, this)
    this.conciliation?.search.on('update', this.update, this)
    this.getCreationDate()
  },
  methods: {
    getCreationDate () {
      this.creationDate = format(this.conciliation.statuses.find(s => s.status === 'created').date, 'dd/MM/y')
    },
    update () {
      this.conciliation?.search.off('update', this.update, this)
      this.conciliation?.search.on('update', this.update, this)
      this.$forceUpdate()
    }
  },
  filters: {
    camelize (text) {
      return text.toLowerCase()
        .split(' ')
        .map(([f, ...rest]) => [f.toUpperCase(), ...rest].join(''))
        .join(' ')
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
.candidate-comment-container
  padding 15px
  border 2px solid $color-azur
  border-radius 25px
  margin-bottom 20px

  header
    ticks()
    margin-bottom 0
    margin-top 0
    font-size 1em
    font-weight 700
</style>
