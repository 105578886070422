<template>
  <div>
    <label :for="name">Contract(s)</label>
    <treeselect
      v-model="value"
      :id="name"
      :name="name"
      :multiple="true"
      :options="contracts"
      placeholder="Selectionner le/les contrat(s)"
      @input="input"
    ></treeselect>
    <modified :enable="original !== value"></modified>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Modified from '/ats/modified'
import Contract from '/requirement/contract.entity'

export default {
  name: 'hippolyte.services.ats.filter.contractSelect',
  components: {
    Treeselect, Modified
  },
  props: {
    modelValue: Array,
    name: String
  },
  async mounted () {
    await this.getContracts()
  },
  data () {
    return {
      contracts: [],
      value: this.modelValue,
      original: this.modelValue
    }
  },
  methods: {
    input () {
      this.$emit('input', this.value)
    },
    async getContracts () {
      this.contracts = await Contract.search(this.$socket)
        .then(r => r.map(c => {
          return {
            id: c.id,
            label: c.name
          }
        }))
    }
  }
}
</script>
<style lang="stylus" scoped>
div
  background white
  margin 0.4em 0
  border-radius 4px
  align-items center
  label
    margin-right 1em
    text-align right
    margin-top auto
    margin-bottom auto
  select
    background #eee
    border-radius 0 4px 4px 0
    font-size 1em
    line-height 1.5em
    border 1px #eee solid
    &:focus
      border 1px #ccc solid
</style>