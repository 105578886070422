import EventEmitter from 'eventemitter3'
import { StatusSet } from '/interview/status'

export default class Filters extends EventEmitter {
  constructor (data = {}) {
    super()
    this.tags = []
    this.search = ''
    this.states = Array.from(StatusSet)
    this.requirements = []
    this.setData(data)
    this.condition = '&&'
  }

  setData (data) {
    Object.assign(this, data)
    this.notify()
    return this
  }
  notify () {
    this.emit('update')
  }
  match (interview) {
    if (!interview) {
      return false
    }
    if (!this.states.includes(interview.status)) {
      return false
    }
    if (this.requirements.length > 0 && (!interview.search || !this.requirements.includes(interview.search))) {
      return false
    }
    const tags = this.tags
      .filter(t => t.value)
    if (tags.length === 0) {
      return true
    }
    if (!interview.tags || interview.tags.length < tags.length) {
      return false
    }
    if (this.condition == '&&') {
      return tags.every(tag => interview.tags.find(({id}) => id === tag.id))
    }
    if (this.condition == '||') {
      const tagIds = tags.map(({ id })=> id)
      return interview.tags.some(({ id }) => tagIds.includes(id))
    }
  }
}
