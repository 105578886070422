<template>
  <div class="csv-export">
    <button @click="onOpen">csv</button>
    <sweet-modal ref="modal" width="75%">
      <form @submit.prevent="onCsv">
        <header>
          Export CSV
        </header>
        <p>
          <input type="checkbox" v-model="running" :id="'running'"/>
          <label :for="'running'">Campagne en cours</label>
        </p>
        <p class="preview" v-if="previewing || count !== null">
          <spinner v-if="previewing" style="transform: scale(0.2, 0.2);"></spinner>
          <table v-else-if="preview.length > 1">
            <thead>
              <tr>
                <template v-for="label in thead">
                  <th>{{ label }}</th>
                </template>
              </tr>
            </thead>
            <tbody>
              <template v-for="row in tbody">
                <tr>
                  <td v-for="value in row">{{ value }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </p>
        <div v-if="count !== null">{{ count }} {{ count > 1 ? 'campagnes' : 'campagne' }}</div>
        <footer>
          <spinner v-if="csv" style="transform: scale(0.2, 0.2);"></spinner>
          <button v-else type="submit" class="sheet" title="Export Excel">Export</button>
        </footer>
      </form>
    </sweet-modal>
  </div>
</template>

<script>
import Campaign from '/campaign/entity'
import jsoncsv from 'json-csv'
import { download } from '/fun'
import encoding from 'encoding'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import Spinner from '/layout/spinner'
import { SweetModal } from 'sweet-modal-vue'
import Papa from 'papaparse'
import cancel from '/cancel.mixin.js'
import { filterList, sortFieldList } from '/campaign/filterArray'

const subscriptionType = {
  'unitary': 'One shot',
  'subscription': 'Abonnement'
}

export default {
  name: 'hippolyte.campaign.csv',
  components: {
    Spinner, SweetModal
  },
  mixins: [cancel],
  props: {
    total: Number,
    campaigns: Array || Campaigns,
    runningCampaign: Boolean,
    query: String,
    filter: String
  },
  data () {
    return {
      ready: false,
      csv: null,
      preview: [],
      count: null,
      previewing: null,
      running: this.runningCampaign
    }
  },
  watch: {
    running () {
      this.loadPreview()
    }
  },
  methods: {
    onOpen () {
      this.loadPreview()
      this.$refs.modal.open()
    },
    async getPage ({ limit, offset }) {
      this.cancel('export')
      try {
        let opts = {
          running: this.running,
          limit,
          offset,
        }
        if (this.query) {
          opts.campaign = this.query
        }
        if (this.filter) {
          opts.filter = filterList[this.filter]
        }
        console.log('opts', opts)
        const raw = await Campaign.api(this.$socket).list(opts, this.token('export'))
        raw.data.docs.map((campaign, i) => {
          raw.data.docs[i].sumExpenses = this.sumExpenses(campaign)
          raw.data.docs[i].sentCandidate = this.getCandidateByStatus(campaign, 'sent')
          raw.data.docs[i].recruitedCandidate = this.getCandidateByStatus(campaign, 'recruited')
        })
        this.cancel('export', null)
        return [raw.data.docs, raw.data.numFound]
      } catch (err) {
        this.handleCancel(err).catch(() => {
          this.cancel('export', null)
        })
      }
    },
    async loadPreview () {
      try {
        this.preview.splice(0, this.preview.length)
        this.previewing = true
        let [docs, count] = await this.getPage({ limit: 10, offset: 0 })
        this.count = count
        const csv = await jsoncsv.buffered(docs, { fields: this.getFields(), fieldSeparator: ';' })
        const blob = new Blob([csv])
        Papa.parse(blob, {
          complete: ({ data }) => {
            this.preview.splice(0, this.preview.length, ...data)
            this.previewing = null
          }
        })
      } catch (err) {
        this.handleCancel(err).catch(() => {
          this.cancel('export', null)
        })
      }
    },
    async onCsv () {
      // @avoid running twice
      this.csv = true
      let offset = 0
      let count = 10
      const list = []
      const limit = 50
      let docs
      while (offset < count) {
        [docs, count] = await this.getPage({ limit, offset })
        list.push(...docs)
        offset = offset + limit
      }
      const csv = await jsoncsv.buffered(list, { fields: this.getFields(), fieldSeparator: ';' })
      const blob = new Blob([encoding.convert(csv, 'ISO-8859-1')], { type: 'text/plain;charset=ISO-8859-1' })
      this.csv = null
      download(blob, `Hippolyte_rh_${Date.now()}.csv`)
      this.$refs.modal.close()
    },
    getFields () {
      return [{
        name: 'name',
        label: 'Intitulé de Campagne',
        quoted: true
      },
      {
        name: 'totalPrice',
        label: 'Budget',
        quoted: true,
        filter: v => Intl.NumberFormat('fr-FR', { useGrouping: false }).format(v / 100)
      },
      {
        name: 'vendor',
        label: 'Vendeur',
        quoted: true
      },
      {
        name: 'prescriptor',
        label: 'Prescripteur',
        quoted: true
      },
      {
        name: 'type',
        label: 'Type',
        quoted: true,
        filter: v => subscriptionType[v]
      },
      {
        name: 'closed',
        label: 'Cloturé',
        filter: v => v ? 'Oui' : 'Non'
      },
      {
        name: 'expectation',
        label: 'Nombre de profils attendus',
        quoted: true
      },
      {
        name: 'startDate',
        label: 'Date de début',
        quoted: true,
        filter: (date) => this.formatDate(date)
      },
      {
        name: 'endDate',
        label: 'Date de fin',
        quoted: true,
        filter: (date) => this.formatDate(date)
      },
      {
        name: 'reportingDate',
        label: 'Date de reporting',
        quoted: true,
        filter: (date) => this.formatDate(date)
      },
      {
        name: 'surveyDate',
        label: 'Date de survey',
        quoted: true,
        filter: (date) => this.formatDate(date)
      },
      {
        name: 'facturationDate',
        label: 'Date de facturation',
        quoted: true,
        filter: (date) => this.formatDate(date)
      },
      {
        name: 'paymentDate',
        label: 'Date de paiement',
        quoted: true,
        filter:(date) => this.formatDate(date)
      },
      {
        name: 'sumExpenses',
        label: 'Somme des dépenses',
        quoted: true,
        filter: v => Intl.NumberFormat('fr-FR', { useGrouping: false }).format(v / 100)
      },
      {
        name: 'sentCandidate',
        label: 'Nombre de candidats envoyés',
        quoted: true,
      },
      {
        name: 'recruitedCandidate',
        label: 'Nombre de candidats recrutés',
        quoted: true,
      }]
    },
    sumExpenses (campaign) {
      return campaign.expenses.docs.reduce((acc, current) => acc + current.price, 0)
    },
    getCandidateByStatus (campaign, status){
      return campaign.searches.docs.reduce((acc, current) => acc + current[`conciliations.${status}`].numFound, 0)
    },
    formatDate (date) {
      return date ? format((typeof date === 'string' ? parseISO(date) : date), 'dd-MM-yyyy') : ''
    },
  },
  computed: {
    thead () {
      return this.preview[0]
    },
    tbody() {
      return Array.from(this.preview).slice(1)
    }
  }
}
</script>
<style scoped lang="stylus">
@require '~/colors.styl'
.csv-export
  margin auto 1em
  .sweet-content-content
    ul
      padding 0
      margin 0.4em
      display flex
      flex-direction row
      flex-wrap wrap
      list-style none
      li
        border-radius 1em
        background-color $color-iceberg
        margin 0.2em 0.6em
        padding 0.4em
        justify-self flex-start
        &:nth-last-child(-n+2)
          color white
          justify-self flex-end
    .preview
      height 20em
      position relative
      overflow-x scroll
      height 20em
      text-align left
      .floatingBarsG-container
        position absolute
        left 0
        top 0
      table
        tbody
          tr
            td
              text-overflow ellipsis
              max-width 10em
              overflow-x hidden
              white-space nowrap
</style>
