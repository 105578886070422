<template>
  <svg v-bind="$props" viewBox="0 0 780 780">
    <path class="cls-1" d="M208.65,309.83c20.4-14,31.06-24.39,54.4-27.61,26.22-3.64,56.46-.5,82.95-.05,2.45,0,4.88.21,7.3.46,7.74-23,17.92-45.72,35.93-59.67,10.77-8.3,16.94-10.2,37.67-5.22,32.91,7.89,67.16,12.79,99.47,23.52,9.58,3.17,19,6,28.21,9.17-11.42-44.35-37-84.69-73.58-113.15a198.07,198.07,0,0,0-83.47-38.11c-33.11-6.43-48.64,14.5-81.15,19.45-30,4.54-63.37,11.78-93,18.28-30.58,6.71-52.6,32.3-66.32,59.2-21.12,41.45-13.19,103.77,4.46,156.08,14.66-16.05,31.85-31.86,47.15-42.35"/>
    <path class="cls-2" d="M683.52,339c-1.61-3.28-8.88-12-11.43-14.82-13.31-16-73.65-52.48-86.12-59.32s-20.57-10.63-31.38-14.4a213,213,0,0,1,6.74,53c0,73.71-37.16,138.46-93.19,175.36,0,.86,0,1.73,0,2.59A186,186,0,0,1,436.69,582.1c23.61,7.48,47.85,12.8,72.74,10.31,35.87-3.59,60-36.38,87.07-57.8,30.29-24,59.93-49.61,80-83.16,17.55-29.4,22.86-80.51,7-112.49"/>
    <path class="cls-3" d="M426.9,217.74c-20.73-5-26.9-3.49-37.67,5.22-18,13.95-28.19,36.63-35.93,59.67,41.38,4.27,79.51,38,99.05,74.28s16.5,82.28,15.79,121.89c56-36.91,93.19-101.65,93.19-175.36a213,213,0,0,0-6.74-53c-9.21-3.2-18.64-6-28.22-9.16-32.3-10.73-66.56-15.63-99.47-23.52"/>
    <path class="cls-4" d="M468.14,478.8A195.72,195.72,0,0,1,360,511.3a193.42,193.42,0,0,1-62.5-10.47A204.58,204.58,0,0,1,188.35,412c-11.27-19-17.7-39.55-26.94-59.29-28,24.74-9.8,59.46-24.87,90.4-4.5,9.22-11.84,17.38-16.09,27.05-10.93,24.91-13.6,47.79-13.38,74.54a137.62,137.62,0,0,0,8,46.19c8.83,24.6,14.4,38.54,36.45,54.24,20.6,14.68,32.23,25.87,57.89,31.84,19.88,4.65,40.92,7.62,60.88,2,42.75-12,94.68-28.89,129.53-56.76a179.24,179.24,0,0,0,36.83-40.12A186,186,0,0,0,468.1,481.39c0-.86,0-1.72,0-2.59"/>
    <path class="cls-5" d="M452.35,356.91c-19.54-36.25-57.67-70-99.05-74.28-2.42-.25-4.86-.41-7.3-.46-26.49-.45-56.73-3.59-83,.05-23.33,3.23-34,13.63-54.38,27.6-15.31,10.5-32.5,26.31-47.16,42.36,7.65,22.66,17.11,43.44,26.85,59.83a204.58,204.58,0,0,0,109.19,88.82A193.14,193.14,0,0,0,360,511.29a195.74,195.74,0,0,0,108.1-32.49c.71-39.61,3.74-85.68-15.79-121.89"/>
  </svg>
</template>

<script>
export default {
  name: 'hippolyte.menu.logo',
}
</script>

<style lang="stylus">
@import '../colors'
.cls-1
  fill #faeda7
.cls-2
  fill #f48767
.cls-3
  fill #f8bdac
.cls-4
  fill #163ee2
.cls-5
  fill #73c2bd
</style>