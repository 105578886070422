<template>
  <ats
    v-bind="$props"
    service="smartrecruiters"
    :savingConf="savingConf"
    :credential="{
      company: '',
      token: '',
      consent: false,
      oauth: false,
      clientId: '',
      clientSecret: '',
    }"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="token">company id</label>
        <input v-model="credential.company" id="username" name="username"/>
        <modified :enable="value.credential.company !== credential.company"/>
      </div>
      <div>
        <label for="oauth">OAuth</label>
        <input type="checkbox" v-model="credential.oauth" id="mode" name="oauth"/>
        <modified :enable="value.credential.oauth !== credential.oauth"/>
      </div>
      <template v-if="credential.oauth">
        <div>
          <label for="client-id">client id</label>
          <input v-model="credential.clientId" id="client-id" name="clientId"/>
          <modified :enable="value.credential.clientId !== credential.clientId"/>
        </div>
        <div>
          <label for="client-secret">client secret</label>
          <input v-model="credential.clientSecret" id="client-secret" name="clientSecret"/>
          <modified :enable="value.credential.clientSecret !== credential.clientSecret"/>
        </div>
      </template>
      <div v-else>
        <label for="token">clé API</label>
        <input v-model="credential.token" id="password" name="password"/>
        <modified :enable="value.credential.token !== credential.token"/>
      </div>
      <div>
        <label for="secret">consentement</label>
        <input type="checkbox" v-model="credential.consent" id="consent" name="consent"/>
        <modified :enable="value.credential.secret !== credential.secret"/>
      </div>
      <div class="meta">
        <label for="meta">meta</label>
        <textarea v-model="metaConfig" cols='50' id="meta" name="meta"></textarea>
        <modified :enable="value.credential.metaConfig !== credential.metaConfig"/>
      </div>
    </template>
  </ats>
</template>
<script>
import Ats from './sdk-v3'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.smartrecruiter.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  data () {
    return {
      metaConfig: JSON.stringify(this.value.meta)
    }
  },
  methods: {
    onInput (event) {
      event.meta = this.metaConfig ? JSON.parse(this.metaConfig) : ''
      this.$emit('input', event)
    }
  }
}
</script>
