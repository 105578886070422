import luhn from 'luhn-alg'
function text (value) {
  try {
    return typeof value === 'string' && value.trim().length >= 1
  } catch {
    return false
  }
}

function match(exp, value) {
  try {
    return exp.test(value)
  } catch {
    return false
  }
}

function email (value) {
  return match(/^.+@.+$/, value)
}

function trim (value) {
  return Array.from(value)
    .filter(c => /^\d$/.test(c))
    .join('')
}

function siret (value) {
  try {
    if (typeof value === 'string') {
      value = trim(value)
      return value.length === 14 && luhn(value)
    }
    if (typeof value === 'number') {
      return String(value).length === 14 && luhn(String(value))
    }
    return false
  } catch {
    return false
  }

}

module.exports = {
  text, match, email, siret, trim
}
