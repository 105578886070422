<template>
  <ats
    v-bind="$props"
    service="digitalrecruiters"
    :savingConf="savingConf"
    :credential="{ company: '', offers_token: '', apply_token: '', consent: false }"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="apply_token">Token candidatures</label>
        <input v-model="credential.apply_token" id="apply_token" name="apply_token"/>
        <modified :enable="value.credential.apply_token !== credential.apply_token"/>
      </div>
      <div>
        <label for="offers_token">Token offres</label>
        <input v-model="credential.offers_token" id="offers_token" name="offers_token" />
        <modified :enable="value.credential.offers_token !== credential.offers_token"/>
      </div>
      <div>
        <label for="source">Source</label>
        <input v-model="credential.source" id="source" name="source" />
        <modified :enable="value.credential.source !== credential.source"/>
      </div>
    </template>
    <template v-slot:title="{ job }">{{ title(job) }}</template>
    <template v-slot:open="{ job }"><a class="button" :href="job.apply_url" target="_blank" title="ouvrir">👁️</a></template>
  </ats>
</template>
<script>
import Ats from './sdk-v3'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.digitalrecruiters.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    title (job) {
      return  [
        job.reference,
        job.title,
        job.address.parts.city
      ].filter(t => t).join(' - ')
    },
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
