<template>
  <component
    :is="iconComponent"
    role="img"
    class="inline-block"
  />
</template>

<script>
const icons = {
  unarchive: require('./icons/UnarchiveIcon.vue'),
  archive: require('./icons/ArchiveIcon.vue'),
  suggested: require('./icons/SuggestIcon.vue'),
  send: require('./icons/SendIcon.vue'),
  reject: require('./icons/RejectIcon.vue'),
  close: require('./icons/CloseIcon.vue'),
  accept: require('./icons/AcceptIcon.vue')
}
export default {
  props: {
    name: {
      type: String,
      required: true,
      validator(value) {
        return Object.prototype.hasOwnProperty.call(icons, value)
      }
    }
  },
  computed: {
    iconComponent() {
      return icons[this.name]
    }
  }
}
</script>