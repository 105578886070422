import Entity from '/entity'
const cond = k => `YesWeChat\\ServiceEntityBundle\\Query\\Condition\\${k}`

export default class Contract extends Entity {
  static async search (socket) {
    let list = await socket.service(`entity.Contract/QUERY`,{
      alias: 'c',
      class: 'Contract',
      parameters: [],
      conditions: []
    })
    return list.map(l => new Contract(l, socket))
  }
}
Contract.entityClass = 'Contract'
Contract.prototype.entityFields = ['id', 'name']
