<template>
  <div class="container" @click="$emit('click')">
    <router-view name="header" :class="{ show: !show.menu }"></router-view>
    <div class="main-container">
      <router-view name="toolbar" :class="{ show: show.toolbar}"></router-view>
      <router-view name="menu" :class="{ show: show.menu}"></router-view>
      <div :class="{ publication_container: true, show: show.interview }">
        <router-view name="interview"
          :class="{ show: show.interview }"
          @start="interview => $emit('start', interview)"
          @archive="interview => $emit('archive', interview)"
          @editRequirement="search => $emit('edit:search', search)"
          @unarchive="interview => $emit('unarchive', interview)"
          @reject="interview => $emit('reject', interview)">
        </router-view>
      </div>
      <transition name="mobile-slide-fade">
        <router-view name="profile"
          :class="{ show: show.profile }"
          @archive="interview => $emit('archive', interview)"
          @start="interview => $emit('start', interview)"
          @open="interview => $emit('open', interview)">
        </router-view>
      </transition>
    </div>
  </div>
</template>
<script>
import Interview from '/interview/interview.js'

export default {
  name: 'hippolyte.layout.office',
  data () {
    return {
      show: this.$show,
    }
  }
}
</script>
<style lang="stylus">
@require '~/colors.styl'
.vue-toast-manager_container
  z-index 9999
  .vue-toast_message
    border 1px white solid
  p
    font-size 0.8em
  .actions
    button
      border 1px white solid
      background $color-astronaut
      color white
      border-radius 4px
      padding 0.3em 0.8em
      margin 0.4em 0
      display block
      width 100%
      &:hover
        cursor pointer
  @media (min-width $breakpoint)
    max-width 500px
  #notifform
    h4.notif-title
      text-align center
      width 100%
      margin 0 0 0.5em 0
    .notif-container
      display flex
      flex-direction row
      width 100%
      .notif-icon
        width 50px
        height 40px
        margin 2em 1em
      .notif-body
        p
          margin 0.5em 0
        div.actions
          button.later
            border 0
.container
  height 100%
  display flex
  flex-grow 1
  min-height 0
  flex-direction column
  .chat-menu .room-list .room-list_container
    height 100%
  .menu_items
    margin-top 0.5em
    display flex
    flex-direction column
.main-container
  width 100%
  height 100%
  flex-grow 1
  min-height 0
  display flex
  .publication_container
  .candidate-container
  .hippo-requirement-form-questions__container
    &.show
      flex-basis 100%
    @media (min-width $breakpoint)
      flex-basis 25%
      &.show
        flex-basis 25%
  @media (min-width $breakpoint)
    .publication_container > .hippo-form
      input
        width: 50%
      .autocomplete-input
        width: calc(50% + 40px)
      .questions
        width: 80%
      .hippo-form_actions
        width: calc(50% + 80px)
      justify-content left
      margin unset
  .publication_container
    min-height 0
    flex-grow 1
    height 100%
</style>
