import Entity from '/entity'
import parseISO from 'date-fns/parseISO'
import Api from '/api'

function toDate (data) {
  if (typeof data === 'string') {
    try {
      return parseISO(data)
    } catch {
      return data
    }
  }
  return data
}

class Campaign extends Entity {
  constructor (data, socket) {
    super(data, socket)
  }
  setData (data = {}) {
    data = Object.assign({}, data)
    if ('endDate' in data) {
      data.endDate = toDate(data.endDate)
    }
    if ('startDate' in data) {
      data.startDate = toDate(data.startDate)
    }
    if ('facturationDate' in data) {
      data.facturationDate = toDate(data.facturationDate)
    }
    if ('surveyDate' in data) {
      data.surveyDate = toDate(data.surveyDate)
    }
    if ('reportingDate' in data) {
      data.reportingDate = toDate(data.reportingDate)
    }
    if ('paymentDate' in data) {
      data.paymentDate = toDate(data.paymentDate)
    }
    super.setData(data)
  }
  static create (data, socket) {
    return new Campaign(data, socket)
  }
  sumExpenses () {
    return this.expenses.docs.reduce((acc, current) => acc + current.price, 0)
  }
  sumImpressions () {
    return this.expenses.docs.reduce((acc, current) => acc + (current.impressions ?? 0), 0)
  }
  sumClicks () {
    return this.expenses.docs.reduce((acc, current) => acc + (current.clicks ?? 0), 0)
  }
  sumCoverage () {
    return this.expenses.docs.reduce((acc, current) => acc + (current.coverage ?? 0), 0)
  }
  sentCandidate () {
    return this.searches.docs.reduce((acc, current) => acc + current['conciliations.sent'].numFound, 0)
  }
  get entityFields () {
    return ['id', 'name', 'expectation', 'effectiveRecruitments', 'comment', 'startDate', 'endDate']
  }
}

Campaign.api = function (socket) {
  return new Api(socket, 'campaigns')
}

export default Campaign