import debug from 'debug'
import axios from 'axios'
const CancelToken = axios.CancelToken
const log = debug('cancel')

export default {
  data () {
    return {
      cancels: {}
    }
  },
  destroyed () {
    for (const source in this.cancels) {
      this.cancel(source, null)
    }
  },
  methods: {
    cancel (loader, value) {
      log(loader, value)
      if (this.cancels[loader] && typeof this.cancels[loader].cancel === 'function') {
        this.cancels[loader].cancel()
      }
      if (typeof value === 'undefined') {
        const source = CancelToken.source()
        this.cancels[loader] = source
        return source
      } else {
        this.cancels[loader] = value
        return value
      }
    },
    token (loader) {
      return this.cancels[loader]?.token
    },
    async handleCancel (err) {
      if (!axios.isCancel(err)) {
        throw err
      }
    }
  }
}
