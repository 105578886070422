export const filterList = {
  'Tout voir': null,
  'Non Facturée' : { facturation: false, late: false, payment: null },
  'Non Soldée': { facturation: true, late: false, payment: false },
  'En retard de paiement': { facturation: true, late: true, payment: false },
  'Soldée': { facturation: true, late: false, payment: true }
}

export const sortFieldList = {
  'Date de début croissante': ['startDate', 'asc'],
  'Date de début décroissante': ['startDate', 'desc'],
  'Date de fin croissante': ['endDate', 'asc'],
  'Date de fin décroissante': ['endDate', 'desc'],
  'Date de facturation croissante': ['facturationDate', 'asc'],
  'Date de facturation décroissante': ['facturationDate', 'desc'],
  'Libellé de campagne A-Z': ['name', 'asc'],
  'Libellé de campagne Z-A': ['name', 'desc'],
  'Coût par candidat croissant': ['cost', 'asc'],
  'Coût par candidat décroissant': ['cost','desc']
}
