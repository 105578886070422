<template>
  <article class="box">
    <span :title="description">
      <p v-if="title" class="title">{{ title }}</p>
      <div class="content">
        <slot></slot>
      </div>
    </span>
  </article>
</template>

<script>
export default {
  name: 'hippolyte.components.box',
  props: {
    title: { type: String },
    description: { type: String, default: '' }
  }
}
</script>

<style lang="stylus" scoped>
@require '~/colors.styl'

.box
    box-sizing border-box
    width 372px
    padding 24px
    border-radius 16px
    border 1px solid rgba(40, 40, 40, 0.15)
    background white
    box-shadow 0px 6px 6px 0px rgba(40, 40, 40, 0.05)
    .title
        text-align center
        margin 0px
        font-size 20px
        font-weight bolder
    .content
        gap 24px
        display flex
        justify-content center
</style>
