<template>
  <ats
    v-bind="$props"
    service="teamtailor"
    :credential="{ token: '', url: '' }"
    :savingConf="savingConf"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="token">url</label>
        <input v-model="credential.url" id="url" name="url"/>
        <modified :enable="value.credential.url !== credential.url"/>
      </div>
      <div>
        <label for="token">token</label>
        <input v-model="credential.token" id="token" name="token"/>
        <modified :enable="value.credential.token !== credential.token"/>
      </div>
      <div class="meta">
        <label for="meta">meta</label>
        <textarea v-model="metaConfig" cols='50' id="meta" name="meta" :placeholder="placeholder"></textarea>
        <modified :enable="value.credential.metaConfig !== credential.metaConfig"/>
      </div>
    </template>
    <template v-slot:title="{ job }">{{ title(job) }}</template>
  </ats>
</template>
<script>
import Ats from './sdk-v3'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'


export default {
  name: 'hippolyte.services.teamtailor.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  data () {
    return {
      metaConfig: JSON.stringify(this.value.meta),
      placeholder: '{"customField":{"value":["id de l\'option"],"custom-field":{"data":{"id":"id du champs","type":"custom-fields"}},"owner":{"data":{"type":"candidates"}}}}'
    }
  },
  methods: {
    title (job) {
      return  [
        job.id || job.reference,
        job.title || job.hippolyte?.search.title,
        job['employment-type'] || 'plus de contrat',
        this.getLocation(job) || job.hippolyte?.search.location?.docs[0]?.city
      ].filter(t => t).join(' - ')
    },
    onInput (event) {
      event.meta = this.metaConfig ? JSON.parse(this.metaConfig) : ''
      this.$emit('input', event)
    },
    getLocation (vacancy) {
      return vacancy.city
    },

  }
}
</script>
