<template>
  <div class="table-container">
    <div v-if="busy" style="position: relative; top: 0; margin-top: -2em; left: 0;">
      <spinner style="transform: scale(0.2, 0.2);"></spinner>
    </div>
    <div v-else-if="recruiter && recruiter.id">
      <button v-on:click.stop="csv"> export </button>
      <table>
        <caption>{{recruiter.company}}</caption>
        <thead>
          <tr>
            <th></th>
            <th v-for="v in table" :key="v.title">
              {{ v.title }}
            </th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td :key="'label'">Reçus</td>
            <td v-for="(v, i) in table" :key="'suggested'+i">
              {{ v.suggested }}
            </td>
            <td :key="'total'" :title="'Nombre total de candidatures reçus sur la période selectionnée'">{{ total.suggested }}</td>
          </tr>
          <tr>
            <td :key="'label'">Envoyés</td>
            <td v-for="(v, i) in table" :key="'sent'+i">
              {{ v.sent }}
            </td>
            <td :key="'total'" :title="'Nombre total de candidatures envoyés sur la période selectionnée'">{{ total.sent }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import Recruiter from '/user/recruiter/recruiter.entity.js'
import { Labels } from '/interview/status'
import { add, endOfDay, startOfDay } from 'date-fns'
import Spinner from '/layout/spinner'
import cancel from '/cancel.mixin.js'
import { Solr, eq, and, or } from '/solr'
import { Csv } from './export'
import pick from 'ramda/src/pick'

function sum (array, status) {
  return array.reduce((acc, current) => acc + current[status], 0)
}

export default {
  name: 'hippolyte.reporting.searchTables',
  props: {
    a: Boolean,
    recruiter: Recruiter || Object,
    search: Boolean,
    start: Date,
    end: Date
  },
  components: { Spinner },
  mixins: [cancel],
  data () {
    return {
      exp: false,
      Labels,
      table: [],
      total: {
        suggested: 0,
        sent: 0
      },
      busy: false
    }
  },
  watch: {
    recruiter (val, old) {
      old?.off('update', this.load, this)
      if (val) {
        this.reset()
        if (val.id) {
          this.load()
        } else {
          val.on('update', this.load, this)
        }
      } else {
        this.reset()
      }
    },
    start: 'load',
    end: 'load',
    a: 'load'
  },
  mounted () {
    this.load()
  },
  methods: {
    async csv () {
      this.exp = true
      await Csv(this.toTable())
      this.exp = false
    },
    reset () {
      this.table = []
      this.total = {
        suggested: 0,
        sent: 0
      }
    },
    toTable () {
      return [
        ['', ...this.table.map(s => s.title)],
        ['Reçus', ...this.table.map(s => s.suggested)],
        ['Envoyés', ...this.table.map(s => s.sent)]
      ]
    },
    async load () {
      if(!this.busy) {
        this.busy = true
        this.reset()
        try {
          this.cancel('table')
          let table = {
            suggested: [],
            sent: []
          }
          const statuses = ['sent', 'suggested']
          await Promise.all(statuses.map(async (status) => {
            const req = new Solr({
              entity: 'Conciliation',
              raw: true,
              query: and(),
              limit: 0,
              offset: 0
            })
            const c = req.join({
              entity: 'ConciliationStatus',
              query: and(eq('status', status), 'date:[@start TO @end]')
            })
            if (this.recruiter && this.recruiter.id) {
              req.query.push(eq('recruiter', this.recruiter.id))
            }
            if (this.a) {
              req.query.push(`{!join entity=Search v="active:true"}`)
            }
            req.parameter('start', add(startOfDay(this.start), { minutes: -this.start.getTimezoneOffset() }).toISOString())
            req.parameter('end', add(endOfDay(this.end), { minutes: -this.end.getTimezoneOffset() }).toISOString())
            const facets = [{
              name: 'search',
              type: 'terms',
              field: 'search',
              limit: 1000
            }]
            const data = await this.$socket.service('entity_solr/QUERY', Object.assign(req.send(), { facets }), this.token('table'))
            data.facets?.search?.buckets?.map((element) => {
              if(status == 'suggested') {
                table.suggested.push(element)
                this.total.suggested += parseInt(element.count)
              } else {
                table.sent.push(element)
                this.total.sent += parseInt(element.count)
              }
            })
          }))
          this.table = table.suggested.map((element) => {
            const sentElement = table.sent.find((el) => el.val == element.val)
            return {
              title: element.val,
              suggested: element.count,
              sent: sentElement ? sentElement.count : 0
            }
          })
          table.sent.forEach((element) => {
            if(!this.table.some((el) => el.title == element.val)) {
              this.table.push({
                title: element.val,
                suggested: 0,
                sent: element.count
              })
            }
          })
          const searches = this.table.map((element) => {
            return element.title
          })
          const searchesReq = new Solr({
            entity: 'Search',
            raw: true,
            query: and(or(...searches.map(s => eq('id', s)))),
            fields: ['title', 'id'],
            limit: searches.length
          })
          const searchesData = await this.$socket.service('entity_solr/QUERY', searchesReq.send(), this.token('table'))
          this.table = this.table.map((element) => {
            return {
              title: searchesData.docs.find((el) => el.id == element.title).title,
              suggested: element.suggested,
              sent: element.sent
            }
          })
          this.cancel('table', null)
          this.busy = false
        } catch (err) {
          this.handleCancel(err).catch(() => {
            this.cancel('table', null)
            this.busy = false
          })
        }
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
.table-container
  max-width 75vw
  overflow-x scroll
table
  border-collapse: collapse
  padding 1em
  caption
    font-weight bold
    padding-bottom 1em
  thead, th
    border 2px solid $color-dark-grey
    padding 0.5em

  thead tr th:nth-child(1)
    border-top-color transparent
    border-left-color transparent
  td
    border 1px solid $color-dark-grey
    padding 1em
    text-align center

</style>
