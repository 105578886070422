<template>
  <ats
    v-bind="$props"
    service="jobaffinity"
    :savingConf="savingConf"
    :credential="{ id: '', token: '', api: false }"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="token">company id</label>
        <input v-model="credential.company" id="username" name="username"/>
        <modified :enable="value.credential.company !== credential.company"/>
      </div>
      <div>
        <label for="token">clé API</label>
        <input v-model="credential.token" id="password" name="password" />
        <modified :enable="value.credential.password !== credential.password"/>
      </div>
      <div>
        <label for="api">mode API</label>
        <input v-model="credential.api" id="api" name="api" type="checkbox"/>
        <modified :enable="value.credential.api !== credential.api"/>
      </div>
    </template>
  </ats>
</template>
<script>
import Ats from './sdk-v3'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.jobaffinity.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
