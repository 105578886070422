<template>
  <span class="date-update">
    <button @click="onOpen" class="edit-campaign-btn">
      <icon-edit
        :title="'Modifier les dates'"
      ></icon-edit>
    </button>
    <sweet-modal ref="modal" width="60%">
      <header>
        <p> Mise à jours des informations de campagne pour </p>
        {{ campaign.name }}
      </header>
      <spinner v-if="saving" style="transform: scale(0.2, 0.2);"></spinner>
      <form @submit.prevent="">
        <span class='input-line'>
          <div>
            <label for="facturation">Date de facturation :</label>
            <v2-datepicker
              v-model="facturation"
              format="DD/MM/yyyy"
              :lang="lang"
              :customLocals="datepickerLocale"
              placeholder="Choisir une date"
            ></v2-datepicker>
          </div>
          <div>
            <label for="payment">Date de solde :</label>
            <v2-datepicker
              v-model="payment"
              format="DD/MM/yyyy"
              :lang="lang"
              :customLocals="datepickerLocale"
              placeholder="Choisir une date"
            ></v2-datepicker>
          </div>
          <div>
            <label for="reporting">Date de reporting :</label>
            <v2-datepicker
              v-model="reporting"
              format="DD/MM/yyyy"
              :lang="lang"
              :customLocals="datepickerLocale"
              placeholder="Choisir une date"
            ></v2-datepicker>
          </div>
          <div>
            <label for="survey">Date de l'enquête :</label>
            <v2-datepicker
              v-model="survey"
              format="DD/MM/yyyy"
              :lang="lang"
              :customLocals="datepickerLocale"
              placeholder="Choisir une date"
            ></v2-datepicker>
          </div>
        </span>
        <span class="input-line">
          <div>
            <label for="satisfaction">Score de satisfaction :</label>
            <div>
              <input
                type="number"
                v-model="satisfaction"
                :id="'satisfaction'"
                :min='0'
                :max='10'
              />
            </div>
          </div>
          <div>
            <label for="recruitements">Recruitements effectifs :</label>
            <div>
              <input
                type="number"
                v-model="recruitements"
                :id="'recruitements'"
                :min='0'
              />
            </div>
          </div>
        </span>
        <span class="input-line">
          <div class="label-left">
            <label for="comment">Informations Complémentaires :</label>
            <div>
              <textarea v-model="comment" :id="'comment'" cols="30" rows="10"></textarea>
            </div>
            <div class="button-container">
              <button v-on:click="onChange('comment', comment)" :disabled="disabled('comment')">Valider</button>
            </div>
          </div>
        </span>
        <span class="list">Liste des dépenses :
          <button v-on:click='addExpenseLine' :disabled="newExpense.length > 0">
            <icon-add
              :title="'Ajouter une source de dépense'"
              :disabled="newExpense.length > 0"
            ></icon-add>
          </button>
          <expense-list
            :expenses="campaign.expenses.docs"
            :modal="true"
            :deleteExpense="this.delete"
          >
          </expense-list>
          <form v-if="newExpense.length > 0" @submit.prevent="registerExpense">
            <span v-for="(expense, i) in newExpense" class='input-line left' :key="expense+i">
              <input type='text' v-model.lazy="newExpense[i].type" placeholder="Source" list="sources" required>
              <input type='number' v-model.lazy="newExpense[i].price" min="0" placeholder="Prix" required>
              <button type="submit" >
                <icon-add
                  :title="'Enregistrer la dépense'"
                ></icon-add>
              </button>
            </span>
            <datalist  id="sources" >
              <option v-for="source in sources" :key="source" :value="source">{{source}}</option>
            </datalist>
          </form>
        </span>
      </form>
    </sweet-modal>
  </span>
</template>

<script>
import Campaign from '/campaign/entity'
import { add, parseISO } from 'date-fns'
import Spinner from '/layout/spinner'
import { SweetModal } from 'sweet-modal-vue'
import cancel from '/cancel.mixin.js'
import IconEdit from '/icons/edit'
import IconAdd from '/icons/add'
import datepickerLocale from '/locale/datepicker_locale'
import ExpenseList from './expenseList.vue'

export default {
  name: 'hippolyte.campaign.update',
  components: {
    SweetModal,
    IconEdit,
    Spinner,
    ExpenseList,
    IconAdd
  },
  mixins: [cancel],
  props: {
    campaign: Campaign,
  },
  data () {
    return {
      saving: false,
      lang: 'fr',
      datepickerLocale,
      facturation: this.campaign.facturationDate || null,
      payment: this.campaign.paymentDate || null,
      survey: this.campaign.surveyDate || null,
      reporting: this.campaign.reportingDate || null,
      satisfaction: this.campaign.satisfaction || null,
      recruitements: this.campaign.effectiveRecruitements || null,
      comment: this.campaign.comment || null,
      newExpense: [],
      sources: [
        'Facebook',
        'Twitter',
        'TikTok',
        'Snapchat',
        'LinkedIn'
      ]

    }
  },
  watch: {
    facturation (val) {
      if (val !== this.campaign.facturationDate) {
        this.onChange('facturationDate', val)
      }
    },
    payment (val) {
      if (val !== this.campaign.paymentDate) {
        this.onChange('paymentDate', val)
      }
    },
    reporting (val) {
      if (val !== this.campaign.reportingDate) {
        this.onChange('reportingDate', val)
      }
    },
    survey (val) {
      if (val !== this.campaign.surveyDate) {
        this.onChange('surveyDate', val)
      }
    },
    satisfaction (val) {
      if (val !== this.campaign.satisfaction) {
        this.onChange('satisfaction', val)
      }
    },
    recruitements (val) {
      if (val !== this.campaign.effectiveRecruitements) {
        this.onChange('effectiveRecruitements', val)
      }
    }
  },
  methods: {
    //@todo faire un bouton pour pouvoir ajouter une nouvelle source de dépense
    onOpen () {
      this.$refs.modal.open()
      this.survey = this.campaign.surveyDate
      this.facturation = this.campaign.facturationDate
      this.payment = this.campaign.paymentDate
      this.reporting = this.campaign.reportingDate
      this.satisfaction = this.campaign.satisfaction
      this.recruitements = this.campaign.effectiveRecruitements
      this.comment = this.campaign.comment
    },
    async registerExpense () {
      this.saving = true
      try{
        this.cancel('add')
        this.newExpense[0].price = this.newExpense[0].price * 100
        const params = Object.assign(this.newExpense[0], { campaign: this.campaign.id })
        await Campaign.api(this.$socket)
          .addExpense(params, this.token('add'))
          .then(this.campaign.expenses.docs.push(params))
          .then(this.newExpense.splice(0, this.newExpense.length))
      } catch (err) {
        this.handleCancel(err)
      }
      this.saving = false
    },
    addExpenseLine () {
      this.newExpense.unshift({
        type:'',
        price:''
      })
    },
    async delete (expense) {
      try {
        this.cancel('delete')
        this.saving = true
        const response = await Campaign.api(this.$socket)
          .deleteExpense(expense, this.token('delete'))
          .then(this.campaign.expenses.docs = this.campaign.expenses.docs.filter(e => e.id !== expense.id))
        this.cancel('date', null)
      } catch (err) {
        this.handleCancel(err)
      }
      this.saving = false
    },
    disabled (field) {
      return this[field] === this.campaign[field] || this[field] === null
    },
    setValue (value) {
      if (value) {
        return (value instanceof Date) ? add(value, { minutes: -value.getTimezoneOffset() }).toISOString() : value
      } else {
        return null
      }
    },
    async onChange (field, value) {
      try {
        this.cancel('date')
        this.saving = true
        const params = {
          field,
          id: this.campaign.id,
          value: this.setValue(value)
        }
        await Campaign.api(this.$socket)
          .update(params, this.token('date'))
          .then(r => Object.assign(this.campaign, { [r.data.field] : (r.data.value instanceof Date) ? parseISO(r.data.value) : r.data.value }))
        this.cancel('date', null)
      } catch (err) {
        this.handleCancel(err)
      }
      this.saving = false
    }
  },
  computed: {
  }
}
</script>
<style scoped lang="stylus">
@require '~/colors.styl'
.date-update
  margin auto 1em
  color black
  .edit-campaign-btn
    background none
  .sweet-modal-overlay
    z-index 1499
  .sweet-content-content
    header
      padding-bottom 3em
      text-align center
    form
      display flex
      flex-direction column
      justify-content space-around
      .list
        padding-left 3em
        button
          background-color transparent
      .input-line
        display flex
        align-self space-between
        justify-content space-around
        padding-bottom 2em
        div
          text-align center
          display flex
          flex-direction column
          &.label-left
            text-align inherit
          .button-container
            display flex
            align-items end
          button
            width 8em
            margin 0.5em
            border-radius 10px
            background-color $color-green
            color $color-white
            &:disabled
              background-color $color-blue_grey_light
        &.left
          justify-content left
          align-items center
          svg
            padding-left 1em
          input
            width 5em
            margin-right 0.2em
        input, textarea
          border 1px solid $color-input-border
          border-radius 4px
        input
          height 32px
          font-size 16px
          width 10em
        textarea
          font-size inherit
          font-family inherit
          width 990px
          height 150px
        .v2-date-wrap
          width 12em
      ul
        list-style none
</style>
