<template>
  <div>
    <div v-if="busy">
      <spinner></spinner>
    </div>
    <div v-else class="stats_container">
      <div class="stats_row">
        <help :title="'Statistiques sourcing'">
          <template v-slot:content>
            <p>Statistiques sourcing sur toutes les cndidatures reçus</p>
            <p>Candidatures reçues = pas seulement les candidatures envoyées au recruteur</p>
          </template>
        </help>
      </div>
      <div class="stats_row">
        <div class="key_stat_container">
          <div class="picto_container">
            <candidates-icon width="40px" height="40px" class="picto"></candidates-icon>
          </div>
          <div class="key_stat">
            <p class="value">{{ statsData.totalCount }}</p>
            <p>Candidatures reçues</p>
          </div>
        </div> 
      </div> 
      <div class="stats_row">
        <div id="reject_container">
          <h4>Motif de refus</h4>
          <progress-bar :bars="rejectSteps" :total="totalRejectedCount" v-if="rejectSteps.length > 0"></progress-bar>
          <div class="empty_stats_msg" v-else>
            <p>Aucune donnée à afficher.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Recruiter from '/user/recruiter/recruiter.entity.js'
import Search from '/requirement/entity'
import { add, endOfDay, startOfDay } from 'date-fns'
import Spinner from '/layout/spinner'
import Statistics from '/reporting/statistics.entity.js'
import CandidatesIcon from '/icons/candidates'
import EyeIcon from '/icons/eye'
import PaperPlaneIcon from '/icons/paper_plane'
import Donut from '/reporting/donut'
import ProgressBar from '/reporting/progressBar'
import { Status, Labels } from '/interview/status'
import Conciliation from '/interview/interview.js'
import Help from '/components/help'
import axios from 'axios'
const CancelToken = axios.CancelToken

const statuses = [
  Status.sent,
  Status.opened,
  Status.started,
  Status.scheduling_meeting,
  Status.met,
  Status.recruited,
  Status.rejected,
  Status.archived
]

export default {
  name: 'hippolyte.reporting.sourcing',
  props: {
    a: Boolean,
    recruiter: Recruiter,
    search: Search,
    start: Date,
    end: Date
  },
  components: {
    Spinner,
    CandidatesIcon,
    EyeIcon,
    PaperPlaneIcon,
    ProgressBar,
    Donut,
    Help
  },
  data () {
    return {
      busy: false,
      cancel: null,
      reasons: [],
      defaultStatsData: {
        totalCount: 0,
        reasons: []
      },
      statsData: {
        totalCount: 0,
        reasons: []
      }
    }
  },
  watch: {
    search: 'load',
    recruiter: 'load',
    start: 'load',
    end: 'load',
    a: 'load',
  },
  computed: {
    rejectSteps () {
      const steps = this.statsData.reasons.map(r => {
        return {
          title: this.reasons.find(({ id }) => id === r.label).name,
          value: r.count,
          style: 'slice_custom'
        }
      })
      return steps.filter(s => s.value > 0)
    },
    totalRejectedCount () {
      const initialValue = 0;
      return this.statsData.reasons.reduce(
        (accumulator, currentValue) => accumulator + currentValue.count,
        initialValue,
      );
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    async load () {
      this.busy = true
      Object.assign(this.statsData, this.defaultStatsData)
      this.loadReasons().then(async () => {
        const opts = {
          recruiter: this.recruiter?.id,
          searches: this.search ? [this.search.id] : null,
          startDate: this.start,
          endDate: this.end
        }
        Object.assign(this.statsData, await Statistics.api(this.$socket).get(opts, {}, '/sourcing').then(({ data }) => data))
        this.busy = false
      })
    },
    async loadReasons () {
      try {
        this.cancel = CancelToken.source()
        const reasons = await Conciliation.getStatusReasons({}, this.$socket, this.cancel.token)
        if (reasons.numFound) {
          this.reasons.splice(0, this.reasons.length, ...reasons.docs)
        }
        console.log('erasons', this.reasons)
      } catch (err) {
        if (!axios.isCancel(err)) {
          throw err
        }
      }
    }
  }
}
</script>
<style lang="stylus">
@require '~/colors.styl'

.stats_container
  .slice
    stroke $color-bg-grey
    stroke-width 2px
  .slice_no,
  .slice_empty
    fill $color-bg-grey
    background-color @fill
  .slice_yes
    fill $color-azur
    background-color @fill
  .slice_started,
  .slice_custom.slice_2
    fill $color-iceberg
    background-color @fill
  .slice_rejected
  .slice_custom.slice_6
    fill $color-red
    background-color @fill
  .slice_opened,
  .slice_custom.slice_1
    fill $color-azur
    background-color @fill
  .slice_sent,
  .slice_custom.slice_0
    fill $color-raisin
    background-color @fill
  .slice_met,
  .slice_custom.slice_4
    fill $color-cyan
    background-color @fill
  .slice_scheduling_meeting,
  .slice_custom.slice_3
    fill $color-iceberg-light
    background-color @fill
  .slice_recruited,
  .slice_custom.slice_5,
  .donut-legend_slice-color.slice_no
    fill $color-peach
    background-color @fill
  .slice_archived,
  .slice_custom.slice_7
    fill $color-yellow
    background-color @fill
    
  .stats_row
    display flex
    flex-direction row
    .key_stat_container
      background #fff
      border-radius 10px
      display flex
      justify-content flex-start
      align-items center
      width 31%
      margin-right 3.5%
      margin-bottom 0
      &:last-child
        margin-right 0
        margin-bottom 0
      .picto_container
        display flex
        padding 24px
        .picto
          padding 10px
          background #E8EDFB
          border-radius 8px
        svg, svg *
          stroke #164ee2
          fill none
      .key_stat
        padding-right 24px
        p
          margin 0
        .value
          margin-bottom 8px
          font-size 24px
          font-weight 600
    #donut_container
      min-width 200px
      width 25%
    #steps_container
      margin-left 24px
      margin-right 24px
      width 30%
    #reject_container
      width calc(45% - 48px)
    .legend_value
      color $color-black
      float left
      font-weight 600
    .legend_title
      font-size 12px
      margin-top 4px
      float right
      text-align right
      max-width 85%
      &:first-letter
        text-transform uppercase
    .float_container::after
      content ''
      clear both
      display block
    .legend_percent
      width 100%
      border-radius 20px
      background $color-bg-grey
      height 12px
      margin-top 6px
      margin-bottom 20px
      .legend_percent_active
        display block
        height 100%
        border-radius 20px
</style>
