<template>
  <ats
    v-bind="$props"
    service="careerbuilder"
    :savingConf="savingConf"
    :credential="{ server: '', user: '', password: '', sourceid: '' }"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="server">url API</label>
        <input v-model="credential.server" id="server" name="server"/>
        <modified :enable="value.credential.server !== credential.server"/>
      </div>
      <div>
        <label for="user">user API</label>
        <input v-model="credential.user" id="user" name="user"/>
        <modified :enable="value.credential.user !== credential.user"/>
      </div>
      <div>
        <label for="password">password API</label>
        <input v-model="credential.password" id="password" name="password" />
        <modified :enable="value.credential.password !== credential.password"/>
      </div>
      <div>
        <label for="sourceid">sourceid</label>
        <input v-model="credential.sourceid" id="sourceid" name="sourceid"/>
        <modified :enable="value.credential.sourceid !== credential.sourceid"/>
      </div>
    </template>
    <template v-slot:title="{ job }">{{ title(job) }}</template>
  </ats>
</template>
<script>
import Ats from './sdk-v2'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

const mapping = {
  'contract': 'Prop3',
  'country': 'Prop31',
  'region': 'Prop32',
  'department': 'Prop33',
  'trade': 'Prop36',
  'reference': 'Prop37',
  'title': 'Prop38',
  'contract2': 'Prop42',
  'description': 'Prop44',
  'profil': 'Prop45',
  'skills': 'Prop46',
  'start': 'Prop87',
  'duration': 'Prop88',
  'salary': 'Prop90',
  'location': 'Prop100',
  'city': 'Prop101',
  'coordinates': 'Prop178',
  'zip': 'Prop502'
}

export default {
  name: 'hippolyte.services.careerbuilder.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  computed: {
    config () {
      // add default mapping
      return this.value.meta ? this.value : Object.assign(this.value, { meta: mapping })
    }
  },
  methods: {
    title (job) {
      return  [
        job.id || job.reference,
        job[this.config.meta.title] || job.hippolyte?.search.title,
        job[this.config.meta.city] || job.hippolyte?.search.location?.docs[0]?.city

      ].filter(t => t).join(' - ')
    },
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
