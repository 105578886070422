<template>
  <article>
      <table>
        <thead>
          <tr>
            <td>titre</td>
            <td>ville</td>
            <td>métier</td>
            <td>
              <input type="checkbox" v-model="active" id="active"/>
              <label for="active">active</label>
            </td>
            <td v-if="loading"><spinner style="transform: scale(0.2, 0.2);"></spinner></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="search in searches" @click="select(search)" :class="{ selected: selected === search}">
            <td>{{search.title}}</td>
            <td>
              <template v-if="search.location.docs.length">{{search.location.docs[0].name}}</template>
            </td>
            <td>
              <template v-if="search.trade.docs.length">{{search.trade.docs[0].name}}</td>
            <td>{{search.active ? 'active' : ''}}</td>
          </tr>
          <tr class="fake" v-for="i in new Array(10 - searches.length)">&nbsp;</tr>
        </tbody>
      </table>
    <footer class="paginate">
      <paginate :count="count" v-model="offset" :limit="limit"></paginate>
    </footer>
  </article>
</template>
<script>
import Spinner from '/layout/spinner'
import Paginate from '/layout/paginate'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import cancel from '/cancel.mixin.js'

export default {
  name: 'hippolyte.services.config.searchList',
  components: { Spinner, Paginate },
  props: {
    recruiter: Recruiter
  },
  data () {
    return {
      selected: null,
      searches: [],
      active: true,
      count: 0,
      limit: 10,
      offset: 0,
      loading: false
    }
  },
  mixins: [cancel],
  mounted() {
    this.paginate()
  },
  watch: {
    offset:'paginate',
    active () {
      this.offset = 0
      this.paginate()
    },
    recruiter () {
      this.offset = 0
      this.paginate()
    }
  },
  methods: {
    async paginate () {
      this.loading = true
      this.searches.splice(0, this.searches.length)
      const { docs, numFound } = await Search.list({
        solr: true,
        recruiter: this.recruiter.id,
        active: this.active || null,
        offset: this.offset,
        limit: this.limit
      }, this.$socket)
      this.searches.push(...docs)
      this.count = numFound
      this.loading = false
    },
    select (search) {
      this.selected = search
      this.$emit('select', search)
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
section
  flex-direction column
  overflow scroll
  height 100%
  .config-container
    display block
    border-radius 5px
    background #eee
    margin 1em
    width 98%
    p
      margin 1em
    .recruiter-search
      margin 1em
      width 30%
      &-input
        border none
        border-radius 5px
        width 100%
  header
    margin 1em
    overflow scroll
    width 95vw
  table
    margin 1em
    border-spacing 0
    tr
      cursor pointer
      &.fake
        height 32.8px
      td
        color: #0a2e36
        padding 0.4em 1em 0.4em 0.4em
    .selected
      border-right 6px #45ADA7 solid
      background alpha($color-astronaut, 0.2)
  article
    select
      margin 2em 0 1em 15em
      font-size 16px
      background-color transparent
      .activeicon
        color #45ADA7
        opacity 0
      .isActive
        opacity 1
  input
    border 1px #0a2e36 solid
    border-radius 3px
    height 2em
  input[type="checkbox"]
    height auto
  label
    margin-right 1em
  .nearest
    padding-bottom 1em
  article.search
    overflow scroll
    max-height 30vh
  button
    background white
    border none
    color black
    border-radius 2em
    line-height 1em
    margin 0.2em 0
    padding 0.4em 1.5em
    font-size 0.9em
    cursor pointer
    text-decoration none
    &::first-letter
      text-transform capitalize
    &:focus, &:hover
      outline none
    &.invert
      color white
      border 1px white solid
    &:disabled
      color #ccc
      cursor default
</style>
