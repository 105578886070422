<template>
   <div class="nearest">
    <header><b>Au plus proche</b></header>
    <section v-if="search">
      <table>
        <thead>
          <tr>
            <th>latitude</th>
            <th>longitude</th>
            <th>{{field}}</th>
            <th>code postal</th>
            <th>cc</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rule, i) in agencies" :key="i + '_rule'">
            <td><input v-model="rule.coords.latitude"/></td>
            <td><input v-model="rule.coords.longitude"/></td>
            <td><input v-model="rule[field]"/></td>
            <td><input v-model="rule.postcode"/></td>
            <td><input v-model="rule.cc"/></td>
            <td><button @click="remove(i)">Supprimer</button></td>
          </tr>
          <tr>
            <td><input v-model="nearest.coords.latitude"/></td>
            <td><input v-model="nearest.coords.longitude"/></td>
            <td><input v-model="nearest[field]"/></td>
            <td><input v-model="nearest.postcode"/></td>
            <td><input v-model="nearest.cc"/></td>
            <td><button @click="add(nearest)">Ajouter</button></td>
          </tr>
        </tbody>
      </table>
      <button @click="save()">Enregistrer</button>
      <button @click="del()">Détruire</button>
      <input type="file" @change="onFile" id="csv_file"/>
      <label for="csv_file" title="importer un csv (séparateur ';') avec une colonne code postal puis emails(utiliser ',' pour plusieurs emails)">csv</label>
      <button @click="onHistory" class="search-action">
        historique
      </button>

    </section>
    <p v-else><i>Selectionnez une recherche</i></p>
  </div>
</template>
<script>
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Spinner from '/layout/spinner'
import Papa from 'papaparse'
import axios from 'axios'
import { download } from '/fun.js'
import encoding from 'encoding'

export default {
  name: 'hippolyte.services.nearest',
  components: { Spinner },
  props: {
    service: String,
    recruiter: Recruiter,
    search: Search,
    field: String,
    multiple: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    search () {
      this.load()
    }
  },
  mounted () {
    this.load()
  },
  data () {
    return {
      loading: false,
      saving: false,
      count: 0,
      agencies: [],
      nearest: this.blank()
    }
  },
  methods: {
    blank () {
      return { coords: { longitude: '', latitude: '' }, [this.field]: '', postcode: '', cc: '' }
    },
    reset () {
      this.agencies.splice(0, this.agencies.length)
    },
    remove (i) {
      this.agencies.splice(i, 1)
    },
    add () {
      this.agencies.push(this.nearest)
      this.nearest = this.blank()
    },
    async save () {
      this.saving = true
      try {
      const data = await  this.$socket.service(
          `${this.service}.nearest.config/SAVE`,
          {
            search: this.search.id,
            config: this.multiple ? this.agencies.map(a => Object.assign({}, a, { [this.field]: a[this.field].split(',') })) : this.agencies
          }
        )
        if (this.multiple) {
          data.map(a => Object.assign(a, { [this.field]: a[this.field].join(',') }))
        }
        this.agencies.splice(0, this.agencies.length, ...data)
        this.$toast('configuration enregistré', { theme: 'success'})
      } catch (err) {
        this.$toast(err.response.data, { theme: 'error'})
      }
      this.saving = false
    },
    del () {
      this.saving = true
      this.$socket.service(
        `${this.service}.nearest.config/DEL`,
        {
          search: this.search.id
        }
      ).then(() => this.reset())
      .finally(() => {
        this.saving = false
      })
    },
    async load () {
      this.reset()
      if (this.search) {
        this.loading = true
        // @todo cancel
        this.$socket.service(`${this.service}.nearest.config/LIST`, { search: this.search.id })
          .then(
            c => {
              if (this.multiple) {
                c = c.map(a => Object.assign(a, { [this.field]: a[this.field].join(',') }))
              }
              c.forEach(a => Object.assign(this.blank(), a))
              this.agencies.splice(0, this.agencies.length, ...c)
            },
            () => this.reset()
          ).finally(() => {
            this.loading = false
          })
     }
    },
    onFile (event) {
      const csv = event.target.files[0]
      Papa.parse(csv, {
        delimiter: ';',
        complete: async ({ data }) => {
          const swap = Array.from(this.agencies, a => {
            return JSON.parse(JSON.stringify(a))
          })
          const fresh = data
            .filter(([postcode, field, cc]) => postcode?.length && field?.length)
            .map(([postcode, field, cc]) => {
              const mails = field.split(',')
                .map(f => f.trim())
                .filter(f => f.length)
              return {
                [this.field]: mails.pop(),
                cc: mails.join(','),
                postcode,
                coords: { latitude: '', longitude: '' }
              }
            })
          const list = swap.concat(fresh).reduce((acc, a) => {
            const dup = acc.find(b => b.postcode === a.postcode)
            if (dup) {
              dup.cc = Array.from(new Set([a[this.field], dup.cc, a.cc]
                .filter(t => typeof t === 'string' && t.length)
                .reduce((acc, a) => acc.concat(a.split(',')), [])
                .filter(t => typeof t === 'string' && t.length && t !== dup[this.field])
              )).join(',')
            } else {
              acc.push(a)
            }
            return acc
          }, [])
          this.agencies.splice(0, this.agencies.length, ...list)
        }
      })
    },
    async onHistory () {
      const data = await this.$socket.service(`${this.service}.nearest.config/HISTORY`,  { search: this.search.id })
      const blob = new Blob([encoding.convert(data, 'ISO-8859-1')], { type: 'text/plain;charset=ISO-8859-1' })
      download(blob, `agency_search-${this.search.id}.csv`)
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
div
  padding-bottom 1em
  header
    margin 1em
  table
    margin 1em
    border-spacing 0
    tr
      cursor pointer
      td
        color: #0a2e36
        padding 0.4em 1em 0.4em 0.4em
  .activeicon
    color #45ADA7
    opacity 0
  .isActive
    opacity 1
  input
    border 1px #0a2e36 solid
    border-radius 3px
    height 2em
  input[type="checkbox"]
    height auto
  button, label
    background white
    border none
    color black
    border-radius 2em
    line-height 1em
    margin 0.2em 0
    padding 0.4em 1.5em
    font-size 0.9em
    cursor pointer
    text-decoration none
    &::first-letter
      text-transform capitalize
    &:focus, &:hover
      outline none
    &.invert
      color white
      border 1px white solid
    &:disabled
      color #ccc
      cursor default
  #csv_file
    width 0.1px
    height 0.1px
    opacity 0
    overflow hidden
    position absolute
    z-index -1
</style>
