<template>
  <span :style="offsetStyle" @click="onClick">
    <span
      :class="avatarClass"
      :style="trombineStyle"
      :title="title">
    </span>
    <span
      v-if="editable && anonymous"
      :class="overlayClass"
      :style="trombineStyle"
      :title="title">
    </span>
  </span>
</template>
<script>

export default {
  name: 'hippolyte.user.avatar',
  props: {
    src: {
      type: String
    },
    offset: Number,
    size: {
      type: String,
      default: 'default'
    },
    prefix: String,
    anonymous: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      firstname: '',
      lastname: ''
    }
  },
  methods: {
    onClick () {
      if (this.editable) {
        this.$emit('select')
      }
    }
  },
  computed: {
    trombineStyle () {
      if (this.src) {
        if (this.editable && this.anonymous) {
          return `background-image: url(${this.thumbUrl}); opacity: 0.2;`
        }
        if (!this.anonymous) {
          return `background-image: url(${this.thumbUrl});`
        }
      }
      return ''
    },
    thumbUrl () {
      return (this.prefix && this.prefix.length)
        ? [this.prefix, this.src].join('/')
        : this.src
    },
    offsetStyle () {
      return `width: ${ {big: 80, default: 60, small: 25, tiny: 40}[this.size] - this.offset}px;`
    },
    title () {
      return [this.firstname, this.lastname].filter(s => s && s.length).join(' ')
    },
    avatarClass () {
      return {
        avatar: true,
        [this.size]: true,
        editable: this.editable
      }
    },
    overlayClass () {
      return {
        avatar: true,
        [this.size]: true,
        overlay: true
      }
    }

  }
}
</script>
<style scoped lang="stylus">
@require '~/colors.styl'
.avatar
  display inline-block
  border-radius 100%
  border 1px #45ADA7 solid
  background-image url('/assets/avatar_default.png')
  background-size cover
  &.default
    width 60px
    height 60px
  &.small
    width 25px
    height 25px
  &.tiny
    width 30px
    height 30px
  &.big
    width 80px
    height 80px
    border 2px #f48767 solid
  &.overlay
    border none
    position absolute
    top 1px
    left 1px
    background-color #000
    opacity 0.5
  &.editable:hover
    cursor pointer
</style>
