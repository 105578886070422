<template>
  <svg v-bind="$props" viewBox="0 0 24 24" fill="red">
    <title v-if="title"> {{ title }} </title>
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path d="M12.5 6.9c1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-.39.08-.75.21-1.1.36l1.51 1.51c.32-.08.69-.13 1.09-.13zM5.47 3.92L4.06 5.33 7.5 8.77c0 2.08 1.56 3.22 3.91 3.91l3.51 3.51c-.34.49-1.05.91-2.42.91-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c.96-.18 1.83-.55 2.46-1.12l2.22 2.22 1.41-1.41L5.47 3.92z"/>
  </svg>
</template>

<script>
import icon from '/icons/mixin.js'

export default {
  name: 'hippolyte.icon.notPaid',
  mixins: [icon('15px')],
  props: { title: String }
}
</script>
