import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import fr from 'date-fns/locale/fr'
import isValid from 'date-fns/isValid'

export function formatDate (value, f) {
  if (!value) {
    return ''
  }
  if (!(value instanceof Date)) {
    value = parseISO(value)
  }
  return isValid(value) ? format(value, f, { locale: fr }) : ''
}
