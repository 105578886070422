<template>
  <article class="progress_bar">
    <div>
      <div v-for="(bar, i) in bars" v-bind:key="i">
        <div class="progress_bar_container">
          <div class="float_container">
            <span class="legend_value">{{ bar.value }}</span>
            <span class="legend_title">{{ bar.title }}</span>
          </div>
          <div class="bar legend_percent">
            <div class="progress" :class="[bar.style, customClass(i)]" :style="{'width': calculateProgress(bar.value) + '%'}"></div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import Spinner from '/layout/spinner'

export default {
  name: 'hippolyte.reporting.progressBar',
  props: {
    bars: Array,
    total: Number
  },
  components: { Spinner },
  methods: {
    calculateProgress (value) {
      if (this.total > 0) {
        return value / this.total * 100
      }
      return 0
    },
    customClass (index) {
      return 'slice_' + (index % 8)
    }
  }
}
</script>

<style lang="stylus" scoped>
.progress_bar
  padding 24px
  .bar
    background #E8EDFB
    border-radius 10px
    .progress
      height 12px
      border-radius 10px
</style>
