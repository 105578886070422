<template>
  <span class="modified">
    <b v-if="enable" title="Modification non sauvegardée">*</b>
  </span>
</template>
<script>
export default {
  name: 'hippolyte.field.modified',
  props: {
    enable: Boolean,
    title: {
      type: String,
      default: 'Modification non sauvegardée'
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
span.modified
  position relative
  b
    position absolute
    color red
</style>
