<template>
  <spinner v-if="spinner && saving"></spinner>
  <form @submit.prevent="onSave" class="hippo-form" v-else>
    <p class="hippo-form_layout" v-if="showStatus">
      <span>Statut</span>
      <span class="active-switch">
        <input type="checkbox" v-model="active" id="active" />
        <label for="active"></label>
      </span>
    </p>
    <p class="hippo-form_layout">
      <label><img src="/assets/icon/company_blue.png" alt="Recruteur"/></label>
      <autocomplete
        class="autocomplete-wrapper"
        v-if="refresh"
        :search="recruiterSearch"
        :get-result-value="r => r.company + '' + r.lastname"
        :default-value="recruiter ? recruiter.company + ' ' + recruiter.lastname : null"
        placeholder="Recruiteur"
        :debounceTime="200"
        @submit="r => recruiter = r"
        ref="autocomplete">
      </autocomplete>
    </p>
    <p class="hippo-form_layout">
      <label><img src="/assets/icon/search_blue.png" alt="Métier"/></label>
      <autocomplete
        class="autocomplete-wrapper"
        v-if="refresh"
        :search="tradeSearch"
        :get-result-value="t => t.name"
        :default-value="trade ? trade.name : null"
        placeholder="Métier"
        :debounceTime="200"
        @submit="t => trade = t"
        ref="autocomplete">
      </autocomplete>
    </p>
    <p class="hippo-form_layout">
      <label><img src="/assets/icon/location_blue.png" alt="Ville"/></label>
      <span style="width: 100%;">
        <autocomplete
          class="autocomplete-wrapper"
          v-if="refresh"
          :search="locationSearch"
          :get-result-value="locationField"
          :default-value="locationField(location)"
          placeholder="Ville"
          :debounceTime="200"
          @submit="l => location = l">
          <template #result="{ result, props }">
            <li v-bind="props">
              <template v-if="result.iso==='MA'">
                {{ result.city }} <i>{{ result.location }} - {{ result.name }}</i>
              </template>
              <template v-else>
                {{ result.name }} <i>{{ result.zip }}</i>
              </template>
            </li>
          </template>
        </autocomplete>
      </span>
    </p>

    <p class="hippo-form_layout hippo-form_layout--vertical" >
      <label class="hippo-form-label--full-width">
        <img src="/assets/icon/contracts_blue.png" alt="Contrats" />
        Type(s) de contrat
      </label>
      <span class="contracts_container">
        <span class="contracts">
          <label v-for="contract in contractsAll"
            :class="{ checked: contractsCheck[contract.id] }"
            tabindex="0"
            @keyup.enter="toggle(contract)"
            @keyup.space="toggle(contract)">
            <input type="checkbox" v-model="contractsCheck[contract.id]"/>{{ contract.name }}
          </label>
        </span>
      </span>
    </p>

    <p class="hippo-form_actions">
      <button :disabled="!isValid || saving" v-show="hasChange" type="submit">
        Valider
      </button>
    </p>
  </form>
</template>
<style lang="stylus" scoped>
@require '~/colors.styl'
.active-switch
  switch($color-yellow, white)
form
  color $color-astronaut
  font-size 1em
  margin auto

p.hippo-form_layout
  display flex
  width 100%
  > :first-child
    min-width 40px
    @media (min-width $breakpoint)
      min-width 80px
    img
      max-height 25px
      max-width 25px

.contracts
  display: flex
  flex-direction row
  margin-top 0.3em
  label
    font-size 1em
    color $color-astronaut
    margin 0
    padding 0.2em 0.3em 0.2em 0.3em
    background lighten($color-cloud, 50%)
    cursor: pointer
    &:first-child
      border-radius 1em 0 0 1em
      padding-left 0.5em
    &:last-child
      border-radius 0 1em 1em 0
      padding-right 0.5em
    &:focus
      text-decoration underline $color-yellow
  label.checked
    background $color-cloud
  label input[type=checkbox]
    display none

.hippo-form_layout
  margin-bottom: 2rem

.hippo-form_layout--vertical
  flex-direction: column
  align-items: flex-start
  justify-content: flex-start

  label
    display: flex
    align-items: center
    font-size: 18px
    margin-bottom: 10px;
    color: $color-astronaut

    & > img
      max-width:none!important
      object-fit: contain;
      text-align center;
      height: 40px
      width: 30px;
      margin-right 20px
      @media (min-width $breakpoint)
        width: 80px;
        margin-right 0

.contracts_container
  display: block;
  @media (min-width $breakpoint)
    margin-left: 80px;
</style>
<script>
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import validate from '/validate'
import pick from 'ramda/src/pick'
import Recruiter from '/user/recruiter/recruiter.entity.js'
import Loader from 'vue-spinner/src/SyncLoader.vue'
import Spinner from '/layout/spinner'
import Search from '/requirement/entity'
import Location from '/location/entity'
import Trade from '/trade/entity'
import Contract from './contract.entity.js'
import Conciliator from '/user/conciliator/role'

const fields = ['active', 'trade', 'radius', 'location', 'contracts']
const serializers = {
  trade (t) {
    return t.id
  },
  location (l) {
    return l.id
  },
  contracts (item) {
    return item.id
  }
}

export default {
  name: 'hippolyte.search.form',
  components: { Loader, Spinner, Autocomplete },

  async mounted () {
    this.contractsAll = await Contract.search(this.$socket)
    this.contractsCheck = this.fromValue().contractsCheck
    this.value.on('update', this.$forceUpdate, this)
  },
  props: {
    value: Search,
    showStatus: {
      type: Boolean,
      default () { return true }
    },
    spinner: {
      type: Boolean,
      default () {
        return false
      }
    },
    syncSave: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  watch: {
    value (){
      Object.assign(this, this.fromValue())
      this.refresh = false
      this.value.on('update', this.$forceUpdate, this)
      this.$nextTick(() => this.refresh = true)
      this.$forceUpdate()
    },
    active (val, old) {
      if (val && !this.canActivate()) {
        this.$nextTick(() => {
          this.active = false
        })
        this.$toast('Pour activer votre recherche, contactez nous', { theme: 'error' })
      }
    }
  },
  data () {
    return {
      active: this.value.active || (!this.value.id && this.canActivate()),
      trade: this.value.trade,
      location: this.value.location,
      recruiter: this.value.recruiter,
      saving: false,
      radius: this.value.radius,
      contractsCheck: {},
      contracts: this.value.contracts,
      contractsAll: [],
      refresh: true,
      initDelta: this.value.getDelta(fields, serializers)
    }
  },
  computed: {
    hasChange () {
      const search = (new Search(this.fromForm())).getDelta(fields, pick(['location', 'trade'], serializers))
      return this.initDelta !== search
    },
    isValid () {
      return this.location?.id && this.trade?.id && this.contractsAll.some(c => this.contractsCheck[c.id])
    }
  },
  methods: {
    canActivate () {
      return this.$recruiter.paying || this.$auth.roles.includes(Conciliator)
    },
    toggle (contract) {
      this.contractsCheck[contract.id] = !this.contractsCheck[contract.id]
    },
    focus () {
      this.$nextTick(() => {
        this.$refs.autocomplete.$refs.input.focus()
      })
    },
    async locationSearch (value) {
      let iso = this.value.location?.iso || 'FR'
      return value.length < 2 ? [] : Location.search({ value, iso }, this.$socket)
    },
    async tradeSearch (value) {
      return value.length < 2 ? [] : Trade.search('name', value, this.$socket)
    },
    async recruiterSearch (value) {
      return value.length < 2 ? [] : Recruiter.search({
        email: value
      }, this.$socket)
    },

    fromForm () {
      return {
        active: this.active,
        trade: this.trade,
        radius: this.radius,
        location: this.location,
        recruiter: this.recruiter,
        contracts: Object.entries(this.contractsCheck)
          .filter(([k, v]) => v)
          .map(([k]) => k)
      }
    },
    async onSave () {
      this.value.setData(this.fromForm())
      this.saving = true
      const create = typeof this.value.id === 'undefined'
      return this.value.save(() => ({
        active: !!this.active,
        trade: this.trade.id,
        radius: this.radius,
        location: this.location.id,
        id: this.value.id || null,
        contracts: this.value.contracts,
        recruiter: this.recruiter.id,
        file: null
      }))
        .then(result => {
          this.initDelta = this.value.getDelta(fields, pick(['trade', 'location', 'contracts'], serializers))
          return result
        })
        .then(result => {
          this.$emit('nesting')
          return result.loadNestedEntities()
        })
      this.$emit('saving', savin)
      if (this.syncSave) {
        await savin
      }
      savin.then(() => {
        if (create) {
          this.$requirements.push(this.value)
        }
        this.$emit('done')
        this.$emit(create ? 'created' : 'updated', this.value)
        this.saving = !!this.spinner // keep spinner on signin while loading context
      })
      if (this.syncSave) {
        this.$router.push({
          name: 'requirement',
          params: {
            id: this.value.id
          }
        })
      }
    },
    fromValue () {
      return {
        active: this.value.active,
        trade: this.value.trade,
        location: this.value.location,
        saving: false,
        radius: this.value.radius,
        contracts: this.value.contracts,
        contractsCheck: this.contractsAll.reduce((acc, c) => {
          return Object.assign(acc, {
            [c.id]: Boolean(this.value?.contracts?.find(i => i.id === c.id))
          })
        }, {})
      }
    },
    locationField (location) {
      if ('FR' === location.iso) {
        return location.name
      }
      if ('MA' === location.iso) {
        return [location.city, location.location, location.name].join(' - ').toUpperCase()
      }
    }
  }
}
</script>
