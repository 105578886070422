<template>
  <ats
    v-bind="$props"
    service="jobplus"
    :savingConf="savingConf"
    :credential="{host: '', username: '', password: '', from: 'ats@hippolyte-rh.fr'}"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="host">hostname</label>
        <input v-model="credential.host" id="host" name="host"/>
        <modified :enable="value.credential.host !== credential.host"/>
      </div>
      <div>
        <label for="username">username</label>
        <input v-model="credential.username" id="username" name="username"/>
        <modified :enable="value.credential.username !== credential.username"/>
      </div>
      <div>
        <label for="password">password</label>
        <input v-model="credential.password" id="password" name="password"/>
        <modified :enable="value.credential.password !== credential.password"/>
      </div>
      <div>
        <label for="from">email sender</label>
        <input v-model="credential.from" id="from" name="from"/>
        <modified :enable="value.credential.from !== credential.from"/>
      </div>
    </template>
    <template v-slot:title="{ job }">{{ title(job) }}</template>
    <template v-slot:open="{ job }"><a class="button" :href="job.url_reponse" target="_blank" title="ouvrir">👁️</a></template>
  </ats>
</template>
<script>
import Ats from './sdk-v2'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.jobplus.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    title (job) {
      return  [
        job.reference,
        job.intitule_poste,
        job.ville
      ].filter(t => t).join(' - ')
    },
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
