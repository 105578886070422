<template>
  <div class="hippo-notification">
    <img src="/assets/icon/bell.png">
    <p>
     <span>Me prévenir quand un nouveau candidat correspond à mes recherches et quand un candidat me parle.</span>
    <ul>
      <li>
        <span @click="registered = !registered">Recevoir des notifications</span>
        <span class="hippo-notif_switch">
          <input type="checkbox" name="registered" v-model="registered" id="registered">
          <label for="registered"></label>
        </span>
      </li>
    </ul>
   </p>
  </div>
</template>
  </div>
<script>
import Notify from '/notify'
import Account from '/user/account/entity'

export default {
  name: 'hippolyte.notify.account.form',
  props: {
    account: Account
  },
  data () {
    const notifier = new Notify(this.account, this.$socket)
    notifier.on('update', s => Object.assign(this, { registered: s }))
    return {
      registered: notifier.registered,
      notifier
    }
  },
  async mounted () {
    this.registered = await this.notifier.status()
    this.$watch('registered', async function (value, old) {
      if (old !== null && value !== this.notifier.registered) {
        if (this.registered) {
          const register = await this.notifier.register()
          await this.notifier.save(register)
        } else {
          await this.notifier.remove()
        }
      }
    })
  },
  destroyed () {
    this.notifier.destroy()
  }
}
</script>
<style lang="stylus">
@require '~/colors.styl'
.hippo-notif_switch
  switch($color-yellow, white)
.hippo-notification
  color $color-astronaut
  margin 5em 1em 1em 2em
  display flex
  flex-direction row
  align-items flex-start
  img
    width 40px
    margin-right 1em
  p
    margin 0
    span:first-child
      cursor pointer
    ul
      padding 0
      width 100%
      margin-top 3em
      li
        list-style none
        width 100%
        display flex
        justify-content space-between
</style>
