<template>
  <form @submit.prevent="save">
    <table>
      <thead>
        <tr>
          <td>account</td>
          <td>activé</td>
          <td>check online</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="account in recruiter.accounts" :key="account.id" v-if="config[account.id]">
          <td>{{account.firstname}} {{account.lastname}}</td>
          <td><input type="checkbox" v-model="config[account.id].active" @change="onUpdate"/></td>
          <td><input type="checkbox" v-model="config[account.id].online" @change="onUpdate"/></td>
          <td><button @click.prevent="remove(account)">Supprimer</button></td>
        </tr>
        <tr v-if="recruiter.accounts.filter(a => !config[a.id]).length">
          <td>
            <select v-model="prompt.account">
              <template v-for="account in recruiter.accounts" v-if="!config[account.id]">
                <option :value="account" :key="account.id">{{account.firstname}} {{account.lastname}}</option>
              </template>
            </select>
          </td>
          <td><input type="checkbox" v-model="prompt.active"/></td>
          <td><input type="checkbox" v-model="prompt.online"/></td>
          <td><button @click.prevent="add">Ajouter</button></td>
      </tbody>
    </table>
    <button type="submit">Enregistrer</button>
  </form>
</template>
<script>
import Recruiter from '/user/recruiter/recruiter.entity'
import Account from '/user/account/entity'
import pick from 'ramda/src/pick'
function blank () {
  return {
    active: true,
    online: true,
    single: true,
    transport: 'email',
    account: null
  }
}
export default {
  name: 'hippolyte.services.email.config',
  props: {
    value: Object,
    recruiter: Recruiter
  },
  watch: {
    value () {
      this.config = this.value
    },
    async recruiter () {
      this.loadAccounts()
    }
  },
  mounted () {
    this.loadAccounts()
  },
  data () {
    return {
      prompt: blank(),
      config: JSON.parse(JSON.stringify(this.value))
    }
  },
  methods: {
    onUpdate () {
      this.setActive()
      this.$emit('input', this.config)
    },
    async loadAccounts () {
      const accounts = await Account.loadIds(this.recruiter.accounts, this.$socket)
    },
    add () {
      this.config[this.prompt.account.id] = pick(['active', 'online', 'single', 'transport'], this.prompt)
      this.prompt = blank()
      this.setActive()
    },
    setActive () {
      this.config.active = this.recruiter.accounts.some(a => this.config[a.id] && this.config[a.id].active)
    },
    remove (account) {
      this.config[account.id] = null
      this.setActive()
      this.$forceUpdate()
    },
    save () {
      this.$emit('input', this.config)
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
form
  padding 1em
input[type="checkbox"]
  height auto
select
  width auto
button
  background white
  border none
  color black
  border-radius 2em
  line-height 1em
  margin 0.2em 0
  padding 0.4em 1.5em
  font-size 0.9em
  cursor pointer
  text-decoration none
  &::first-letter
    text-transform capitalize
  &:focus, &:hover
    outline none
  &.invert
    color white
    border 1px white solid
  &:disabled
    color #ccc
    cursor default
</style>
