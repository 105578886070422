<template>
  <div>
    <label :for="name">Location</label>
    <treeselect
      v-model="value"
      :id="name"
      :name="name"
      :defaultOptions="modelValue"
      :valueFormat="'object'"
      :multiple="false"
      :async="true"
      :load-options="getLocations"
      :loadingText="'Recherche en cours ...'"
      :searchPromptText="'Ecrivez pour lancer la recherche ...'"
      :noResultsText="'Aucun résultats ...'"
      :normalizer="normalizer"
      placeholder="Forcer la localisation"
      @input="input"
      ></treeselect>
    <modified :enable="original !== value"></modified>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import cancel from '/cancel.mixin.js'
import Modified from '/ats/modified'
import Location from '/location/entity'

export default {
  name: 'hippolyte.services.ats.filter.locationSelect',
  components: {
    Treeselect, Modified
  },
  mixins: [cancel],
  props: {
    modelValue: Object,
    name: String
  },
  data () {
    return {
      value: this.modelValue,
      original: this.modelValue
    }
  },
  methods: {
    input (v) {
      this.value = v ? { id: v.id, city: v.city, zip: v.zip } : null
      this.$emit('input', this.value)
    },
    normalizer (location) {
      return {
        id: location.id,
        label: `${location.city} (${location.zip})`
      }
    },
    async getLocations ({ searchQuery: research, callback }) {
      this.cancel('location')
      const { data: { docs: list } } = await Location.api(this.$socket).list({ research }, { cancel: this.token('location') })
      this.cancel('location', null)
      const results = list.map(l => ({
        id : l.id,
        city: l.city,
        zip: l.zip
      }))
      callback(null, results)
    }
  }
}
</script>
<style lang="stylus" scoped>
div
  background white
  margin 0.4em 0
  border-radius 4px
  align-items center
  label
    margin-right 1em
    text-align right
    margin-top auto
    margin-bottom auto
  select
    background #eee
    border-radius 0 4px 4px 0
    font-size 1em
    line-height 1.5em
    border 1px #eee solid
    &:focus
      border 1px #ccc solid
</style>