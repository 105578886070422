import Entity from '/entity'
import Api from '/api'
const cond = k => `YesWeChat\\ServiceEntityBundle\\Query\\Condition\\${k}`

class Location extends Entity {
  static async search (opts, socket) {
    let iso = opts.iso || 'FR'
    let value = opts.value?.toLowerCase() || ''
    let list = await socket.service(`entity.Location/QUERY`,{
      alias: 'l',
      class: Location.prototype.entityClass,
      limit: 10,
      order_by: [
        {
          field: {
            type: 'YesWeChat\\ServiceEntityBundle\\Query\\Condition\\Field',
            name: `length(l.name)`
          },
          direction: 'ASC'
        },
        {
          field: {
            type: 'YesWeChat\\ServiceEntityBundle\\Query\\Condition\\Field',
            name: `l.name`
          },
          direction: 'ASC'
        },
        {
          field: {
            type: 'YesWeChat\\ServiceEntityBundle\\Query\\Condition\\Field',
            name: `l.zip`
          },
          direction: 'ASC'
        }
      ],
      parameters: [
        { type: cond('Parameter'), name: 'name', value: `%${value}%` },
        { type: cond('Parameter'), name: 'iso', value: `${iso.toUpperCase()}` }
      ],
      conditions: [
        {
          type: cond('OrX'),
          conditions: [
            {
              type: cond('Like'),
              value: 'name',
              subject: {
                type: cond('Field'),
                name: `lower(l.name)`
              }
            },
            {
              type: cond('Like'),
              value: 'name',
              subject: {
                type: cond('Field'),
                name: `lower(l.location)`
              }
            },
            {
              type: cond('Like'),
              value: 'name',
              subject: {
                type: cond('Field'),
                name: `lower(l.city)`
              }
            }
          ]
        },
        {
          type: cond('Equals'),
          value: 'iso',
          subject: {
            type: cond('Field'),
            name: `l.iso`
          }
        }
      ]
    })
    return list.map(l => new Location(l, socket))
  }

  static create (data) {
    return new Location(data)
  }
}

Location.prototype.entityClass = 'Location'
Location.prototype.entityFields = ['id', 'name']

Location.api = function (socket) {
  return new Api(socket, 'location')
}

export default Location
