<template>
  <details open>
    <summary>
      <section>
        <div v-if="isAtLeast('sdk', 3, 3)" class="checkbox-container" :title="active ? 'désactiver le filtre': 'activer le filtre'">
          <input type="checkbox" v-model="active" :name="'active_' + id" :id="'active_' + id" />
          <label :for="'active_' + id"></label>
          <modified :enable="original.active !== active"></modified>
        </div>
        <div>
          <input v-model="name" :name="'filterTitle_' + id" :id="'filterTitle_' + id" placeholder="Nom du filtre">
          <modified :enable="original.name !== name"></modified>
        </div>
      </section>
      <section class="action">
        <div class="btn" @click.prevent="preview" :disabled="filterRunning" style="position: relative;">
          🔍️
          <spinner v-if="filterRunning" style="transform: scale(0.2, 0.2); position: absolute; top: -1em;"></spinner>
        </div>
        <div class="btn" @click.prevent="remove" title="supprimer">✖</div>
        <div class="btn" @click.prevent="undo" title="annuler les modifications">↶</div>
        <div class="btn" v-if="previous" @click.prevent="up(i)" title="prioriser">▲</div>
        <div class="btn" v-if="next" @click.prevent="down(i)" title="déprioriser">▼</div>
      </section>
    </summary>
    <fieldset>
      <legend><h2>Filtres</h2></legend>
      <section>
        <div> Inclut
          <div title="departements ou codes postaux séparés par des virgules">
            <label :for="'zips_' + id">zips</label>
            <input v-model="zips" :id="'zips_' + id" :name="'zips_' + id"/>
            <modified :enable="original.zips !== zips"></modified>
          </div>
          <div title="expression à trouver dans le titre (joker possible)">
            <label :for="'titles_' + id">titre</label>
            <input v-model="title" :id="'titles_' + id" :name="'titles_' + id"/>
            <modified :enable="original.title !== title"></modified>
          </div>
          <div title="expression à trouver dans la reference à séparer par des '|' (jocker possible)">
            <label :for="'reference_' + id">reference</label>
            <input v-model="reference" :id="'reference_' + id" :name="'reference_' + id"/>
            <modified :enable="original.reference !== reference"></modified>
          </div>
        </div>
      </section>
      <section>
        <div> N'inclut pas
          <div title="departements ou codes postaux séparés par des virgules">
            <label :for="'nozips_' + id">zips</label>
            <input v-model="noZips" :id="'nozips_' + id" :name="'nozips_' + id"/>
            <modified :enable="original.noZips !== noZips"></modified>
          </div>
          <div title="expression à ne pas trouver dans le titre (jocker possible)">
            <label :for="'notitles_' + id">titre</label>
            <input v-model="noTitle" :id="'notitles_' + id" :name="'notitles_' + id"/>
            <modified :enable="original.noTitle !== noTitle"></modified>
          </div>
          <div title="expression à ne pas trouver dans la reference à séparer par des '|' (jocker possible)">
            <label :for="'noreference_' + id">reference</label>
            <input v-model="noReference" :id="'noreference_' + id" :name="'noreference_' + id"/>
            <modified :enable="original.noReference !== noReference"></modified>
          </div>
        </div>
      </section>
    </fieldset>
    <section v-if='isAtLeast("sdk", 3, 1)'>
      <textarea
        title="fonction javascript prenant l'offre en parametre et renvoyant un booleen. true si l'offre doit être retenu."
        v-model="custom"
        placeholder="function (job) { job.data().agencie === 'The A Team'}"
        :id="'custom_' + id"
        :name="'custom_' + id"/>
      <modified :enable="original.custom !== custom"></modified>
    </section>
    <fieldset>
      <legend><h2>Search</h2></legend>
      <section>
        <div v-if="isAtLeast('sdk', 3, 4, 4)">
          <div>Joignabilité</div>
          <div class="checkbox-container" :title="requireContact ? 'désactiver la joignabilité': 'activer la joignabilité'">
            <input type="checkbox" v-model="requireContact" :name="'requireContact_' + id" :id="'requireContact_' + id" />
            <label :for="'requireContact_' + id"></label>
            <modified :enable="original.requireContact !== requireContact"></modified>
          </div>
        </div>
        <div v-if="isAtLeast('sdk', 3, 4, 6)">
          <div>Désactiver l'IA</div>
          <div class="checkbox-container" :title="withoutAI ? 'désactiver l\'analyse IA': 'activer l\'analyse IA'">
            <input type="checkbox" v-model="withoutAI" :name="'withoutAI_' + id" :id="'withoutAI_' + id" />
            <label :for="'withoutAI_' + id"></label>
            <modified :enable="original.withoutAI !== withoutAI"></modified>
          </div>
        </div>
        <div v-if="isAtLeast('sdk', 3, 4, 8)">
          <label :for="'processTags_' + id">Process</label>
          <input v-model="processTags" :id="'processTags_' + id" :name="'processTags_' + id"/>
          <modified :enable="original.processTags !== processTags"/>
        </div>
      </section>
      <section>
        <div title="campagne à utiliser pour la search">
          <label :for="'campaign_' + id">campagne</label>
          <input v-model="campaign" :id="'campaign' + id" :name="'campaign_' + id"/>
          <modified :enable="original.campaign !== campaign"></modified>
        </div>
        <div title="formId à utiliser pour la search">
          <label :for="'formId_' + id">formId</label>
          <input v-model="formId" :id="'formId_' + id" :name="'formId_' + id"/>
          <modified :enable="original.formId !== formId"></modified>
        </div>
      </section>
      <section class="tradeSelect">
        <contract-select :model-value="contract"  :name="'contract_' + id" @input="value => contract = value"></contract-select>
        <trade-select :model-value="trade" :name="'trade_' + id"
        @input="value => trade = value"></trade-select>
      </section>
      <section v-if="isAtLeast('sdk', 3, 4, 4)">
        <account-select :model-value="account" :name="'accounts_' + id" @input="value => account = value"></account-select>
      </section>
      <section v-if="isAtLeast('sdk', 3, 4, 4)">
        <location-select :model-value="location" :name="'location_' + id" @input="value => location = value"></location-select>
        <div title="radius autour de la localisation">
          <label :for="'radius_' + id">Radius</label>
          <input type="number" v-model="radius" :id="'radius_' + id" :name="'radius_' + id" placeholder="30"/>
          <modified :enable="original.radius !== radius"></modified>
        </div>
      </section>
      <section v-if="isAtLeast('sdk', 3, 4, 15)">
        <ai-version-select :model-value="aiVersion" :name="'aiVersion_' + id" @input="value => aiVersion = value"></ai-version-select>
      </section>
      <section class="description">
        <div>
          <label :for="'description_' + id">Description</label>
          <vue-editor
            v-model="description"
            :name="'description_' + id"
            :id="'description_' + id"
            placeholder="Description de la search"
            :editorToolbar="customToolbar"
          ></vue-editor>
          <modified :enable="original.description !== description"></modified>
        </div>
      </section>
    </fieldset>
  </details>
</template>
<script>
import Spinner from '/layout/spinner'
import { VueEditor } from 'vue2-editor/dist/vue2-editor.umd.min'
import { isAtLeast } from '../fun'
import TradeSelect from '../components/selects/trade'
import ContractSelect from '../components/selects/contract'
import AccountSelect from '../components/selects/account'
import aiVersionSelect from '../components/selects/aiversion'
import LocationSelect from '../components/selects/location'
import Modified from '/ats/modified'
import cancel from '/cancel.mixin.js'

export default {
  name: 'hippolyte.services.ats.filter',
  components: {
    VueEditor,
    Spinner,
    Modified,
    TradeSelect,
    ContractSelect,
    AccountSelect,
    LocationSelect,
    aiVersionSelect
  },
  mixins: [cancel],
  props: {
    filter: Object,
    previous: Boolean,
    next: Boolean,
    versions: Object,
    filterRunning: Boolean,
  },
  watch: {
    filter: {
      handler (filter, old) {
        this.original = this.capture(filter)
      },
      deep: true
    }
  },
  data () {
    const original = this.capture(this.filter)
    return Object.assign(
      this.capture(this.filter),
      {
        original,
        customToolbar: [["bold", "italic", "underline"]]
      }
    )
  },
  computed: {
    updated () {
      return JSON.stringify(this.capture()) !== JSON.stringify(this.capture(this.original))
    }
  },
  methods: {
    capture (filter) {
      filter ??= this
      return {
        id: filter.id,
        name: filter.name || '',
        active: filter.active ?? true,
        zips: filter.zips || '',
        noZips: filter.noZips || '',
        title: filter.title || '',
        noTitle: filter.noTitle || '',
        reference: filter.reference || '',
        noReference: filter.noReference || '',
        custom: filter.custom || '',
        description: filter.description || '',
        contract: Array.from(filter.contract || []),
        trade: filter.trade || null,
        campaign: filter.campaign || '',
        formId: filter.formId || '',
        requireContact: filter.requireContact ?? false,
        withoutAI: filter.withoutAI ?? false,
        processTags: filter.processTags || '',
        account: Array.from(filter.account || []),
        location: filter.location || null,
        radius: filter.radius || null,
        aiVersion: filter.aiVersion || null
      }
    },
    isAtLeast (lib, ...args) {
      return isAtLeast(this.versions, lib, ...args)
    },
    preview () {
      this.$emit('preview', this.capture())
    },
    update () {
      this.$emit('input', this.capture())
    },
    remove () {
      this.$emit('remove', this.filter)
    },
    up () {
      this.$emit('up', this.filter)
    },
    down () {
      this.$emit('down', this.filter)
    },
    undo () {
      Object.assign(this, this.capture(this.original))
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~/colors.styl'
input[type="checkbox"]
  height auto
section:first-child
  justify-content start
details
  & summary
    display flex
    flex-direction row
    justify-content space-between
  section div
    display flex
    div
      display inline
      label
        text-align left
        display block
    .description
      justify-content center
    .quillWrapper
      min-width 600px
      .ql-container
        font-family 'Montserrat'
  &>.tradeSelect
    justify-content center
  .formAction
    display flex
    background-color transparent
  textarea
    background #eee
    border-radius 0 4px 4px 0
    font-size 1em
    line-height 1.5em
    border 1px #eee solid
    width 80%
    height 100px
    &:focus
      border 1px #ccc solid
  section, .action
    display flex
    justify-content end
    .btn
      display flex
      align-items center
      background-color $color-torrent
  div
    display grid
    grid-template-columns 150px
    background white
    margin 0.4em 0
    padding 3px 3px 3px 0.8em
    border-radius 4px
    align-items center
    label
      grid-column 1
      margin-right 1em
      text-align right
      margin-top auto
      margin-bottom auto
    input, select
      grid-column 2
      background #eee
      border-radius 0 4px 4px 0
      font-size 1em
      line-height 1.5em
      border 1px #eee solid
      &:focus
        border 1px #ccc solid
    button
      grid-column 3
    small
      color red
      grid-column 2
button, .btn
  background white
  border none
  color black
  border-radius 2em
  line-height 1em
  margin 0.2em 0
  padding 0.4em 1.5em
  font-size 0.9em
  cursor pointer
  text-decoration none
  &::first-letter
    text-transform capitalize
  &:focus, &:hover
    outline none
  &.invert
    color white
    border 1px white solid
  &:disabled
    color #ccc
    cursor default
  #floatingBarsG-container
    width auto
.checkbox-container
    display flex
    padding-left 1em
    switch($color-green, white)
    align-items center
</style>
