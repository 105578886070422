<template>
  <svg v-bind="$props" viewBox="0 0 18 14">
    <path d="M1 13.3636V12.6363C1 9.82468 3.27928 7.54541 6.09091 7.54541C8.90254 7.54541 11.1818 9.82468 11.1818 12.6363V13.3636" stroke-width="1" stroke-linecap="round"/>
    <path d="M9.72729 9.00001C9.72729 6.99172 11.3554 5.36365 13.3637 5.36365C15.3719 5.36365 17 6.99172 17 9.00001V9.36365" stroke-width="1" stroke-linecap="round"/>
    <path d="M6.09098 7.54548C7.69759 7.54548 9.00007 6.243 9.00007 4.63639C9.00007 3.02974 7.69759 1.72729 6.09098 1.72729C4.48433 1.72729 3.18188 3.02974 3.18188 4.63639C3.18188 6.243 4.48433 7.54548 6.09098 7.54548Z" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.3636 5.36364C14.5686 5.36364 15.5454 4.3868 15.5454 3.18182C15.5454 1.97684 14.5686 1 13.3636 1C12.1586 1 11.1818 1.97684 11.1818 3.18182C11.1818 4.3868 12.1586 5.36364 13.3636 5.36364Z" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
import icon from '/icons/mixin.js'

export default {
  name: 'hippolyte.atom.icon.candidates',
  mixins: [icon('20px')]
}
</script>
