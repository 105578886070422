<template>
  <select v-model="reason" class="search_filter">
    <option disabled :value="null">Selectionner un motif</option>
    <template v-for="r in reasons" >
      <option :value="r.id" :key="r.id">{{r.name}}</option>
    </template>
  </select>
</template>
<script>

export default {
  name: 'hippolyte.atoms.reason',
  props: {
    reasons: Array, 
    value: String
  },
  data () {
    return {
      reason: this.value
    }
  },
  watch: {
    reason (val) {
      this.$emit('input', val)
    }
  }
}
</script>
<style lang="stylus" scoped>

.search_filter
  width 95%
  border 0
  border-bottom 2px solid #000
  padding 5px
  background transparent
  margin 10px
  option
    overflow hidden
    text-overflow ellipsis
</style>
