<template>
  <ats
    v-bind="$props"
    service="handcraft"
    :savingConf="savingConf"
    :credential="{
      url: ''
    }"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="url">apply url</label>
        <input v-model="credential.url" id="url" name="url"/>
        <modified :enable="value.credential.url !== credential.url"/>
      </div>
    </template>
  </ats>
</template>
<script>
import Ats from './sdk-v3'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.handcraft.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
