import { Status } from '/interview/status'

function asc (a, b) {
  if (a < b) {
    return -1
  }
  if (a > b) {
    return 1
  }
  return 0
}

function desc (a, b) {
  return - asc(a, b)
}

const extracts = {
  location (a) {
    return a.candidate.location?.toLowerCase()
  },
  candidate (a) {
    return a.candidate.lastname?.toLowerCase() + ' ' + a.candidate.firstname?.toLowerCase()
  },
  trade (a) {
    return a.candidate.custom.candidatTrade?.toLowerCase()
  },
  conciliatedAt (a) {
    return a.statuses.find(i => i.status === Status.created).date
  },
  createdAt (a) {
    return a.candidate.createdAt
  },
  sentAt (a) {
    return a.sentAt
  }
}

export default function order (table, comparator, dir='asc') {
  if ('function' === typeof extracts[comparator]) {
    table.sort(function (a, b) {
      return { asc, desc }[dir](extracts[comparator](a), extracts[comparator](b))
    })
  }
  return table
}
