<template>
  <svg v-bind="$props" viewBox="0 0 512 512" :class="iconClassName">
    <title v-if="title"> {{ title }} </title>
    <path style="fill:#FF421D;" d="M229.869,0C102.917,0,0,102.917,0,229.869c0,126.952,102.917,229.869,229.869,229.869s229.869-102.917,229.869-229.869
	  C459.738,102.917,356.821,0,229.869,0z M313.676,260.518H146.063c-16.926,0-30.649-13.723-30.649-30.649
	  c0-16.927,13.723-30.65,30.649-30.65h167.613c16.925,0,30.649,13.723,30.649,30.65C344.325,246.795,330.601,260.518,313.676,260.518
	  z"/>
  </svg>
</template>

<script>
import icon from '/icons/mixin.js'

export default {
  name: 'hippolyte.icon.remove',
  mixins: [icon('15px')],
  props: { title: String }
}
</script>
