<template>
  <ats
     v-bind="$props"
    service="talentsoft-frontoffice"
    :savingConf="savingConf"
    :credential="{ url: '', key: '', secret: '', agencie: '' }"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="url">url</label>
        <input v-model="credential.url" id="url" name="url"/>
        <modified :enable="value.credential.url !== credential.url"/>
      </div>
      <div>
        <label for="key">key</label>
        <input v-model="credential.key" id="key" name="key"/>
        <modified :enable="value.credential.key !== credential.key"/>
      </div>
      <div>
        <label for="secret">secret</label>
        <input v-model="credential.secret" id="secret" name="secret"/>
        <modified :enable="value.credential.secret !== credential.secret"/>
      </div>
      <div>
        <label for="agencie">agencie</label>
        <input v-model="credential.agencie" id="agencie" name="agencie"/>
        <modified :enable="value.credential.agencie !== credential.agencie"/>
      </div>
      <div>
        <label for="consent">consentement</label>
        <input type="checkbox" v-model="credential.consent" id="consent" name="consent"/>
        <modified :enable="value.credential.consent !== credential.consent"/>
      </div>
    </template>
  </ats>
</template>
<script>
import Ats from './sdk-v3'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.talentsoft-frontoffice.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
