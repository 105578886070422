<template>
  <svg v-bind="$props" viewBox="0 0 111.83 111.82">
    <path fill="#FFFC00" d="M111.83,55.91A55.92,55.92,0,1,1,55.92,0a55.91,55.91,0,0,1,55.91,55.91"/><path fill="#ffffff" d="M21.72,74V72.91a2.83,2.83,0,0,1,1.62-1.09c5.62-1.11,9.39-4.7,12.23-9.41,2.38-4,2-4.87-2.4-6.43-.25-.09-.51-.16-.76-.26a9.47,9.47,0,0,1-2.67-1.49c-2-1.71-.81-4.18,1.51-4.74,1.7-.41,3.18.68,4.83.89,1.1.14,1.54-.23,1.41-1.41a56.32,56.32,0,0,1-.2-9.09,15.39,15.39,0,0,1,7.94-13.05,20,20,0,0,1,21.48-.07,15.35,15.35,0,0,1,7.9,11.79,52.91,52.91,0,0,1,0,10.3c-.11,1.49.19,1.76,1.66,1.42,1.83-.43,3.37-1.38,5.27-.54a2.93,2.93,0,0,1,1.88,2.52,2.81,2.81,0,0,1-1,2,10.62,10.62,0,0,1-3.09,1.62c-.83.33-1.69.59-2.49,1-1.66.78-2.14,1.81-1.37,3.46,2.7,5.69,6.65,10,13.06,11.51a3,3,0,0,1,1.87,1.14V74a5,5,0,0,1-2.92,2.4,13.75,13.75,0,0,1-3.85,1.09,3,3,0,0,0-3.12,2.86c-.26,1.84-.82,2.1-2.69,2-.45,0-.88-.12-1.32-.2-3.67-.65-7.11-.28-10.13,2.16a22.2,22.2,0,0,1-1.88,1.28c-5.14,3.34-10.4,3.72-15.77.59-1.46-.86-2.85-1.84-4.27-2.77a9.52,9.52,0,0,0-3.85-1.41c-2.23-.34-4.36.17-6.52.4-1.11.13-2.19-.19-2.25-1.34-.16-2.78-1.86-3.46-4.19-3.71a11.84,11.84,0,0,1-3.18-1A4.67,4.67,0,0,1,21.72,74"/>
  </svg>
</template>

<script>
import icon from '/icons/mixin'

export default {
  name: 'hippolyte.icon.social.snapchat',
  mixins: [icon('15px')]
}
</script>
