<template>
  <svg v-bind="$props" viewBox="0 0 24 24" fill="#000000">
    <title v-if="title"> {{ title }} </title>
    <path d="M0 0h24v24H0V0z" fill="none"/>
    <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 5-8-5h16zm0 12H4V8l8 5 8-5v10z"/>
  </svg>
</template>

<script>
import icon from '/icons/mixin.js'

export default {
  name: 'hippolyte.icon.mail',
  mixins: [icon('15px')],
  props: { title: String }
}
</script>