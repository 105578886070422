<template>
  <div class="candidates">
    <header class="filters">
      <states-filter
        v-model="filters.states"
        :statuses="statuses"
        :counter="counter">
      </states-filter>
      <csv :searches="filters.requirements" :states="filters.states"/>
    </header>
    <div class="candidates-container">
      <section class="candidate-list">
        <article class="candidate-list_container">
          <table class="table">
            <thead>
              <tr>
                <th>Candidat</th>
                <th>Métier</th>
                <th>Statut</th>
              </tr>
            </thead>
            <lazy-interview
              :recruiter="recruiter"
              :filters="filters"
              :statuses="statuses"
              :order="'created'"
              :counter="counter">
              <template #interview="{interview}">
                <candidate-row
                  :selected="interview.id === $route.params.interview"
                  :key="interview.id"
                  :interview="interview"
                  :reasons="reasons">
                </candidate-row>
              </template>
            </lazy-interview>
          </table>
        </article>
      </section>
      <div v-html="description" class="search-description"></div>
    </div>
  </div>
</template>
<script>
import Candidate from '/user/candidate/candidate.entity'
import Recruiter from '/user/recruiter/recruiter.entity'
import CandidateRow from '/user/conciliator/candidate.row'
import Search from '/requirement/entity'
import Filter from '/filter/'
import order from '/interview/order'
import StatesFilter from '/filter/states'
import LazyInterview from '/interview/lazy'
import Csv from '/interview/csv'
import statuses from '/interview/ordered.status.js'
import Conciliation from '/interview/interview.js'
import axios from 'axios'
const CancelToken = axios.CancelToken

export default {
  name: 'hippolyte.conciliator.candidates.list',
  components: { CandidateRow, StatesFilter, LazyInterview, Csv },
  props: {
    recruiter: Recruiter,
    search: Search,
    filters: Filter
  },
  watch: {
    recruiter: 'refreshCounter',
    search () {
      this.updateSearch()
      this.refreshCounter()
    },
    filters: 'refreshCounter'
  },
  data () {
    return {
      reasons: [],
      comparator: 'conciliatedAt',
      counter: statuses.reduce((acc, s) => Object.assign(acc, { [s]: 0 }), { all: 0 }),
      statuses,
      counting: null,
      description: null
    }
  },
  mounted () {
    this.loadReasons()
    this.refreshCounter()
    this.search?.loading.then(() => this.description = this.search.description)
  },
  methods: {
    updateSearch () {
      if (this.search?.id)  {
        this.search?.loading.then(() => this.description = this.search.description)
      } else {
        this.description = null
      }
    },
    async loadReasons () {
      try {
        this.cancel = CancelToken.source()
        const reasons = await Conciliation.getStatusReasons({}, this.$socket, this.cancel.token)
        if (reasons.numFound) {
          this.reasons.splice(0, this.reasons.length, ...reasons.docs)
        }
      } catch (err) {
          if (!axios.isCancel(err)) {
            throw err
          }
      }
    },
    async refreshCounter () {
      this.cancelCountStates?.cancel()
      this.counting = true
      this.cancelCountStates = CancelToken.source()
      return Conciliation.list({
        solr: true,
        limit: this.statuses.length,
        recruiter: this.recruiter,
        searches: [this.search],
        active: this.filters.active,
        archived: this.filters.archived,
        closed: this.filters.closed,
        count: true
      }, this.$socket, this.cancelCountStates.token)
      .then(count => {
        this.count = count.facets.count
        Object.keys(this.counter).map(k => this.counter[k] = 0)
        this.counter.all = count.facets.count
        if (count.facets.count) {
          count.facets.status.buckets.map(s => {
            this.counter[s.val] = s.count
          })
        }
        this.counting = false
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'

.candidates
  height 100vh
  header
    height 2.5em
    display flex
    align-content center
    &> *
      height 2em
      margin auto 0.4em
.candidate-list
  height 100%
  flex 2
  article.candidate-list_container
    overflow-y auto
    overflow-x hidden
    height calc(100% - 4em)
    table
      th
        z-index 2
        position sticky
        top 0
      border-collapse collapse
      font-size 0.9em
      width 100%
      thead
        color black
        font-size 0.9em
        th
          background #faeda7
          padding 10px 10px
          text-align left
          &:first-child
            padding-left 15px
.candidates-container
  display flex
  width 100%
  height 100%
.search-description
  flex 1
  height 100%
  width 20em
  padding 1em
  background-color #f7fafd
  border-right 1px solid white
  overflow scroll
</style>
