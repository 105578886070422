<template>
  <ats
    v-bind="$props"
    service="manpower"
    :savingConf="savingConf"
    :credential="{ host: '', url: '', auth: '', clientId: '', clientSecret: '' }"
    :cached="true"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="auth">auth</label>
        <input v-model="credential.auth" id="auth" name="auth"/>
        <modified :enable="value.credential.auth !== credential.auth"/>
      </div>
      <div>
        <label for="url">url</label>
        <input v-model="credential.url" id="url" name="url"/>
        <modified :enable="value.credential.url !== credential.url"/>
      </div>
      <div>
        <label for="host">host</label>
        <input v-model="credential.host" id="host" name="host"/>
        <modified :enable="value.credential.host !== credential.host"/>
      </div>
      <div>
        <label for="clientId">clientId</label>
        <input v-model="credential.clientId" id="clientId" name="clientId"/>
        <modified :enable="value.credential.clientId !== credential.clientId"/>
      </div>
      <div>
        <label for="clientSecret">clientSecret</label>
        <input v-model="credential.clientSecret" id="clientSecret" name="clientSecret"/>
        <modified :enable="value.credential.clientSecret !== credential.clientSecret"/>
      </div>
    </template>
  </ats>
</template>
<script>
import Ats from './sdk-v3'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.manpower.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
