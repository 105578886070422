<template>
  <article class="donut">
    <div v-if="busy">
      <spinner></spinner>
    </div>
    <div v-else>
      <div style="position: relative;">
        <svg :width="width" :height="width" >
          <template v-for="(slice, i) in slices">
            <path :d="slice.path" :class="[slice.style, 'slice']" :key="i" :title="slice.title" v-if="slice.path"></path>
          </template>
          <path :d="backgroundPath" class="slice slice_empty" v-if="total === 0"></path>
          <text x="75" y="75" class="donut-inside">
            <tspan class="donut-total">{{ percentage }}%</tspan>
          </text>
          <text x="75" y="100" class="donut-inside">
            <tspan>contactés</tspan>
          </text>
        </svg>
      </div>
      <ul class="donut-legend">
        <li class="donut-legend_slice" v-for="(slice, i) in slices" v-bind:key="i">
          <div class="float_container">
            <span class="legend_value">{{ slice.value }}</span>
            <span class="legend_title">{{ slice.title }}</span>
          </div>
          <div class="legend_percent">
            <span class="legend_percent_active" :class="['donut-legend_slice-color', slice.style]" :style="{'width': (slice.value / total * 100) + '%'}"></span>
          </div>
        </li>
      </ul>
    </div>
  </article>
</template>

<script>
import Spinner from '/layout/spinner'

export default {
  name: 'hippolyte.reporting.donut',
  props: {
    sections: Array,
    width: {
      type: Number,
      default: 150
    },
    radius: {
      type: Number,
      default: 60
    },
    spread: {
      type: Number,
      default: 12
    },
    busy: {
      type: Boolean,
      default: false
    },
    percentage: {
      type: Number,
      default: 0
    }
  },
  components: { Spinner },
  computed: {
    total () {
      return this.sections.reduce((count, s) => count + s.value, 0)
    },
    backgroundPath () {
      return arcPath(this.width / 2, this.width / 2, this.radius, this.spread, 0, 359.99)
    },
    slices () {
      const slices = []
      let start = 0
      for (let i = 0; i < this.sections.length; i++) {
        const section = this.sections[i]
        if (section?.value > 0) {
          const end = start + (section.value !== this.total ? (section.value * 360 / this.total) : 359.99)
          slices.push({
            path: arcPath(this.width / 2, this.width / 2, this.radius, this.spread, start, end),
            style: section.style,
            title: section.title,
            value: section.value
          })
          start = end
        }
      }
      return slices
    }
  }
}

function arcPath (x, y, radius, spread, startAngle, endAngle) {
  const innerStart = polarToCartesian(x, y, radius, endAngle)
  const innerEnd = polarToCartesian(x, y, radius, startAngle)
  const outerStart = polarToCartesian(x, y, radius + spread, endAngle)
  const outerEnd = polarToCartesian(x, y, radius + spread, startAngle)
  const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'
  return [
    'M', outerStart.x, outerStart.y,
    'A', radius + spread, radius + spread, 0, largeArcFlag, 0, outerEnd.x, outerEnd.y,
    'L', innerEnd.x, innerEnd.y,
    'A', radius, radius, 0, largeArcFlag, 1, innerStart.x, innerStart.y,
    'L', outerStart.x, outerStart.y, 'Z'
  ].join(' ')
}

function polarToCartesian (centerX, centerY, radius, angleInDegrees) {
  const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0
  return {
    x: centerX + (radius * Math.cos(angleInRadians)),
    y: centerY + (radius * Math.sin(angleInRadians))
  }
}
</script>

<style lang="stylus" scoped>
.donut
  display flex
  flex-direction column
  align-items center
  justify-content center
  > div
    width 100%
  span
    cursor pointer
  svg
    display block
    margin auto
    text.donut-inside
      text-anchor middle
      fill #000
      font-size: 14px
      .donut-total
        font-weight bold
        font-size 30px
  .donut-legend
    padding 0 24px
    margin-top 24px
    list-style none
    display flex
    flex-direction column
    .donut-legend_slice
      *:hover
        cursor default
      margin 0.1em 0
</style>
