<template>
  <svg v-bind="$props" viewBox="0 0 60 60" :class="iconClassName" :style="{ fill: color }">
    <title v-if="title"> {{ title }} </title>
    <path d="M37.44,45.88A205.55,205.55,0,0,1,19.68,33V31.33A205.45,205.45,0,0,1,37.44,18.47l1.15,1.68A150.76,150.76,0,0,0,21.93,32.2a160,160,0,0,0,16.66,12Z"/>
  </svg>
</template>
<script>
import icon from '/icons/mixin.js'

export default {
  name: 'hippolyte.icon.triangle',
  mixins: [icon('20px')],
  props: {
    color: String,
    title: String
  }
}
</script>
