<template>
  <form @submit.prevent="onComment">
    <textarea
      v-model="comment"
      rows="6"
      class="candidate_comment-text"
      placeholder="commentaire">
    </textarea>
    <button class="picto_btn save_comment" type="submit" v-if="commentUpdated || commentUpdating" :disabled="commentUpdating">
      <span><template>Enregistrer</template></span>
    </button>
  </form>
</template>

<script>
import Interview from '/interview/interview.js'
import Loader from 'vue-spinner/src/BounceLoader.vue'
export default {
  name: 'profile.comment',
  components: {
    Loader
  },
  props: {
    value: Interview
  },
  watch: {
    value () {
      this.comment = this.value.comment
      this.listenUpdating()
    }
  },
  data () {
    this.listenUpdating()
    return {
      comment: this.value.comment,
      commentUpdating: this.value.updating
    }
  },
  computed: {
    commentUpdated () {
      return this.value.comment !== this.comment
    }
  },
  methods: {
    onComment () {
      this.value.setData({ comment: this.comment })
      this.value.save('comment')
      this.commentUpdating = this.value.updating
      this.listenUpdating()
      this.$emit('comment')
    },
    listenUpdating () {
      if (this.value.updating) {
        this.value.updating.then(
          () => {
            this.commentUpdating = false
          },
          () => {
            this.commentUpdating = false
          }
        )
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~/colors.styl'
textarea
  width 100%
  border: none;
  border-bottom: 2px $color-banana solid;
  resize: none;
  
.save_comment 
  float right
</style>
