<template>
  <nav class="toolbar-container" :class="{ show: $show.toolbar }">
    <section>
      <main class="toolbar-group">
        <router-link tag="button" class="toolbar-item" title="Dashboard"
          :to="{ name: prefix() + 'reporting_dashboard', params: { graph: 'reporting_dashboard' } }"
          :class="{ 'toolbar-selected': $route.name.includes('reporting_dashboard') }">Stats recruteur
        </router-link>
        <router-link tag="button" class="toolbar-item" title="Sourcing"
          :to="{ name: prefix() + 'sourcing', params: { graph: 'sourcing' } }"
          :class="{ 'toolbar-selected': $route.name.includes('sourcing') }">Stats sourcing
        </router-link>
        <router-link tag="button" class="toolbar-item" title="Donuts"
          :to="{ name: prefix() + 'donuts', params: { graph: 'donuts' } }"
          :class="{ 'toolbar-selected': $route.name.includes('donuts') }">Répartition des status
        </router-link>
        <router-link tag="button" class="toolbar-item" title="Evolving"
          :to="{ name: prefix() + 'evolving', params: { graph: 'evolving' } }"
          :class="{ 'toolbar-selected': $route.name.includes('evolving') }">
          Evolution candidats
        </router-link>
        <router-link tag="button" class="toolbar-item" title="Origin"
          :to="{ name: prefix() + 'origin', params: { graph: 'origin' } }"
          :class="{ 'toolbar-selected': $route.name.includes('origin') }">Origine candidats
        </router-link>
        <router-link tag="button" class="toolbar-item" title="Distribution"
          :to="{ name: prefix() + 'distribution', params: { graph: 'distribution' } }"
          :class="{ 'toolbar-selected': $route.name.includes('distribution') }">Distribution
        </router-link>
        <router-link tag="button" class="toolbar-item" title="Tables"
          :to="{ name: prefix() + 'tables', params: { graph: 'tables' } }"
          :class="{ 'toolbar-selected': $route.name.includes('tables') }">
          statistiques campagnes
        </router-link>
        <router-link tag="button" class="toolbar-item" title="Screen AI"
          :to="{ name: prefix() + 'screen_ai', params: { graph: 'screen_ai' } }"
          :class="{ 'toolbar-selected': $route.name.includes('screen_ai') }">Screen AI</router-link>
      </main>
    </section>
  </nav>
</template>
<script>
import Account from '/user/account/entity.js'

export default {
  name: 'hippolyte.statMenu',
  props: {
    account: Account,
    start: Date,
    end: Date,
    r: Object,
    s: Object,
    a: Boolean
  },
  methods: {
    prefix() {
      if (this.$route.name.includes('recruiter')) {
        return 'recruiter_'
      } else if (this.$route.name.includes('search')) {
        return 'search_'
      } else {
        return ''
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
.toolbar-container
  display none
  @media (min-width $breakpoint)
    display flex
  flex-direction row
  flex-basis "%s" % $panel.first.width
  justify-content space-evenly
  height 100%
  width 100%
  main
    height 50%
    .toolbar-item
      height calc(28px + 2em)
      & img
        width 30px
  .toolbar-group
    display flex
    flex-direction row
    align-items center
    .toolbar-item
      width 100%
      align-self center
      margin 0 auto
      background transparent
      padding .5em 1em
      color: $color-toolbar_text
      cursor pointer
      outline none
      &:hover
        border-radius 20%
        background $color-toolbar_back
        color: #FFFFFF
        margin 0
      &.toolbar-selected
        border-radius 20%
        border-right 4px #45ADA7 solid
        border-bottom 4px #45ADA7 solid
        background #73c2bd
        color: #FFFFFF
</style>
