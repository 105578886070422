<template>
  <spinner v-if="spinner && saving"></spinner>
  <form @submit.prevent="onRegister" class="hippo-form" v-else>
    <p>
      <span class="gender">
        <button tabindex="0" :class="{ checked: gender === 'female'}" @click.prevent="gender='female'">Madame</button>
        <button tabindex="0" :class="{ checked: gender === 'male'}" @click.prevent="gender='male'">Monsieur</button>
      </span>
    </p>

    <p class="hippo-form_inline">
      <label for="lastname"><img src="/assets/icon/user_blue.png"/></label>
      <span class="hippo-mobile_name">
        <input name="lastname" placeholder="Nom" v-model="lastname" type="text"/>
        <input name="firstname" placeholder="Prénom" v-model="firstname" type="text"/>
      </span>
    </p>
    <p class="hippo-form_layout">
      <label for="company"><img src="/assets/icon/company_blue.png"/></label>
      <input name="company" placeholder="Entreprise" v-model="company" type="text"/>
    </p>
    <p class="hippo-form_layout">
      <label for="email"><img src="/assets/icon/send_blue.png"/></label>
      <input name="email" placeholder="Adresse mail" v-model="email" type="email"/>
    </p>
    <p class="hippo-form_layout">
      <label for="phone"><img src="/assets/icon/phone_blue.png"/></label>
      <input name="phone" placeholder="Téléphone" v-model="phone" type="text"/>
    </p>
    <p class="hippo-form_layout">
      <label for="location"><img src="/assets/icon/location_blue.png"/></label>
      <autocomplete
        class="autocomplete-wrapper"
        placeholder="Ville"
        :search="locationSearch"
        :get-result-value="locationField"
        :default-value="locationField(location)"
        :debounceTime="200"
        @submit="l => location = l">
        <template #result="{ result, props }">
          <li v-bind="props">
            <template v-if="result.iso==='MA'">
              {{ result.city }} <i>{{ result.location }} - {{ result.name }}</i>
            </template>
            <template v-else>
              {{ result.name }} <i>{{ result.zip }}</i>
            </template>
          </li>
        </template>
      </autocomplete>
    </p>
    <p class="hippo-form_layout" v-if="value.id === null">
      <span>
        <input id="cgu" type="checkbox" v-model="cgu" tabindex="-1"/>
      </span>
      <label for="cgu" tabindex="0" @keyup.enter="() => cgu = !cgu" @keyup.space="() => cgu = !cgu">
        <span :class="{ fakecheck: true, checked: cgu }"><span><span></span></span></span>
        J'accepte les Conditions Générales d'Utilisation d'Hippolyte RH et je reconnais avoir pris connaissance des termes de la Politique de confidentialité d'Hippolyte RH pour le traitement de mes données personnelles
      </label>
    </p>
    <p class="hippo-form_actions">
      <button :disabled="!isValid || saving" v-show="hasChange" type="submit">
        {{ value.id === null ? "S'inscrire" : "Valider" }}
      </button>
    </p>
  </form>
</template>
<style lang="stylus" scoped>
@require '~/colors.styl'
form
  color $color-grey
  font-size 1em
  margin auto
  p.hippo-form_inline, p.hippo-form_layout
    display flex
    width 100%
    > :first-child
      min-width 40px
      @media (min-width $breakpoint)
        min-width 80px
        text-align center
      img
        max-height 25px
        max-width 25px
    .autocomplete-input
      width: calc(100% + 40px)
  .hippo-form_inline span
    input:last-child
      margin-top 1em
    @media(min-width $breakpoint)
      flex-direction row
      justify-content space-between
      width 50%
      input:last-child
        margin-top 0
        margin-left 12px
    display flex
    flex-direction column
    input
      width 100%

  .gender
    width 100%
    @media(min-width $breakpoint)
      width: calc(50% + 160px)
    display flex
    flex-direction row
    justify-content center
    button
      font-size 1em
      color $color-astronaut
      margin 0
      padding 0.2em 0.3em 0.2em 0.3em
      background lighten($color-cloud, 50%)
      border 2px lighten($color-cloud, 50%) solid
      &:first-child
        border-radius 1em 0 0 1em
        padding-left 0.5em
      &:last-child
        border-radius 0 1em 1em 0
        padding-right 0.5em
      &.checked
        background $color-cloud
      &:focus
        border-color $color-astronaut
  input#cgu
    display none
  label[for=cgu]
    display flex
    flex-direction row
    align-items center
    font-size 11px
    .fakecheck
      margin-right 2em
      > span
        margin auto
        border 3px $color-astronaut solid
        border-radius 8px
        width 8px
        height 8px
        display block
      &.checked
        > span > span
          margin 2px
          background $color-astronaut
          border-radius 4px
          width 4px
          height 4px
          display block
    border-bottom 2px transparent solid
    &:focus
      border-color $color-astronaut
</style>
<script>
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import validate from '/validate'
import pick from 'ramda/src/pick'
import Recruiter from '/user/recruiter/recruiter.entity.js'
import Location from '/location/entity'
import Spinner from '/layout/spinner'

const fields = ['id', 'gender', 'firstname', 'lastname', 'company', 'email', 'location', 'phone']

export default {
  name: 'hippolyte.recruiter.form',
  components: { Autocomplete, Spinner },
  props: {
    value: Recruiter,
    spinner: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return Object.assign({
        saving: false,
        cgu: false,
        lastDelta: this.value.getDelta(fields)
      },
      pick(fields, this.value),
    )
  },
  computed: {
    isValid () {
      return this.location
        && this.gender !== null
        && validate.email(this.email)
        && validate.text(this.company)
        && validate.text(this.firstname)
        && validate.text(this.lastname)
        && this.location?.id
        && validate.text(this.phone)
        && (this.value.id !== null || this.cgu)
    },
    hasChange () {
      const recruiter = new Recruiter(pick(fields, this))
      return this.lastDelta !== recruiter.getDelta(fields)
    }
  },
  methods: {
    async locationSearch (value) {
      let iso = this.value.location?.iso || 'FR'
      return value.length < 2 ? [] : Location.search({ value, iso }, this.$socket)
    },
    locationField (location) {
      if ('MA' === location.iso) {
        return [location.city, location.location, location.name].join(' - ').toUpperCase()
      }
      return location.name
    },
    async onRegister () {
      if (this.isValid) {
        let data = pick(fields, this.$data)
        const recruiter = new Recruiter(data, this.$socket)
        this.saving = recruiter.save((function () {
          let data = pick(fields, recruiter)
          data.location = data.location?.id
          return data
        }))
          .then(
            () => {
              if (this.value.id) {
                this.$toast('Profil modifié', { theme: 'success'})
              }
              this.value.setData(recruiter.marshall(fields))
              this.saving = false,
              this.$emit('done', recruiter)
              this.lastDelta = this.value.getDelta(fields)
            },
            err => {
              if (err.response.status === 409) {
                this.$toast('Cet email est déjà utilisé', { theme: 'error'})
                let unwatch = this.$watch('email', () => {
                  unwatch()
                  this.errors.email = null
                })
              }
              throw err
            }
          )
          .catch(err => {
            this.saving = false
          })
      }
    }
  }
}
</script>
