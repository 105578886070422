<template>
  <div id="app">
    <router-view></router-view>
    <vue-toast ref='toaster'></vue-toast>
    <modal-confirm ref='confirm'></modal-confirm>
  </div>
</template>
<script>
import 'vue-toast/dist/vue-toast.min.css'
import VueToast from 'vue-toast'
import ModalConfirm from '/layout/confirm'
export default {
  name: 'Hippolyte',
  components: { VueToast, ModalConfirm },
  mounted () {
    this.$refs.toaster.setOptions({
      position: 'right bottom'
    })
  }
}
</script>
<style lang="stylus">
@require '~/base.styl'
</style>
