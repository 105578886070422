<template>
  <button v-if="canArchive" @click.stop="$emit('archive', interview)" :disabled="interview.updating" :class="'archive_'+type">
    <loader v-if="interview.isArchiving()"size="0.8em" :color="color"></loader>
    <div class="icon-wrapper" v-else >
      <img class="action-archive" src="/assets/icon/archive.png"/>
    </div>
    <span><template v-if="type === 'button'">Archiver</template></span>
  </button>
</template>
<script>
import Interview from '/interview/interview.js'
import Loader from 'vue-spinner/src/BounceLoader.vue'

export default {
  name: 'candidate.status.tools.archive',
  components: { Loader },
  props: {
    type: {
      type: String,
      default: 'button'
    },
    interview: Interview,
    color: {
      default: '#ffc857',
      type: String
    }
  },
  computed: {
    canArchive () {
      return this.interview.canArchive()
    }
  },
  methods: {
    onArchive () {
      this.$emit('archive', this.interview)
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
button
  .v-spinner
    display inline-block
  &.archive_icon
    &:hover
      color: $color-yellow
    background $color-astronaut
    color $color-white
    border 2px $color-yellow solid
    border-radius 1em
    margin 0.2em
    padding 0.2em
    font-size 1.2em
    width 2em
    height 2em
    display block
    text-align center
    cursor pointer
  img.action-archive
    height 1.2em
    width 1.2em
    margin 0 0 0 1px
    padding 0
  .icon-wrapper
    background $color-blue_lighter
    border-radius 2em
    border 1px $color-blue_lighter solid
    margin 0
    padding 0.2em
    height 1.2em
    width 1.2em
  span
    margin 0 0.4em
  &.archive_button
    width: 100%
    padding 0 !important
    margin 0
    display flex
    flex-direction row
    align-items center
    border 3px #45ADA7 solid !important
    font-family: $font
    font-size: 0.8em;
    submiter()
    .v-spinner
      display inline-block
</style>
