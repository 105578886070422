<template>
  <div
    :class="{'room-item_container': true, selected: room.selectedState }"
    @click.prevent="() => $emit('select', room)">
      <div class="room-item_wrapper">
        <button class="room-item_close" v-if="closable" @click.stop.prevent="$emit('close', room)">✖</button>
        <span class="avatar-container">
          <avatar :src="avatar" :anonymous="hideAvatar" :prefix="$config.thumbUrl" size="tiny"></avatar>
          <span :class="{ isOnline: online, presence: true }" :title="online ? 'connecté' : 'déconnecté'"></span>
        </span>
        <slot name="title" :profile="profile"></slot>
        <div class="room-item_unread" v-show="room.unread > 0">{{ room.unread }}</div>
    </div>
  </div>
</template>

<script>
import Room from 'minou/src/room'
import Avatar from '/user/avatar'
import Candidate from '/user/candidate/candidate.entity'
import Recruiter from '/user/recruiter/recruiter.entity'

export default {
  components: { Avatar },
  name: 'hippolyte.menu.room.item',
  props: {
    room: Room,
    profile: [Candidate, Recruiter],
    closable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    this.room.on('selected', this.$forceUpdate, this)
    this.room.on('unselected', this.$forceUpdate, this)
    this.room.on('unread:message', this.$forceUpdate, this)

    this.profile?.on('update', () => {
      Object.assign(this, this.profile.marshall())
      this.$forceUpdate()
    })
    if (this.profile && this.profile.loading) {
      this.profile.loading.then(() => {
        this.onUpdateUsers()
        this.$chat.on('users:update', this.onUpdateUsers, this)
      })
    }
    return Object.assign(this.profile?.marshall() || {}, {
      hideAvatar: null,
      online: false,
      unread: this.room.unread,
      avatar: null
    })
  },
  watch: {
    profile () {
      Object.assign(this, this.profile.marshall())
    }
  },
  methods: {
    onUpdateUsers (users) {
      let user = this.room.users.find(u => u.uid === String(this.chatUser))
      this.online = user && user.activity !== null
    }
  },
  computed: {
    avatarSrc () {
      return this.avatar
    }
  },
  destroyed () {
    this.room.off('selected', this.$forceUpdate, this)
    this.room.off('unselected', this.$forceUpdate, this)
    this.$chat.off('users:update', this.onUpdateUsers, this)
  }
}
</script>
<style lang="stylus">
@require '~/colors.styl'
$text = $color-white

.room-item_container
  color #404042
  /* background-color $color-menu_light */
  gradient-border($color-menu_back)
  height 3em
  padding-right 4px
  &:hover
    background alpha(#b8e0dd, 0.3) !important
    cursor pointer
  &.selected
    background alpha(#b8e0dd, 0.5)
    padding-right 0
    border-right 4px $color-yellow solid
  .room-item_wrapper
    position relative
    width 100%
    display flex
    .room-item_close
      position absolute
      top 0
      left -1em
      background none
      color #f48767
      z-index 3
      &:hover
        cursor pointer
        top -2px
        left calc(-1em - 2px)
        color darken(#f48767, 20%)
    margin-left 1.5rem
    padding 0.2em 0
    height: 42px
    display flex
    flex-direction row
    align-items center
    .room-item_name
      margin 0 0.5em
      font-size 0.8em
      font-weight 100
      width 58%
      overflow hidden
      text-overflow ellipsis
    .room-item_unread
      text-align center
      color #fff
      width 1.6em
      height @width
      line-height @width
      font-size 0.7em
      border-radius 50%
      background-color #45ADA7
      font-weight bold
      padding 0.1em
    .avatar-container
      position relative
      height 42p
      width: 42px
      .presence
        border 1px solid $color-menu_text
        display inline-block
        width 6px
        height 6px
        border-radius 1em
        background-color $color-disconnected
        position absolute
        top 2px
        right 1px
      .presence.isOnline
        background-color $color-connected
      .room-recent
        display inline-block
        position absolute
        top 0px
        left 0px
        color $color-yellow
</style>
