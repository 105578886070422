<template>
  <svg v-bind="$props" viewBox="0 0 21 18">
    <path d="M19.4767 1L9.41403 17L7.88259 9.11827L1 4.98344L19.4767 1Z" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.83557 9.15109L19.4766 1" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
import icon from '/icons/mixin.js'

export default {
  name: 'hippolyte.atom.icon.paper_plane',
  mixins: [icon('20px')]
}
</script>
