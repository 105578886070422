<template>
  <div v-if="search">
    <form @submit.stop.prevent="onUpdate">
      <fieldset>
        <div>
          <label for="config">activé</label>
          <input type="checkbox" v-model="config.active" @change="onUpdate" id="config" name="config"/>
        </div>
      </fieldset>
    </form>
    <p class="title">{{ title }}</p>
  </div>
  <p v-else>Séléctionnez une search</p>
</template>
<script>
export default {
  name: 'hippolyte.services.geomail.config',
  props: {
    value: Object,
    search: Object
  },
  data () {
    return {
      config: {
        active: this.value.active || false
      }
    }
  },
  computed: {
    title () {
      return [
        this.search.title,
        this.search.location.docs[0]?.name,
        this.search.trade.docs[0]?.name
      ].filter(v => v).join(' ')
    }
  },
  methods: {
    onUpdate () {
      console.log('event geomail', this.config)
      this.$emit('input', this.config)
    }
  }
}
</script>

<style lang="stylus" scoped>
.title
  margin-left 1rem
</style>
