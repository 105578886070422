<template>
  <tr class="profile_item" v-if="field.label">
    <td class="profile-item_name">{{ field.label }}</td>
    <td class="profile-item_value" v-if="!editing && !saving" @click="transForm" @focus="transForm" tabindex=0>
      <a v-if="field.type === 'link'"
       :href="value"
        target="_blank">&#128279;
      </a>
      <audio v-else-if="field.type === 'audio'" controls :src="value"></audio>
      <template v-else>{{ value }}</template>
    </td>
    <td class="profile-item_edit" v-if="!editing && field.base_name && !saving">
      <span @click="transForm">
        <svg viewBox="-15 -15 484.00019 484" xmlns="http://www.w3.org/2000/svg">
          <path d="m401.648438 18.234375c-24.394532-24.351563-63.898438-24.351563-88.292969 0l-22.101563 22.222656-235.269531 235.144531-.5.503907c-.121094.121093-.121094.25-.25.25-.25.375-.625.746093-.871094 1.121093 0 .125-.128906.125-.128906.25-.25.375-.371094.625-.625 1-.121094.125-.121094.246094-.246094.375-.125.375-.25.625-.378906 1 0 .121094-.121094.121094-.121094.25l-52.199219 156.96875c-1.53125 4.46875-.367187 9.417969 2.996094 12.734376 2.363282 2.332031 5.550782 3.636718 8.867188 3.625 1.355468-.023438 2.699218-.234376 3.996094-.625l156.847656-52.324219c.121094 0 .121094 0 .25-.121094.394531-.117187.773437-.285156 1.121094-.503906.097656-.011719.183593-.054688.253906-.121094.371094-.25.871094-.503906 1.246094-.753906.371093-.246094.75-.621094 1.125-.871094.125-.128906.246093-.128906.246093-.25.128907-.125.378907-.246094.503907-.5l257.371093-257.371094c24.351563-24.394531 24.351563-63.898437 0-88.289062zm-232.273438 353.148437-86.914062-86.910156 217.535156-217.535156 86.914062 86.910156zm-99.15625-63.808593 75.929688 75.925781-114.015626 37.960938zm347.664062-184.820313-13.238281 13.363282-86.917969-86.917969 13.367188-13.359375c14.621094-14.609375 38.320312-14.609375 52.945312 0l33.964844 33.964844c14.511719 14.6875 14.457032 38.332031-.121094 52.949218zm0 0"/>
        </svg>
      </span>
    </td>
    <td v-if="field.type === 'link'" class="preview-cv">
      <button @click="openModal">
        <svg class="preview-cv-btn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.005 512.005">
          <path d="M505.749,475.587l-145.6-145.6c28.203-34.837,45.184-79.104,45.184-127.317c0-111.744-90.923-202.667-202.667-202.667 S0,90.925,0,202.669s90.923,202.667,202.667,202.667c48.213,0,92.48-16.981,127.317-45.184l145.6,145.6 c4.16,4.16,9.621,6.251,15.083,6.251s10.923-2.091,15.083-6.251C514.091,497.411,514.091,483.928,505.749,475.587z M202.667,362.669c-88.235,0-160-71.765-160-160s71.765-160,160-160s160,71.765,160,160S290.901,362.669,202.667,362.669z"/>
        </svg>
      </button>
      <sweet-modal ref="preview" width="100%" v-on:close="closeModal">
        <figure v-if="filePreviewStrategy === 'image'">
          <img :src="urlSrc" alt="cv-image" />
        </figure>
        <div v-else-if="filePreviewStrategy === 'gviewer' || filePreviewStrategy === 'pdf'" class="iframe-content">
          <iframe :src="urlSrc" class="preview-content" :key="value" :frameborder="0"/>
        </div>
        <a class="download-link" :href="value" download>Télécharger l'original</a>
      </sweet-modal>
    </td>
    <td class="profile-item_edit_value" v-if="editing || saving">
      <textarea v-if="field.type === 'AI'" v-model="valueHolder" @change="update" :class="saving ? 'profile-item_saving' : ''" :disabled="saving" ref="inputvalue" @blur="editing=false" rows="30" cols="50" />
      <textarea v-else v-model="valueHolder" @change="update" :class="saving ? 'profile-item_saving' : ''" :disabled="saving" ref="inputvalue" @blur="editing=false"  />
    </td>
  </tr>
</template>
<script>
import { SweetModal } from 'sweet-modal-vue'
const FileType = require('file-type/browser');

export default {
  name: 'hippolyte.profile.editable_item',
  components: {
    SweetModal
  },
  props: ['value', 'field'],
  data () {
    return {
      editing: false,
      valueHolder: this.value,
      saving: false,
      filePreviewStrategy: null,
      pdfUrl: '',
      isBlob: false
    }
  },
  watch: {
    value () {
      this.valueHolder = this.value
    }
  },
  computed: {
    urlSrc: function () {
      switch (this.filePreviewStrategy) {
        case 'gviewer':
           return `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(this.value)}`
        case 'image':
          return this.value
        case 'pdf':
          return this.pdfUrl
      }
    },
  },
  methods: {
    transForm () {
      if (this.field.base_name) {
        this.editing = !this.editing
        if (this.editing) {
          this.$nextTick(() => this.$refs.inputvalue.focus())
        }
      }
    },
    update () {
      this.$emit('update', { value: this.valueHolder, field: this.field, callback: this.done.bind(this) })
      this.saving = true
      this.editing = false
    },
    done () {
      this.saving = false
    },
    async openModal () {
      await this.setPreviewStrategyByFileExtension()
      await this.$refs.preview.open()
      this.$forceUpdate()
    },
    async setPreviewStrategyByFileExtension () {
        this.filePreviewStrategy = null
        this.isBlob = false
        const response = await fetch(this.value)
          .then(response => {
            const contentType = response.headers.get('Content-Type').split(";")[0]
            if (contentType === 'application/pdf') {
              return response.blob()
                .then(blob => {
                  this.isBlob = true
                  this.pdfUrl = URL.createObjectURL(blob)
                })
            } else {
              return response
            }
          })

        if (!this.isBlob) {
          const fileType = (await FileType.fromStream(response.body)) || []
          if (['docx', 'doc', 'ppt', 'cfb'].includes(fileType.ext)) {
            return this.filePreviewStrategy = 'gviewer'
          }
          else if (['jpg', 'png'].includes(fileType.ext)) {
            return this.filePreviewStrategy = 'image'
          } else {
            return this.filePreviewStrategy = 'unknown'
          }
        } else {
          return this.filePreviewStrategy = 'pdf'
        }

    },
    closeModal () {
      this.isBlob = false
      this.filePreviewStrategy = null
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
/* $r = 5px */
$r = 30.5px
.profile_item
    margin-top 1.2em
    color $color-profile_second_invert
    font-size 0.8em
    display block
    > *
        padding 0.2em
        vertical-align middle
    @media (min-width $breakpoint)
        margin-top 1.5em
    .profile-item_name
        background-color #45ADA7
        padding 0.2em 0.2em 0.2em 0.6em
        border-radius $r 0 0 $r
    .profile-item_value
        border-radius 0 $r $r 0
        background-color $color-white
        color #404042
        border 1px #45ADA7 solid
        overflow hidden
        overflow-wrap break-word
        a
            text-decoration none
            display inline-block
            vertical-align middle
            height 100%
        audio
            display block
            height 2em
            width 250px
    .profile-item_edit
      cursor pointer
      width 1.4em
      svg
        width 16px
        fill #45ADA7
        &:hover
          transform scale(1.1)
    .profile-item_edit_value
      input
        width 200px
        &.profile-item_saving
          background $color-yellow
  .preview-cv
    button
      background none
    svg
      fill #45ADA7
      &:hover
        transform scale(1.2)
    &-btn
      width 15px
      cursor pointer
.sweet-modal.is-alert .sweet-content
  color black
  display flex
  align-items center
  iframe
    @media (min-width $breakpoint)
      height 900px
.sweet-modal.is-visible .sweet-content
  @media (min-width $breakpoint)
    height 1200px
.sweet-content-content
  > *
    color black
  object
    width 100%
    height 90vh
    embed
      width 100%
      height 100%
  iframe
    width 100%
    height 90vh
    transform-origin 0 0
  figure
    width 100%
    height 90vh
    img
      width 100%
      height 100%
      object-fit contain
  .download-link
    font-size 0.9em
    color $color-astronaut
    text-decoration none
    &:hover
      color $color-astronaut-dark
      font-weight bold
</style>
