<template>
  <tr @click="onCandidate" :class="{ selected }">
    <td class="candidate_avatar_name">
      <avatar class="avatar_cl" v-if="!hide.avatar"
        :anonymous="hideAvatar"
        :src="(custom && custom.avatar) || avatar"
        size="tiny"
        :prefix="$config.thumbUrl"
        @select="onCandidate">
      </avatar>
      <font-awesome-icon class="candidate-reviewed" icon="certificate"
        v-if="!hide.reviewed && interview.candidate.reviewedAt"
        :title="'Vérifié le '+ reviewedTitle">
      </font-awesome-icon>
      <font-awesome-icon class="candidate-duplicate" icon="exclamation-circle"
        v-if="interview.candidate.duplicateFrom"
        title="Duplicate !">
      </font-awesome-icon>
      <span>
        <div class="candidate_name" @click.stop="onCandidate" v-if="!hide.name">{{ firstname }} {{ lastname }}</div>
        <div class="candidate_infos">
          <span>{{ formattedDate }}</span>-<span>{{ interview.candidate.location }}</span>
        </div>
        <span  v-if="!hide.mail" title="email">{{ email }}</span>
      </span>
    </td>
    <td v-if="!hide.sentAt">{{ interview.sentAt | format('P-p') }}</td>
    <td v-if="!hide.phone">
      <span title="'téléphone">{{ phone }}</span>
    </td>
    <slot name="columns"></slot>
  </tr>
</template>
<script>
import Avatar from '/user/avatar'
import Candidate from '/user/candidate/candidate.entity.js'
import Interview from '/interview/interview.js'
import Loader from 'vue-spinner/src/BounceLoader.vue'

export default {
  name: 'recruiter.candidates.row',
  components: { Avatar, Loader },
  props: {
    interview: Interview,
    selected: {
      type: Boolean,
      default: false
    },
    color: {
      default: '#ffc857',
      type: String
    },
    hide: {
      type: Object,
      default () {
        return {
          reviewed: true
        }
      }
    }
  },
  data () {
    const c = this.interview.candidate
    c.off('update', this.updateCandidate, this)
    c.on('update', this.updateCandidate, this)
    return c.marshall()
  },
  destroyed () {
    this.interview.candidate.off('update', this.updateCandidate, this)
  },
  methods: {
    updateCandidate (candidate) {
      Object.assign(this, candidate.marshall())
    },
    isUpdating () {
      return Boolean(this.interview.updating)
    },
    onCandidate () {
      this.$emit('click', this.interview)
    }
  },
  computed: {
    reviewedTitle () {
      return this.$options.filters.format(this.interview.candidate.reviewedAt, 'P-p')
    },
    formattedDate () {
      if (this.interview.candidate.createdAt !== null) {
        return (this.interview.candidate.createdAt).toLocaleString()
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
tr
  font-size 14px
  td.candidate_avatar_name
    position relative
    display flex
    & *
      margin auto 0
    span
      margin-left: 5px
      .candidate_name
        cursor pointer
    .candidate-reviewed
      position absolute
      color $color-astronaut_lighter
    .candidate-duplicate
      position absolute
      color $color-peach
    .candidate_infos
      font-size 0.8em
  td
    color: #0a2e36
    padding: 15px 10px
    &.candidate_actions
      padding: 0
    button
      margin 0
      padding 0
      background none
      border none
      cursor pointer
      img
        &.action-contact
          height 30px
        &.action-reject, &.action-archive, &.action-unarchive
          height 20px
    a
      color $color-astronaut
      text-decoration: none
</style>
