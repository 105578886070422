<template>
  <ats
    v-bind="$props"
    service="icims"
    :savingConf="savingConf"
    :credential="{
      client_id: '',
      client_secret: '',
      customer_id: '',
      folder: ''
    }"
    :cached="true"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="token">Client ID</label>
        <input v-model="credential.client_id" id="client_id" name="client_id"/>
        <modified :enable="value.credential.client_id !== credential.client_id"/>
      </div>
      <div>
        <label for="client_secret">Client Secret</label>
        <input v-model="credential.client_secret" id="client_secret" name="client_secret"/>
        <modified :enable="value.credential.client_secret !== credential.client_secret"/>
      </div>
      <div>
        <label for="customer_id">Customer ID</label>
        <input v-model="credential.customer_id" id="customer_id" name="customer_id"/>
        <modified :enable="value.credential.customer_id !== credential.customer_id"/>
      </div>
      <div>
        <label for="folder">Folder</label>
        <input v-model="credential.folder" id="folder" name="folder"/>
        <modified :enable="value.credential.folder !== credential.folder"/>
      </div>
    </template>
  </ats>
</template>
<script>
import Ats from './sdk-v3'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.icims.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
