<template>
  <section>
    <help :title="'Distribution des conciliations'">
      <template v-slot:content>
        <p>Les statistiques se basent sur la date de création de la conciliation.</p>
        <p>Pensez à utiliser les datepicker (en haut à droite) si vous souhaitez voir la distribution sur une autre période.</p>
      </template>
    </help>
    <header>
      <h2>Distribution des conciliations</h2>
    </header>
    <div style="position: relative">
      <div v-if="busy"
      style="position: absolute; top: 0; margin-top: -2em; left: 0;">
        <spinner style="transform: scale(0.2, 0.2);"></spinner>
      </div>
      <canvas v-if="recruiter || search" ref="conciliationDistributionChart" width="400" height="150"></canvas>
      <div v-else >
          Pas de recruteur ou de search selectionné
      </div>
    </div>
  </section>
</template>
<script>
import Recruiter from '/user/recruiter/recruiter.entity.js'
import Search from '/requirement/entity'
import Help from '/components/help'
import { Labels } from '/interview/status'
import { xLabels, backgroundColor } from './utilsArray'
import Spinner from '/layout/spinner'
import { Solr, and, eq } from '/solr'
import { Chart, PointElement, BubbleController, LinearScale, CategoryScale, Legend, Title, Tooltip } from 'chart.js'
import { getISODay, parseISO, add, endOfDay, startOfDay } from 'date-fns'
import cancel from '/cancel.mixin.js'
Chart.register(PointElement, BubbleController, LinearScale, CategoryScale, Legend, Title, Tooltip)

export default {
  name: 'hippolyte.reporting.byDistribution',
  props: {
    recruiter: Recruiter,
    search: Search,
    start: Date,
    end: Date,
    status: Array
  },
  mounted () {
    if (this.recruiter || this.search) {
      this.load()
    }
  },
  components: {
    Spinner,
    Help
  },
  mixins: [cancel],
  data () {
    return {
      busy: false,
      distributionSource: [],
      distributionChart: null,
      cancels: {}
    }
  },
  watch: {
    search: 'load',
    recruiter: 'load',
    start: 'load',
    end: 'load',
    distributionSource: 'update'
  },
  destroyed () {
    this.distributionChart?.destroy()
  },
  methods: {
    async loadData () {
        const req = new Solr({
          entity: 'ConciliationStatus',
          raw: true,
          query: 'status:created',
          limit: 0,
          offset: 0
        })
        if (this.search && this.search.id) {
          req.join({
            entity: 'Conciliation',
            query: and(eq('search', this.search.id))
          })
        }
        if (this.recruiter && this.recruiter.id) {
          req.join({
            entity: 'Conciliation',
            query: and(eq('recruiter', this.recruiter.id))
          })
        }
        req.parameter('start', add(startOfDay(this.start), { minutes: -this.start.getTimezoneOffset() }).toISOString())
        req.parameter('end', add(endOfDay(this.end), { minutes: -this.end.getTimezoneOffset() }).toISOString())
        const reqFacets = {
          filters: ['date:[@start TO @end]'],
          facets: [{
            type: 'range',
            name: 'creationDate',
            field: 'date',
            start: add(startOfDay(this.start), { minutes: -this.start.getTimezoneOffset() }).toISOString(),
            end: add(endOfDay(this.end), { minutes: -this.end.getTimezoneOffset() }).toISOString(),
            gap: '+1HOUR',
            facets: this.status.map(status => {
              return {
                type: 'query',
                name: 'conciliation_' + status,
                query: `{!join entity=Conciliation v=\"{!join entity=ConciliationStatus v=\\"status:${status}\\"}\"}`
              }
            })
          }]
        }
        return this.$socket.service('entity_solr/QUERY', Object.assign(req.send(), reqFacets), this.token('distribution'))
    },
    toDataset({ facets }) {
      return this.status.map( (status, i) => {
        return {
          label: Labels[status].plural,
          data: facets.creationDate?.buckets.filter(d => d.count > 0).map((range,i) => {
            return {
              x: getISODay(parseISO(range.val)), // week day (1=Monday, 7=Sunday)
              y: parseISO(range.val).getHours(), // hour of the day
              size: range['conciliation_' + status].count // rayon de la bulle
            }
          }).reduce((acc, { x, y, size }) => {
            const found = acc.find(i => i.x === x && i.y === y)
            if (found) {
              found.size += size
            } else {
              acc.push({ x, y, size })
            }
            return acc
          }, []),
          backgroundColor: backgroundColor[i]
        }
      })
    },
    async load () {
      try {
        this.cancel('distribution')
        this.busy = true
        if (this.recruiter || this.search) {
          const data = await this.loadData()
          this.distributionSource.splice(0, this.distributionSource.length, ...this.toDataset(data))
        } else {
          this.distributionSource.splice(0, this.distributionSource.length)
        }
        this.cancel('distribution', null)
        this.busy = false
      } catch (err) {
        this.handleCancel(err).catch(() => {
          this.cancel('distribution', null)
        })
      }
    },
    update () {
      if (this.distributionChart) {
        this.distributionChart.data.datasets = this.distributionSource
        this.distributionChart.data.labels = this.status.map(status => Labels[status].plural)
        this.distributionChart.update()
      } else {
        this.distributionChart = new Chart(this.$refs.conciliationDistributionChart, {
          type: 'bubble',
          data: {
            datasets: this.distributionSource
          },
          options: {
            elements: {
              point: { // set size of bubbles
                radius: context => {
                  return Math.ceil(Math.log(context.dataset.data[context.dataIndex]?.size + 1) * 10)
                }
              }
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: tooltipItem => {
                    const [label, day, hour, conc] =  [tooltipItem.label, xLabels[tooltipItem.dataset.data[tooltipItem.dataIndex].x], tooltipItem.dataset.data[tooltipItem.dataIndex].y, tooltipItem.dataset.data[tooltipItem.dataIndex].size]
                    return `${label}: ${day}, ${hour}h, ${conc} Cand.`
                  }
                }
              },
              legend: {
                labels: {
                  boxWidth: 20,
                  pointStyle: 'rectRounded',
                  usePointStyle: true,
                  color: 'rgb(19, 77, 96)',
                  font:{
                    size: 16,
                    family: 'Montserrat'
                  }
                },
                onClick: function(e, legendItem) {
                  var index = legendItem.datasetIndex
                  var ci = this.chart
                  var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden
                  var anyOthersAlreadyHidden = false
                  var allOthersHidden = true
                  // figure out the current state of the labels
                  ci.data.datasets.forEach(function(e, i) {
                    var meta = ci.getDatasetMeta(i)
                    if (i !== index) {
                      if (meta.hidden) {
                        anyOthersAlreadyHidden = true
                      } else {
                        allOthersHidden = false
                      }
                    }
                  })
                  // if the label we clicked is already hidden
                  // then we now want to unhide (with any others already unhidden)
                  if (alreadyHidden) {
                    ci.getDatasetMeta(index).hidden = null
                  } else {
                    // otherwise, lets figure out how to toggle visibility based upon the current state
                    ci.data.datasets.forEach(function(e, i) {
                      var meta = ci.getDatasetMeta(i)
                      if (i !== index) {
                        // handles logic when we click on visible hidden label and there is currently at least
                        // one other label that is visible and at least one other label already hidden
                        // (we want to keep those already hidden still hidden)
                        if (anyOthersAlreadyHidden && !allOthersHidden) {
                          meta.hidden = true
                        } else {
                          // toggle visibility
                          meta.hidden = meta.hidden === null ? !meta.hidden : null
                        }
                      } else {
                        meta.hidden = null
                      }
                    })
                  }
                  ci.update()
                },
              },
            },
            layout: {
              padding: { bottom: 30 }
            },
            scales: {
              x: {
                min: 0.5,
                max: 7.5,
                grid: { offset: true },
                ticks: {
                  beginAtZero: true,
                  callback: value => xLabels[value]
                }
              },
              y: {
                min: 0,
                max: 24,
                reverse: true,
                ticks: {
                  stepSize: 2,
                  autoSkip: false
                }
              },
            },
            responsive: true
          }
        })
      }
    }
  }
}
</script>
