<template>
  <layout @start="onStart"></layout>
</template>
<script>
import Layout from '/layout/app.office'

export default {
  name: 'hippolyte.layout.conciliator',
  components: { Layout },
  methods: {
    async onStart (interview) {
      const room = await this.$chat.create({
        id: ['/conciliator', this.$account.id, interview.candidate.id].join('/'),
        name: 'précision'
      })
      this.$profiles.load(interview.candidate.id)
      room.join()
    }
  }
}
</script>
