<template>
  <nav class="toolbar-container" :class="{ show: $show.toolbar }">

    <header class="toolbar-group" @click="$router.push('/')">
      <logo></logo>
    </header>
    <main class="toolbar-group">
      <router-link tag="button" class="toolbar-item" title="Interviews" :to="{ name: 'chat' }" :class="{ 'toolbar-selected': ['chat', 'interview', 'interview_id'].includes($route.name) }">
        <img src="/assets/icon/chat_white.png"/>
        <span class="unread" v-if="unread">{{ unread }}</span>
      </router-link>
      <router-link tag="button" class="toolbar-item" title="Candidats" :to="{ name: 'candidate_list' }" :class="{ 'toolbar-selected': ['candidate_list', 'candidates_profile'].includes($route.name) }">
        <img src="/assets/icon/candidates_white.png"/>
      </router-link>
      <router-link tag="button" class="toolbar-item" title="Reporting" :to="{ name: 'donuts', query: { a: true },  }" :class="{ 'toolbar-selected': $route.name === 'reporting' }">
        <img src="/assets/icon/stat_white.png"/>
      </router-link>
      <router-link tag="button" class="toolbar-item" title="Paramètres" :to="{ name: 'profile' }" :class="{ 'toolbar-selected': ['profile', 'notif'].includes($route.name) }">
        <img src="/assets/icon/settings_white.png"/>
      </router-link>
      <router-link tag="button" class="toolbar-item" title="ATS" :to="{ name: 'ats_home' }" :class="{ 'toolbar-selected': ['ats', 'ats_home'].includes($route.name) }">
        <img src="/assets/icon/businessman.png"/>
      </router-link>
    </main>

    <footer class="toolbar-group">
      <notify-button class="toolbar-item" :account="account" v-if="notif"></notify-button>
      <button class="toolbar-item"
        title="Déconnexion"
        v-if="$auth && $auth.user"
        @click="onLogout">
        <img src="/assets/icon/logout.png"/>
      </button>
    </footer>

  </section>
</template>
<script>
import Recruiter from '/user/recruiter/recruiter.entity.js'
import Account from '/user/account/entity.js'
import NotifyButton from '/notify/button'
import Notif from '/notify'
import Conciliator from '/user/conciliator/role'
import Logo from './logo'

export default {
  name: 'hippolyte.toolbar',
  props: {
    account: Account
  },
  data () {
    this.$chat?.on('unread:message', ({ from, to }) => {
      this.unread = to
    })
    const s = Notif.support
    return {
      notif: s.sw && s.push && s.notif,
      unread: this.$chat?.unread
    }
  },
  components: { NotifyButton, Logo },
  methods: {
    onLogout () {
      this.$auth.logout()
      this.recruiter.setData((new Recruiter()).marshall())
      this.account.setData((new Account()).marshall())
      this.$router.push({ name: 'login' })
      window.location.reload()
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
.toolbar-container
  display none
  @media (min-width $breakpoint)
    display flex
  flex-direction column
  flex-shrink 0
  flex-grow 0
  flex-basis "%s" % $panel.first.width
  justify-content space-between
  background-color #73c2bd
  color $color-toolbar_text
  height 100%
  width "%s" % $panel.first.width
  header
    margin 0.5em
    cursor pointer
    img
      width 40px
  main
    height 50%
    .toolbar-item
      height calc(28px + 2em)
      & img
        width 30px
  footer
    height 20%
    &.toolbar-group
      justify-content: flex-end
      .toolbar-item
        img
          width 20px
  .toolbar-group
    display flex
    flex-direction column
    align-items center
    .toolbar-item
      width 100%
      align-self center
      margin 0 auto
      background transparent
      padding 1em 0
      border none
      color: $color-toolbar_text
      cursor pointer
      outline none
      &:hover
        background $color-toolbar_light
      margin 0
      &.toolbar-selected
        border-right 4px #45ADA7 solid
        background $color-toolbar_light
  button[title="Interviews"]
    position relative
    .unread
      position absolute
      width 20px
      height 20px
      background $color-pink
      right 5px
      top 5px
      border-radius 10px
      color $color-white
      font-size 12px
      text-align center
      line-height 20px
</style>
