<template>
  <article>
    <header>
      <h2>Chat</h2>
    </header>
      <div style="position: relative">
        <div v-if="busy"
        style="position: absolute; top: 0; margin-top: -2em; left: 0;">
          <spinner style="transform: scale(0.2, 0.2);"></spinner>
        </div>
        <canvas ref="chatStat" width="400" height="150"></canvas>
      </div>
  </article>
</template>
<script>
import Recruiter from '/user/recruiter/recruiter.entity.js'
import Search from '/requirement/entity'
import { Chart, BarElement, BarController, LinearScale, CategoryScale, Legend, Title, Tooltip } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import parseISO from 'date-fns/parseISO'
import Spinner from '/layout/spinner'
import cancel from '/cancel.mixin.js'
Chart.register(BarElement, BarController, LinearScale, CategoryScale, Legend, Title, Tooltip)

export default {
  name: 'hippolyte.reporting.chat',
  props: {
    recruiter: Recruiter,
    search: Search,
    start: Date,
    end: Date,
    gap: Object
  },
  components: { Spinner },
  mixins: [cancel],
  data () {
    return {
      busy: false,
      chat: [],
      chatStat: null,
      cancels: {},
    }
  },
  watch: {
    recruiter: 'load',
    search: 'load',
    start: 'load',
    end: 'load'
  },
  mounted () {
    this.load()
  },
  methods: {
    displayChat () {
      const data = {
        labels: this.chat.map(obj => obj.label),
        datasets: [{  data: this.chat.map(obj => obj.value),
          label: 'Nombre de Rooms ouvertes',
          backgroundColor:'rgb(60, 157, 173)'
        }]}
      if (this.chatStat) {
        this.chatStat.data = data
        this.chatStat.update()
      } else {
        this.chatStat = new Chart(this.$refs.chatStat, {
          type: 'bar',
          data: data,
          plugins: [ChartDataLabels],
          options: {
            plugins: {
              legend: { display: false },
              datalabels: {
                color: 'white',
                display: function (context) {
                  return context.dataset.data[context.dataIndex] > 0
                }
              }
            },
            layout: {
              padding: { bottom: 30 }
            },
            scales: {
              x: { grid: { display: false } },
              y: { grid: { display: false } }
            },
            responsive: true
          }
        })
      }
    },
    async load () {
      this.cancel('chat')
      let recruiter
      if (this.recruiter) {
        recruiter = this.recruiter.accounts.map(a => a.id)
      } else if (this.search) {
        recruiter = this.search.recruiter?.accounts.map(a => a.id) // tableau contenant les accounts_id
      }
      if (recruiter) {
        try {
          const data = await this.$socket.service('stat/SHOW', { recruiter, start: this.start.toISOString(), end: this.end.toISOString(), gap: this.gap.gap}, this.token('chat') )
          this.cancel('chat', null)
          this.chat.splice(0, this.chat.length, ...Object.entries(data.result).map(([date, d]) => {
            return {
              date: parseISO(date),
              label: this.$options.filters.format(parseISO(date), this.gap.formatLabel),
              value: d.number_room
            }
          }))
        } catch (err) {
          this.chat.splice(0, this.chat.length)
          this.handleCancel(err).catch(() => {
            this.cancel('chat', null)
          })
        }
      } else {
        this.chat.splice(0, this.chat.length)
      }
      this.displayChat()
    }
  }
}
</script>