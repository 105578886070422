<template>
  <form @submit.prevent="onInternalComment">
    <textarea
      v-model="internalComment"
      rows="6"
      class="candidate_internal-comment-text"
      placeholder="commentaire">
    </textarea>
    <button type="submit" class="picto_btn save_comment" type="submit" v-if="edited || updating" :disabled="updating">
      <span>Enregistrer</span>
    </button>
  </form>
</template>

<script>
import Candidate from '/user/candidate/candidate.entity.js'
import Loader from 'vue-spinner/src/BounceLoader.vue'
export default {
  name: 'profile.internal.comment',
  components: {
    Loader
  },
  props: {
    value: Candidate
  },
  watch: {
    value () {
      this.internalComment = this.value.internalComment
    }
  },
  data () {
    return {
      internalComment: this.value.internalComment,
      updating: false
    }
  },
  methods: {
    async onInternalComment () {
      try {
        this.value.setData({ internalComment: this.internalComment })
        this.updating = this.value.save({ internalComment: this.internalComment })
        await this.updating
        this.$emit('update', this.internalComment)
      } catch {}
      this.updating = false
    },
    listenUpdating () {
      if (this.value.updating) {
        this.value.updating.then(
          () => {
            this.internalCommentUpdating = false
          },
          () => {
            this.internalCommentUpdating = false
          }
        )
      }
    }
  },
  computed: {
    edited () {
      return this.value.internalComment !== this.internalComment
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~/colors.styl'
textarea
  width 100%
  border: none;
  border-bottom: 2px $color-yellow solid;
  resize: none;
  
.save_comment 
  float right
</style>
