import pick from 'ramda/src/pick'
const service = verb => `entity.Candidate/${verb}`

import EventEmitter from 'eventemitter3'
const cond = k => `YesWeChat\\ServiceEntityBundle\\Query\\Condition\\${k}`

import { normalize, blank, props } from './normalize.entity.js'
export default class Candidate extends EventEmitter {
  constructor (data, socket) {
    super()
    this.setData(blank, data)
    this.socket = socket
    this.loading = false
    this.loaded = false
  }

  setData (...data) {
    const trace = JSON.stringify(this.marshall())
    Object.assign(this, ...data)
    if (trace !== JSON.stringify(this.marshall())) {
      this.emit('update', this)
    }
    return this
  }

  load () {
    if (!this.loading && this.id) {
      this.loading = this.socket.service(service('READ'), { id: this.id })
        .then(data => {
          this.watch()
          this.loading = false
          this.loaded = true
          return data
        })
        .then(normalize)
        .then(this.setData.bind(this))
        .then(() => this)
    }
    return this.loading
  }

  save (data) {
    return this.socket.service(service('SAVE'), Object.assign({ id: this.id }, data))
  }

  watch () {
    if (!this.watching) {
      this.watching = this.socket.sub(`/entity/Candidate/${this.id}`, 'ENTITY', this.load.bind(this))
    }
    return this.watching
  }

  saveField (field, value) {
    return this.socket.service(service('EDIT_FIELD'), {
      id: this.id,
      value,
      field: field.base_name
    })
  }

  marshall () {
    return pick(props, this)
  }

  reset () {
    this.loaded = false
    return this.setData(blank)
  }
}

Candidate.props = props

Candidate.getByChatUser = async function (chatUser, socket) {
  let list = await socket.service(service('QUERY'), {
    alias: 'r',
    class: 'Candidate',
    parameters: [
      { type: cond('Parameter'), name: 'chatUser', value: chatUser }
    ],
    conditions: [
      {
        type: cond('Equals'),
        value: 'chatUser',
        subject: {
          type: cond('Field'),
          name: 'r.chatUser'
        }
      }
    ]
  })
  if (list.length === 1) {
    const candidate = new Candidate(list[0], socket)
    this.loaded = true
    candidate.watch()
    return candidate
  }
}

Candidate.loadIds = async function (candidates, socket, cancel) {
  if (candidates.length < 1) {
    return
  }
  let batch = Promise.resolve()
  const chunk = 10
  for (let i = 0; i < candidates.length; i += chunk) {
    const { promise, run } = loadBatch(candidates.slice(i, i + chunk), socket, cancel)
    batch.then(run)
    batch = promise
  }
  return batch
}
function loadBatch (candidates, socket, cancel) {
  let run
  let promise = new Promise(function (resolve, reject) {
    run = async function () {
      try {
        let list = await socket.service(service('QUERY'), {
          alias: 'r',
          class: 'Candidate',
          parameters: [
            { type: cond('Parameter'), name: 'id', value: candidates.map(c => c.id) }
          ],
          conditions: [
            {
              type: cond('In'),
              value: 'id',
              subject: {
                type: cond('Field'),
                name: 'r.id'
              }
            }
          ]
        }, { cancel })
        list.forEach(c => {
          candidates.filter(i => i.id === c.id).map(c2 => {
            c2.watch()
            c2.setData(normalize(c))
            c2.loaded = true
            c2.loading = false
          })
        })
        resolve(candidates)
      } catch (err) {
        reject(err)
        candidates.forEach(c => {
          c.loading = false
        })
      }
    }
  })
  candidates.forEach(c => {
    c.loading = promise.then(() => c)
  })
  return { promise, run }
}

Candidate.create = function (data, socket) {
  return new Candidate(normalize(data), socket)
}
