<template>
  <googlemaps-map
    :center="candidateCoords"
    :zoom.sync="zoom"
    :options="mapOptions">
    <googlemaps-marker :position="candidateCoords"/>
  </googlemaps-map>
</template>
<script>
import Candidate from '/user/candidate/candidate.entity.js'

export default {
  name: 'hippolyte.profile.map',
  props: {
    value: String
  },
  data () {
    let candidateCoords = {
      lat: 48.8587741,
      lng : 2.2069771
    }
    try {
      const coords = this.value.split(',')
      candidateCoords = {
        lat: parseFloat(coords[0]),
        lng: parseFloat(coords[1])
      }
    } catch {}
    return {
      candidateCoords,
      zoom: 8,
      mapOptions: {}
    }
  },
  watch: {
    value () {
      let coords = this.value.split(',')
      this.candidateCoords = {
        lat: parseFloat(coords[0]),
        lng: parseFloat(coords[1])
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
.vue-google-map
  height 350px
</style>
