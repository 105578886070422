<template>
  <section class="layout-session_wrapper">
    <div class="layout-session_panel">
      <slot name="panel"></slot>
    </div>
    <div class="layout-session_form">
      <slot name="form"></slot>
    <div>
  </section>
</template>
<style lang="stylus">
@require '~/colors.styl'
$panel = 20%
$panel-desktop = 50%
.layout-session_wrapper
  height 100%
  width: 100%
  display: flex
  flex-direction column
  @media (min-width $breakpoint)
    flex-direction row
  .layout-session_panel
    background $color-astronaut
    padding 2em 2em 2em 2em
    height "calc(%s - %s)" % ($panel 4em)
    color $color-white
    display flex
    flex-direction column
    align-items center
    justify-content space-evenly
    @media (min-width $breakpoint)
      box-shadow -15px 9px 21px 15px rgba(0,0,0,0.49)
      width "calc(%s - %s)" % ($panel-desktop 4em)
      height calc(100% - 9em)
      padding-bottom 8em
    &> *
      text-align center
    &> header
      width 100%
      text-align center
      @media (min-width $breakpoint)
        text-align right
        margin-right 4em
      img
        height 3.5em
        @media (min-width $breakpoint)
          height unset

  .layout-session_form
    overflow-y: auto;
    height: 100% - $panel
    width: 90%
    padding: 0 5%
    @media (min-width $breakpoint)
      width 100% - $panel-desktop
      height 100%
      display flex
      margin-top 10%
      flex-direction column
      .hippo-form
        margin auto
        width 80%
        max-width 500px
</style>
<script>
export default {
  name: 'hippolyte.layout.session'
}
</script>
