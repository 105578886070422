<template>
  <svg v-bind="$props" viewBox="0 0 20 28.7" :class="iconClassName" :style="{ fill: color }">
    <title v-if="title"> {{ title }} </title>
    <path d="M1.2 28c-.2 0-.4-.1-.5-.2-.3-.3-.3-.7-.1-1l6.3-9.7-5.5-3c-.2-.1-.3-.4-.4-.6s.1-.5.3-.7L18.2.8c.3-.2.7-.2 1 .1.3.3.3.7.1 1L13 11.7l4.8 3.1c.2.1.3.4.4.6s-.1.5-.3.6L1.7 27.9c-.1.1-.3.1-.5.1zm2-14.7l5.1 2.9c.2.1.3.3.4.5.1.2 0 .4-.1.6l-4.5 6.9L16 15.5l-4.5-2.9c-.2-.1-.3-.3-.3-.5s0-.4.1-.6l4.6-7.1-12.7 8.9z"/>
  </svg>
</template>
<script>
import icon from '/icons/mixin.js'

export default {
  name: 'hippolyte.icon.zap',
  mixins: [icon('20px')],
  props: {
    color: String,
    title: String
  }
}
</script>
