<template>
  <div :class="{ 'candidate-card': true, selected, editable }" @click="onSelect">

    <header class="card_name">
      <h3>
        <template v-if="!editable || !editing">
          <a target="_blank" :href="`${portraitUrl}?scope=apply`">{{ firstname }} {{ lastname }}</a>
        </template>

        <template v-else-if="editable && (editing || saving)">
          <input v-model="firstname" tabindex=0 :disabled="saving === 'firstname'" placeholder="prénom" @blur="update('firstname')"/>
          <input v-model="lastname" tabindex=0 :disabled="saving === 'lastname'" placeholder="nom" @blur="update('lastname')"/>
        </template>
      </h3>
      <button  @click="editing = !editing" v-if="editable && !saving" class="editable">
        <img src="/assets/icon/pen_blue.png"/>
      </button>
      <spinner v-if="editable && saving" style="transform: scale(0.2, 0.2);" class="editable"></spinner>
    </header>

    <article>
      <div class="card_content">
        <div class="card_content_values">
          <div class="card_location" v-if="location || editing || saving">
            <img src="/assets/city.png" class="card_picto" />
            <template v-if="!editable || !editing">
            {{ location }}
            </template>
            <template v-else-if="editable && (editing || saving)">
              <input v-model="location" tabindex=0 :disabled="saving" placeholder="ville" @blur="update('location')"/>
            </template>
            <template>
              <div>
                <input v-model="custom.postcode" tabindex=0 :disabled="saving" placeholder="CP" @blur="update('postcode')"/>
              </div>
            </template>
          </div>
          <div class="card_contact" v-if="email || editing || saving">
            <img src="/assets/mail.png" class="card_picto" />
            <template v-if="!editable || !editing">
              <a :class="getState('email')" :title="getTitle('email')" :href="'mailto:' + email">{{ email }}</a>
              <span v-if="reachability.email && reachability.email.error"> {{ reachability.email.error }}</span>
            </template>
            <template v-else-if="editable && editing">
              <input v-model="email" tabindex=0 :disabled="saving === 'email'" placeholder="email" @blur="update('email')"/>
            </template>
          </div>
          <div class="card_contact" v-if="phone || editing || saving">
            <img src="/assets/phone.png" class="card_picto"/>
            <template v-if="!editable || !editing">
              <a :class="getState('sms')" :title="getTitle('sms')" :href="'tel:' + phone">{{ phone }}</a>
              <span :class="getState('interview')">
                <phone-icon :title="getTitle('interview')" color='none'></phone-icon>
              </span>
              <span v-if='reachability.phone && reachability.phone.error' >{{ reachability.phone.error }}</span>
            </template>
            <template v-else-if="editable && editing">
              <input v-model="phone" tabindex=0 :disabled="saving === 'phone'" placeholder="téléphone" @blur="update('phone')"/>
            </template>
          </div>
          <div class="card_contact">
            <i class="card_picto" />⚧️</i>
            <template>
              <label for="Madame">
                <input type="radio" id="Madame" value="Madame" v-model="gender" tabindex=0 :disabled="saving === 'gender'" @change="update('gender')"/>
                Madame
              </label>
              <label for="Monsieur">
                <input type="radio" id="Monsieur" value="Monsieur" v-model="gender" tabindex=0 :disabled="saving === 'gender'" @change="update('gender')"/>
                Monsieur
              </label>
              <label for="Na">
                <input type="radio" id="Na" value="" v-model="gender" tabindex=0 :disabled="saving === 'gender'" @change="update('gender')"/>
                Na
              </label>
            </template>
          </div>

          <div v-if="createdAt">{{ formattedDate }}</div>
          <div class="card_tags" v-if="interview && interview.tags && interview.tags.length">
            <font-awesome-icon icon="tags" title="tags" size="sm"></font-awesome-icon>
            {{ interview.tags.map(t => t.name).join(' ') }}
          </div>
        </div>
      </div>
      <div class="card_avatar">
        <avatar
          :editable="editable"
          @select="onAvatar"
          :anonymous="hideAvatar"
          :src="avatar || custom.avatar"
          :prefix="$config.thumbUrl">
        </avatar>
        <div class="card_actions">
          <slot name="actions"></slot>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
import Avatar from '/user/avatar'
import Candidate from '/user/candidate/candidate.entity.js'
import parseISO from 'date-fns/parseISO'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import fr from 'date-fns/locale/fr'
import Interview from '/interview/interview.js'
import Conciliator from '/user/conciliator/role'
import Spinner from '/layout/spinner'
import axios from 'axios'
import PhoneIcon from '/icons/phone'
const CancelToken = axios.CancelToken

export default {
  name: 'recruiter.candidates.item',
  components: { Avatar, Spinner, PhoneIcon },
  props: {
    interview: Interview,
    candidate: Candidate,
    selected: {
      type: Boolean,
      default: false
    },
    color: {
      default: '#ffc857',
      type: String
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    profile () {
      this.onUpdateCandidate()
      this.loadReachables()
    }
  },
  data () {
    this.candidate.on('update', this.onUpdateCandidate.bind(this))
    return Object.assign({ editing: false, saving: false, portraitUrl: null }, this.candidate.marshall(), { reachables: [], reachability: {} })
  },
  mount () {
    this.getPortrait()
  },
  mounted () {
    this.loadReachables()
      .then(() => {
        this.reachables.forEach(r => {
          this.state(r.type)
        })
      })
  },
  computed: {
    stateText () {
      if (this.interview) {
        let title = ''
        if (this.interview.isArchived()) title = "Archivé"
        if (this.interview.isSent()) title = "Nouveau"
        if (this.interview.isOpened()) title = "Visité"
        if (this.interview.isRejected()) title = "Rejeté"
        if (this.interview.isStarted()) title = "En discussion"
        let since = formatDistanceToNow(parseISO(this.interview.statuses[0].date), { locale: fr })
        return title + ' depuis ' + since
      }
    },
    canArchive () {
      return this.interview?.canArchive()
    },
    canStart () {
      return this.interview?.canStart()
    },
    canUnarchive () {
      return this.interview?.canUnarchive()
    },
    canReject () {
      return this.interview?.canReject()
    },
    formattedDate () {
      if (this.candidate.createdAt !== null) {
        return (this.candidate.createdAt).toLocaleString()
      }
    }
  },
  methods: {
    getTitle (type) {
      return this.reachability[type] ? `${this.reachability[type].title} par ${type}` : `${type} non vérifié`
    },
    getState (type) {
      return this.reachability[type] ? this.reachability[type].state : null
    },
    state (type) {
      const r = this.reachables.find(r => r.type === type)
      if (r) {
        this.reachability[type] = {}
      } else {
        this.reachability[type] = null
      }
      if (r.pending) {
        this.reachability[type] = { state: 'pending', title: 'En cours de vérification' }
      } else {
        this.reachability[type] = r.ack ?
          { state:  'valid', title:  'Joignable' } :
          { state:  'not-valid', title:  'Non joignable'}
        // this.reachability[target] = { state: r.ack ? 'valid' : 'not-valid' , title: r.ack ? 'joignable' : 'non joignable' }
      }
      if (this[r.target] && r.value !== this[r.target]) {
        this.reachability[type].error = `le ${r.target} n'est pas le bon : '${this[r.target]}' au lieu de '${r.value}`
      }
    },
    async loadReachables () {
      try {
        this.cancel = CancelToken.source()
        const { docs } = await Interview.getReachables({ candidate: this.candidate.id }, this.$socket, this.cancel.token)
        this.reachables.splice(0, this.reachables.length, ...docs)
      } catch (err) {
        if (!axios.isCancel(err)) {
          throw err
        }
      }
    },
    onSelect () {
      this.$emit('select', this.interview || this.candidate)
    },
    async onUpdateCandidate () {
      Object.assign(this, this.candidate.marshall())
      this.getPortrait()
    },
    async getPortrait () {
      this.portraitUrl = await this.$socket.service('portrait/GET', { candidate: this.id, conciliation: this.interview.id })
    },
    onAvatar () {
      if (this.editable) {
        this.candidate.save({ hideAvatar: !this.hideAvatar} )
      }
    },
    edit (field) {
      this.editing = field
      if (this.editing) {
        this.$nextTick(() => this.$refs[field].focus())
      }
    },
    async update (field) {
      if (this.candidate[field] !== this[field] || this.custom[field] !== this[field]) {
        try {
          this.saving = field
          const fields = {
            firstname: 'first_name',
            lastname: 'last_name',
            location: 'custom_field_candidat_location_string',
            postcode: 'custom_field_postcode',
            gender: 'gender'
          }
          await this.candidate.saveField(
            { base_name: fields[field] || field },
            this[field] || this.custom[field]
          )
        } catch {}
        this.saving = false
      }
    }
  }
}
</script>
<style lang="stylus">
@require '~/colors.styl'
.candidate-card
  background  $color-white
  margin 0
  padding 0
  color #404042
  &.selected
    border-color $color-grey_lighter
  header
    text-align center
    color #404042
    background-color $color-white
    border none
    margin 0
    padding 0
    h3
      text-align: left
      margin 0.2em 0 0
  article
    display flex
    flex-wrap nowrap
    padding 0.3em 0
    justify-content: space-between
    .card_avatar
      position relative
      margin 0 1em
    .card_actions
      display flex
      flex-direction row
      justify-content flex-end
    .card_content
      height 100%
      display flex
      justify-content space-between
      flex-direction column
      overflow-x hidden
      .card_content_values
        margin-top 0.8em
        color #404042
        a
          text-decoration none
        .card_location, .card_contact, .card_tags
          margin 0.1em 0
          svg
            vertical-align middle
          .valid > svg
            stroke: $color-green;
          a.valid::after
            padding-left 0.2em
            content: "\2713"; /* Unicode check mark */
            color: $color-green;
          .not-valid > svg
            stroke: $color-pink;
          a.not-valid::after
            padding-left 0.2em
            content: "\26A0"; /* Unicode warning sign */
            color: $color-pink;
          .pending > svg
            stroke: $color-peach;
          a.pending::after
            padding-left 0.2em
            content: "\231B"; /* Unicode hourglass */
            color: $color-peach;
          .card_picto
            max-width 15px
            max-height 15px
          svg
            color #114B5F
        .card_contact
          a
            overflow-wrap anywhere
            color $color-grey
  input
    border-bottom 2px $color-yellow solid
  .card_name
    position relative
    a
      text-decoration none
      color inherit
    button
      width 20px
      height 20px
      cursor pointer
      img
        width 20px
        height 20px
    .editable
      position absolute
    button.editable
      top 0
      right 10px
      background none
    #floatingBarsG-container.editable
      top -20px
      right -20px


@media (max-width: $breakpoint)
  .card_name, .card_location, .card_avatar
    display none
</style>
