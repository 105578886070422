import { Status } from '/interview/status'
const ordered = [
  Status.created,
  Status.waiting_acceptation,
  Status.suggested,
  Status.accepted,
  Status.declined,
  Status.do_not_send,
  Status.sent,
  Status.opened,
  Status.started,
  Status.scheduling_meeting,
  Status.met,
  Status.recruited,
  Status.archived,
  Status.rejected,
  Status.discarded
]
export default ordered
