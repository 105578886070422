<template>
  <div class='select-container'>
    <label :for="name">Prompt AI</label>
    <treeselect
      v-model="value"
      :id="name"
      :name="name"
      :defaultOptions="modelValue"
      :valueFormat="'object'"
      :multiple="false"
      :async="true"
      :load-options="getPrompt"
      :loadingText="'Recherche en cours ...'"
      :searchPromptText="'Ecrivez pour lancer la recherche ...'"
      :noResultsText="'Aucun résultats ...'"
      :normalizer="normalizer"
      placeholder="Selectionner le prompt AI du voice call"
      @input="input"
      ></treeselect>
    <modified :enable="original !== value"></modified>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Modified from '/ats/modified'
import { Solr, eq, and, or } from '/solr'

export default {
  name: 'hippolyte.services.ats.filter.aiVersionSelect',
  components: {
    Treeselect, Modified
  },
  props: {
    modelValue: Object,
    name: String
  },
  data () {
    return {
      value: this.modelValue,
      original: this.modelValue
    }
  },
  methods: {
    input () {
      const input = {
        id: this.value.id,
        identifier: this.value.identifier
      }
      this.$emit('input', input ?? null)
    },
    normalizer (option) {
      // || pour la compatibilité
      return {
        id: option.id || option,
        label: option.identifier || option
      }
    },
    async getPrompt ({ searchQuery: research, callback }) {
      const req = new Solr({
        entity: 'AiPrompt',
        raw: true,
        limit: 10,
        query: and(eq('type', 'phone_questions'), or(eq('identifier', 'phone_questions'), `identifier:/.*${research}.*/`)),
        fields: ['id', 'type', 'identifier']
      })
      const { docs } = await this.$socket.service('entity_solr/QUERY', req.send())
      callback(null, docs)
    }
  }
}
</script>
<style lang="stylus" scoped>
div
  background white
  margin 0.4em 0
  border-radius 4px
  align-items center
  label
    margin-right 1em
    text-align right
    margin-top auto
    margin-bottom auto
  select
    background #eee
    border-radius 0 4px 4px 0
    font-size 1em
    line-height 1.5em
    border 1px #eee solid
    &:focus
      border 1px #ccc solid
</style>