export default function (size) {
  return {
    props: {
      width: {
        type: String,
        default: size
      },
      height: {
        type: String,
        default: size
      },
      xmlns: {
        type: String,
        default: 'http://www.w3.org/2000/svg'
      }
    },
    computed: {
      iconClassName () {
        return 'hippolyte-icon_' + this.$options.name.split('.').pop()
      }
    }
  }
}
