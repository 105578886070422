<template>
  <div class="hippolyte-reporting_container">
    <section>
      <article class="hippolyte-reporting_filters">
        <stat-menu
          :start="startDate"
          :end="endDate"
          :r="recruiter"
          :s="search"
          :a="a"
        ></stat-menu>
        <div class="g-flex">
          <span v-if="company"><h4>{{ company }}<h4></span>
          <span v-if="title">{{ title }}</span>
        </div>
        <div class="d-flex">
          <csv
           :recruiter="recruiter"
           :searches="currentSearch"
           :states="exportStatus"
           :start="startDate"
           :end="endDate"></csv>
          <form @submit.prevent="filterData">
            <label for="startDate">Du</label>
            <v2-datepicker v-model="startDate" format="DD/MM/yyyy" :lang="lang" :customLocals="datepickerLocale"></v2-datepicker>
            <label for="endDate">Au</label>
            <v2-datepicker v-model="endDate" format="DD/MM/yyyy" :lang="lang" :customLocals="datepickerLocale"></v2-datepicker>
          </form>
        </div>
      </article>
      <article v-if="$route.name.includes('origin')">
        <help :title="'Provenance des candidats sourcés'">
          <template v-slot:content>
            <p>Les statistiques se basent sur le champ "custom_field_onboard_source" éditable sur le conciliateur (champ "Source").</p>
            <p>Pensez à utiliser les datepicker si vous souhaitez changer de période. C'est la date de changement de statut qui est utilisée ici.</p>
          </template>
        </help>
        <ul class="legend">
          <li class="legend_state" v-for="(s, i) in status">
            <label @click="buildExportStatus" :for="'_'+s">
              <span class="legend_color" :style="`background: ${colors[i]};`">{{ statusSource[s] ? '✓' : '' }}</span>
              <span>{{ Labels[s].plural }}</span>
            </label>
            <input type="checkbox" v-model="statusSource[s]" :id="'_'+s"/>
          </li>
          <li><button @click="toggleStatus(true)">tous</button></li>
          <li><button @click="toggleStatus(false)">aucun</button></li>
        </ul>
      </article>
      <article>
        <router-view
          :start="startDate"
          :end="endDate"
          :recruiter="recruiter"
          :search="search"
          :a="a"
          :status="status"
          :gap="gap"
          :statusSource="statusSource"
        ></router-view>
      </article>
      <!-- <article>
        <graph-chat
          :recruiter="recruiter"
          :search="search"
          :start="startDate"
          :end="endDate"
          :gap="gap"
        ></graph-chat>
      </article> -->
    </section>
  </div>
</template>
<script>
import Recruiter from '/user/recruiter/recruiter.entity.js'
import Search from '/requirement/entity'
import { Labels } from '/interview/status'
import Csv from '/interview/csv'
import BarChart from '/layout/chart'
import GraphByOrigins from './byOrigins'
import GraphByDistribution from './byDistribution'
import Donuts from './donuts'
import Help from '/components/help'
import sourceEvolving from './sourceEvolving'
import SearchTable from './searchTable'
import DateTable from './dateTable'
import GraphChat from './chat.vue'
import { backgroundColor, status } from './utilsArray'
import format from 'date-fns/format'
import endOfMonth from 'date-fns/endOfMonth'
import startOfMonth from 'date-fns/startOfMonth'
import isLastDayOfMonth from 'date-fns/isLastDayOfMonth'
import isFirstDayOfMonth from 'date-fns/isFirstDayOfMonth'
import differenceInMonths from 'date-fns/differenceInMonths'
import datepickerLocale from '/locale/datepicker_locale'
import add from 'date-fns/add'
import differenceInDays from 'date-fns/differenceInDays'
import Spinner from '/layout/spinner'
import StatMenu from '/menu/statMenu'

export default {
  name: 'hippolyte.reporting',
  props: {
    a: Boolean,
    recruiter: Recruiter,
    search: Search,
    start: {
      type: Date,
      default() {
        return startOfMonth(new Date())
      }
    },
    end: {
      type: Date,
      default() {
        return endOfMonth(new Date())
      }
    },
    graph: String
  },
  components: {
    BarChart,
    GraphChat,
    Spinner,
    Csv,
    Help,
    StatMenu
  },
  data () {
    return {
      company: this.recruiter?.company,
      title: this.search?.title,
      startDate: this.start,
      endDate: this.end,
      lang: 'fr',
      datepickerLocale,
      status,
      Labels,
      chat: [],
      chatStat: null,
      cancels: {},
      colors: backgroundColor,
      statusSource: status.reduce((acc, s) => Object.assign({ [s]: true }, acc), {}),
      exportStatus: [],
      currentSearch: []
    }
  },
  watch: {
    search (val, old) {
      val?.off('update', this.setTitle)
      this.search?.on('update', this.setTitle, this)
      if (!this.search) {
        this.setTitle()
      }
      this.currentSearch = this.search ? [this.search.id] : []
    },
    recruiter (val, old) {
      val?.off('update', this.setCompany)
      this.recruiter?.on('update', this.setCompany, this)
      this.currentSearch = this.search ? [this.search.id] : []
      if (!this.recruiter) {
        this.setCompany()
      }
    },
    startDate () {
      this.$router.push(this.queryParams())
    },
    endDate () {
      this.$router.push(this.queryParams())
    }
  },
  mounted () {
    this.search?.on('update', this.setTitle, this)
    this.recruiter?.on('update', this.setCompany, this)
    this.buildExportStatus()
  },
  methods: {
    setCompany () {
      this.company = this.recruiter?.company
    },
    setTitle () {
      this.title = this.search?.title
    },
    queryParams () {
      return {
        query: {
          a: this.a,
          start: format(add(this.startDate, { minutes: -this.startDate.getTimezoneOffset() }), 'yyyy-MM-dd'),
          end: format(this.endDate, 'yyyy-MM-dd'),
        },
        params: { graph: this.graph }
      }
    },
    buildExportStatus () {
      setTimeout(() => {
        this.exportStatus.splice(
          0,
          this.exportStatus.length,
          ...Object.entries(this.statusSource)
            .filter(([, v]) => v)
            .map(([k]) => k)
        )
      }, 10)
    },
    toggleStatus (turn) {
      status.forEach(s => Object.assign(this.statusSource, { [s]: turn }))
      this.buildExportStatus()
    },
    formatPeriod({ start, end }) {
      return {
        start: format(start, 'yyy-MM-dd'),
        end: format(end, 'yyyy-MM-dd')
      }
    }
  },
  computed: {
    gap () {
      const diff = ((this.endDate.getTime() - this.startDate.getTime()) / 86400000).toFixed(0)
      if (diff <= 31 * 2) {
        return {
          gap: 'DAY',
          formatLabel: 'dd/MM'
        }
      } else if (diff < 365 * 3) {
        return {
          gap: 'MONTH',
          formatLabel: 'dd/MM/yy'
        }
      } else if (diff > 365 * 3) {
        return {
          gap: 'YEAR',
          formatLabel: 'dd/MM/yy'
        }
      }
    }
  }
}
</script>
<style lang="stylus" scope>
@require '~/colors.styl'
.hippolyte-reporting_container
  position relative
  height 100%
  section
    height 100%
    overflow-y auto
    margin 1em
    header
      color $color-astronaut
    .reporting_evolve header
      display flex
      flex-direction row
      justify-content space-between
      align-items center
      div
        display inline-block
    h2
      font-size 20px
      display inline-block
    small
      cursor help
.hippolyte-reporting_filters
  display flex
  flex-direction row
  justify-content space-between
  .g-flex
    justify-content flex-end
    display flex
    line-height 33px
  .d-flex
    justify-content flex-end
    display flex
    line-height 33px
    label
      margin-right 10px
      margin-left 20px
.hippolyte-reporting_graphs
  display flex
  flex-wrap wrap
  justify-content space-between
.reporting_evolve
  header
    button
      cursor pointer
      color $color-astronaut
    .reporting_current
      display inline-block
      text-align center
      min-width 10em
.legend
  list-style none
  margin 0
  margin-top 24px
  padding 0
  display flex
  flex-direction row
  .legend_state
    *:hover
      cursor default
    margin 0.1em 0
    color $color-astronaut
    *
      vertical-align middle
    .legend_color
      text-align center
      border-radius 6px
      width 1.2em
      height 1.2em
      display inline-block
      margin 0 0.5em
    input[type="checkbox"]
      display none
</style>
