<template>
  <ats
    v-bind="$props"
    service="beetween"
    :savingConf="savingConf"
    :credential="{ url: '', saveUrl: '' }"
    :cached="true"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="url">url</label>
        <input v-model="credential.url" id="url" name="url"/>
        <modified :enable="value.credential.url !== credential.url"/>
      </div>
      <div>
        <label for="url">url de postulation</label>
        <input v-model="credential.saveUrl" id="save-url" name="saveUrl"/>
        <modified :enable="value.credential.saveUrl !== credential.saveUrl"/>
      </div>
    </template>
  </ats>
</template>
<script>
import Ats from './sdk-v3'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.beetween.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
