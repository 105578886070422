<template>
  <ats
    v-bind="$props"
    service="talentview"
    :savingConf="savingConf"
    :credential="{ url: '', privateKey: '', encryptionKey: '', slug: '' }"
    @input="onInput"
    @update="updateCompanies">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="privateKey">clé privée</label>
        <input v-model="credential.privateKey" id="privateKey" name="privateKey"/>
        <modified :enable="value.credential.privateKey !== credential.privateKey"/>
      </div>
      <div>
        <label for="encryptionKey">clé de chiffrement</label>
        <input v-model="credential.encryptionKey" id="encryptionKey" name="encryptionKey"/>
        <modified :enable="value.credential.encryptionKey !== credential.encryptionKey"/>
      </div>
      <div>
        <label for="url">url d'audience</label>
        <input v-model="credential.url" id="url" name="url"/>
        <modified :enable="value.credential.url !== credential.url"/>
      </div>
      <div>
        <label for="slug">Nom du Client dans l'ats</label>
        <select v-model="credential.slug" id="slug" name="slug">
          <option disabled value="">Selectionner un nom</option>
          <template v-for="company in companies">
            <option :value="company.slug" :key="company.slug">{{ company.slug }}</option>
          </template>
        </select>
        <modified :enable="value.credential.slug !== credential.slug"/>
        <button @click="delCompany()" v-if="credential.slug" title="supprimer le nom du client">✖</button>
        <small v-if="!credential.slug">Attention si pas de valeur, les offres affichées concernent tous les recruteurs de cet ATS !</small>
      </div>
    </template>
    <template v-slot:title="{ job }">{{ title(job) }}</template>
    <template v-slot:open="{ job }"><a class="button" :href="job.url" target="_blank" title="ouvrir">👁️</a></template>
  </ats>
</template>
<script>
import Ats from './sdk-v2'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.talentview.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  data () {
    return {
      companies: []
    }
  },
  async mounted () {
    await this.updateCompanies()
   },
  methods: {
    async updateCompanies () {
      this.companies = await this.$socket.service('talentview.companies/LIST', { recruiter: this.recruiter.id })
    },
    title (job) {
      return  [
        job.id || job.reference,
        job.slug,
        job.name || job.hippolyte?.search.title,
        job.job_type || 'plus de contract',
        (job.address && job.address.city) || job.hippolyte?.search.location?.docs[0]?.city
      ].filter(t => t).join(' - ')
    },
    delCompany () {
      this.value.credential.slug = null
    },
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
