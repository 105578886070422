<template>
  <article>
    <header>
      <a @click.stop :href="setMailTo()">
        <icon-mail
          :title="`Contacter ${campaign['recruiter.detail'].docs[0].company}`"
        ></icon-mail>
      </a>
      <button @click="openBoard()" class="edit-search-btn">
        <icon-edit
          :title="'Editer la campagne dans le board'"
        ></icon-edit>
      </button>
      <div class="campaignTitle">{{ campaign.name }}
        <span>
          <survey-modal
            v-if="ended() && !campaign.surveyDate"
            @update="updateSurveyDate"
            :campaign="campaign"
          ></survey-modal>
        </span>
        <div v-bind:class="isHot()">
          {{ Intl.NumberFormat('fr-FR').format(campaign.sumExpenses() / 100) }} € / {{ Intl.NumberFormat('fr-FR').format(campaign.totalPrice / 100) }} € ({{ campaign.totalPrice ? Math.ceil((campaign.sumExpenses() / campaign.totalPrice) * 100) : 0 }} %)
          <span v-if="campaign.sumImpressions()"> - impressions: {{ Intl.NumberFormat('fr-FR').format(campaign.sumImpressions()) }}</span>
          <span v-if="campaign.sumCoverage()"> {{ campaign.sumImpressions() ? '/' : '-' }} couvertures: {{ Intl.NumberFormat('fr-FR').format(campaign.sumCoverage()) }}</span>
          <span v-if="campaign.sumClicks()"> {{ (campaign.sumImpressions() || campaign.sumCoverage()) ? '/' : '-' }} clics: {{ Intl.NumberFormat('fr-FR').format(campaign.sumClicks()) }}</span>
        </div>
        <div class="billing">
          <div>
            <span :class="[campaign.facturationDate ? 'green' : '']">
              {{ getFacturationDate() }}
            </span>
            -
            <span :class="[campaign.paymentDate ? 'green' : '' ]">
              {{ getPaymentDate() }}
            </span>
            -
            <span :class="[campaign.surveyDate ? 'green' : '' ]">
              {{ getSurveyDate() }}
            </span>
            <update-modal
              :campaign="campaign"
            ></update-modal>
          </div>
        </div>
      </div>
    </header>
    <div class="alert-icons">
      <icon-fire
        class="warn-zone"
        v-if="isLow()"
        :title="'(nombre de candidats envoyés / nombre de candidat attendus) inférieur à 80% du temps écoulé de la campagne'"
      ></icon-fire>
      <not-paid
        v-if="isLate()"
        :title="'Paiement en retard de plus de 30 jours'"
      >
      </not-paid>
    </div>
    <div>
      <div class="report">
        <header :title="rangeDateTitle()">{{rangeDateLabel()}}</header>
        <span class="meter">
          <span class="meter-bar normal" :style="{width: (ratioTime()) * 100 + '%'}"></span>
          <span class="meter-bar"
            :style="{width: (ratioRecruit() * 100) + '%'}"
            :class="ratioClass()">
          </span>
        </span>
        <footer
          :title="`${campaign.sentCandidate()} envoyés sur ${campaign.expectation} attendus`">
          {{ campaign.sentCandidate() }} / {{ campaign.expectation }}
        </footer>
      </div>
      <div v-if="campaign.sentCandidate()" class="cost">
        <span>
          {{ Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(getCandidateCost() / 100) }} / candidat
        </span>
        -
        <span v-bind:class="[getBrutMargin() < 0 ? 'isHot' : '' ]">
          {{ Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'}).format(getBrutMargin() / 100) }}  de marge brut
        </span>
      </div>
    </div>
    <div class="campaign-details" v-if="selected === campaign">
      <expense-list :expenses="campaign.expenses.docs">
      </expense-list>
      <ul class="searches">
        <li v-for="search in campaign.searches.docs" :key="search.id">
          <a @click.stop v-if="search.recruiterAccounts" :href="setMailTo(search)">
            <icon-mail :title="`Contacter ${search.recruiterAccounts.length > 1 ? 'les recruteurs' : 'le recruteur'}`"></icon-mail>
          </a>
          <router-link class="search-link" :to="setReportParams(search)">
            <icon-stats :title="'Voir les statistiques de la search'"></icon-stats>
          </router-link>
          <router-link class="search-link" tag="button" :to="setsearchParams(search)" >{{ search.title }} </router-link>
          <span class="status_pill">
            <img title="Envoyé" src="/assets/icon/sent.png"/>
            <span>{{ search['conciliations.sent'].numFound }}</span>
          </span>
          <span class="status_pill">
            <img title="Recruté" src="/assets/icon/unicorn_blue.png"/>
            <span>{{ search['conciliations.recruited'].numFound }}</span>
          </span>
        </li>
      </ul>
    </div>
  </article>
</template>

<script>
import Campaign from '/campaign/entity'
import IconStats from '/icons/stats'
import IconEdit from '/icons/edit'
import IconMail from '/icons/mail'
import NotPaid from '/icons/notPaid'
import IconFire from '/icons/fire'
import differenceInDays from 'date-fns/differenceInDays'
import format from 'date-fns/format'
import { fr } from 'date-fns/locale'
import updateModal from './updateModal.vue'
import ExpenseList from './expenseList.vue'
import SurveyModal from './surveyModal.vue'
import { parseISO } from 'date-fns'

// @todo paginate
export default {
  name: 'hippolyte.campagne.item',
  components: {
    IconStats,
    IconEdit,
    IconMail,
    NotPaid,
    IconFire,
    updateModal,
    ExpenseList,
    SurveyModal
  },
  props: {
    campaign: Campaign,
    selected: Campaign
  },
  methods: {
    updateSurveyDate (value) {
      if (this.campaign.surveyDate) {
        this.campaign.surveyDate = value
      } else {
        Object.assign(this.campaign, { surveyDate: value })
      }
    },
    ended () {
      return this.campaign.endDate < Date.now()
    },
    openBoard () {
      try {
        window.open([
          document.location.protocol, '',
          document.location.host.replace('conciliator', 'conciliateur'),
          'recruiter',
          this.campaign.recruiter,
          'campaign',
          this.campaign.id,
          'edit'
        ].join('/'))
      } catch (err) {
        console.log(err)
      }
    },
    setsearchParams (search) {
      return {
        name: 'candidate_list',
        params: { search: search.id },
        query: { r: search.recruiter }
      }
    },
    setReportParams (search) {
      return {
        name: 'reporting',
        params: { search: search.id },
        query: {
          start: format(this.campaign.startDate, 'yyyy-MM-dd'),
          end: format(this.campaign.endDate, 'yyyy-MM-dd')
        }
      }
    },
    setMailTo (search=null) {
      const accounts = this.campaign['recruiter.detail'].docs[0]['recruiter.mail'].docs
      let mailString
      if (search) {
        mailString = accounts
          .filter(account => search.recruiterAccounts?.includes(account.id))
          .map(a => a.email).join(';')
      } else {
        mailString = accounts.map(a => a.email).join(';')
      }
      return `mailto:${mailString}?subject=Hippolyte-rh x ${search ? `${this.campaign['recruiter.detail'].docs[0].company} - ${search.title}` : this.campaign['recruiter.detail'].docs[0].company }`
    },
    getBrutMargin () {
      return this.campaign.totalPrice - this.campaign.sumExpenses()
    },
    getCandidateCost () {
      return this.campaign.sentCandidate() ?  this.campaign.sumExpenses()/this.campaign.sentCandidate() : 0
    },
    formatDate (date) {
      return date instanceof Date ? date : parseISO(date)
    },
    getFacturationDate () {
      return this.campaign.facturationDate ? `Facturée le ${format(this.formatDate(this.campaign.facturationDate), 'dd/MM/yyyy')}` : 'Non facturée'
    },
    getPaymentDate () {
      return this.campaign.paymentDate ? `Soldée le ${format(this.formatDate(this.campaign.paymentDate), 'dd/MM/yyyy')}` : 'Non soldée'
    },
    getSurveyDate () {
      return this.campaign.surveyDate ? `Enquête envoyée le ${format(this.formatDate(this.campaign.surveyDate), 'dd/MM/yyyy')}` : 'Enquête non envoyée'
    },
    isHot () {
      if (this.ratioExpense() > 0.9) {
        return "isHot"
      } else if (this.ratioExpense() > 0.7) {
        return "isWarm"
      } else {
        return null
      }
    },
    ratioExpense () {
      return this.campaign.totalPrice > 0 ? (this.campaign.sumExpenses() / this.campaign.totalPrice) : 0
    },
    ratioTime () {
      return this.spend() / this.range()
    },
    ratioRecruit () {
      return this.campaign.sentCandidate() / this.campaign.expectation
    },
    range () {
      return differenceInDays(this.campaign.endDate, this.campaign.startDate)
    },
    spend () {
      return differenceInDays(new Date(), this.campaign.startDate) > 0 ? Math.min(differenceInDays(new Date(), this.campaign.startDate), this.range()) : 0
    },
    rangeDateLabel () {
      return `${format(this.campaign.startDate, 'd/L', { locale: fr })} - ${format(this.campaign.endDate, 'd/L', { locale: fr })}`
    },
    rangeDateTitle () {
      return `du ${format(this.campaign.startDate, 'PPPP', { locale: fr })} au ${format(this.campaign.endDate, 'PPPP', { locale: fr })}`
    },
    isLate () {
      return this.campaign.facturationDate && !this.campaign.paymentDate && differenceInDays(new Date(), this.campaign.facturationDate) > 30
    },
    isLow () {
      return this.ratioRecruit() < this.ratioTime() * 0.8
    },
    isNormal () {
      return !this.isLow() && this.ratioRecruit() < this.ratioTime() * 1.2
    },
    ratioClass () {
      if (this.isLow()) {
        return 'low'
      }
      if (this.isNormal()) {
        return 'normal'
      }
      return 'high'
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'

header
  font-weight bold
  padding 0 1em
.campaigns_container
  margin-bottom 3em
  a
    padding-top 0.3em
    &:hover svg
      fill $color-iceberg
section
  margin 0
  list-style none
  article
    display grid
    grid-template-columns 60em 40px 50%
    padding 0.4em 1em
    header
      grid-column 1
      display flex
      div
        color #aaa
        font-size 0.8em
      .campaignTitle
        color inherit
        font-size inherit
        cursor pointer
        .billing
          display flex
          padding-top 0.5em
          .green
            color $color-green
      .edit-search-btn
        height: 2em
        width 2.8em
        display flex
        align-items center
        justify-content center
        padding 0.2em
        margin-right 0.4em
        background none
        svg
          width 100%
          height 100%
        &:hover svg
          fill $color-iceberg
          transform scale(1.1)
        img
          width 1em
          height: 1.2em
          margin 0
        &:hover
          cursor pointer
          color $color-yellow
    .isHot
      color red
    .isWarm
      color #f48767
    .alert-icons
      grid-column 2
      display flex
      justify-content space-evenly
      &>svg
        height 1.5em
        display flex
        align-items center
    div
      grid-column 3
      .report
        height 2em
        display flex
        color #aaa
        font-size 0.8em
        align-items center
        &>header
          align-self center
          min-width 5em
          justify-content flex-end
        .meter
          display block
          width 300px
          height 12px
          border-radius 6px
          border 1px solid #ccc
          overflow hidden
          .meter-bar
            display block
            height 50%
            &.low
              background-color $color-peach
            &.normal
              background-color $color-astronaut
            &.high
              background-color $color-iceberg
        &>footer
          align-self center
          margin-left 1em
      .cost
        display flex
        width 60%
        color $color-grey_harder
        justify-content space-evenly
        font-size 0.8em
        padding-top 0.5em
    .warn_zone
      grid-column 2
    div.campaign-details
      grid-column 1 / 4
      grid-row 2
      display grid
      grid-template-columns: 1fr 1fr
      padding inherit
      flex-direction row
    ul
      list-style none
      display grid
      align-items center
      justify-content center
      .expenses
        grid-column 1
        padding-top 0.2em
      .searches
        grid-column 3
        padding 0
        &>a:hover svg
          fill $color-iceberg
      .search-link
        cursor pointer
      .status_pill
        border 1px $color-iceberg solid
        border-radius 1em
        height 1.5em
        display inline-flex
        flex-direction row
        align-items center
        span
          margin 0.4em
        img
          height 1em
          margin 0.4em
</style>
