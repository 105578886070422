<template>
  <div>
    <div>
      rejeter le candidat ?
    </div>
    <reason-select v-model="reason" :reasons="filterReasons()"></reason-select>
    <div class='action'>
      <button class="interview-action" @click="closeConfirm">Abandonner</button>
      <button class="interview-action" :class="reason ? '' : 'disabled'" @click.stop="onAction" :disabled="!reason">
        Rejeter
      </button>
    </div>
  </div>
</template>

<script>
import Conciliation from '/interview/interview.js'
import ReasonSelect from './reason_select'

export default {
  name: 'hippolyte.reasons.confirm',
  components: {
    ReasonSelect
  },
  props: {
    conciliation: Conciliation,
    reasons: Array,
    status: String,
  },
  data () {
    return {
      reason: null
    }
  },
  methods: {
    filterReasons() {
      return this.reasons?.filter(r => r.status === this.status).sort((a, b) => a.name > b.name)
    },
    closeConfirm () {
      this.$emit('close')
    },
    async onAction () {
      switch (this.status) {
        case 'discarded':
          await this.onDiscard()
          break
        case 'rejected':
          await this.onReject()
          break
      }
    },
    async onDiscard () {
      await this.conciliation.discard(this.reason)
      this.closeConfirm()
    },
    async onReject () {
      await this.conciliation.reject(this.reason)
      this.closeConfirm()
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '~/colors.styl'

.action
  text-align end
</style>
