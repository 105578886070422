<template>
  <svg v-bind="$props" viewBox="-0.118 -0.109 51.583181 66.106319">
    <path d="m 32.40258,0.33098374 c -2.637,0.07 -5.291,0.62100001 -7.814,1.52999996 -9.771,4.078 -16.5659998,12.9760013 -19.6399997,23.6520013 -0.519,2.004 -0.94,4.01 -1.166,6.08 0,0.067 -0.162,2.105 0.129,1.555 0.291,-0.484 0.42,-1.197 0.582,-1.748 1.488,-5.014 3.3019999,-9.577 6.0839997,-13.912 6.666,-9.8050013 17.375,-16.1130013 28.698,-12.6180013 2.104,0.71 3.98,1.747 5.856,2.976 0.355,0.259 2.976,2.3300013 2.394,0.453 -0.485,-1.486 -1.714,-2.751 -2.783,-3.754 -3.598,-3.114 -7.944,-4.33099997 -12.34,-4.21399996 z m -2.55,12.50500126 c -1.367652,-0.03578 -2.722298,0.273389 -3.939,0.899 -4.044,2.037 -5.663,6.987 -3.592,11.031 2.038,4.045 6.986,5.664 11.031,3.594 4.044,-2.039 5.662,-6.989 3.592,-11.031 -1.354861,-2.690636 -4.080447,-4.417378 -7.092,-4.493 z m 5.54,19.956 c -2.59,1.55 -5.63,2.425 -8.865,2.425 -0.678,0 -1.326,-0.031 -2.006,-0.097 v 23.812 c 0,2.2 0.518,3.85 1.522,4.951 1.035,1.132 2.298,1.682 3.883,1.682 1.616,0 2.945,-0.55 3.945,-1.65 1.005,-1.1 1.521,-2.75 1.521,-4.983 v -26.139 z" style="fill:#2164f3;fill-rule:evenodd"/>
  </svg>
</template>

<script>
import icon from '/icons/mixin'

export default {
  name: 'hippolyte.icon.social.indeed',
  mixins: [icon('15px')]
}
</script>
