import pick from 'ramda/src/pick'
import pickBy from 'ramda/src/pickBy'
import keys from 'ramda/src/keys'
import { EventEmitter } from 'eventemitter3'
const service = (verb, entity='Search') => `entity.${entity}/${verb}` // @fixme !
const cond = k => `YesWeChat\\ServiceEntityBundle\\Query\\Condition\\${k}`
const zip = (acc, v) => Object.assign(acc, { [v]: v })

export default class Entity extends EventEmitter {
  constructor (data, socket) {
    super()
    this.socket = socket
    this.setData(data)
    this.loading = false
  }

  setData (data) {
    const delta = this.getDelta()
    Object.assign(this, data)
    this.hasUpdate(delta)
    return this
  }

  getDelta (opts, serializers) {
    return JSON.stringify(this.marshall(opts, serializers))
  }

  hasUpdate (delta) {
    if (delta !== this.getDelta()) {
      this.emit('update')
    }
  }

  load () {
    if (!this.loading && this.id) {
      this.loading = this.socket.service(service('READ', this.entityClass), { id: this.id })
        .then(this.setData.bind(this))
      this.loading.then(() => {
        this.loading = false
      })
    }
    return this.loading
  }

  save (fn) {
    if (!this.saving) {
      this.saving = this.socket.service(service('SAVE'), fn ? fn() : this.marshall())
        .then(data => {
          this.saving = false
          return data
        })
        .then(this.setData.bind(this))
        .then( data => {
          this.emit('saved')
          return data
        })
    }
    return this.saving
  }

  marshall (f, serializers={}) {
    serializers
    if (!f) {
      f = this.entityFields
    }
    let entities = pickBy(e => e instanceof Entity || (e && e.marshall && 'function' === typeof e.marshall), this)
    let props = keys(entities)
      .filter( e => f.includes(e))
      .reduce((acc, k) => {
        if (k in serializers) {
          acc[k] = serializers[k](entities[k])
        } else {
          acc[k] = entities[k].marshall()
        }
        return acc
      }, pick(f, this))
    return keys(pickBy(e => Array.isArray(e), props))
      .reduce((acc, k) => {
        let e = acc[k]
        if (k in serializers) {
          acc[k] = acc[k].map(serializers[k])
          return acc
        }
        acc[k] = acc[k].map(e => {
          if (e instanceof Entity || (e && e.marshall && 'function' === typeof e.marshall)) {
            return e.marshall()
          }
          return e
        })
        return acc
      }, props)
  }

}

Entity.getByChatUser = async function (chatUser, socket, Cast) {
  let list = await socket.service(service('QUERY'), {
    alias: 'r',
    class: Entity.entityClass,
    parameters: [
      { type: cond('Parameter'), name: 'chatUser', value: chatUser }
    ],
    conditions: [
      {
        type: cond('Equals'),
        value: 'chatUser',
        subject: {
          type: cond('Field'),
          name: 'r.chatUser'
        }
      }
    ]
  })
  if (list.length === 1) {
    return new Cast(list[0], socket)
  }
}
