<template>
  <ats
     v-bind="$props"
    service="talentsoft"
    :savingConf="savingConf"
    :credential="{ url: '', key: '', secret: '' }"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="url">url</label>
        <input v-model="credential.url" id="url" name="url"/>
        <modified :enable="value.credential.url !== credential.url"/>
      </div>
      <div>
        <label for="key">key</label>
        <input v-model="credential.key" id="key" name="key"/>
        <modified :enable="value.credential.key !== credential.key"/>
      </div>
      <div>
        <label for="secret">secret</label>
        <input v-model="credential.secret" id="secret" name="secret"/>
        <modified :enable="value.credential.secret !== credential.secret"/>
      </div>
      <div>
        <label for="consent">consentement</label>
        <input type="checkbox" v-model="credential.consent" id="consent" name="consent"/>
        <modified :enable="value.credential.consent !== credential.consent"/>
      </div>
    </template>
    <template v-slot:title="{ job }">{{ title(job) }}</template>
    <template v-slot:open="{ job }"><a class="button" :href="job.vacancyCareerUrl" target="_blank" title="ouvrir">👁️</a> </template>
  </ats>
</template>
<script>
import Ats from './sdk-v2'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.talentsoft.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    title (job) {
      try {
      return  [
        job.reference,
        job.unpublished ? job.hippolyte?.search.title : (job.title || job.jobDescription?.title),
        job.unpublished ? 'plus de contrat' : job.contractType?.label || job.jobDescription?.contractType?.label,
        job.unpublished ? job.hippolyte?.search.location?.docs[0]?.city : job.address || job.location?.address || job.department?.label || job.location?.department?.label
      ].filter(t => t).join(' - ')
      } catch (e) {
        console.error(e)
        return 'flute'
      }
    },
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
