<template>
  <div :class="{'message-time': true, self: isReceiver(message)}">
    {{ time }}
  </div>
</template>
<script>
import isSameYear from 'date-fns/isSameYear'
import format from 'date-fns/format'
import fr from 'date-fns/locale/fr'

export default {
  name: 'hippolyte.interview.time',
  props: {
    message: {
      required: true
    },
    isReceiver: Function,
    date: Boolean
  },
  computed: {
    time () {
      let f = ''
      if (this.date) {
        f = isSameYear(new Date(), this.message.date) ? 'dd/MM - ' : 'dd/MM/y - '
      }
      return format(this.message.date, f+ 'p', { locale: fr })
    }
  }
}
</script>
<style scoped lang="stylus">
@require '~/colors.styl'
.message-time
  color $color-green
  font-size 11px
  margin-top 3px
  padding-top 0
  &.self
    text-align right
    color $color-grey_lighter
    @media (min-width $breakpoint)
      margin-right 17px

</style>
