<template>
  <article>
    <help :title="'Statistiques recruteur'">
      <template v-slot:content>
        <p>Les statistiques se basent sur la date de changement de statut de la conciliation (statuts "envoyé" ou "suggéré").</p>
        <p>Pensez à utiliser les datepicker (en haut à droite) si vous souhaitez voir les datas sur une autre période.</p>
        <p>Le premier tableau ne contient que les searchs sur lesquelles des candidats ont été reçus ou envoyés.</p>
      </template>
    </help>
    <header>
      <h2>Statistiques recruteur du {{start | format('dd/MM/yy')}} au {{end | format('dd/MM/yy')}}</h2>
    </header>
    <div v-if="recruiter">
      <search-table
        :busy="busy"
        :recruiter="recruiter"
        :search="search ? true : false"
        :end="end"
        :start="start"
        :a="a"
      ></search-table>
      <date-table
        :busy="busy"
        :recruiter="recruiter"
        :search="search ? true : false"
        :end="end"
        :start="start"
        :a="a"
        :gap="gap"
      ></date-table>
    </div>
    <div v-else>
      Pas de recruteur selectionné
    </div>
  </article>
</template>
<script>

import searchTable from './searchTable'
import dateTable from './dateTable'
import Help from '/components/help'

export default {
  name: 'hippolyte.reporting.tables',
  components: {
    searchTable,
    dateTable,
    Help
  },
  props: {
    busy: Boolean,
    a:Boolean,
    recruiter: Object,
    search: Object,
    start: Date,
    end: Date,
    gap: Object,
  }
}

</script>