<template>
  <chat-menu
    title="Interviews"
    :rooms="$chat.rooms"
    @close="() => Object.assign($show, { menu: false, interview: true })"
    :key="$chat.rooms.length">
    <template #roomItem="{ room }">
      <room-item :key="room.id"
        v-if="!room.closing"
        :room="room"
        :closable="isClosable(room)"
        @close="onClose"
        :profile="getProfile(room)"
        @select="onRoom">
        <template #title>
          <room-title :room="room" :profile="getProfile(room)"></room-title>
        <template>
      </room-item>
    </template>
  </chat-menu>
</template>
<script>
import ChatMenu from '/menu/chat'
import RoomItem from '/menu/room'
import RoomTitle from '/menu/room.title'

export default {
  name: 'hippolyte.conciliator.menu.chat',
  components: {
    ChatMenu,
    RoomItem,
    RoomTitle
  },
  methods: {
    onRoom (room) {
      let ids = room.id.split('/')
      Object.assign(this.$show, {
        menu: false,
        interview: true,
        profile: false
      })
      if (this.$chat.current !== room) {
        this.$router.push({
          name: 'interview',
          params: {
            candidate: ids.pop(),
            recruiter: ids.pop()
          }
        })
      }
    },
    getProfile (room) {
      return this.$profiles.find(room.id.split('/').pop())
    },
    isClosable (room) {
      return room.closable && room.id.split('/')[1] === 'conciliator'
    },
    async onClose (room) {
      const current = this.$chat.current
      await this.$chat.close(room)
      this.$forceUpdate()
      if (current === room) {
        let ids = this.$chat.current.id.split('/')
        this.$router.push({
          name: 'interview',
          params: {
            candidate: ids.pop(),
            recruiter: ids.pop()
          }
        })
      }
    },

  },
  filters: {
    firstLetter: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.substring(0, 1) + '.'
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
.candidate_search-tags__container {
  min-height: 25px;
}
.container
  .app-header
    width 100%
  .menu_items
    margin-top 0.5em
    display flex
    flex-direction column
</style>
