import { Status } from '/interview/status'

export const backgroundColor = [
  '#d5e4ef',
  '#6b41b9',
  '#114b5F',
  '#028090',
  '#ffc857',
  '#164ee2',
  '#f8bdac',
  '#CDB1FF',
  '#ce265e',
  '#45ADA7',
  '#faeda7',
  '#f48767',
  '#596a71',
  '#73c2bd',
  '#0a2e36',
  '#19d089',
  '#FF457F',
  '#06E3F4',
  '#404042',
  '#bdc4d2'
]

export const status = [
  Status.created,
  Status.suggested,
  Status.waiting_acceptation,
  Status.accepted,
  Status.declined,
  Status.do_not_send,
  Status.sent,
  Status.opened,
  Status.started,
  Status.met,
  Status.scheduled,
  Status.scheduling_meeting,
  Status.recruited,
  Status.archived,
  Status.rejected,
  Status.discarded
]

export const received = [
  Status.suggested,
  Status.accepted,
  Status.do_not_send,
  Status.sent,
  Status.opened,
  Status.started,
  Status.met,
  Status.scheduled,
  Status.scheduling_meeting,
  Status.recruited,
  Status.archived,
  Status.rejected,
  Status.discarded
]

export const transmitted = [
  Status.sent,
  Status.opened,
  Status.started,
  Status.met,
  Status.scheduling_meeting,
  Status.recruited,
  Status.archived,
  Status.rejected,
]

export const xLabels = {
  1 : 'Lundi',
  2 : 'Mardi',
  3 : 'Mercredi',
  4 : 'Jeudi',
  5 : 'Vendredi',
  6 : 'Samedi',
  7 : 'Dimanche',
}

