export default {
  install: function (Vue, options) {
    Vue.prototype.$toast = function toast (text, options) {
      const vm = this.$root.$children[0].$refs.toaster.showToast(text, options)
      return {
        toaster: vm,
        toast: vm.toasts[0]
      }
    }
  }
}
