<template>
  <div class="merge-container">
    <div class="merge-container_main">
      <form @submit.prevent="onUpdate">
        <div>
          <label for="config">activé</label>
          <input type="checkbox" v-model="config.active" @change="onUpdate" id="config" name="config"/>
        </div>
        <div v-if="config.integration">
          <header>{{ config.integration.name }}</header>
          <img :src="config.integration.square_image" width="200px" height="200px" @click="openFeatures"/>
        </div>
        <merge-link :linkToken="linkToken" :onSuccess="onSuccess" v-if="linkToken">
          <template slot="button" slot-scope="props">
            <button @click="props.onClick">Choisir un ATS</button>
          </template>
        </merge-link>
        <button type="submit">save</button>
      </form>
      <div style="position: relative;">
        <h2>Offres</h2>
        <div v-if="busy">
          <spinner style="transform: scale(0.2, 0.2);"></spinner>
        </div>
        <ul v-else>
          <li v-for="job in jobs" :key="job.id" v-if="job.status">
            {{ job.remote_id }} - {{ job.name }}
            <span class="action">
              <button @click.prevent="openJob(job)" title="description">👁️</button>
              <template  v-if="job.hippolyte && job.hippolyte.search">
                {{ job.hippolyte.search.title }}
                <button @click="unlink(job)" title="retirer">🗑️</button>
                <button v-if="search && search.id !== job.hippolyte.search.id" @click="link(job)">lier</button>
              </template>
              <template v-else-if="search">
                <button @click="link(job)" title="lier">🔗</button>
              </template>
              </span>
            </span>
          </li>
        </ul>
        <paginate :next="next" :previous="previous" @select="paginate"></paginate>
      </div>
    </div>
    <div class="merge-container_preview">
      <div v-if="opened" v-html="opened.description"></div>
    </div>
    <sweet-modal ref="modal" width="80%">
      <div v-if="cancels.features">
        <spinner style="transform: scale(0.2, 0.2);"></spinner>
      </div>
      <div v-else-if="features">
        <ul>
          <li v-for="model in features.integration.available_model_operations">
            <b>{{model.model_name}}</b>
            <span>{{model.available_operations.join(' / ')}}</span>
          </li>
        </ul>
        <pre>{{ JSON.stringify(features, null, 2) }}</pre>
      </div>
   </sweet-modal>
  </div>
</template>
<script>
import clone from 'ramda/src/clone'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Spinner from '/layout/spinner'
import cancel from '/cancel.mixin.js'
import Paginate from '/layout/paginate_cursor'
import MergeLink from "@mergeapi/vue-merge-link"
import { SweetModal } from 'sweet-modal-vue'

export default {
  name: 'hippolyte.services.merge.config',
  components: {
    Paginate, Spinner, MergeLink, SweetModal
  },
  mixins: [cancel],
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search
  },
  watch: {
    offset: 'load',
    recruiter: 'generateLinkToken'
  },
  data () {
    return {
      config: Object.assign(
        {
          credential: '',
          publicToken: null
        },
        clone(this.value),
      ),
      jobs: [],
      limit: 10,
      next: null,
      previous: null,
      busy: false,
      selected: null,
      opened: null,
      linkToken: null,
      features: null
    }
  },
  mounted () {
    this.load()
    this.generateLinkToken()
  },
  methods: {
    onSuccess (token) {
      this.config.active = true
      this.config.publicToken = token
      this.onUpdate()
    },
    async generateLinkToken () {
      const { link_token } = await this.$socket.service('merge-ats.jobs/TOKEN', { recruiter: this.recruiter.id })
      this.linkToken = link_token
    },
    select (job) {
      this.selected = this.selected === job ? null : job
    },
    openJob(job) {
      if (job !== this.opened) {
        this.opened = job
      }
    },
    async openFeatures () {
      await this.loadFeatures()
      this.$refs.modal.open()
    },
    onUpdate () {
      this.$emit('input', this.config)
    },
    paginate (cursor) {
      this.cursor = cursor
      this.load()
    },
    async load () {
      this.cancel('jobs')
      this.busy = true
      this.selected = null
      try {
        const data = await this.$socket.service(`merge-ats.jobs/LIST`, {
          recruiter: this.recruiter.id,
          limit: this.limit,
          cursor: this.cursor
        }, { cancel: this.token('jobs') })
        this.jobs.splice(0, this.limit, ...data.docs)
        this.next = data.next
        this.previous = data.previous
        this.cancel('jobs', null)
        this.busy = false
      } catch (err) {
        try { this.handleCancel(err) } catch {
            this.busy = false
        }
      }
    },
    async loadFeatures () {
      this.cancel('features')
      try {
        const data = await this.$socket.service(`merge-ats.jobs/FEATURES`, {
          recruiter: this.recruiter.id
        }, { cancel: this.token('features') })
        this.features = data
        this.next = data.next
        this.cancel('features', null)
      } catch (err) {
        this.handleCancel(err)
      }
    },
    async link (job) {
      try {
        const data = await this.$socket.service(`merge-ats.jobs/LINK`, {
          search: this.search.id,
          job: job.remote_id
        })
        Object.assign(job, data)
        this.jobs
          .filter(v => v.remote_id !== job.remote_id && v.hippolyte?.search)
          .filter(v => v.hippolyte.search.id === job.hippolyte.search.id)
          .forEach(v => Object.assign(v, { hippolyte: { search: null } }))
        this.$toast(`${job.name} lié à ${this.search.title || this.search.id}`, { theme: 'success' })
        this.$forceUpdate()
      } catch (err) {
        if (err.response && err.response.status === 409) {
          return this.$toast(`Une autre recherche est déjà lié à cet offre`, { theme: 'error' })
        }
        this.$toast(`${job.name} n'a pas pu être lié à ${this.search.title || this.search.id}`, { theme: 'error' })
      }
    },
    async unlink (job) {
      try {
        const data = await this.$socket.service(`merge-ats.jobs/LINK`, {
          search: job.hippolyte.search.id,
          job: null
        })
        this.$toast(`${job.hippolyte.search.title} n'as plus d'offre`, { theme: 'success' })
        Object.assign(job, { hippolyte: { search: null } })
      } catch {
        this.$toast(`${job.hippolyte.search.title} n'a pas pu être délié`, { theme: 'error' })
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
input[type="checkbox"]
  height auto
form
  margin 1em
  div
    display grid
    grid-template-columns 100px 350px
    background white
    margin 0.4em 0
    padding 3px 3px 3px 0.8em
    width 450px
    border-radius 4px
    label
      grid-column 1
      margin-right 1em
      text-align right
      margin-top auto
      margin-bottom auto
    input
      grid-column 2
      background #eee
      border-radius 0 4px 4px 0
      font-size 1em
      line-height 1.5em
      border 1px #eee solid
      &:focus
        border 1px #ccc solid
button, .button
  background white
  border none
  color black
  border-radius 2em
  line-height 1em
  margin 0.2em 0
  padding 0.4em 1.5em
  font-size 0.9em
  cursor pointer
  text-decoration none
  &::first-letter
    text-transform capitalize
  &:focus, &:hover
    outline none
  &.invert
    color white
    border 1px white solid
  &:disabled
    color #ccc
    cursor default
.merge-container
  display flex

  .merge-container_main
    width 40%
    ul
      padding 1em
      list-style none
      li
        background white
        margin 0.5em 0
        width auto
        padding 0.4em 0.8em
        border-radius 1em
        cursor pointer
        display flex
        justify-content space-between
        button, .button
          background $color-torrent
          padding 0.2em 0.4em
          margin 0.1em
  .merge-container_preview
    position relative
    min-height 800px
    width 60%
    margin 0 4%
    iframe
      min-height 800px
      width 100%
    background white
</style>
