<template>
  <div class="chat-prompt_container">
    <header>
      <div class="toolbar" @mousedown.prevent="toggle" >
        <b>Messages rapides<zap color="#8F9BB3" width="14px"></zap></b>
        <div title="Réduire" :class="{ close: true}">
          <toggle :color="'black'" :class="{ close }"></toggle>
        </div>
      </div>
      <snippets class="snippets" :class="{ close }" :account="$account" :candidate="candidate" :conciliation="conciliation" @select='update' :linebreak="false"></snippets>
    </header>
    <form @submit.prevent="say()">
      <textarea
        v-model="writing"
        :placeholder="myPlaceholder"
        ref="input"
        :style="inputStyle"
        @keyup.enter.exact="say()"
        @keydown.enter.exact.prevent
        :disabled="disabled"
        @keydown.enter.ctrl.exact="newline">
      </textarea>
      <button type="submit" :style="inputStyle" :class="{ disabled }">
        <font-awesome-icon icon="envelope"/>
      </button>
    </form>
  </div>
</template>
<script>
import Room from 'minou/src/room/silent'
import Snippets from '/snippet/list'
import Zap from '/icons/zap'
import Toggle from '/icons/triangle'
import Candidate from '/user/candidate/candidate.entity'
import Conciliation from '/interview/interview.js'

export default {
  name: 'ChatFormWithMenu',
  components: { Snippets, Zap, Toggle },
  props: {
    room: {
      required: true,
      type: Room
    },
    placeholder: {
      type: String,
      default: 'Ecris ton message ...'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    candidate: Candidate,
    conciliation: Conciliation
  },
  data () {
    return {
      writing: '',
      inputHeight: '40px',
      inputLineHeight: '40px',
      scroll: 'scroll',
      close: true,
      myPlaceholder: this.placeholder
    }
  },
  computed: {
    inputStyle () {
      return {
        'height': this.inputHeight,
        'line-height': this.inputLineHeight,
        'overflow-y': this.scroll
      }
    }
  },
  watch: {
    writing: function () {
      this.resize()
    }
  },
  methods: {
    say () {
      if (!this.disabled) {
        this.room.send({ text: this.writing })
        this.writing = ''
      }
    },
    update (text) {
      this.close = true
      this.writing = text.replace(/</g, '&lt;').replace(/>/g, '&gt;')
      this.$nextTick(() => this.resize())
    },
    newline() {
      this.writing = `${this.writing}\n`
    },
    resize () {
      if (this.writing.length === 0) {
        this.inputHeight = '40px'
        this.inputLineHeight = '40px'
      } else if (this.$refs.input.scrollHeight <= 200){
        this.inputHeight = `${this.$refs.input.scrollHeight}px`
        this.inputLineHeight = '25px'
      } else if (this.$refs.input.scrollHeight > 200) {
          this.inputHeight = '200px'
          this.inputLineHeight = '25px'
      }
    }, 
    toggle () {
      this.close = !this.close
    }
  }
}
</script>
<style scoped lang="styl">
.chat-prompt_container
  header
    font-size 0.8em
    width 100%
    padding 0
    border-radius 10px 10px 0 0
    spacer-h()
    .toolbar
      display flex
      justify-content space-between
      margin .2em
      spacer-v()
      margin-top 0
      margin-bottom .4em
      padding .4em 0
      *:not(:last-child)
        color #8F9BB3
      b
        display flex
        align-items center
        padding-left .5em
        svg
          padding-left 1em
    .close
      svg
        cursor pointer
        transform rotate(-90deg)
        transition transform 0.3s ease-in-out
        width 2em
        height 2em
        border-radius 3em
        background white
        text-align center
        line-height 1em
        background rgb(239, 244, 250)
        &.close
          transform rotate(90deg)
    .snippets
      max-height 20vh
      overflow-y scroll
      transition max-height .3s ease-in-out, opacity .3s ease-in-out
      opacity 1
      &.close
        max-height 0
        opacity 0
  form
    width 100%
    margin-left auto
    margin-right auto
    display flex
    margin-left 0.8em
    margin-right 0.8em
    textarea
      height 60px
      padding 0 30px 0 60px
      display block
      width calc(100% - 110px)
      border none
      resize none
      overflow hidden
      &:focus
        padding-left 30px
        padding-right 60px
        outline none
      &:focus ~ button[type=submit]
        opacity 1
        transition opacity .12s linear .18s,transform .12s ease-out .18s,margin-top 0s linear
    button[type="submit"]
      width 50px
      height 50px
      right 15px
      display block
      padding 0
      color #fff
      border none
      border-radius 50%
      cursor pointer
      text-align center
      margin auto
      opacity 0
      &.disabled
        opacity 0.5
        cursor wait
      opacity 1
      width 40px
      height 42px
      top auto
      right 9px
      border-radius 0 5px 5px 0
@media only screen and (max-width $breakpoint)
  .chat-prompt_container form textarea
    // height 50px
     // padding 0 30px 0 60px
    // font-size 1em
    //line-height 2em
  #chat_form_container form #send_message_button
    width 40px
    height 40px
</style>
