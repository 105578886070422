import axios from 'axios'

export function collectIds (data, acc = []) {
  if (typeof data === 'string' && data.length > 0) {
    return acc.concat([data])
  }
  if (Array.isArray(data)) {
    return data.reduce((acc, d) => collectIds(d, acc), [])
  }
  if (typeof data === 'object' && data !== null && 'id' in data) {
    return collectIds(data.id, acc)
  }
  return acc
}

export function handleCancel (thrown) {
  if (!axios.isCancel(thrown)) {
    throw thrown
  }
}

// source https://raw.githubusercontent.com/kennethjiang/js-file-download
export function download (blob, filename) {
  if (typeof window.navigator.msSaveBlob !== 'undefined') {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const blobURL = window.URL.createObjectURL(blob)
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = blobURL
    tempLink.setAttribute('download', filename)
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank')
    }
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
    window.URL.revokeObjectURL(blobURL)
  }
}

export function solrEntity(doc, opts) {
  return Object.entries(opts).reduce((acc, [index, fn]) => {
    if (doc[index] && doc[index].numFound) {
      return Object.assign(acc, { [index]: fn(doc[index].docs[0]) })
    }
    return acc
  }, {})
}

export function idOrDocs(prop, Klass, collection = false) {
  if (prop === null || typeof prop === 'undefined') {
    return collection ? [] : Klass.create({})
  }
  if (prop instanceof Klass) {
    return prop
  }
  if (typeof prop === 'string') {
    return Klass.create({ id: prop })
  }
  if (prop.docs) {
    const list = prop.docs.map(p => idOrDocs(p, Klass))
    return collection ? list : list[0]
  }
  if (typeof prop === 'object') {
    return Klass.create(prop)
  }
}

function curry (fn) {
  return function (nesteed, data) {
    return typeof data === 'undefined' ? function (missing) { return fn(nesteed, missing) } : fn(nesteed, data)
  }
}

function _solrItem (nesteed, item) {
  const d = typeof item === 'string' ? { id: item } : item?.docs[0]
  return Object.entries(nesteed)
    .reduce((acc, [k, fn]) => {
      return Object.assign(acc, { [k]: d && k in d ? fn(d[k]) : null })
    }, Object.assign({}, d))
}

export const solrItem = curry(_solrItem)

function _solrCollection (nesteed, data) {
  const d = data?.docs || []
  return d.map(solrItem(nesteed))
}

export function isAtLeast (versions, lib, ...args) {
  let compatible
  const version = versions && versions[lib]
  if (version) {
    const vs = version.split('.').map(Number).slice(0, args.length)
    while (args.length && typeof compatible === 'undefined') {
      const a = args.shift()
      const v = vs.shift()
      if (v === a) {
        if (args.length === 0) {
          compatible = true
        }
      } else {
        compatible = v > a
      }
    }
  }
  return Boolean(compatible)
}

export const solrCollection = curry(_solrCollection)
