<template>
  <div class="flatchr-container">
    <div class="flatchr-container_main">
      <form @submit.prevent="onUpdate">
        <div>
          <label for="config">activé</label>
          <input type="checkbox" v-model="config.active" @change="onUpdate" id="config" name="config"/>
        </div>
        <div>
          <label for="key">key</label>
          <input v-model="config.key" id="key" name="key"/>
        </div>
        <div>
          <label for="slug">slug</label>
          <input v-model="config.slug" id="slug" name="slug"/>
        </div>
        <button type="submit">ok</button>
      </form>
      <div style="position: relative;">
        <h2>Offres</h2>
        <div v-if="busy">
          <spinner style="transform: scale(0.2, 0.2);"></spinner>
        </div>
        <ul v-else>
          <li v-for="job in jobs" :key="job.id">
            {{ job.vacancy.slug }} - {{ job.vacancy.title }} - {{ job.vacancy.contract_type }} {{ job.vacancy.address && job.vacancy.address.locality }}
            <span class="action">
              <a class="button" :href="job.url" target="_blank" title="ouvrir">👁️</a>
              <template  v-if="job.hippolyte && job.hippolyte.search">
                {{ job.hippolyte.search.title }}
                <button @click="unlink(job)" title="retirer">🗑️</button>
                <button v-if="search && search.id !== job.hippolyte.search.id" @click="link(job)" title="remplacer">🔗</button>
              </template>
              <template v-else-if="search">
                <button @click="link(job)" title="lier">🔗</button>
              </template>
              </span>
            </span>
          </li>
        </ul>
        <paginate v-model="offset" :count="count" :limit="limit"></paginate>
      </div>
    </div>
    <button @click.prevent="onTransfer" :disabled="!search">Transférer</button>
    <sweet-modal ref="transfer">
      <h4>Tranférer un candidat</h4>
      <form @submit.prevent="transfer">
        <label for="candidate">Candidat id</label>
        <input name="candidate" v-model="candidate"/>
        <div v-if="cancels.transfer">
          <spinner style="transform: scale(0.2, 0.2);"></spinner>
        </div>
        <button type="submit" v-else>Transférer</button>
      </form>
    </sweet-modal>
  </div>
</template>
<script>
import clone from 'ramda/src/clone'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Spinner from '/layout/spinner'
import cancel from '/cancel.mixin.js'
import Paginate from '/layout/paginate'
import { SweetModal } from 'sweet-modal-vue'

export default {
  name: 'hippolyte.services.flatchr.config',
  components: {
    Paginate, Spinner, SweetModal
  },
  mixins: [cancel],
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search
  },
  watch: {
    offset: 'load'
  },
  data () {
    return {
      config: Object.assign(
        { key: '', slug: '' },
        clone(this.value)
      ),
      jobs: [],
      limit: 10,
      offset: 0,
      count: 0,
      busy: false,
      selected: null,
      candidate: ''
    }
  },
  mounted () {
    this.load()
  },
  methods: {
    select (job) {
      this.selected = this.selected === job ? null : job
    },
    onUpdate () {
      this.$emit('input', this.config)
    },
    async load () {
      this.cancel('jobs')
      this.busy = true
      this.selected = null
      try {
        const data = await this.$socket.service(`flatchr.jobs/LIST`, {
          recruiter: this.recruiter.id,
          limit: this.limit,
          offset: this.offset
        }, { cancel: this.token('jobs') })
        this.jobs.splice(1, this.limit, ...data.docs)
        this.count = data.count
        this.cancel('jobs', null)
        this.busy = false
      } catch (err) {
        try {
          this.handleCancel(err)
        } catch {
          this.busy = false
        }
      }
    },
    async link (job) {
      try {
        const data = await this.$socket.service(`flatchr.jobs/LINK`, {
          search: this.search.id,
          job: job.vacancy.slug
        })
        Object.assign(job, data)
        this.$toast(`${job.title} lié à ${this.search.title || this.search.id}`, { theme: 'success' })
        this.$forceUpdate()
      } catch (err) {
        if (err.response && err.response.status === 409) {
          return this.$toast(`Une autre recherche est déjà lié à cet offre`, { theme: 'error' })
        }
        this.$toast(`${job.title} n'a pas pu être lié à ${this.search.title || this.search.id}`, { theme: 'error' })
      }
    },
    async unlink (job) {
      try {
        await this.$socket.service(`flatchr.jobs/LINK`, {
          job: job.vacancy.slug
        })
        Object.assign(job, { hippolyte: { search: null } })
        this.$toast(`${job.hippolyte.search.title} n'as plus d'offre`, { theme: 'success' })
      } catch {
        this.$toast(`${job.hippolyte.search.title} n'a pas pu être délié`, { theme: 'error' })
      }
    },
    onTransfer () {
      this.$refs.transfer.open()
    },
    async transfer () {
      this.cancel('transfer')
      try {
        const data = await this.$socket.service(`flatchr.jobs/SEND`, {
          search: this.search.id,
          candidate: this.candidate
        }, { cancel: this.token('transfer') })
        this.$toast(`${this.candidate} transféré`, { theme: 'success' })
        this.cancel('transfer', null)
        this.$refs.transfer.close()
      } catch (err) {
        this.$toast(`${this.candidate} n'a pas pu être transféré`, { theme: 'error' })
        this.handleCancel(err).catch()
        this.cancel('transfer', null)
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
input[type="checkbox"]
  height auto
form
  margin 1em
  div
    display grid
    grid-template-columns 100px 350px
    background white
    margin 0.4em 0
    padding 3px 3px 3px 0.8em
    width 450px
    border-radius 4px
    label
      grid-column 1
      margin-right 1em
      text-align right
      margin-top auto
      margin-bottom auto
    input
      grid-column 2
      background #eee
      border-radius 0 4px 4px 0
      font-size 1em
      line-height 1.5em
      border 1px #eee solid
      &:focus
        border 1px #ccc solid
button, .button
  background white
  border none
  color black
  border-radius 2em
  line-height 1em
  margin 0.2em 0
  padding 0.4em 1.5em
  font-size 0.9em
  cursor pointer
  text-decoration none
  &::first-letter
    text-transform capitalize
  &:focus, &:hover
    outline none
  &.invert
    color white
    border 1px white solid
  &:disabled
    color #ccc
    cursor default
.flatchr-container
  display flex

  .flatchr-container_main
    ul
      padding 1em
      list-style none
      li
        background white
        margin 0.5em 0
        width auto
        padding 0.4em 0.8em
        border-radius 1em
        cursor pointer
        display flex
        justify-content space-between
        button, .button
          background $color-torrent
          padding 0.2em 0.4em
          margin 0.1em
</style>
