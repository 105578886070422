<template>
  <div>
    <button @click="previous" :disabled="value === 0">◀</button>
    {{ (value / limit + 1) + ' / ' + (Math.ceil(count / limit)) }}
    <button @click="next" :disabled="value >= count - limit">▶</button>
  </div>
</template>
<script>
export default {
  name: 'hippolyte.paginate',
  props: {
    value: Number,
    count: Number,
    limit: {
      type: Number,
      default () {
        return 10
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    previous () {
      this.select(this.value - this.limit)
    },
    next () {
      this.select(this.value + this.limit)
    },
    select (value) {
      this.$emit('input', value)
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
button
  background white
  border none
  color black
  border-radius 2em
  line-height 1em
  margin 0.2em 0
  padding 0.4em 1.5em
  font-size 0.9em
  cursor pointer
  text-decoration none
  &::first-letter
    text-transform capitalize
  &:focus, &:hover
    outline none
  &.invert
    color white
    border 1px white solid
  &:disabled
    color #ccc
    cursor default
</style>
