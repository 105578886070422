<template>
  <div>
    <span class='candidate' :class="{ open }" v-if="open" :title="open ? `${candidateUnread} ${candidateUnread > 1 ? 'messages' : 'message'} non ${candidateUnread > 1 ? 'lus' : 'lu'} par le candidat` : ''">{{candidateUnread > 0 ? candidateUnread : ''}}</span>
    <span class='conciliator'  v-if="conciliatorUnread" :title="conciliatorUnread ? `${conciliatorUnread} ${conciliatorUnread > 1 ? 'messages' : 'message'} non ${conciliatorUnread > 1 ? 'lus' : 'lu'}` : ''">{{conciliatorUnread > 0 ? conciliatorUnread : ''}}</span>
  </div>
</template>
<script>
import Candidate from '/user/candidate/candidate.entity.js'
import debug from 'debug'
import parseISO from 'date-fns/parseISO'
import add from 'date-fns/add'
import cancel from '/cancel.mixin.js'
const log = debug('cancel')

export default {
  name: 'hippolyte.profile.presence',
  components: {
  },
  mixins: [cancel],
  props: {
    profile: Candidate
  },
  watch: {
    profile ( val, old ) {
      if (old && this.getRoom(old)) {
        old.off('update', this.onProfileChange, this)
        this.$socket.unsub(this.getRoom(old).id, 'ACK')
      }
      val?.on('update', this.onProfileChange, this)
      this.onProfileChange()
    }
  },
  mounted () {
    this.profile?.on('update', this.onProfileChange, this)
    this.$chat.on('room:remove', this.onRemove, this)
    this.$chat.on('room:add', this.onAdd, this)
  },
  destroy () {
    this.$chat.off('room:remove', this.onRemove, this)
    this.$chat.off('room:add', this.onAdd, this)
    this.profile?.off('update', this.onProfileChange, this)
    this.getRoom(this.profile)?.off('unread:message', this.onProfileChange, this)
  },
  data () {
    return {
      candidateUnread: 0,
      conciliatorUnread: 0,
      lastAck: {},
      open: false
    }
  },
  methods: {
    getConciliatorUnread () {
      this.getRoom(this.profile)?.off('unread:message', this.onProfileChange, this)
      this.getRoom(this.profile)?.on('unread:message', this.onProfileChange, this)
      this.conciliatorUnread = this.getRoom(this.profile)?.unread
    },
    async getLastAck (room, profile) {
      this.cancel('lastAcks')
      try {
        const lastAcks = await this.$socket.service('attendance/list', { rooms: [room.id], chatUser: profile.chatUser }, this.token('lastAcks'))
        const lastAck = lastAcks.find(a => a.roomId === room.id)
        lastAck.date = add(parseISO(lastAck.date), { minutes: -parseISO(lastAck.date).getTimezoneOffset() })
        this.lastAck = lastAck
        this.cancel('lastAcks', null)
      } catch (err) {
        this.handleCancel('lastAcks').catch( () => {
          this.cancel('lastAcks', null)
          throw err
        })
      }
    },
    getRoom (profile) {
      return this.$chat.rooms.find(room => room.id.split('/')[3] === profile.id)
    },
    onAdd (room) {
      if (room === this.getRoom(this.profile)){
        this.userPresence()
        this.open = true
      }
    },
    onRemove (room) {
      if (room === this.getRoom(this.profile)) {
        this.unread = 0
        this.open = false
      }
    },
    onProfileChange() {
      this.userPresence()
      this.getConciliatorUnread()
    },
    async userPresence (newack = null) {
      const room = this.getRoom(this.profile)
      let unread = 0
      this.open = !!room
      this.cancel('room')
      if (room) {
        await room.loading
        // check if another room is requested during loading
        if (room.id !== this.getRoom(this.profile)?.id) {
          return
        }
        if (!newack) { // first loading
          this.$socket.sub(room.id, 'ACK', this.userPresence.bind(this))
          await this.getLastAck(room, this.profile)
        } else if (newack.user == this.profile.chatUser) {
          this.lastAck = newack
        }
        if (newack && !(newack.date instanceof Date)) {
          newack.date = add(parseISO(newack.date), { minutes: -parseISO(newack.date).getTimezoneOffset() })
        }
        unread = (newack && newack.user == this.profile.chatUser) ? 0 : room.messages
          .filter(message => message.user && message.user !== 'undefined' && (this.lastAck.date < message.date)).length
        this.cancel('room', null)
      }
      this.candidateUnread = unread
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
span
  border 1px solid $color-menu_text
  display inline-block
  width 14px
  height 14px
  border-radius 1em
  position absolute
  background-color $color-connected

  &.candidate
    top -8px
    left -10px

  &.conciliator
    background-color $color-peach
    bottom -8px
    left -10px
</style>
