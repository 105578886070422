import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faCertificate } from '@fortawesome/free-solid-svg-icons/faCertificate'
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons/faBoxOpen'
import { faBox } from '@fortawesome/free-solid-svg-icons/faBox'
import { faList } from '@fortawesome/free-solid-svg-icons/faList'
import { faGlasses } from '@fortawesome/free-solid-svg-icons/faGlasses'
import { faUserSlash } from '@fortawesome/free-solid-svg-icons/faUserSlash'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons/faUserFriends'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faRocket } from '@fortawesome/free-solid-svg-icons/faRocket'
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome'
import { faTags } from '@fortawesome/free-solid-svg-icons/faTags'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus'
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell'
import { faBellSlash } from '@fortawesome/free-solid-svg-icons/faBellSlash'
import { faCartPlus } from '@fortawesome/free-solid-svg-icons/faCartPlus'
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons/faThumbsDown'
import { faUserEdit } from '@fortawesome/free-solid-svg-icons/faUserEdit'
import { faParachuteBox } from '@fortawesome/free-solid-svg-icons/faParachuteBox'
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons/faFolderOpen'

library.add(faEnvelope)
library.add(faAngleRight)
library.add(faAngleLeft)
library.add(faComment)
library.add(faCertificate)
library.add(faBoxOpen)
library.add(faBox)
library.add(faList)
library.add(faGlasses)
library.add(faUserSlash)
library.add(faUserFriends)
library.add(faMapMarkerAlt)
library.add(faSignInAlt)
library.add(faSignOutAlt)
library.add(faLock)
library.add(faAt)
library.add(faPhone)
library.add(faExclamationCircle)
library.add(faRocket)
library.add(faHome)
library.add(faTags)
library.add(faUserPlus)
library.add(faBell)
library.add(faBellSlash)
library.add(faCartPlus)
library.add(faThumbsDown)
library.add(faUserEdit)
library.add(faParachuteBox)
library.add(faFolderOpen)
