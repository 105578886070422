import pick from 'ramda/src/pick'
import { EventEmitter } from 'eventemitter3'
import Recruiter from '/user/recruiter/recruiter.entity'
import Requirement from '/requirement/entity'
const service = verb => `entity.RecruiterAccount/${verb}`
const cond = k => `YesWeChat\\ServiceEntityBundle\\Query\\Condition\\${k}`
const fields = ['id', 'email', 'firstname', 'lastname', 'chatUser', 'gender', 'phone', 'recruiter', 'searches']
const blank = fields.reduce((acc, val) => Object.assign(acc, { [val]: null }), {})
blank.searches = []

function normalize (value) {
  return value
}

export default class Account extends EventEmitter {
  constructor (data, socket) {
    super()
    this.custom = {}
    this.searches = []
    this.setData(Object.assign({}, blank, data))
    this.socket = socket
    this.loading = false
  }

  setData (data) {
    const delta = this.getDelta()
    let surcharge = {}
    data = Object.assign({}, data)
    if (data.recruiter) {
      surcharge.recruiter = new Recruiter(data.recruiter)
    }
    if (data.searches) {
      const searches = data.searches.map(s => {
        if (typeof s === 'undefined' || s === null) {
          return s
        }
        if (s instanceof Requirement) {
          return s
        }
        if (typeof s === 'string') {
          return new Requirement({ id: s })
        }
        if (typeof s === 'object') {
          return new Requirement(s)
        }
      }).filter(i => i)
      delete data.searches
      this.searches.splice(0, this.searches.length, ...searches)
    }
    Object.assign(this, data, surcharge)
    this.hasUpdate(delta)
    return this
  }

  getDelta (opts) {
    return JSON.stringify(this.marshall(opts))
  }

  hasUpdate (delta) {
    if (delta !== this.getDelta()) {
      this.emit('update')
    }
  }

  load () {
    if (!this.loading && this.id) {
      this.loading = this.socket.service(service('READ'), { id: this.id })
        .then(data => {
          this.loading = false
          return data
        })
        .then(normalize)
        .then(this.setData.bind(this))
    }
    return this.loading
  }

  save (serializer) {
    if (!this.saving) {
      const s = this.id ? service('SAVE') : 'recruiter.signin/CREATE'
      this.saving = this.socket.service(s, serializer ? serializer() : this.marshall())
        .then(data => {
          this.saving = false
          return data
        })
        .then(normalize)
        .then(this.setData.bind(this))
    }
    return this.saving
  }

  marshall (f=fields) {
    let data = pick(f, this)
    if (data.recruiter instanceof Recruiter) {
      data.recruiter = data.recruiter.marshall()
    }
    if (Array.isArray(data.searches)) {
      data.searches = data.searches.map(s => s.marshall())
    }
    return data
  }
}

Account.getByChatUser = async function (chatUser, socket) {
  let list = await socket.service(service('QUERY'), {
    alias: 'a',
    class: 'AccountRecruiter',
    parameters: [
      { type: cond('Parameter'), name: 'chatUser', value: chatUser }
    ],
    conditions: [
      {
        type: cond('Equals'),
        value: 'chatUser',
        subject: {
          type: cond('Field'),
          name: 'a.chatUser'
        }
      }
    ]
  })
  if (list.length === 1) {
    return new Account(list[0], socket)
  }
}

Account.loadIds = async function (accounts, socket, cancel) {
  if (accounts.length < 1) {
    return
  }
  try {
    let list = await socket.service(service('QUERY'), {
      alias: 'r',
      class: 'RecruiterAccount',
      parameters: [
        { type: cond('Parameter'), name: 'id', value: accounts.map(c => c.id) }
      ],
      conditions: [
        {
          type: cond('In'),
          value: 'id',
          subject: {
            type: cond('Field'),
            name: 'r.id'
          }
        }
      ]
    }, { cancel })
    list.forEach(c => {
      accounts.filter(i => i.id === c.id).map(c2 => c2.setData(normalize(c)).loading = false)
    })
  } catch (err) {
    console.log(err)
    accounts.filter(c => c).forEach(c => {
      c.loading = false
    })
  }
}
