<template>
  <ul class="hippolyte-filters">
    <template v-for="status in statuses">
      <li @click="toggle([status])" :class="{ checked: isFilter([status]) }" :title="Labels[status].plural">
        <b>{{ Labels[status].plural }}</b>
        <span class="counter">{{ counter[status] | enough }}</span>
      </li>
    </template>
    <li @click="toggle(statuses)" :class="{ checked: isFilter(statuses) }">
      <b>Tous</b>
      <span class="counter" v-if="counter.all  > 0 ">{{ counter.all | enough }}</span>
    </li>
  </ul>
</template>
<script>
import { Status, Labels } from '/interview/status'

export default {
  name: 'hippolyte.filter.states',
  props: {
    value: Array,
    statuses: Array,
    counter: Object
  },
  data () {
    return {
      states: this.value,
      Labels
    }
  },
  methods: {
    toggle (states) {
      if (!this.isFilter(states)) {
        this.states.splice(0, this.states.length, ...states)
        let { name, params, query } = this.$router.currentRoute
        query = Object.assign({}, query, { states: states.join(',') })
        this.$router.push({ name, params, query })
      }
    },
    isFilter (states) {
      if (Array.isArray(states) && states.length === this.states.length) {
        const filters = Array
          .from(this.states)
          .sort()
        return Array
          .from(states)
          .sort()
          .map((s, i) => s === filters[i])
          .filter(e => !e)
          .length === 0
      }
      return false
    }
  },
  filters: {
    enough (count) {
      return count > 0 ? count : ''
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
ul.hippolyte-filters
  list-style none
  margin 0
  padding-left 0
  display flex
  justify-content space-between
  flex-direction row
  width 100%
  height 2em
  li
    height 2em
    font-size 0.85em
    color black
    margin auto
    &.checked
      color #6b41b9
    display flex
    flex-direction column
    align-items center
    b
      align-self start
      text-overflow ellipsis
      overflow hidden
      max-width calc(50vw / 16)
    cursor pointer
    .counter
      font-size 0.8em
      margin-left 0.4em
    white-space nowrap
</style>
