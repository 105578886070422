<template>
  <svg v-bind="$props" viewBox="0 0 24 24">
    <title v-if="title"> {{ title }} </title>
    <!-- @todo à changer par l'icon adéquate -->
    <path class="cls-1" d="M18,19H2a1,1,0,0,1-1-1V8.5a1,1,0,0,1,1-1l8,5L18,7.5a1,1,0,0,1,1,1V18A1,1,0,0,1,18,19Z"/>
    <path d="M10,9c2.53-2.3,4.91-4.45,4.91-6.3A2.68,2.68,0,0,0,12.21,0,3,3,0,0,0,10,1,3,3,0,0,0,7.79,0a2.68,2.68,0,0,0-2.7,2.7C5.09,4.55,7.47,6.7,10,9ZM7.79,1.64a1.31,1.31,0,0,1,1,.45L10,3.55l1.24-1.46a1.31,1.31,0,0,1,1-.45A1,1,0,0,1,13.27,2.7c0,.92-1.67,2.59-3.27,4.08C8.4,5.29,6.73,3.61,6.73,2.7A1,1,0,0,1,7.79,1.64Z"/>
  </svg>
</template>

<script>
import icon from '/icons/mixin.js'

export default {
  name: 'hippolyte.icon.satisfaction',
  mixins: [icon('18px')],
  props: { title: String }
}
</script>