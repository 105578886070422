<template>
  <section class="interview-container">
    <slot name="header"></slot>
    <conversation :room="room" v-if="room" :isReceiver="isReceiver" ref="conversation">
      <template #item="{ message }">
        <slot name="message" :message="message" :isReceiver="isReceiver"></slot>
      </template>
    </conversation>
    <slot name="prompt" :room="room"></slot>
  </section>
</template>
<script>
import Room from 'minou/src/room'
import Conversation from 'chat-ui-hippolyte/src/conversation'
import Interview from './interview.js'

export default {
  name: 'hippolyte.interview',
  components: {
    Conversation
  },
  props: {
    interview: {
      type: Interview,
      required: false
    },
    room: Room
  },
  data () {
    return { visibilityHandler: null }
  },
  mounted () {
    if (this.room?.selectedState) {
      this.scrollBottom()
    }
    this.room?.on('update:messages', this.updateAndScroll, this)
    this.interview?.on('update', this.$forceUpdate, this)
    this.syncVisibility()
    this.watchVisibilityChange()
  },
  destroyed () {
    if (this.room) {
      this.$chat.setFocus(this.room, false)
    }
    document.removeEventListener('visibilitychange', this.visibilityHandler)
  },
  watch: {
    room: function (next, old) {
      old.off('update:messages', this.updateAndScroll, this)
      old.interview?.off('update', this.$forceUpdate, this)
      this.$chat.setFocus(this.room)
      this.interview?.on('update', this.$forceUpdate, this)
      this.room.on('update:messages', this.updateAndScroll, this)
      if (this.room.selectedState) {
        this.scrollBottom()
      } else {
        let fn = this.scrollBottom.bind(this)
        this.room.on('selected', fn)
        let off = room => room.off('selected', fn)
        this.room.once('unselected', off )
      }
    }
  },
  methods: {
    isReceiver (message) {
      return message.user === this.$auth.user.id
    },
    scrollBottom (i = 100) {
      this.$nextTick(() => {
        if (i < 0) return
        if (this.$refs.conversation) {
          this.$refs.conversation.scrollBottom()
        } else {
          if (this.interview?.loading) {
            this.interview.loading.then(() => this.scrollBottom(i - 2))
          } else if (this.interview?.isStarted()) {
            setTimeout(() => this.scrollBottom(i - 2), i)
          }
        }
      })
    },
    updateAndScroll () {
      this.$forceUpdate()
      this.scrollBottom()
    },
    watchVisibilityChange () {
      this.visibilityHandler = this.syncVisibility.bind(this)
      document.addEventListener('visibilitychange', this.visibilityHandler)
    },
    syncVisibility () {
      switch (document.visibilityState) {
        case 'visible':
          this.$chat.setFocus(this.room)
          break
        case 'hidden':
          this.$chat.setFocus(this.room, false)
          break
      }
    }
  }
}
</script>
<style lang="stylus">
@require '~/colors.styl'

.interview-container {
    display none
    flex-direction column
    margin-left 1em
    margin-right 1em
    align-items stretch
    overflow-x: hidden
    &.show {
      display flex
    }
    .messages_list {
      max-height: 100%;
      font-family: $font
      font-size 0.9em
    }
    .message-group {
      margin-bottom 0.4em
    }
    .message_container {
        margin-right 0 !important
        padding 0
        padding-right 0.8em
    }
    .message_container > .message {
        overflow-wrap anywhere
        color $color-white
        background $color-blue
        padding 5px 15px 5px
        border-radius 5px
        box-shadow none
        display inline-block
    }
    .message_container.self > .message {
        color $color-astronaut_dark
        background $color-blue_lighter
        padding 5px 15px 5px
    }
    .chat-prompt_container form {
        margin 0.4em 0.8em 1em 0
        width calc(100% - 1.6em)
        textarea {
            padding-left 30px
            padding-right 60px
            border-radius 5px  0px 0px 5px
            height 40px
            top auto
            color $color-astronaut_dark
            background $color-blue_lighter
            font-family $font
            font-size 1em
        }

        button[type="submit"] {
            left: 3%;
            right: 3%;
            opacity 1
            width 40px
            height 40px
            top auto
            border-radius 0 5px 5px 0
            background $color-blue
        }
    }
}

@media (min-width: $breakpoint)
    .interview-container
        display flex
        .messages_list
            padding-left 0em
        button
            color $color-white
            background-color $color-blue
            border 0
            padding 0.6em 1em
            border-radius 3px
            font-size 1.2em
            font-weight bold
            font-weight bold
            cursor pointer
</style>
