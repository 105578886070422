<template>
    <article class="reporting_evolve">
      <help :title="'Évolution du nombre de candidats sourcés'">
        <template v-slot:content>
          <p>Les statistiques se basent sur la date de création de la conciliation.</p>
          <p>Pensez à utiliser les datepicker (en haut à droite) si vous souhaitez voir l'évolution sur une autre période.</p>
        </template>
      </help>
      <header>
        <h2>Évolution du nombre de candidats sourcés créés du {{start | format('d-MM-yy')}} au {{end | format('d-MM-yy')}}</h2>
      </header>
      <div style="position: relative;">
        <div v-if="busy" style="position: absolute; top: 0; margin-top: -2em; left: 0;">
          <spinner style="transform: scale(0.2, 0.2);"></spinner>
        </div>
      </div>
      <canvas ref="candidateSources" width="400" height="150"></canvas>
    </article>
</template>
<script>
import Recruiter from '/user/recruiter/recruiter.entity.js'
import Search from '/requirement/entity'
import Help from '/components/help'
import { Labels } from '/interview/status'
import { Chart, BarElement, BarController, LinearScale, CategoryScale, Legend, Title, Tooltip } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { format, parseISO, add, endOfDay, startOfDay } from 'date-fns'
import Spinner from '/layout/spinner'
import { status, backgroundColor } from './utilsArray'
import { Solr, and, eq } from '/solr'
import cancel from '/cancel.mixin.js'
Chart.register(BarElement, BarController, LinearScale, CategoryScale, Legend, Title, Tooltip)

export default {
  name: 'hippolyte.reporting.sourceEvolving',
  props: {
    a: Boolean,
    recruiter: Recruiter,
    search: Search,
    start: Date,
    end: Date,
    gap: Object
  },
  components: {
    Spinner,
    Help
  },
  mixins: [cancel],
  data () {
    return {
      busy: false,
      status,
      Labels,
      candidateSources: null,
      cancels: {},
      facets: [],
    }
  },
  watch: {
    a: 'load',
    search: 'load',
    recruiter: 'load',
    start: 'load',
    end: 'load',
    facets: 'update'
  },
  mounted () {
    this.load()
  },
  destroyed () {
    this.candidateSources?.destroy()
  },
  methods: {
    format(data) {
      return Object.assign({}, {
        labels: data.facets.date?.buckets.map((obj, i, b) => {
          return format(parseISO(obj.val), this.gap.formatLabel)
        }),
        datasets: this.status.map(s => {
          return {
            label: Labels[s].plural,
            data: data.facets.date?.buckets.map(obj => {
              return obj.status?.buckets.find(d => d.val === s)?.count || 0
            }),
            backgroundColor: backgroundColor[status.indexOf(s)]
          }
        })
      })
    },
    getOptions () {
      return {
        plugins: {
          legend: {
            labels: {
              boxWidth: 20,
              pointStyle: 'rectRounded',
              usePointStyle: true,
              color: 'rgb(19, 77, 96)',
              font:{
                size: 16,
                family: 'Montserrat'
              }
            },
            onClick: function(e, legendItem) {
              var index = legendItem.datasetIndex
              var ci = this.chart
              var alreadyHidden = (ci.getDatasetMeta(index).hidden === null) ? false : ci.getDatasetMeta(index).hidden
              var anyOthersAlreadyHidden = false
              var allOthersHidden = true
              // figure out the current state of the labels
              ci.data.datasets.forEach(function(e, i) {
                var meta = ci.getDatasetMeta(i)
                if (i !== index) {
                  if (meta.hidden) {
                    anyOthersAlreadyHidden = true
                  } else {
                    allOthersHidden = false
                  }
                }
              })
              // if the label we clicked is already hidden
              // then we now want to unhide (with any others already unhidden)
              if (alreadyHidden) {
                ci.getDatasetMeta(index).hidden = null
              } else {
                // otherwise, lets figure out how to toggle visibility based upon the current state
                ci.data.datasets.forEach(function(e, i) {
                  var meta = ci.getDatasetMeta(i)
                  if (i !== index) {
                    // handles logic when we click on visible hidden label and there is currently at least
                    // one other label that is visible and at least one other label already hidden
                    // (we want to keep those already hidden still hidden)
                    if (anyOthersAlreadyHidden && !allOthersHidden) {
                      meta.hidden = true
                    } else {
                      // toggle visibility
                      meta.hidden = meta.hidden === null ? !meta.hidden : null
                    }
                  } else {
                    meta.hidden = null
                  }
                })
              }
              ci.update()
            },
          },
          datalabels: {
            color: 'white',
            display: function (context) {
              return context.dataset.data[context.dataIndex] > 0
            }
          }
        },
        layout: {
          padding: { bottom: 30 }
        },
        scales: {
          x: { stacked: true, grid: { display: false } },
          y: { stacked: true, grid: { display: false } }
        },
        responsive: true
      }
    },
    update () {
      if (this.candidateSources) {
        this.candidateSources.data = this.format(this.facets)
        this.candidateSources.update()
      } else {
        this.candidateSources = new Chart(this.$refs.candidateSources, {
          type: 'bar',
          data: this.format(this.facets),
          plugins: [ChartDataLabels],
          options: this.getOptions()
        })
      }
    },
    async load () {
      try{
        this.busy = true
        this.cancel('evolve')
        const req = new Solr({
          entity: 'ConciliationStatus',
          raw: true,
          limit: 0,
          offset: 0
        })
        const r = req.join({
          entity: 'Conciliation',
          query: and('{!join entity=ConciliationStatus v="date:[@start TO @end] AND status:created"}')
        })
        if (this.a) {
          r.query.push(`{!join entity=Search v="active:true"}`)
        }
        if (this.search && this.search.id) {
          r.query.push(eq('search', this.search.id))
        }
        if (this.recruiter && this.recruiter.id) {
          r.query.push(eq('recruiter', this.recruiter.id))
        }
        req.parameter('start', add(startOfDay(this.start), { minutes: -this.start.getTimezoneOffset() }).toISOString())
        req.parameter('end', add(endOfDay(this.end), { minutes: -this.end.getTimezoneOffset() }).toISOString())
        const reqFacets = {
          facets: [{
            type: 'range',
            name: 'date',
            field: 'date',
            start: add(startOfDay(this.start), { minutes: -this.start.getTimezoneOffset() }).toISOString(),
            end: add(endOfDay(this.end), { minutes: -this.end.getTimezoneOffset() }).toISOString(),
            gap: '+1' + this.gap.gap,
            facets: [{
              type: 'terms',
              name: 'status',
              field: 'status',
              limit: this.status.length,
            }]
          }]
        }
        this.facets = await this.$socket.service('entity_solr/QUERY', Object.assign(req.send(), reqFacets), this.token('evolve'))
        this.cancel('evolve', null)
        this.busy = false
      } catch (err) {
        this.handleCancel(err).catch(() => {
          this.cancel('evolve', null)
        })
      }
    }
  }
}
</script>
<style lang="stylus" scope>
@require '~/colors.styl'
.reporting_evolve
  header
    button
      cursor pointer
      color $color-astronaut
    .reporting_current
      display inline-block
      text-align center
      min-width 10em
</style>
