<template>
  <ats
    v-bind="$props"
    service="workday"
    :credential="{ username: '', password: '', wsdl: '', wsdl_extra: '' }"
    @input="onInput">
    <template v-slot:credential="{ credential }">
      <div>
        <label for="username">Username</label>
        <input v-model="credential.username" id="username" name="username"/>
        <modified :enable="value.credential.username !== credential.username"/>
      </div>
      <div>
        <label for="username">Password</label>
        <input v-model="credential.password" id="password" name="password"/>
        <modified :enable="value.credential.password !== credential.password"/>
      </div>
      <div>
        <label for="wsdl">WSDL</label>
        <input v-model="credential.wsdl" id="wsdl" name="wsdl"/>
        <modified :enable="value.credential.wsdl !== credential.wsdl"/>
      </div>
      <div>
        <label for="wsdl">WSDL extra</label>
        <input v-model="credential.wsdl_extra" id="wsdl_extra" name="wsdl_extra"/>
        <modified :enable="value.credential.wsdl_extra !== credential.wsdl_extra"/>
      </div>
    </template>
    <template v-slot:title="{ job }">{{ title(job) }}</template>
  </ats>
</template>

<script>
import Ats from './sdk-v2'
import Recruiter from '/user/recruiter/recruiter.entity'
import Search from '/requirement/entity'
import Modified from '/ats/modified'

export default {
  name: 'hippolyte.services.workday.config',
  components: { Ats, Modified },
  props: {
    value: Object,
    recruiter: Recruiter,
    search: Search,
    savingConf: Boolean
  },
  methods: {
    title (job) {
      return [
        job.id || job.reference,
        job.Report_Entry[0].Job_Posting_Title || job.hippolyte?.search.title,
        job.Report_Entry[0].Location.Location_City || job.hippolyte?.search.location?.docs[0]?.city
      ].filter(i => i).join(' - ')
    },
    onInput (event) {
      this.$emit('input', event)
    }
  }
}
</script>
