<template>
  <section>
    <header>
      <h2>Provenance des candidats sourcés</h2>
    </header>
    <div style="position: relative">
      <div v-if="busy"
      style="position: absolute; top: 0; margin-top: -2em; left: 0;">
        <spinner style="transform: scale(0.2, 0.2);"></spinner>
      </div>
      <canvas ref="onboardSource" width="400" height="150"></canvas>
    </div>
  </section>
</template>
<script>
import Recruiter from '/user/recruiter/recruiter.entity.js'
import Search from '/requirement/entity'
import { Labels } from '/interview/status'
import { backgroundColor } from './utilsArray'
import Spinner from '/layout/spinner'
import { Solr, and, eq } from '/solr'
import { Chart, BarElement, BarController, LinearScale, CategoryScale, Legend, Title, Tooltip } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { add, endOfDay, startOfDay } from 'date-fns'
import cancel from '/cancel.mixin.js'
Chart.register(BarElement, BarController, LinearScale, CategoryScale, Legend, Title, Tooltip)

export default {
  name: 'hippolyte.reporting.byOrigins',
  props: {
    a: Boolean,
    recruiter: Recruiter,
    search: Search,
    start: Date,
    end: Date,
    status: Array,
    statusSource: Object
  },
  components: { Spinner },
  mixins: [cancel],
  data () {
    return {
      busy: false,
      sourceChart: null,
      facets: [],
      cancels: {},
    }
  },
  watch: {
    a: 'load',
    search: 'load',
    recruiter: 'load',
    start: 'load',
    end: 'load',
    facets: 'update',
    statusSource: {
      handler: 'load',
      deep: true
    }
  },
  mounted () {
    this.load()
  },
  destroyed () {
    this.sourceChart?.destroy()
  },
  methods: {
    async loadSources () {
      // récuperation des sources
      const req = new Solr({
        entity: 'Candidate',
        raw: true,
        limit: 0,
        offset: 0
      })
      let r = req.join({
        entity: 'Conciliation',
        fromField: 'id',
        toField: 'candidate',
        query: and()
      })
      if (this.search && this.search.id) {
        r.query.push(eq('search', this.search.id))
      }
      if (this.recruiter && this.recruiter.id) {
        r.query.push(eq('recruiter', this.recruiter.id))
      }
      r.query.push('{!join entity=ConciliationStatus v="current:true AND date:[@start TO @end]"}')
      req.parameter('start', add(startOfDay(this.start), { minutes: -this.start.getTimezoneOffset() }).toISOString())
      req.parameter('end', add(endOfDay(this.end), { minutes: -this.end.getTimezoneOffset() }).toISOString())
      const facets = {
        facets: [{
          name: 'sources',
          type: 'terms',
          field: 'custom_field_onboard_source',
          limit: 10
        }]
      }
      return this.$socket.service('entity_solr/QUERY', Object.assign(req.send(), facets), this.token('source'))
    },
    async load () {
      try{
        this.cancel('source')
        this.busy = true
        const sources = await this.loadSources()
        const req = new Solr({
          entity: 'ConciliationStatus',
          raw: true,
          query: and('current:true AND date:[@start TO @end]'),
          limit: 0,
          offset: 0
        })
        const r = req.join({
          entity: 'Conciliation',
          query: and()
        })
        if (this.a) {
          r.query.push(`{!join entity=Search v="active:true"}`)
        }
        if (this.search && this.search.id) {
          r.query.push(eq('search', this.search.id))
        }
        if (this.recruiter && this.recruiter.id) {
          r.query.push(eq('recruiter', this.recruiter.id))
        }
        req.parameter('start', add(startOfDay(this.start), { minutes: -this.start.getTimezoneOffset() }).toISOString())
        req.parameter('end', add(endOfDay(this.end), { minutes: -this.end.getTimezoneOffset() }).toISOString())
        const facets = {
          filters: ['date:[@start TO @end]'],
          facets: sources.facets.sources?.buckets.map((source, index) => {
            req.parameter(`source_${index}`, source.val)
            return {
              type: 'query',
              name: source.val,
              query: `{!join entity=Conciliation v="{!join entity=Candidate v=\\"custom_field_onboard_source:@source_${index}\\"}"}`,
              facets: [{
                type: 'terms',
                name: 'status',
                field: 'status',
                mincount: 0,
                limit: this.status.length
              }]
            }
          })
        }
        const excludeFacets = {
          type: 'query',
          name: 'Autres sources',
          query: `{!join entity=Conciliation v="{!join entity=Candidate v=\\"*:* AND -custom_field_onboard_source:[* TO *]\\"}"}`,
          facets: [{
            type: 'terms',
            name: 'status',
            field: 'status',
            mincount: 0,
            limit: this.status.length
          }]
        }
        facets.facets?.splice(facets.facets.length, 0, excludeFacets)
        this.facets = await this.$socket.service('entity_solr/QUERY', Object.assign(req.send(), facets), this.token('source'))
        this.cancel('source', null)
        this.busy = false
      } catch (err) {
        this.handleCancel(err).catch(() => {
          this.cancel('source', null)
        })
      }
    },
    format (data) {// mise en forme des datas
      if (data.facets) {
        return Object.entries(data.facets).slice(1).map(([label, data], index) => {
          return {
            label,
            data: this.status.filter(s => this.statusSource[s]).map(status => data.status?.buckets.find(b => b.val === status)?.count || 0),
            backgroundColor: backgroundColor[index]
          }
        })
      } else {
        return []
      }
    },
    getOptions() {
      return {
        plugins: {
          legend: {
            labels: {
              boxWidth: 20,
              pointStyle: 'rectRounded',
              usePointStyle: true,
              color: 'rgb(19, 77, 96)',
              font:{
                size: 16,
                family: 'Montserrat'
              }
            }
          },
          datalabels: {
            color: 'white',
            display: function(context){
              return context.dataset.data[context.dataIndex] > 0;
            }
          }
        },
        layout: {
          padding: { bottom: 30 }
        },
        responsive: true,
        scales: {
          x: { stacked: true, grid: { display: false } },
          y: { stacked: true, grid: { display: false } }
        }
      }
    },
    update () {
      if (this.sourceChart) {
        this.sourceChart.data.datasets = this.format(this.facets)
        this.sourceChart.data.labels = this.status.filter(s => this.statusSource[s] ).map(status => Labels[status].plural)
        this.sourceChart.update()
      } else {
        this.sourceChart = new Chart(this.$refs.onboardSource, {
          type: 'bar',
          data: {
            labels: this.status.filter(s => this.statusSource[s] ).map(status => Labels[status].plural),
            datasets: this.format(this.facets)
          },
          plugins: [ChartDataLabels],
          options: this.getOptions()
        })
      }
    }
  }
}
</script>
