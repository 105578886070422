<template>
  <div class='select-container'>
    <label :for="name">Compte(s) recruteur(s)</label>
    <treeselect
      v-model="value"
      :id="name"
      :name="name"
      :multiple="true"
      :options="accounts"
      placeholder="Selectionner le/les compte(s) recruteur(s)"
      @input="input"
    ></treeselect>
    <modified :enable="original !== value"></modified>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import Modified from '/ats/modified'
import Account from '/user/account/entity'

export default {
  name: 'hippolyte.services.ats.filter.accountSelect',
  components: {
    Treeselect, Modified
  },
  inject: ['recruiter'],
  props: {
    modelValue: Array,
    recruiter: Object,
    name: String
  },
  async mounted () {
    await this.loadAccounts()
  },
  data () {
    return {
      accounts: [],
      value: this.modelValue,
      original: this.modelValue
    }
  },
  methods: {
    input () {
      this.$emit('input', this.value)
    },
    async loadAccounts () {
      await Account.loadIds(this.recruiter.accounts, this.$socket)
      this.accounts = this.recruiter.accounts.map(a => {
        return {
          id: a.id,
          label: [a.firstname, a.lastname].join(' ')
        }
      })
    }
  }
}
</script>
<style lang="stylus" scoped>
div
  background white
  margin 0.4em 0
  border-radius 4px
  align-items center
  label
    margin-right 1em
    text-align right
    margin-top auto
    margin-bottom auto
  select
    background #eee
    border-radius 0 4px 4px 0
    font-size 1em
    line-height 1.5em
    border 1px #eee solid
    &:focus
      border 1px #ccc solid
</style>