<template>
  <section class="chat-menu">
    <button class="close-menu" @click="$emit('close')">&#10005;</button>
    <article class="requirement-list">
      <header>
        <b>Recherches</b>
      </header>
      <div class="requirement-list_container">
        <template v-for="requirement in requirementsFiltered">
          <router-link tag="div" :class="{'requirement-item_container': true, selected: requirement.id === current.id }" :to="{ name: 'requirement', params: { id: requirement.id }}">
            <requirement-item :requirement="requirement"></requirement-item>
          </router-link>
        </template>
      </div>
    </article>
  </section>
</template>
<script>
import RequirementItem from '/menu/requirement'
import Requirement from '/requirement/entity.js'

export default {
  name: 'hippolyte.menu.requirements',
  props: {
    title: String,
    current: Requirement
  },
  data () {
    return { requirements: this.$requirements }
  },
  components: {
    RequirementItem
  },
  computed: {
    requirementsFiltered () {
      return this.requirements.filter(r => r.conciliations.length > 0)
    }
  }
}
</script>
<style lang="stylus">
@require '~/colors.styl'
$text = $color-white

.checkbox-container
  switch(white, $color-astronaut)

.chat-menu {
  padding-top 1em
  .requirement-list {
    flex-grow: 1;
    height: 100%;
    margin-bottom: 1em;
    overflow-y: auto;
    background-color: $color-white;

    header {
      font-size: 1em;
      margin-left: 1.5rem;
      &:after {
        margin-top: 0.4em;
        content: "";
        display: block;
        width: 20px;
        border: 2px #f48767 solid;
        border-radius: 4px;
      }
      button {
        float right
        margin 0 1.2em
        padding 0
        background none
        border none
        img {
          width 1.2em
        }
        cursor pointer
      }
    }
    .requirement-list_container {
      margin-top: 0.5em;

      .requirement-item_container {
        color $color-menu_text
        /* gradient-border($color-menu_back) */
        padding-right: 4px;
        &:hover {
          background $color-menu_light
          cursor pointer
        }
        &.selected {
          background-color black
          padding-right 0
        }

        .requirement-item_wrapper {
          margin-left 1rem
          padding 0.5em 0
          display flex
          flex-direction row
          align-items center

          .requirement-item_infos {
            /* width 80% */

            .requirement-item_trade {
              font-size: 14px;
            }

            .requirement-item_created_at,
            .requirement-item_city {
              font-weight: bold;
              font-size: 10px;
            }
          }

          .requirement-item_active {
            width 20%
          }
        }
      }
    }
  }
}
</style>
