<template>
  <section class="chat-menu">
    <button class="close-menu" @click="$emit('close')">&#10005;</button>
    <article class="settings-list">
      <header>
        <b>Paramètres</b>
      </header>
      <div class="settings-list_container">
        <router-link tag="div" :class="{'settings-item_container': true, selected: $route.name === 'profile' }" :to="{ name: 'profile' }">
          <div class="settings-item_wrapper">👤 Profil</div>
        </router-link>
        <router-link tag="div" :class="{'settings-item_container': true, selected: $route.name === 'notif' }" :to="{ name: 'notif' }" v-if="notif">
          <div class="settings-item_wrapper">🔔 Notifications</div>
        </router-link>
        <router-link tag="div" :class="{'settings-item_container': true, selected: $route.name === 'notif_history' }" :to="{ name: 'notif_history' }">
          <div class="settings-item_wrapper">📡 Notifs log<div>
        </router-link>
        <router-link tag="div" :class="{'settings-item_container': true, selected: $route.name === 'timeline' }" :to="{ name: 'timeline' }">
          <div class="settings-item_wrapper">🗞️ Timeline<div>
        </router-link>
        <router-link tag="div" :class="{'settings-item_container': true, selected: $route.name === 'snippet' }" :to="{ name: 'snippet' }">
          <div class="settings-item_wrapper">⚡ Messages rapides</div>
        </router-link>
      </div>
    </article>
  </section>
</template>
<script>
import Notif from '/notify'
export default {
  name: 'hippolyte.menu.settings.conciliator',
  data () {
    const s = Notif.support
    return {
      notif: s.sw && s.push && s.notif
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
.chat-menu {
  padding-top 1em
  .settings-list {
    flex-grow: 1;
    height: 100%;
    margin-bottom: 1em;
    header {
      font-size: 1em;
      margin-left: 1.5rem;
      &:after {
        margin-top: 0.4em;
        content: "";
        display: block;
        width: 20px;
        border: 2px $color-yellow solid;
        border-radius: 4px;
      }
    }
    .settings-list_container {
      margin-top: 0.5em;
      .settings-item_container {
        color #404042
        gradient-border($color-menu_back)
        padding-right: 4px;
        &:hover {
          background $color-menu_light
          cursor pointer
        }
        &.selected {
          background $color-menu_light
          padding-right 0
          border-right 4px $color-yellow solid
        }
        .settings-item_wrapper {
          margin-left 1.5rem
          padding 0.5em 0
          display flex
          flex-direction row
          align-items center
        }
      }
    }
  }
}
</style>
