<template>
    <section class="profile-header-light_container" @click="() => $emit('open')" v-if="profile">
      <div class="profile-header_content">
        <avatar
          :anonymous="hideAvatar"
          :src="avatar || custom.avatar"
          size="tiny"
          :prefix="$config.thumbUrl">
        </avatar>
        <div class="profile-header_infos">
          <div class="profile-header_name">
            <b>{{ firstname }} {{ lastname }}</b>
          </div>
          <div class="profile-header_location" v-if="location">
            <img src="/assets/city.png" class="card_picto" /> 
            <span>{{ location}}</span>
          </div>
        </div>
        <div class="profile-header_contact" v-if="interview && interview.tags.length">
          # {{ interview.tags.map(t => t.name).join(' ') }}
        </div>
      </div>
    </section>
</template>
<script>
import Avatar from '/user/avatar'
import Profile from '/user/candidate/candidate.entity.js'
import Interview from '/interview/interview.js'

export default {
  name: 'hippolyte.profile.header.light',
  components: { Avatar },
  props: {
    profile: Profile,
    interview: Interview
  },
  methods: {
    updateProfile () {
      Object.assign(this, this.profile?.marshall())
      this.$forceUpdate()
    }
  },
  watch: {
    profile (val, old) {
      old?.off('update', this.updateProfile, this)
      this.profile?.on('update', this.updateProfile, this)
    }
  },
  data () {
    this.profile?.on('update', this.updateProfile, this)
    return this.profile?.marshall() || {}
  }
}
</script>
<style lang="stylus">
@require '~/colors.styl'
.profile-header-light_container
  background-color $color-profile_back
  color $color-profile_text
  display flex
  width: calc(100% - 80px);
  margin-left: 25px;
  @media (min-width: $breakpoint)
    display none
  flex-direction row
  font-size 1.2em
  justify-content center
  .profile-header_content
    display flex
    flex-direction row
    margin-top 5px
    width 100%
  .profile-header_infos
    margin-left 5px
    display flex
    flex-direction column
    width: 100%;
  .profile-header_location
    font-size 0.85em
    display flex
    flex-direction row
    .card_picto
      max-width 15px
      max-height 15px
      margin: 0 10px 0 0;    
    span
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      max-width: 70%;
  .profile-header_name b  
    max-width: 80%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
</style>
