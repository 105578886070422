<template>
  <candidate-row :interview="interview" :selected="selected" @click="onProfile" :hide="{mail:true,phone:true,sentAt:true}">
    <template v-slot:columns>
      <td>{{ custom.candidatTrade }}</td>
      <td class="btn-group">
        <img :title="`Suggéré${interview.aiData ? ` (scoreAI: ${interview.aiData.aiScore})` :''}`" v-if="interview.isCreated() || interview.isSuggested()" class="interview-status" :style="{ backgroundColor: aiScoreColor }" src="/assets/icon/suggested.png"/>
        <img title="En attente" v-if="interview.isWaitingAcceptation()" class="interview-status" src="/assets/icon/waiting.png"/>
        <img title="Décliné" v-if="interview.isDeclined()" class="interview-status" src="/assets/icon/declined.png"/>
        <img title="Accepté" v-if="interview.isAccepted()" class="interview-status" src="/assets/icon/accepted.png"/>
        <img title="Envoyé" v-if="interview.isSent()" class="interview-status" src="/assets/icon/sent.png"/>
        <img title="Ouvert" v-if="interview.isOpened()" class="interview-status" src="/assets/icon/open.png"/>
        <img title="Commencé" v-if="interview.isStarted()" class="interview-status" src="/assets/icon/started.png"/>
        <img title="Recruté" v-if="interview.isRecruited()" class="interview-status" src="/assets/icon/unicorn_blue.png"/>
        <img title="Archivé" v-if="interview.isArchived()" class="interview-status" src="/assets/icon/archive.png"/>
        <img title="Rejeté" v-if="interview.isRejected()" class="interview-status" src="/assets/icon/reject_red.png"/>
        <img title="Indésirable" v-if="interview.isDiscarded()" class="interview-status" src="/assets/icon/reject_red.png"/>
        <img title="Recaler" v-if="interview.isDoNotSent()" class="interview-status" src="/assets/icon/reject_red.png"/>

        <button v-if="isAllowed('suggest')"
          class="interview-action"
          @click.stop="onSuggest"
          :disabled="isUpdating()"
          title="Suggérer le candidat">
          <loader v-if="interview.isSuggesting()" size="0.8em" :color="color"></loader>
          <img src="/assets/icon/suggested_white.png"/>
        </button>

        <button v-if="isAllowed('undiscard')"
          class="interview-action"
          @click.stop="onUndiscard"
          :disabled="isUpdating()"
          title="Suggérer le candidat">
          <loader v-if="interview.isSuggesting()" size="0.8em" :color="color"></loader>
          <img src="/assets/icon/suggested_white.png"/>
        </button>

        <button v-if="isAllowed('send')"
          class="interview-action"
          @click.stop="onSend"
          :disabled="isUpdating()"
          title="Envoyer au recruteur">
          <loader v-if="interview.isSending()" size="0.8em" :color="color"></loader>
          <img src="/assets/icon/send_white.png"/>
        </button>

        <button v-if="isAllowed('archive')"
          class="interview-action"
          @click.stop="onArchive"
          :disabled="isUpdating()"
          title="Archiver le candidat">
          <loader v-if="interview.isArchiving()" size="0.8em" :color="color"></loader>
          <img src="/assets/icon/archive_white.png"/>
        </button>

        <button v-if="isAllowed('unarchive')"
          class="interview-action"
          @click.stop="onUnarchive"
          :disabled="isUpdating()"
          title="Désarchiver le candidat">
          <loader v-if="interview.isUnarchiving()" size="0.8em" :color="color"></loader>
          <img src="/assets/icon/unarchive_white.png"/>
        </button>

        <button v-if="isAllowed('recruit')"
          class="interview-action"
          @click.stop="onRecruit"
          :disabled="isUpdating()"
          title="Recruter le candidat">
          <loader v-if="interview.isRecruiting()" size="0.8em" :color="color"></loader>
          <img src="/assets/icon/unicorn_white.png"/>
        </button>

        <template v-if="isAllowed('discard')">
          <button
            class="interview-action"
            @click.stop="confirm('discarded')"
            :disabled="isUpdating()"
            title="Rejeter le candidat">
            <loader v-if="interview.isDiscarding()" size="0.8em" :color="color"></loader>
            <img src="/assets/icon/reject_white.png"/>
          </button>

          <sweet-modal class="modal" ref="confirm">
            <confirm
              :status='toStatus'
              :reasons='reasons'
              :conciliation="interview"
              @close="closeConfirm"
            ></confirm>
          </sweet-modal>
        </template>

        <button v-if="isAllowed('reject')"
          class="interview-action"
          @click.stop="confirm('rejected')"
          :disabled="isUpdating()"
          title="Rejeter le candidat">
          <loader v-if="interview.isRejecting()" size="0.8em" :color="color"></loader>
          <img src="/assets/icon/reject_white.png"/>
        </button>

        <button v-if="isAllowed('accept')"
          class="interview-action"
          @click.stop="onAccept"
          :disabled="isUpdating()"
          title="Accepter pour le candidat">
          <loader v-if="interview.isAccepting()" size="0.8em" :color="color"></loader>
          <img src="/assets/icon/accepted_white.png"/>
        </button>

        <button v-if="isAllowed('decline')"
          class="interview-action"
          @click.stop="onDecline"
          :disabled="isUpdating()"
          title="Décliner pour le candidat">
          <loader v-if="interview.isDeclining()" size="0.8em" :color="color"></loader>
          <img src="/assets/icon/declined_white.png"/>
        </button>

        <button v-if="isAllowed('cancel')"
          class="interview-action"
          @click.stop="onCancel"
          :disabled="isUpdating()"
          title="Recaler">
          <loader v-if="interview.isCanceling()" size="0.8em" :color="color"></loader>
          <img src="/assets/icon/reject_white.png"/>
        </button>

      </td>
    </template>
  </candidate-row>
</template>
<script>
import CandidateRow from '/user/candidate/candidate-row'
import Interview from '/interview/interview.js'
import { SweetModal } from 'sweet-modal-vue'
import Loader from 'vue-spinner/src/BounceLoader.vue'
import { Status, allowed } from '/interview/status'
import { EventBus } from '../../event-bus'
import Confirm from '/reasons/confirm'

export default {
  name: 'conciliator.candidates.row',
  components: { CandidateRow, SweetModal, Loader, Confirm },
  props: {
    reasons: Array,
    interview: Interview,
    selected: Boolean,
    color: {
      default: '#ffc857',
      type: String
    }
  },
  data () {
    this.interview.candidate.off('update', this.marshall, this)
    this.interview.candidate.on('update', this.marshall, this)
    return Object.assign(this.interview.candidate.marshall(), {
      toStatus: null,
      reason: null,
      hide: true
    })
  },
  watch: {
    interview (interview, old) {
      old.candidate.off('update', this.marshall, this)
      interview.candidate.on('update', this.marshall, this)
    }
  },
  destroyed () {
    this.interview.candidate.off('update', this.marshall, this)
  },
  methods: {
    closeConfirm () {
      this.$refs.confirm.close()
    },
    confirm (status) {
      this.toStatus = status
      this.$refs.confirm.open()
    },
    filterReasons(status) {
      return this.reasons.filter(r => r.status === status)
    },
    isAllowed (transition) {
      return allowed(this.interview.status, transition, this.$socket.auth)
    },
    marshall () {
      Object.assign(this, this.interview.candidate.marshall())
    },
    isUpdating () {
      return Boolean(this.interview.updating)
    },
    onProfile () {
      this.$router.push({
        name: 'candidates_profile',
        params: {
          search: this.interview.search.id,
          interview: this.interview.id
        },
        query: Object.assign({}, this.$router.currentRoute.query)
      })
    },
    hideProfile () {
      EventBus.$emit("hide-profile", this.hide)
    },
    confirmDiscard () {
      this.$refs.confirmDiscard.open()
    },
    closeConfirmDiscard () {
      this.$refs.confirmDiscard.close()
    },
    async onSend () {
      await this.interview.send()
      this.hideProfile()
    },
    async onSuggest () {
      await this.interview.suggest()
      this.hideProfile()
    },
    async onUnarchive () {
      await this.interview.unarchive()
      this.hideProfile()
    },
    async onArchive () {
      await this.interview.archive()
      this.hideProfile()
    },
    async onDiscard () {
      await this.interview.discard(this.reason)
      this.hideProfile()
    },
    async onReject () {
      await this.interview.reject(this.reason)
      this.hideProfile()
    },
    async onUndiscard () {
      await this.interview.undiscard()
      this.hideProfile()
    },
    async onRecruit () {
      await this.interview.recruit()
      this.hideProfile()
    },
    async onAccept () {
      await this.interview.accept()
      this.hideProfile()
    },
    async onDecline () {
      await this.interview.decline()
      this.hideProfile()
    },
    async onCancel () {
      await this.interview.cancel()
      this.hideProfile()
    }
  },
  computed: {
    conciliatedAt () {
      return this.interview.statuses.find(i => i.status === Status.created).date
    },
    aiScoreColor () {
      const score = this.interview.aiData?.aiScore
      if (score) {
        var r = Math.floor((1 - score) * 255)
        var g = Math.floor(score * 255)
        var b = 0
        return `rgb(${r},${g},${b})`
      } else {
        return ''
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
tr:nth-child(even)
  background alpha(#b8e0dd, 0.3)
tr
  td.candidate_avatar_name
  color #000
    display: flex
    & *
      margin: auto 0
    span
      margin-left: 5px
      .candidate_name
        cursor pointer
        font-size 1.1em
      .candidate_email
        font-size 0.9em
    .avatar_cl
      cursor pointer
  td
    color: #404042
    padding: 5px 10px
    &.candidate_actions
      padding: 0
    button
      margin 0
      padding 0
      background none
      border none
      cursor pointer
      img
        &.action-contact
          height 30px
        &.action-reject, &.action-archive, &.action-unarchive
          height 20px
    a
      color: $color-astronaut
      text-decoration: none
  td.btn-group
    display flex
    .interview-action
      submiter()
      padding 0.5em 0.5em 0.4em 0.6em
      border-radius 0
      &:first-of-type
        border-radius 1em 0 0 1em
      &:last-of-type
        border-radius 0 1em 1em 0
      img
        max-width 20px
        max-height 20px
    svg.interview-status
      color $color-grey
  border-right 6px transparent solid
  &.selected
    border-right 6px #45ADA7 solid
    background alpha($color-astronaut, 0.2)
.interview-status
  border-radius 50%
  padding 5px
  max-width 25px
  max-height 25px
  margin: auto 15px;
</style>
