import Entity from '/entity'
import { Solr, or, and, eq, search } from '/solr'
const cond = k => `YesWeChat\\ServiceEntityBundle\\Query\\Condition\\${k}`
const cache = new Map()

class Trade extends Entity {
  static async list (opts, socket, cancel) {
    const req = new Solr({
      entity: 'Trade',
      raw: true,
      limit: 10,
      query: search({ insensitive: true }, 'global_search', opts.query)
    })
    return await socket.service('entity_solr/QUERY', req.send(), cancel )
  }

  static async search (field, value, socket) {
    let list = await socket.service(`entity.Trade/QUERY`,{
      alias: 't',
      class: 'Trade',
      limit: 10,
      parameters: [
        { type: cond('Parameter'), name: field, value: `%${value.toLowerCase()}%`  }
      ],
      conditions: [
        {
          type: cond('Like'),
          value: field,
          subject: {
            type: cond('Field'),
            name: `LOWER(t.${field})`
          }
        }
      ]
    })
    return list.map(l => new Trade(l, socket))
  }
  async load () {
    if (cache.has(this.id)) {
      this.setData(cache.get(this.id))
    } else {
      await super.load()

      cache.set(this.id, this.marshall())
    }
    return this
  }

  static create (data) {
    return new Trade(data)
  }
}

Trade.prototype.entityClass = 'Trade'
Trade.prototype.entityFields = ['id', 'name']

export default Trade
