<template>
  <div class="room-item_name">{{ lastname }} <span>{{ firstname | firstLetter }}</span></div>
</template>
<script>
import Room from 'minou/src/room'
import Candidate from '/user/candidate/candidate.entity'
export default {
  name: 'hippolyte.menu.chat.room.title',
  props: {
    room: Room,
    profile: Candidate
  },
  data () {
    this.profile.on('update', this.update, this)
    return this.profile.marshall()
  },
  methods: {
    update (profile) {
      Object.assign(this, profile.marshall())
    }
  },
  destroy () {
    this.profile.off('update', this.update, this)
  },
  filters: {
    firstLetter: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.substring(0, 1) + '.'
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
.candidate_search-tags__container {
  min-height: 25px;
}
.container
  .app-header
    width 100%
  .menu_items
    margin-top 0.5em
    display flex
    flex-direction column
</style>
