<template>
  <div class="dashboard">
    <section class="campaigns">
      <header>
        <div class="title">
          <h4>Campagnes</h4>
          <div class="checkbox-container" title="Campagnes en cours uniquement">
            <input type="checkbox" v-model="running" :id="'running'" />
            <label :for="'running'"></label>
          </div>
          <div class="search">
            <input type="text" v-model="query" class="recruiter-search-input"/>
          </div>
        </div>
        <div class="btn-container">
          <paginate :count="count" v-model="offset" :limit="limit"></paginate>
          <csv
            :runningCampaign="running"
            :query="query"
            :filter="filter"
          ></csv>
          <select v-model="filter" class="select">
            <option disabled value="">Filtrer par:</option>
            <template v-for="(text, filter) in filterList">
              <option :value="filter" :key="filter">{{ filter }}</option>
            </template>
          </select>
          <select v-model="sortField" class="select">
            <option disabled value="">Trier par:</option>
            <template v-for="(label,i) in Object.keys(sortFieldList)">
              <option :value="label" :key="label+i">{{ label }}</option>
            </template>
          </select>
        </div>
      </header>
      <spinner style="transform: scale(0.2, 0.2);" v-if="loading"></spinner>
      <div class="campaigns_container" v-else>
        <section>
          <div v-for="campaign in campaigns" @click="selected = selected === campaign ? null : campaign" :key="campaign.id">
            <campaign-item :campaign="campaign" :selected="selected"></campaign-item>
          </div>
        </section>
      </div>
      <footer class="paginate">
      </footer>
    </section>
  </div>
</template>

<script>
import CampaignItem from '/campaign/campaignItem'
import Campaign from '/campaign/entity'
import Spinner from '/layout/spinner'
import Paginate from '/layout/paginate'
import Csv from '/campaign/csv'
import cancel from '/cancel.mixin.js'
import { Solr, eq, and, or } from '/solr'
import { filterList, sortFieldList } from '/campaign/filterArray'

// @todo paginate
export default {
  name: 'hippolyte.campagne.list',
  components: {
    Spinner,
    Csv,
    Paginate,
    CampaignItem
  },
  mixins: [cancel],
  data () {
    return {
      selected: null,
      campaigns: [],
      query: '',
      running: true,
      loading: false,
      count: 0,
      offset: 0,
      limit: 20,
      sortField: '',
      sortFieldList,
      filter: ''
    }
  },
  mounted () {
    this.load()
  },
  watch: {
    filter: 'reload',
    sortField: 'reload',
    query: 'reload',
    running: 'reload',
    offset: 'load',
    selected (campaign) {
      this.cancel('expenses')
      if (campaign && campaign.expenses.numFound > 0) {
        Promise.all(campaign.expenses.docs.map(async expense => {
          const req = new Solr({
            entity: 'Conciliation',
            raw: true,
            query: and(
              or(...campaign.searches.docs.map(s => eq('search', s.id))),
              `{!join entity=Candidate v="custom_field_onboard_source:@expense"}`
            ),
            limit: 0
          })
          req.parameter('expense', expense.type)
          const res = await this.$socket.service('entity_solr/QUERY', req.send(), { cancel: this.token('expenses') })
          expense.count = res.numFound
        })).then(
          () => this.cancel('expenses', null),
          this.handleCancel.bind(this)
        )
      }
    }
  },
  computed: {
    filterList: function () {
      if (this.filter) {
        return filterList
      } else {
        // hide "tout voir" when no filter
        return Object.keys(filterList).reduce((object, key) => {
          if (filterList[key] !== null) {
            object[key] = filterList[key]
          }
          return object
        }, {})
      }
    }
  },
  methods: {
    async load () {
      try {
        this.cancel('campaigns')
        this.loading = true
        let opts = {
          running: this.running,
          limit: this.limit,
          offset: this.offset,
        }
        if (this.query) {
          opts.q = this.query
        }
        if (this.filterList[this.filter]) {
          opts.filter = this.filterList[this.filter]
        }
        if (this.sortField) {
          opts.sorts = this.sortFieldList[this.sortField]
        }
        const { docs, numFound } = await Campaign.api(this.$socket)
          .list(opts, this.token('campaigns'))
          .then(response => {
            if (opts.count) {
              return response.data.numFound
            }
            return {
              numFound: response.data.numFound,
              docs: response.data.docs.map(c => {
                return Campaign.create(c)
              })
            }
          })
        this.campaigns.splice(0, this.campaigns.length, ...docs)
        this.count = numFound
        this.cancel('campaigns', null)
      } catch (err) {
        this.handleCancel(err)
      }
      this.loading = false
    },
    reload () {
      const was = this.offset
      this.offset = 0
      this.count = 0
      if (was === 0) {
        this.load()
      }
    },
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
.v2-picker-panel-wrap
  z-index 9999
.dashboard
  display flex
  height 100%
  overflow-y auto
.campaigns
  header
    font-weight bold
    padding 0 1em
  .campaigns_container
    margin-bottom 3em
  .title
    display flex
  .btn-container
    display flex
    align-items center
  .checkbox-container
    switch($color-green, white)
    margin 1.4em 2em
    align-items center
  .search
    position relative
    input
      margin 0.5em 0 1em 1em
      width -webkit-fill-available
      padding-left 0.5em
      height 2em
      background alpha(#d5e4ef, 0.5) right 50% / 13% no-repeat url('/assets/icon/search.svg')
      color #404042
      font-size 1.2em
  .select
    justify-self end
    align-self center
    border 0
    border-bottom 2px solid #000
    padding 5px
    background transparent
    margin 0 10px
    option
      overflow hidden
      text-overflow ellipsis
</style>
