<script>
import { formatDate } from '/layout/date'

export default {
  name: 'hippolyte.profile.item',
  props: ['value', 'field'],
  render: function (createElement) {
    if (!this.value) {
      return null
    }
    let html
    if (this.field.type === 'link') {
      html = `<a href="${this.value}" target="_blank">&#128279;</a>`
      if (this.field.label) {
        return createElement(
          'tr',
          { class: 'profile_item' },
          [
            createElement('td', { class: 'profile-item_name' }, this.field.label),
            createElement('td', { class: 'profile-item_value', domProps: { innerHTML: html } })
          ]
        )
      } else {
        return createElement('p', { class: 'profile-item_field'}, html)
      }
    }
    if (this.field.type === 'audio') {
      html = `<audio src="${this.value}" controls></audio>`
      if (this.field.label) {
        return createElement(
          'tr',
          { class: 'profile_item' },
          [
            createElement('td', { class: 'profile-item_name' }, this.field.label),
            createElement('td', { class: 'profile-item_value', domProps: { innerHTML: html } })
          ]
        )
      } else {
        return createElement('p', { class: 'profile-item_field'}, html)
      }
    }
    else if (this.field.type === 'date') {
      html = formatDate(this.value, this.field.format)
    }
    else if (this.field.type === 'bool') {
      html = this.value ? 'oui' : 'non'
    } else if (typeof this.value === 'string') {
      let lines = this.value.split(/\r\n|\r|\n/)
      if (lines.lenght === 1) {
        html = this.value
      } else {
        html = lines.reduce((acc, line, i) => {
          acc.push(line)
          if (i !== lines.length - 1) {
            acc.push(createElement('br'))
          }
          return acc
        }, [])
      }
    } else {
      html = this.value
    }
    if (this.field.label) {
      return createElement(
        'tr',
        { class: 'profile_item' },
        [
          createElement('td', { class: 'profile-item_name' }, this.field.label),
          createElement('td', { class: 'profile-item_value' }, html)
        ]
      )
    }
    return createElement('p', { class: 'profile-item_field' }, html)
  }
}
</script>
<style lang="stylus">
@require '~/colors.styl'
$r = 5px
.profile_item
    margin-top 1.2em
    color $color-profile_second_invert
    font-size 0.8em
    display block
    > *
        padding 0.1em 0.3em
    @media (min-width $breakpoint)
        margin-top 1.5em
    .profile-item_name
        background-color $color-profile_second
        border-radius $r 0 0 $r
        vertical-align: middle
    .profile-item_value
        border-radius 0 $r $r 0
        background-color $color-white
        color $color-profile_text
        border 1px $color-profile_second solid
        a
            text-decoration none
        audio
          display block
          height 2em
          width 250px
</style>
