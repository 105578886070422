import Entity from '/entity'
import Trade from '/trade/entity'
import Location from '/location/entity'
import { Solr, or, and, eq, search, insensitiveSearch } from '/solr'

const service = verb => `entity.Search/${verb}`
const cond = k => `YesWeChat\\ServiceEntityBundle\\Query\\Condition\\${k}`

class Requirement extends Entity {
  constructor (data, socket) {
    super(data, socket)
    this.conciliations = []
  }

  destroy () {
    this.removeAllListeners()
  }

  setData (data) {
    if (data === null || typeof data !== 'object') {
      return this
    }
    let surcharge = {}

    if (data.location && !(data.location instanceof Location)) {
      surcharge.location = new Location(data.location, this.socket)
    }

    if (data.trade && !(data.trade instanceof Trade)) {
      surcharge.trade = new Trade(data.trade, this.socket)
    }
    if (data.createdAt && !(data.createdAt instanceof Date)) {
      surcharge.createdAt = new Date(data.createdAt)
    }
    return super.setData(Object.assign({}, data, surcharge))
  }

  async loadNestedEntities() {
    await Promise.all([
      (this.location && !this.location.name) ? this.location.load() : true,
      (this.trade && !this.trade.name) ? this.trade.load() : true
    ])
    this.emit('update')

    return this
  }

  async loadById(id, socket) {
    const search = await socket.service('entity.Search/QUERY', {
      alias: 's',
      class: 'Search',
      parameters: [
        {
          type: cond('Parameter'),
          name: 'id',
          value: id
        }
      ],
      conditions: [
        {
          type: cond('In'),
          value: 'id',
          subject: {
            type: cond('Field'),
            name: 's.id'
          }
        }
      ]
    })
    const searchResult = this.setData(search)
    return new Requirement(searchResult, socket)
  }

  static create (data) {
    return new Requirement(data)
  }
}

Requirement.prototype.entityClass = 'Search'
Requirement.prototype.entityFields = ['id', 'location', 'trade', 'recruiter', 'createdAt', 'contracts', 'active', 'file']

Requirement.list = async function (opts, socket) {
  if (opts.solr) {
    const req = new Solr(Object.assign({
      entity: 'Search',
      raw: true
    }, { limit: opts.limit, offset: opts.offset }))

    if (opts.recruiter) {
      req.query.push(eq('recruiter', opts.recruiter))
    }

    req.relation({
      entity: 'Trade',
      name: 'trade'
    }, {
      entity: 'Location',
      fromField: 'location',
      toField: 'id',
      name: 'location'
    })
    const active = { true: 'true', false: 'false' }[opts.active]
    if (active) {
      req.query.push(eq('active', active))
    }
    if (opts.status && Array.isArray(opts.status)) {
      opts.status.map(status => {
        req.parameter(status)
        req.relation({
          entity: 'Conciliation',
          name: 'conciliation.' + status,
          fromField: 'id',
          toField: 'search',
          query: `{!join entity=ConciliationStatus v="current:true AND status:@${status}"}`,
          limit: 0
        })
      })
    }
    return socket.service('entity_solr/QUERY', req.send())
  }
  const args = {
    alias: 's',
    class: 'Search',
    parameters: [],
    conditions: []
  }
  if ('limit' in opts) {
    args.limit = opts.limit
  }
  if ('offset' in opts) {
    args.offset = opts.offset
  }
  if (opts.recruiter) {
    args.parameters.push({
      type: cond('Parameter'),
      name: 'recruiter',
      value: opts.recruiter.id
    })
    args.conditions.push({
      type: cond('Equals'),
      value: 'recruiter',
      subject: {
        type: cond('Field'),
        name: 's.recruiter'
      }
    })
  }
  if ('active' in opts) {
    args.parameters.push({
      type: cond('Parameter'),
      name: 'active',
      value: opts.active
    })
    args.conditions.push({
      type: cond('Equals'),
      value: 'active',
      subject: {
        type: cond('Field'),
        name: 's.active'
      }
    })
  }
  const list = await socket.service(`entity.Search/QUERY`, args)
  return list.map(l => new Requirement(l, socket))
}

Requirement.loadIds = async function (searches, socket, cancel) {
  if (searches.length < 1) {
    return
  }
  let batch = Promise.resolve()
  const chunk = 10
  for (let i = 0; i < searches.length; i += chunk) {
    const { promise, run } = loadBatch(searches.slice(i, i + chunk), socket, cancel)
    batch.then(run)
    batch = promise
  }
  return batch
}
function loadBatch (searches, socket, cancel) {
  let run
  let promise = new Promise(function (resolve, reject) {
    run = async function () {
      try {
        let list = await socket.service('entity.Search/QUERY', {
          alias: 's',
          class: 'Search',
          parameters: [
            { type: cond('Parameter'), name: 'id', value: searches.map(c => c.id) }
          ],
          conditions: [
            {
              type: cond('In'),
              value: 'id',
              subject: {
                type: cond('Field'),
                name: 's.id'
              }
            }
          ]
        }, { cancel })
        list.forEach(d => {
          searches
            .filter(s => d.id === s.id)
            .map(s2 => {
              s2.setData(d)
              s2.loading = false
            })
        })
        resolve(searches)
      } catch (err) {
        reject(err)
        searches.forEach(c => {
          c.loading = false
        })
      }
    }
  })
  searches.forEach(c => {
    c.loading = promise.then(() => c)
  })
  return { promise, run }
}

Requirement.countStatus = async function (opts = {}, socket, cancel) {
  const req = new Solr(Object.assign({
    entity: 'Search',
    raw: true
  }, { limit: opts.limit, offset: opts.offset }))

  if (opts.recruiter) {
    req.query.push(eq('recruiter', opts.recruiter))
  }

  req.relation({
    entity: 'Trade',
    name: 'trade'
  }, {
    entity: 'Location',
    fromField: 'location',
    toField: 'id',
    name: 'location'
  })
  const active = { true: 'true', false: 'false' }[opts.active]
  if (active) {
    req.query.push(eq('active', active))
  }
  if (opts.status && Array.isArray(opts.status)) {
    let query = or()
    opts.status.forEach(status => {
      req.parameter(status)
      req.relation({
        entity: 'Conciliation',
        name: 'conciliation.' + status,
        fromField: 'id',
        toField: 'search',
        query: `{!join entity=ConciliationStatus v="current:true AND status:@${status}"}`,
        limit: 0
      })
      if (opts.hotOnly) {
        query.push(`({!join entity=ConciliationStatus v="current:true AND status:@${status}"})`)
      }
    })
    if (opts.hotOnly) {
      req.join({
        entity: 'Conciliation',
        fromField: 'id',
        toField: 'search',
        query
      })
    }
  }
  req.sorts('createdAt')
  return socket.service('entity_solr/QUERY', req.send(), { cancel })
}

export default Requirement
