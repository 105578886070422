import '/version.js'
import Vue from 'vue'
import VueRouter from 'vue-router'
import App from '/layout/app.vue'
import Layout from '/layout/app.conciliator.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import './icons'
import Login from '/session/login.vue'
import Context from '/user/conciliator/context'
import Toolbar from '/menu/toolbar'
import Menu from '/menu/chat.conciliator.vue'
import config from 'minou/src/config'
import MenuHeader from '/menu/header'
import Candidates from '/user/conciliator/candidates'
import Requirement from '/requirement/entity'
import Requirements from '/menu/requirements'
import RequirementForm from '/requirement/form'
import Chat from '/chat/interview'
import Profile from '/profile/profile.vue'
import Filters from '/filter'
import { Status } from '/interview/status'
import VueToast from '/layout/notify.plugin'
import ModalConfirm from '/layout/confirm.plugin'
import Settings from '/menu/conciliator.settings'
import ProfileForm from '/user/recruiter/form'
import NotifyForm from '/notify/form'
import SearchList from '/menu/candidates_searches'
import Interview from '/interview/interview.js'
import Candidate from '/user/candidate/candidate.entity'
import Recruiter from '/user/recruiter/recruiter.entity'
import Reporting from '/menu/searches_paginate'
import Graphs from '/reporting/graphs'
import { formatDate } from '/layout/date'
import 'vue-googlemaps/dist/vue-googlemaps.css'
import VueGoogleMaps from 'vue-googlemaps'
import 'v2-datepicker/lib/index.css'
import V2Datepicker from 'v2-datepicker'
import Location from '/location/entity'
import Trade from '/trade/entity'
import NotificationHisory from '/notify/history'
import ConfigAts from '/ats/config'
import Campaigns from '/campaign/dashboard'
import endOfDay from 'date-fns/endOfDay'
import SnippetEditor from '/snippet/editor'
import Timeline from '/timeline/list'
import Donuts from '/reporting/donuts'
import StatsSourcing from '/reporting/sourcing'
import StatsDashboard from '/reporting/dashboard'
import byOrigins from '/reporting/byOrigins'
import byDistribution from '/reporting/byDistribution'
import ScreenAI from "/reporting/screenAi"
import sourceEvolving from '/reporting/sourceEvolving'
import tables from '/reporting/tables'

const searchListFiltersDefault = Object.freeze({
  hotOnly: true,
  active: true,
  closed: false,
  archived: false,
  match: ''
})
function extractSearchListFilters (query) {
  const b = { 'true': true, 'false': false}
  return {
    hotOnly: b[query.hotOnly] ?? searchListFiltersDefault.hotOnly,
    active: b[query.active] ?? searchListFiltersDefault.active,
    archived: b[query.archived] ?? searchListFiltersDefault.archived,
    closed: b[query.closed] ?? searchListFiltersDefault.closed,
    match: query.match ?? searchListFiltersDefault.match
  }
}


let context

async function run () {
  context.inject(Vue.prototype)
  Vue.use(ModalConfirm)
  Vue.use(VueToast)
  Vue.use(VueGoogleMaps, {
    load: {
      apiKey: context.config.GMAP_API_KEY
    },
  })
  Vue.component('font-awesome-icon', FontAwesomeIcon)
  Vue.filter('format', formatDate)
  Vue.use(V2Datepicker)
  const filters =  new Filters({
    states: [
      Status.suggested
    ]
  })
  const routes = [
    { path: '/login', component: Login, name: 'login', meta: { loaded: false } },
    {
      path: '/dashboard',
      alias: '/',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'dashboard',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            interview: Campaigns
          },
          props: {
            toolbar: {
              account: context.account
            }
          }
        }
      ]
    },
    {
      path: '/chat',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'chat',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            menu: Menu,
            interview: Chat,
            profile: Profile
          },
          props: {
            toolbar: {
              account: context.account
            },
            interview: function (route) {
              const room = context.chat.current

              return {
                room,
                profile: room && context.profiles.find(room.id.split('/').pop())
              }
            },
            profile: function () {
              const room = context.chat.current
              return {
                profile: room && context.profiles.find(room.id.split('/').pop())
              }
            }
          }
        }
      ]
    },
    {
      path: '/candidates/:search?',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'candidate_list',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            menu: SearchList,
            interview: Candidates
          },
          props: {
            toolbar: {
              account: context.account
            },
            menu: function (route) {
              const filters = extractSearchListFilters(route.query)
              context.loadRecruiters(filters)
              return {
                recruiterId: route.query.recruiter,
                searchId: route.params.search ,
                filters
              }
            },
            interview: function (route) {
              const search = route.params.search
              if (route.query.states) {
                filters.setData({
                  states: route.query.states.split(',').map(s => Status[s])
                })
              }
              let s
              if (search) {
                s = new Requirement({ id: route.params.search }, context.socket)
                const req = s.load()
                filters.requirements.splice(0, filters.requirements.length, search)
              }
              filters.setData(extractSearchListFilters(route.query))

              const recruiter = route.query.recruiter ? new Recruiter({ id: route.query.recruiter }, context.socket) : null
              return { search: s, filters, recruiter }
            }
          }
        }
      ]
    },
    {
      path: '/candidates/:search/:interview',
      component: Layout,
      meta: { loaded: true, chat: true },
      children: [
        {
          path: '',
          meta: { loaded: true, chat: true },
          name: 'candidates_profile',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            menu: SearchList,
            interview: Candidates,
            profile: Profile
          },
          props: {
            toolbar: {
              account: context.account
            },
            menu: function (route) {
              const filters = extractSearchListFilters(route.query)
              context.loadRecruiters(filters)
              return {
                recruiterId: route.query.recruiter,
                searchId: route.params.search ,
                filters
              }
            },
            interview: function (route) {
              const search = route.params.search
              if (route.query.states) {
                filters.setData({
                  ...extractSearchListFilters(route.query),
                  states: route.query.states.split(',').map(s => Status[s])
                })
              }
              const s = new Requirement({ id: route.params.search }, context.socket)
              s.load()
              filters.requirements.splice(0, filters.requirements.length, search)
              return { search: s, filters }
            },
            profile: function (route) {
              const interview = new Interview({ id: route.params.interview, candidate: new Candidate(context.socket) }, context.socket)
              const profile = new Candidate({}, context.socket)
              const search = route.params.search
              if (route.query.states) {
                filters.setData({
                  states: route.query.states.split(',').map(s => Status[s])
                })
              }
              const s = new Requirement({ id: route.params.search }, context.socket)
              s.load()
              interview.load().then(() => {
                profile.setData({ id: interview.candidate.id })
                interview.candidate.setData(profile)
                profile.load()
              })
              return { interview, profile, requirement: search }
            }
          }
        }
      ]
    },
    {
      path: '/requirements/new',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'requirement_add',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            menu: SearchList,
            interview: RequirementForm
          },
          props: {
            toolbar: {
              account: context.account
            },
            menu: {
              selected: [], filters
            },
            interview: function () {
              const value = new Requirement({
                  location: new Location(),
                  trade: new Trade()
                }, context.socket)
              value.on('saved', () => {
                router.push({
                  name: 'candidate_list',
                  params: {
                    search: value.id
                  }
                })
              })
              return { value, filters }
            }
          }
        }
      ]
    },
    {
      path: '/requirements/:search',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'requirement_edit',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            menu: SearchList,
            interview: RequirementForm
          },
          props: {
            toolbar: {
              account: context.account
            },
            menu: function (route) {
              return {
                selected: [context.requirements.find(c => c.id === route.params.search)],
                filters
              }
            },
            interview: function (route) {
              let search = context.requirements.find(r => r.id === route.params.search)
              if (!search) {
                search = new Requirement({ id: route.params.search }, context.socket)
              }
              return { value: search, filters }
            }
          }
        }
      ]
    },
    {
      path: '/settings/profile',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'profile',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            menu: Settings,
            interview: ProfileForm
          },
          props: {
            toolbar: {
              account: context.account
            },
            interview: {
              value: context.recruiter
            }
          }
        }
      ]
    },
    {
      path: '/settings/notification',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'notif',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            menu: Settings,
            interview: NotifyForm
          },
          props: {
            toolbar: {
              account: context.account
            },
            interview: {
              account: context.account
            }
          }
        }
      ]
    },
    {
      path: '/settings/history',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'notif_history',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            menu: Settings,
            interview: NotificationHisory
          },
          props: {
            toolbar: {
              account: context.account
            }
          }
        }
      ]
    },
    {
      path: '/settings/snippet',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'snippet',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            menu: Settings,
            interview: SnippetEditor
          },
          props: {
            toolbar: {
              account: context.account
            }
          }
        }
      ]
    },
    {
      path: '/settings/timeline',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'timeline',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            menu: Settings,
            interview: Timeline
          },
          props: {
            toolbar: {
              account: context.account
            },
            interview (route) {
              return {
                query: route.query
              }
            }
          }
        }
      ]
    },{
      path: '/reporting/:graph?',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'reporting',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            menu: Reporting,
            interview: Graphs
          },
          props: {
            toolbar: {
              account: context.account
            },
            menu: function (route) {
              return {
                recruiter: null,
                q: route.query.q,
                a: { 'true': true, 'false': false }[route.query.a],
                o: Number(route.query.o),
                graph: route.params.graph || 'reporting_dashboard'
              }
            },
            interview: function (route) {
              return {
                recruiter: null,
                a: { 'true': true, 'false': false }[route.query.a],
                start: route.query.start && new Date(route.query.start),
                end: route.query.end && new Date(route.query.end),
                graph: route.params.graph || 'reporting_dashboard'
              }
            }
          },
          children: [{
            path: '',
            name: 'reporting_dashboard',
            component: StatsDashboard,
          },{
            path: '',
            name: 'donuts',
            component: Donuts,
          },{
            path: '',
            name: 'sourcing',
            component: StatsSourcing,
          },{
            path:'',
            name: 'evolving',
            component: sourceEvolving
          },{
            path:'',
            name: 'origin',
            component: byOrigins
          },{
            path:'',
            name: 'distribution',
            component: byDistribution
          },{
            path:'',
            name: 'tables',
            component: tables
          },
          {
            path:'',
            name: 'screen_ai',
            component: ScreenAI
          }]
        }
      ]
    },{
      path: '/reporting/:graph/recruiter/:recruiter',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'reporting_recruiter',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            menu: Reporting,
            interview: Graphs
          },
          props: {
            toolbar: {
              account: context.account
            },
            menu: function (route) {
              let recruiter
              if (route.params.recruiter) {
                recruiter = context.recruiters.find(c => c.id === route.params.recruiter) ||  Recruiter.create({ id : route.params.recruiter })
              }
              return {
                recruiter,
                q: route.query.q,
                a: { 'true': true, 'false': false }[route.query.a],
                o: Number(route.query.o),
                graph: route.params.graph || 'reporting_dashboard'
              }
            },
            interview: function (route) {
              let recruiter
              if (route.params.recruiter) {
                recruiter = context.recruiters.find(c => c.id === route.params.recruiter) ||  Recruiter.create({ id : route.params.recruiter })
              }
              return {
                recruiter,
                a: { 'true': true, 'false': false }[route.query.a],
                start: route.query.start && new Date(route.query.start),
                end: route.query.end && new Date(route.query.end),
                graph: route.params.graph || 'reporting_dashboard'
              }
            }
          },
          children: [{
            path: '',
            name: 'recruiter_reporting_dashboard',
            component: StatsDashboard,
          },{
            path: '',
            name: 'recruiter_donuts',
            component: Donuts,
          },{
            path: '',
            name: 'recruiter_sourcing',
            component: StatsSourcing,
          },{
            path:'',
            name: 'recruiter_evolving',
            component: sourceEvolving
          },{
            path:'',
            name: 'recruiter_origin',
            component: byOrigins
          },{
            path:'',
            name: 'recruiter_distribution',
            component: byDistribution
          },{
            path:'',
            name: 'recruiter_tables',
            component: tables
          },
          {
            path:'',
            name: 'recruiter_screen_ai',
            component: ScreenAI
          }]
        }
      ]
    },{
      path: '/reporting/:graph/search/:search',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'reporting_search',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            menu: Reporting,
            interview: Graphs
          },
          props: {
            toolbar: {
              account: context.account
            },
            menu: function (route) {
              return {
                recruiterId: route.query.recruiter,
                search: route.params.search,
                q: route.query.q,
                a: { 'true': true, 'false': false }[route.query.a],
                o: Number(route.query.o),
                graph: route.params.graph || 'reporting_dashboard'
              }
            },
            interview: function (route) {
              let search = null
              let recruiter = null
              if (route.params.search) {
                search = new Requirement({ id: route.params.search }, context.socket)
                recruiter = new Recruiter({}, context.socket)
                search.load().then(() => {
                  recruiter.setData(search.recruiter)
                })
              }
              return {
                search,
                recruiter,
                a: { 'true': true, 'false': false }[route.query.a],
                start: route.query.start && new Date(route.query.start),
                end: route.query.end && endOfDay(new Date(route.query.end)),
                graph: route.params.graph || 'reporting_dashboard'
              }
            }
          },
          children: [{
            path: '',
            name: 'search_reporting_dashboard',
            component: StatsDashboard,
          },{
            path: '',
            name: 'search_donuts',
            component: Donuts,
          },{
            path: '',
            name: 'search_sourcing',
            component: StatsSourcing,
          },{
            path:'',
            name: 'search_evolving',
            component: sourceEvolving
          },{
            path:'',
            name: 'search_origin',
            component: byOrigins
          },{
            path:'',
            name: 'search_distribution',
            component: byDistribution
          },{
            path:'',
            name: 'search_tables',
            component: tables
          },
          {
            path:'',
            name: 'search_screen_ai',
            component: ScreenAI
          }]
        }
      ]
    },
    {
      path: '/ats/:recruiter?',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'ats_home',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            interview: ConfigAts
          },
          props: {
            toolbar: {
              account: context.account
            },
            interview: function (route) {
              return route.params.recruiter && { recruiter: Recruiter.create({ id: route.params.recruiter }, context.socket) }
            }
          }
        }
      ]
    },
    {
      path: '/recruiter/:recruiter/ats/:ats?',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'ats',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            interview: ConfigAts
          },
          props: {
            toolbar: {
              account: context.account
            },
            interview: function (route) {
              return {
                recruiter: Recruiter.create({ id: route.params.recruiter }, context.socket),
                ats: route.params.ats,
                query: {
                  offset: typeof route.query.offset === 'string' && parseInt(route.query.offset),
                  limit: route.query.limit,
                  search: new Requirement({ id: route.query.search }),
                  query: route.query.query
                }
              }
            }
          }
        }
      ]
    },
    {
      path: '/:id',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'interview_id',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            menu: Menu,
            interview: Chat,
            profile: Profile
          },
          props: {
            toolbar: {
              account: context.account
            },
            interview: function (route) {
              const interview = context.interviews.find(i => route.params.id === i.id)
              context.selectInterview(interview)
              const room = context.chat.current
              function onRemoveCurrent (removed) {
                if (room === removed) {
                  router.push({
                    name: 'chat'
                  })
                }
              }
              routeListeners.push(function () {
                context.chat.off('room:remove', onRemoveCurrent)
              })
              context.chat.on('room:remove', onRemoveCurrent)
              return {
                interview,
                room: context.chat.current,
                profile: context.profiles.load(interview.candidate.id)
              }
            },
            profile: function (route) {
              const interview = context.interviews.find(i => route.params.id === i.id)
              context.selectInterview(interview)
              let requirement
              if (interview?.search) {
                requirement = context.requirements.find(r => r.id === interview.search)
              }
              return {
                interview,
                profile: context.profiles.load(interview.candidate.id),
                requirement
              }
            }
          },
          async beforeEnter (route, from, next) {
            let interview = context.interviews.find(i => route.params.id === i.id)
            if (interview && interview.isArchived()) {
              function onAdded (room) {
                if (room && room.id === interview.roomId()) {
                  context.chat.off('room:update', onAdded)
                  next()
                }
              }
              try {
                await interview.start()
                context.chat.on('room:update', onAdded)
              } catch (err) {
                next(err)
              }
            } else if (interview && interview.isStarted()) {
              Object.assign(context.show, {
                menu: false,
                interview: true,
                profile: false
              })
              next()
            } else if (interview && !interview.isStarted()) {
              Object.assign(context.show, {
                menu: false,
                interview: false,
                profile: true
              })
              next()
            } else {
              next()
            }
          }
        }
      ]
    },
    {
      path: '/:recruiter/:candidate',
      component: Layout,
      meta: { loaded: true },
      children: [
        {
          path: '',
          name: 'interview',
          components: {
            header: MenuHeader,
            toolbar: Toolbar,
            menu: Menu,
            interview: Chat,
            profile: Profile
          },
          props: {
            toolbar: {
              account: context.account
            },
            interview: function (route) {
              let room = context.chat.rooms.find(r => r.id?.split('/').pop() === route.params.candidate)
              if (context.chat.current !== room && room.id) {
                context.chat.join(room)
              }
              return {
                room,
                profile: context.profiles.find(route.params.candidate)
              }
            },
            profile: function (route) {
              return {
                profile: context.profiles.find(route.params.candidate)
              }
            }
          }
        }
      ]
    },
    { path: '/:recruiter/:candidate/candidates', component: Layout, props: { mode: 'candidates' } },
  ]

  if (process.env.NODE_ENV !== 'production') {
    console.log('should not be visible in prod env')
    const { default: Colors } = require('/layout/colors')
    routes.unshift({ path: '/colors', component: Colors, meta: { loaded: false } })
  }

  const router = new VueRouter({ routes })

  router.beforeEach(async function (to, from, next) {
    if (!context.loaded && to.matched.some(record => record.meta.loaded)) {
      await context.load()
    }
    next()
  })

  router.afterEach(function () {
    routeListeners.forEach(fn => fn())
    routeListeners.splice(0, routeListeners.length)
  })

  function firstChatInterview () {
    return context.interviews.find(i => i.isOpened() || i.isStarted())
  }

  const routeListeners = []

  filters.setData({
    tags: context.interviews
      .reduce((acc, i) => {
        acc.push(...i.tags.filter(tag => !acc.find(t => t.id === tag.id)))
        return acc
      }, [])
      .map(tag => Object.assign({ value: false }, tag))
    })

  Vue.use(VueRouter)
  new Vue({
    el: '#app',
    router,
    render: h => h(App)
  })
}

config
  .load('config')
  .then( data => {
    context = new Context(data)
    window.ywcontext = context
    return context.load()
  })
  .then(run)
