<template>
  <section v-if="show && openable">
<!-- faire un header avec le nom du candidat et un bouton de fermeture -->
    <div class="action-items">
      <button class="room-item_reduce"  @click.stop.prevent="$emit('reduce')">-</button>
      <button class="room-item_close"  @click.stop.prevent="$emit('close', room)">✖</button>
    </div>
    <header>
      {{`${profile.firstname} ${profile.lastname}` }}
    </header>
<!-- importer interview.vue -->
    <interview
      :room="room"
      :profile="profile"
      :conciliation="conciliation"
    ></interview>
  </section>
</template>
<script>
import Interview from '/chat/interview'
import Candidate from '/user/candidate/candidate.entity'
import Conciliation from '/interview/interview.js'
import Room from 'minou/src/room'

export default {
  name: 'hippolyte.conciliator.profile.chatLight',
  components: {
    Interview
  },
  props: {
    show: Boolean,
    openable: Boolean,
    profile: Candidate,
    room: Room,
    conciliation: Conciliation,
    connected: {
      default () {
        return this.$connected
      }
    }
  },
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'

section
  div.action-items
    display flex
    justify-content space-between
    padding 0 10px
    button
      background-color transparent
      &.room-item_reduce
        font-size 25px
  header
    padding 0.2em 1.2em
  section
    padding-bottom 3.2em
</style>
