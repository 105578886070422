<template>
  <section class="chat-menu">
    <button class="close-menu" @click="$emit('close')">&#10005;</button>
    <article class="requirement-list">
      <header>
        <b>Reporting</b>
      </header>
      <div class="search">
        <input type="text" v-model="query" class="recruiter-search-input"/>
      </div>
      <form>
      <div class="checkbox-container" title="Recherches actives uniquement">
        <input type="checkbox" v-model="activeSearchOnly" :id="'activeSearchOnly'" />
        <label :for="'activeSearchOnly'"></label>
      </div>
      </form>
      <spinner v-if="loading.recruiters" style="transform: scale(0.2, 0.2);"></spinner>
      <div class="requirement-list_container">
        <ul>
          <template v-for="recruiter in recruiters">
            <li :key="recruiter.id" :class="{isActive: selectedRecruiter === recruiter}">
              <div  class="recruiter-item">
                <div @click="toggleRecruiter(recruiter)" class="recruiter-name" :class="{'recruiter-name': true, selectedRecruiter: recruiter === selectedRecruiter}">
                  <button @click.stop="queryRecruiter(recruiter)">
                    <icon-eye :title="'voir les statistiques recruteur'"></icon-eye>
                  </button>
                  <h4 class="recruiter-name-content">{{ recruiter.company }}</h4>
                </div>
              </div>
              <div v-if="selectedRecruiter === recruiter" :class="{isActive: selectedRecruiter === recruiter}" class="requirement-infos">
                <template v-for="search in selectedRecruiter.searches.docs">
                  <div :class="{'requirement-item_container': true, selected: (selectedSearch && search.id === selectedSearch.id)}" @click="toggle(search)">
                    <template>
                      <div class="requirement-item_wrapper">
                        <div class="requirement-item_infos">
                          <div>
                            <div class="requirement-item_trade">{{ search.trade.name }}<i v-if="search.title"> {{search.title}}</i></div>
                            <div class="requirement-item_created_at">{{ search.createdAt | format('dd MMMM yyyy') }}</div>
                            <div class="requirement-item_city">{{ search.location.city }}</div>
                          </div>
                        </div>
                        <div v-if="search.active">✓</div>
                      </div>
                    </template>
                  </div>
                </template>
              </div>
            </li>
          </template>
        </ul>
      </div>
      <footer class="paginate">
        <paginate :count="count" v-model="offset" :limit="limit"></paginate>
      </footer>
    </article>
  </section>
</template>
<script>
import RequirementItem from '/menu/requirement'
import Search from '/requirement/entity.js'
import iconEye from '/icons/eye'
import Paginate from '/layout/paginate'
import Spinner from '/layout/spinner'
import cancel from '/cancel.mixin.js'

export default {
  name: 'hippolyte.conciliator.menu.reporting',
  mixins: [cancel],
  props: {
    q: String,
    o: [Number, String],
    a: Boolean,
    search: Search,
    recruiter: Recruiter,
    graph: String
  },
  data () {
    return {
      searches: [],
      recruiters: [],
      query: this.q || '',
      activeSearchOnly: this.a || true,
      selectedSearch: this.search,
      selectedRecruiter: this.recruiter,
      displayed: null,
      count: 0,
      offset: this.o || 0,
      limit: 20,
      loading: {
        recruiters: null
      }
    }
  },
  components: {
    RequirementItem,
    iconEye,
    Paginate,
    Spinner
  },
  mounted () {
    this.load()
  },
  watch: {
    search () {
    this.selectedSearch = this.search
    },
    query () {
      const was = this.offset
      this.offset = 0
      this.count = 0
      if (was === 0) {
        this.load()
      }
      this.$router.push(this.queryParams())
    },
    offset () {
      this.$router.push(this.queryParams())
      this.load()
    },
    activeSearchOnly () {
      const was = this.offset
      this.offset = 0
      this.count = 0
      if (was === 0) {
        this.load()
      }
      this.$router.push(this.queryParams())
    }
  },
  methods: {
    queryParams () {
      return {
        query: {
          o: this.offset,
          q: this.query,
          a: this.activeSearchOnly
        },
        params: {
          graph: this.graph
        }
      }
    },
    async load () {
      try {
        this.cancel('recruiters')
        this.recruiters.splice(0, this.recruiters.length)
        let opts = {
          limit: this.limit,
          offset: this.offset,
          solr: true
        }
        if (this.query) {
          opts.company = this.query
        }
        if (this.activeSearchOnly) {
          opts.active = true
        }
        this.loading.recruiters = true
        const list = await Recruiter.search(opts, this.$socket, this.token('recruiters'))
        this.recruiters.splice(0, this.recruiters.length, ...list.docs.map(data => new Recruiter(data, this.$socket)))
        this.count = list.numFound
        this.loading.recruiters = false
        this.cancel('recruiters', null)
      } catch (err) {
        this.handleCancel(err)
      }
    },
    queryRecruiter (recruiter) {
      this.toggleRecruiter(recruiter)
      if (this.displayed === recruiter.id) {
        this.displayed = null
        this.$router.push(Object.assign(
          this.queryParams(),
          { name: this.graph }
        ))
      } else {
        this.displayed = recruiter.id
        this.selectedRecruiter = recruiter
        this.$router.push(Object.assign(
          this.queryParams(),
          {
            name: `recruiter_${this.graph}`,
            params: {
              recruiter: recruiter.id,
              graph: this.graph
            }
          }
        ))
      }
    },
    async toggleRecruiter (recruiter) {
      this.selectedSearch = null
      if (this.selectedRecruiter === recruiter) {
        this.selectedRecruiter = null
      } else {
        this.selectedRecruiter = recruiter
      }
    },
    toggle (search) {
      if (this.search && this.search.id === search.id) {
        this.selectedSearch = null
        this.$router.push(Object.assign(
          this.queryParams(),
          {
            name: `recruiter_${this.graph}`,
            params: { recruiter: this.search.recruiter.id }
          }
        ))
      } else {
        this.$router.push(Object.assign(
          this.queryParams(),
           {
              name: `search_${this.graph}`,
              params: {
                search: search.id,
                graph: this.graph
              }
           }
        ))
      }
    }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
$text = $color-white

.chat-menu {
  padding-top 1em
  position relative
  .close-menu {
    color #73c2bd
  }
  #floatingBarsG-container {
    height 50px
  }
  .requirement-list {
    flex-grow: 1;
    height: 100%;
    margin-bottom: 1em;
    overflow-y: auto;
    header {
      font-size: 1em;
      margin-left: 1.5rem;
      &:after {
        margin-top: 0.4em;
        content: "";
        display: block;
        width: 20px;
        border: 2px #163ee2 solid;
        border-radius: 4px;
      }
      button {
        float right
        margin 0 1.2em
        padding 0
        background none
        border none
        img {
          width 1.2em
        }
        cursor pointer
      }
    }
    .checkbox-container {
      switch($color-green, white)
      float right
      margin 0.4em
    }
    .search {
      position relative
      input {
        margin-top 1em
        width -webkit-fill-available
        padding-left 0.5em
        height 2em
        background alpha(#d5e4ef, 0.5) right 50% / 13% no-repeat url('/assets/icon/search.svg')
        color #404042
        font-size 1.2em
      }
    }
    .requirement-list_container {
      margin-top: 0.5em;
      h4 {
        margin 0
        &:hover {
          cursor pointer
        }
      }

      ul {
        list-style none
        padding-left 0

        li:nth-child(even) {
          background alpha(#b8e0dd, 0.5)
        }

        li.isActive {
          box-sizing border-box
          background #b8e0dd
        }
      }

      .recruiter-item {
        display flex
        align-items center
        padding-left 0.2em
        color #404042
        position relative
        border-right 3px solid transparent
        box-sizing border-box

        button {
          margin-right 0.5em
          background-color transparent
          svg {
            &:hover{
              fill $color-iceberg
              cursor pointer
            }
          }
        }

        &:hover {
          border-right 3px solid #6b41b9
          background alpha(#000, 0.2)
        }

        .recruiter-counter {
          font-size 0.7em
          font-weight bold
          color $color-white
          right 0.2em
          width 1.6em
          height 1.6em
          display flex
          align-items center
          justify-content center
          padding 0.2em
          border-radius 50%
          background-color #45ADA7
        }
      }

      .recruiter-name {
        display flex
        align-items center
        text-align left
        padding 0.4em 0
        margin 0.2em 0.5em 0 0.5em
        width 75%

        &-content {
          margin 0 0.2em 0 0
          font-size 0.9em
        }
      }

      .requirement-infos {
        color #404042
      }

      .edit-search-btn {
        height 2em
        width 2.8em
        color white
        fill black
        display flex
        align-items center
        justify-content center
        padding 0.2em
        margin-right 0.4em
        background none
        &:hover {
          cursor pointer
        }

        &:hover svg {
          fill #6b41b9
          transform scale(1.1)
        }
      }

      .requirement-item_container {
        position relative
        color $color-menu_text
        color black
        padding-right: 0;
        border-right 3px solid transparent
        &:hover {
          background alpha(#000, 0.3)
          border-right 3px solid #6b41b9
          cursor pointer
        }
        &.selected {
          background alpha(#73c2bd, 0.5)
          border-right 3px solid #6b41b9

          &:hover {
            background alpha(#000, 0.3)
            }
        }
        .requirement-item_wrapper {
          margin-left 0.5rem
          padding 0.5em 0
          display flex
          flex-direction row
          align-items center

          .requirement-item_infos {
            width 80%
            color #404042

            .requirement-item_trade {
              font-size: 14px;
            }

            .requirement-item_created_at,
            .requirement-item_city {
              font-weight: bold;
              font-size: 12px;
            }
          }

          .requirement-item_active {
            width 20%
          }
        }
      }
    }
  }
}
</style>
