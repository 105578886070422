<template>
  <div class="app-header">
    <div class="header-hamburger" @click="toggle">
      <span class="bar"></span>
      <span class="bar"></span>
      <span class="bar"></span>
    </div>
    <div class="menu-notif" v-if="mobileNotif">{{ mobileNotif }}</div>
    <profile
      :key="profile && profile.id"
      :profile="profile"
      :interview="interview"
      @open="Object.assign($show, { interview: false, profile: true })"
      >
    </profile>
  </div>
</template>
<script>
import Interview from '/interview/interview.js'
import Profile from '/profile/header.light'
export default {
  name: 'hippolyte.menu.header',
  components: {
    Interview, Profile
  },
  methods: {
    toggle () {
      Object.assign(this.$show, { menu: true, interview: false, profile: false })
    }
  },
  props: {
    interview: Interview,
    profile: Object
  },
  data () {
    this.$chat?.on('unread:message', ({ from, to }) => {
      this.mobileNotif = to
    })
    return {
      mobileNotif: this.$chat?.unread
    }
  }
}
</script>
<style lang="stylus">
@require '~/colors.styl'
.app-header
  display none
  &.show
    display flex
    flex-direction row
  @media (min-width $breakpoint)
    display none !important
  height 60px
  background $color-white
  .header-hamburger
    width 45px
    margin-left 10px
    margin-top 10px
    cursor pointer
    span
      background $color-yellow
      width 70%
      height 2px
      display block
      margin 20% auto
  img
    max-height 75%
    margin auto
    display block
  .menu-notif
    position absolute
    width 20px
    height 20px
    background $color-pink
    left 45px
    top 5px
    border-radius 50px
    color $color-white
    font-size 12px
    text-align center
    line-height 20px
</style>
