<template>
  <ul>
    <li title="Filtrer par nom de recruteur.">
      <input type="text" v-model="match" placeholder="company"/>
    </li>
    <li>
      <label :title="hotOnly ? 'à traiter seulement' : 'tous'">
        <input type="checkbox" v-model="hotOnly"/>Candidats à traiter
      </label>
    </li>
    <li>
      <label :title="active ? 'Search actives' : 'Search inactives'">
        <input type="checkbox" v-model="active"/>Searches actives
      </label>
    </li>
    <li>
      <label :title="closed ? 'Campagnes inactives seulement' : 'Campagne actives'">
        <input type="checkbox" v-model="closed"/>Campagnes fermées
      </label>
    </li>
    <li>
      <label :title="archived ? 'Recruteurs archivés seulement' : 'Recruteurs non archivés'">
        <input type="checkbox" v-model="archived"/>Recruteurs archivés
      </label>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'hippolyte.conciliator.menusearchfilter',
  props: {
    value: Object
  },
  watch: {
    match (match) {
      this.$emit('input', Object.assign({}, this.value, { match }))
    },
    hotOnly (hotOnly) {
      this.$emit('input', Object.assign({}, this.value,  { hotOnly }))
    },
    closed (closed) {
      this.$emit('input', Object.assign({}, this.value, { closed }))
    },
    active (active) {
      this.$emit('input', Object.assign({}, this.value, { active }))
    },
    archived (archived) {
      this.$emit('input', Object.assign({}, this.value, { archived }))
    }
  },
  data () {
    return { ...this.value }
  }
}
</script>
<style lang="stylus" scoped>
@require '~/colors.styl'
$text = $color-white

.match {
  ul {
    list-style none
    padding-left 0
    display flex
    flex-direction column
    justify-content center
  }
  input[type="text"] {
    width -webkit-fill-available
    padding-left 0.5em
    height 2em
    background alpha(#d5e4ef, 0.5) right 50% / 13% no-repeat url('/assets/icon/search.svg')
    color #404042
  }
  label {
    padding-left 0.5em
    height 1em
    background none
    color #404042
    font-size 1em
    input {
      height 1em
      width auto
    }
  }
  .active-switch {
    switch($color-yellow, $color-white)
    position absolute
    bottom -20px
    right 4px
  }
  div {
    width -webkit-fill-available
  }
}
</style>
