export class Join {
  constructor (opts = {}) {
    Object.assign(this, {
      entity: '',
      name: '',
      query: and(),
      fromField: '',
      toField: '',
      raw: false,
      joins: []
    }, opts)
  }

  send () {
    const acc = {
      entity: this.entity
    }
    if (!isEmpty(this.query)) {
      acc.query = this.query.toString()
    }
    if (this.name.length) {
      acc.name = this.name
    }
    if (this.joins.length) {
      acc.joins = this.joins.map(j => j.send())
    }
    if (this.fromField.length) {
      acc.fromField = this.fromField
    }
    if (this.toField.length) {
      acc.toField = this.toField
    }
    if (typeof this.limit === 'number') {
      acc.limit = this.limit
    }
    if (Array.isArray(this.fields) && this.fields.length) {
      acc.fields = this.fields
    }
    return acc
  }

  from (field) {
    this.fromField = field
    return this
  }

  to (field) {
    this.toField = field
    return this
  }
  join(...opts) {
    const res = opts.map(j => {
      const join = (j instanceof Join) ? j : new Join(j)
      this.joins.push(join)
      return join
    })
    return res.length > 1 ? res : res[0]
  }
}

export class Relation extends Join {
  constructor (opts = {}) {
    super(opts)
    Object.assign(this, {
      relations: []
    }, opts)
  }

  relation (...opts) {
    const res = opts.map(r => {
      const relation = (r instanceof Relation) ? r : new Relation(r)
      this.relations.push(relation)
      return relation
    })
    return res.length > 1 ? res : res[0]
  }

  send () {
    const acc = super.send()

    if (this.relations.length) {
      acc.relations = this.relations.map(r => r.send())
    }
    if (typeof this.limit === 'number') {
      acc.limit = this.limit
    }
    if (typeof this.offset === 'number') {
      acc.offset = this.offset
    }
    return acc
  }

  limits (to, offset) {
    this.limit = to
    if (typeof offset === 'number') {
      this.offset = offset
    }
  }
}

export class Solr extends Relation {
  constructor (opts = {}) {
    super(Object.assign({}, {
      type: 'basic',
      raw: false,
      groups: [],
      sort: [],
      parameters: []
    }, opts))
  }

  group (...name) {
    this.groups.push(...name)
    return name
  }

  send () {
    return Object.assign(
      super.send(),
      { type: this.type, raw: this.raw },
      this.groups.length ? { groups: this.groups.map(name => ({ field: name })) } : {},
      this.sort.length ? { sorts: this.sort } : {},
      { parameters: this.parameters }
    )
  }

  sorts (field, direction = 'asc') {
    this.sort.push({ field, direction })
  }

  parameter (name, value) {
    this.parameters.push({ name, value: `"${typeof value === 'undefined' ? name : value}"` })
  }
}

function isEmpty (c) {
  if (typeof c === 'undefined' || c === null) {
    return true
  }
  if (Array.isArray(c)) {
    return c.length === 0 || c.every(isEmpty)
  }
  if (c instanceof Cond) {
    return isEmpty(c.conds)
  }
  if (typeof c === 'string') {
    return c.length === 0
  }
  if (typeof c === 'number') {
    return false
  }
  if (typeof c === 'object') {
    return isEmpty(c.id)
  }
}

class Cond {
  constructor (...conds) {
    this.conds = conds
  }

  isEmpty () {
    return isEmpty(this)
  }

  toString () {
    return this.conds
      .filter(c => !isEmpty(c))
      .map(c => (c instanceof Cond && this.conds.length > 1) ? `(${c.toString()})` : c)
  }

  push (...conds) {
    this.conds.push(...conds)
    return this
  }
}

class And extends Cond {
  toString () {
    return this.isEmpty() ? null : super.toString().join(' AND ')
  }
}

class OR extends Cond {
  toString () {
    return this.isEmpty() ? null : super.toString().join(' OR ')
  }
}

export function and (...props) {
  return new And(...props)
}

export function or (...props) {
  return new OR(...props)
}

export function eq (...args) {
  switch (args.length) {
    case 0:
      return eq
    case 1:
      return b => eq(args[0], b)
    case 2:
      return `${args[0]}:"${escape(args[1])}"`
  }
}

export function search (opts, ...args) {
  switch (args.length) {
    case 0:
      return search
    case 1:
      return b => search(args[0], b)
    case 2:
      if (opts.insensitive) {
        return and(...args[1].split(' ')
          .filter(a => a !== null && a.length > 0)
          .map(a => {
            if (a.length > 0) {
              let newString = a.split('')
                .map(a => `(${a.toLowerCase()}|${a.toUpperCase()})`)
                .join('')
              return `${args[0]}:/.*${escape(newString)}.*/`
            }

            return `${args[0]}:/.*${escape(a)}.*/`
          })
        )
      } else {
        return and(...args[1].split(' ')
        .filter(a => a !== null && a.length > 0)
        .map(a => `${args[0]}:/.*${escape(a)}.*/`)
        )
      }
  }
}

function escape(token) {
  return token.replace(/(\/[!*+&|()[]{}^~?:"])/g, '\\$1')
}
