export default function tagToText (snippet, { candidate, search, recruiter }) {
  switch (snippet.entity) {
    case 'Candidat':
      if (candidate) {
        return candidate[snippet.field]
      }
      break
    case 'Recruiter':
      if (recruiter) {
        return recruiter[snippet.field]
      }
      break
    case 'Search':
      if (search) {
        if (snippet.field === 'trade' || snippet.field === 'location') {
          return search[snippet.field].name
        }
        return search[snippet.field]
      }
      break
  }
  return snippet.value
}
