<template>
  <svg v-bind="$props" viewBox="0 0 109.68 109.67" >
    <path fill="#1D9BF0" d="M109.68,54.84A54.84,54.84,0,1,1,54.84,0a54.83,54.83,0,0,1,54.84,54.84"/><path fill="#ffffff" d="M85.14,36.05a24.47,24.47,0,0,1-6,1.72c1-.16,2.4-1.92,3-2.63a11.12,11.12,0,0,0,2-3.66c.05-.11.09-.24,0-.32a.33.33,0,0,0-.32,0,31,31,0,0,1-7.18,2.74.48.48,0,0,1-.49-.13,4.78,4.78,0,0,0-.63-.64A12.52,12.52,0,0,0,72,31a12.14,12.14,0,0,0-5.33-.86,12.75,12.75,0,0,0-5,1.43,13,13,0,0,0-4.08,3.33,12.61,12.61,0,0,0-2.44,4.88A13.13,13.13,0,0,0,55,45c0,.28,0,.32-.25.28-9.85-1.46-18-5-24.64-12.54-.29-.32-.45-.32-.69,0-2.89,4.34-1.49,11.33,2.14,14.76.48.46,1,.92,1.51,1.34a12.93,12.93,0,0,1-4.76-1.34c-.28-.18-.43-.07-.45.25a6.83,6.83,0,0,0,.08,1.41,12.61,12.61,0,0,0,7.79,10.08,7.13,7.13,0,0,0,1.57.48,13.89,13.89,0,0,1-4.66.14c-.34-.06-.47.11-.34.43,2,5.6,6.48,7.26,9.8,8.21.44.08.89.08,1.33.18,0,0-.05,0-.08.08-1.09,1.68-4.91,2.92-6.69,3.55a23.89,23.89,0,0,1-10.16,1.3c-.55-.08-.66-.08-.81,0s0,.22.16.36c.69.46,1.4.86,2.11,1.26a33.27,33.27,0,0,0,6.8,2.71c12.21,3.38,26,.9,35.13-8.21,7.19-7.15,9.72-17,9.72-26.89,0-.39.46-.6.73-.81a23.73,23.73,0,0,0,4.78-5,1.51,1.51,0,0,0,.31-.95v-.05c0-.16,0-.11-.24,0"/>
  </svg>
</template>

<script>
import icon from '/icons/mixin'

export default {
  name: 'hippolyte.icon.social.twitter',
  mixins: [icon('15px')]
}
</script>
