<template>
  <section class="interview-container" :key="room.id" v-if="profile && room">
    <interview :room="room" :interview="conciliation">
      <template #message="{ message, isReceiver }">
        <div :key="'time_'+message.id" :class="{ 'message-group': true, 'message-group_self': isReceiver(message) }">
          <avatar v-if="!isReceiver(message)"
            :anonymous="profile.hideAvatar"
            class="interview_avatar"
            :key="'avatar:' + message.id"
            :src="profile.avatar || profile.custom.avatar"
            :prefix="$config.thumbUrl"
            size="tiny">
          </avatar>
          <div class="message-group_body">
            <message :key="'message:' + message.id" :model="message" :isReceiver="isReceiver"></message>
            <message-time :message="message" :isReceiver="isReceiver" :date="isFirstOfDay(message)"/>
          </div>
        </div>
      </template>
      <template #prompt="{ room }">
        <prompt :room="room" :placeholder="'Écrivez votre message'" :disabled="!connected.state" :candidate="profile" :conciliation="conciliation"></prompt>
      </template>
    </interview>
  </section>
</template>
<script>
import Prompt from '/chat/prompt'
import Message from 'chat-ui-hippolyte/src/message'
import Room from 'minou/src/room'
import InterviewVue from '/interview/interview.vue'
import MessageTime from '/interview/time'
import Avatar from '/user/avatar'
import isSameDay from 'date-fns/isSameDay'
import Candidate from '/user/candidate/candidate.entity'
import Conciliation from '/interview/interview.js'

export default {
  name: 'hippolyte.conciliator.interview',
  components: {
    Interview: InterviewVue, Message, MessageTime, Prompt, Avatar
  },
  props: {
    room: Room,
    profile: Candidate,
    conciliation: Conciliation,
    connected: {
      default () {
        return this.$connected
      }
    }
  },
  methods: {
    isFirstOfDay (message) {
      const at = this.room.messages.indexOf(message)
      return at === 0 || !isSameDay(message.date, this.room.messages[at - 1].date)
    }
  }
}
</script>
<style lang="stylus">
@require '~/colors.styl'

.interview-container
    min-height 0
    height 100% !important
    .show display flex
    .interview-open-container
        padding-top 20%
        .interview-open
            margin: auto
            text-align center
        p
            color $color-blue
        button
            color $color-white
            background-color $color-blue
            border 0
            padding 0.6em 1em
            border-radius 3px
            font-size 1.2em
            font-weight bold
            font-weight bold
            &:hover
                cursor pointer
    .messages_list
        margin-right 0
        padding-right 5px
    .message-group
        width 100%
        display flex
        flex-direction row
        justify-content flex-start
        &.message-group_self
            justify-content flex-end
            flex-direction row-reverse
        .interview_avatar
            margin-right 0.4em
        .message-group_body
            width 100%
            display flex
            flex-direction column
        .message_container:not(.self)
            display inline-block
        .message_container > .message
            border-radius 5px 5px 5px 0
            box-shadow none
            display inline-block
            margin-bottom 0 !important
        .message_container.self > .message
            border-radius 5px 5px 0px 5px
            box-shadow none
@media (min-width $breakpoint)
    .interview-container
        .messages_list
            margin-right -17px
        form
            width: 97% !important
            max-width: 97% !important;
            input
                bottom 46% !important
            button[type=submit]
                right 8px !important
        .message_container:not(.self)
            max-width calc(97% - 42px - 1.6em) !important
            margin-left unset !important
            overflow hidden
            height auto
            padding-right 10px
</style>
