<template>
  <layout class="login-container">
    <template v-slot:panel>
      <header>
        <img src="/assets/logo_white.png"/>
      </header>
    </template>
    <template v-slot:form>
      <div>
        <form v-if="state === 'welcome'" @submit.prevent="requestLogin" class="hippo-form">
          <h3 v-if="state === 'welcome'">Saisissez votre adresse mail</h3>
          <div v-if="error">{{ error }}</div>
          <p class="hippo-form_layout">
            <label><img src="/assets/icon/send_yellow.png"></label>
            <input placeholder="Email" v-model="email" type="email"/>
          </p>
          <p class="login_signin">
            <router-link :to="{ name: 'register' }">S'inscrire</router-link>
            <button type="submit">Se connecter</button>
          <p/>
        </form>
        <div class="loader" v-else-if="state === 'loading'">
          <loader color="#114b5F"></loader>
        </div>
        <form v-else-if="state === 'sent'" @submit.prevent="doLogin" class="hippo-form">
          <p>Un lien unique vous à été transmis par email pour finaliser votre connexion.</p>
          <p class="hippo-form_actions">
            <button type="submit">Corriger mon Email</a>
          </p>
        </form>
      </div>
    </template>
  <layout>
</template>

<script>
import Loader from 'vue-spinner/src/BounceLoader.vue'
import pick from 'ramda/src/pick'
import Layout from '/layout/session'

export default {
  name: 'login',
  components: { Loader, Layout },
  data () {
    return {
      state: 'welcome',
      id: null,
      chatUser: this.$auth.user && this.$auth.user.id,
      email: '',
      session: null,
      error: null
    }
  },
  async mounted () {
    try {
      this.session = await this.$socket.getSession()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    doLogin () {
      this.state = 'welcome'
    },
    async logout () {
      await this.$socket.close()
      this.$auth.logout()
      document.location.reload()
    },
    async requestLogin () {
      this.state = 'welcome'
      this.error = null
      if (!this.email) {
        return
      }
      this.state = 'loading'
      this.$socket.service('auth/REQUEST', { email: this.email })
        .then(() => {
          this.state = 'sent'
        },
          () => {
            this.error = 'Cet email est incorrect'
            this.state = 'welcome'
          }
        )
    }
  }
}
</script>
<style lang="stylus" scoped>
@require "~/colors.styl"
.login-container
  .layout-session_form
    h3
      text-align center
    .loader
      margin-left 50%
    form
      margin 0
      padding 0
      color $color-grey
      font-size 0.8em
      margin-left auto
      margin-right auto
      @media (min-width $breakpoint)
        width 50%
        font-size 1em
      p
        &.login_signin
          display flex
          flex-direction row
          align-items flex-end
          justify-content space-between
          margin-top 2em
          a
            color $color-blue
            text-decoration-line none
      h3
        text-align left
p.hippo-form_layout
  display flex
  width 100%
  align-items flex-end
  > :first-child
    min-width 50px
    display flex
    align-items flex-end
    img
      max-height 35px
      max-width 35px
</style>
